<template>
    <div
        v-if="rerenderComponent"    
        v-click-outside="stopMultiselect"
        style="position: relative"
    >
        <div class="widgetAlign">
            <!-- Use this container as drop target if allowed -->
            <div
                id="fileWidgetContainer"
                :class="babyView ? 'widgetContainerBaby' : ''"
                class="widgetContainer"
                style="position: relative; background-color: white"
                :style="useWidth ? 'max-width: 100%' : ''"
                @dragover="dragOver"
                @dragleave="onDragLeave"
                @dragenter="designateDropZone"
                @drop="dropFile"
                @dragover.prevent
                @drop.prevent
            >
                <div
                    v-if="dropUiActive"
                    class="dropUiContainer"
                >
                    <div class="dropUi">
                        <p style="color: rgb(var(--v-theme-dunkelgrau)); font-size: 25px">
                            Dateien hier loslassen!
                        </p>
                        <img
                            :src="plusIcon"
                            class="iconToDunkelgrau pr-1"
                            style="height: 75px"
                        >
                    </div>
                </div>
                <ClickableBadge
                    :event-list="badgeEventList"
                    :delete-button="widgetMode !== 'files'"
                    @badge-clicked="widgetBadgeClicked"
                    @clear-badges="clearBadges"
                />
                <!-- Header of Dateien -->
                <div class="header">
                    <div
                        style="max-width: 100%; height: 56px; max-height: 56px"
                        class="mx-4 d-flex justify-space-between align-center"
                    >
                        <div
                            class="pa-0 d-flex align-center"
                            style="width: 100%"
                        >
                            <v-btn
                                v-if="folderDepth > 0 && widgetMode === 'files'"
                                aria-label="Zurück"
                                tabindex="0"
                                class="optionBtn mr-1"
                                size="x-small"
                                variant="text"
                                color="rgba(248, 248, 248, 1.0)"
                                @click="handleBackButton"
                            >
                                <img
                                    :src="leftIcon"
                                    class="icon iconToWhite"
                                    alt="Zurück"
                                >
                            </v-btn>

                            <v-btn
                                v-else
                                size="small"
                                variant="text"
                                :disabled="accountRole === 'teacher'"
                                :aria-hidden="accountRole === 'teacher'"
                                class="optionBtn d-flex justify-center align-center"
                                style="cursor: pointer;"
                                alt="Wechseln zwischen Dateien und Aufgaben"
                                @click="toggleFilesOrHomework"
                            >
                                <img
                                    :src="headerIcon"
                                    class="iconToWhite"
                                    :style="this.widgetMode === 'files' ? 'height: 25px' : 'height: 30px'"
                                    alt="Dateien"
                                >
                            </v-btn>

                            <div
                                v-if="!multiselectActive"
                                style="display: flex; align-items: center; width: 100%"
                            >
                                <!-- Accessibility -->
                                <h1 class="visually-hidden">
                                    Dateien
                                </h1>
                                <!--
                                    For some reason the clearable prop is not properly working on the textfield.
                                    It sets the string to null instead of '' and the custom callback with @click:clear gets overridden -.-
                                    -->
                                <v-text-field
                                    v-model="searchString"
                                    variant="underlined"
                                    hide-details
                                    density="compact"
                                    :append-icon="searchString.trim().length > 0 ? 'mdi-close' : ''"
                                    :label="widgetMode === 'files' ? 'Dateien suchen...' : 'Aufgaben suchen...'"
                                    style="flex-grow: 1;"
                                    theme="dark"
                                    @focus="focusedSearch = true"
                                    @blur="focusedSearch = false"
                                    @update:model-value="executeSearch"
                                    @click:append="cancelSearch"  
                                />
                            </div>
                        </div>

                        <div
                            v-if="!multiselectActive"
                            class="pa-0 d-flex align-center justify-end"
                            style="width: 20%"
                        >
                            <FileSystemPlusMenu
                                v-if="widgetMode === 'files' && !currentFolder.rulesetAppointment && !currentFolder.rulesetTask"
                                :folder-type="'groupFolder'"
                                :item="currentFolder"
                                :start-folder-creation="handleNewFolder"
                                :upload-local-file-function="handleUploadButtonClick"
                                :is-storage-full="isStorageUsed"
                                role="button"
                                alt="Plus-Menü"
                                @create-learning-path="handleLearningPathDialog"
                            />
                        </div>
                        <div
                            v-if="multiselectActive"
                        >
                            <v-menu
                                :offset="[0,10]"
                                location="left"
                                style="z-index: 12"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        size="x-small"
                                        variant="text"
                                        color="white"
                                        class="ml-2 pa-0 optionBtn"
                                        style="width: 20px; height: 20px; float: right"
                                        v-bind="props"
                                    >
                                        <img
                                            :src="bearbeitenIcon"
                                            class="icon20 iconToWhite"
                                            alt=""
                                        >
                                    </v-btn>
                                </template>
                                <div
                                    class="d-flex justify-center flex-column"
                                    style="background-color: white; width: 200px; height: 200px"
                                >
                                    <v-btn
                                        elevation="0"
                                        block
                                        class="pa-0"
                                        style="text-transform: inherit; height: 50px"
                                        @click="workOnMultiselectFiles('copy')"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            style="width: 100%"
                                        >
                                            <v-icon
                                                class="mx-2"
                                                style="filter: brightness(0); font-size: 17px;"
                                            >
                                                mdi-file-move-outline
                                            </v-icon>
                                            <p class="mr-auto">
                                                Kopieren
                                            </p>
                                        </div>
                                    </v-btn>
                                    <v-btn
                                        elevation="0"
                                        block
                                        class="pa-0"
                                        style="text-transform: inherit; height: 50px"
                                        @click="workOnMultiselectFiles('move')"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            style="width: 100%"
                                        >
                                            <img
                                                class="mx-2"
                                                :src="penIcon"
                                                alt
                                                style="filter: brightness(0); height: 15px;"
                                            >
                                            <p class="mr-auto">
                                                Verschieben
                                            </p>
                                        </div>
                                    </v-btn>
                                    <v-btn
                                        elevation="0"
                                        block
                                        class="pa-0"
                                        style="text-transform: inherit; height: 50px"
                                        @click="deleteMultiselectFiles"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            style="width: 100%"
                                        >
                                            <img
                                                class="mx-2"
                                                :src="papierkorbIcon"
                                                alt
                                                style="filter: brightness(0); height: 15px;"
                                            >
                                            <p
                                                class="mr-auto"
                                                style="color: rgb(var(--v-theme-rot))"
                                            >
                                                Löschen
                                            </p>
                                        </div>
                                    </v-btn>
                                    <v-btn
                                        elevation="0"
                                        block
                                        class="pa-0"
                                        style="text-transform: inherit; height: 50px"
                                        @click="stopMultiselect"
                                    >
                                        <div
                                            class="d-flex align-center"
                                            style="width: 100%"
                                        >
                                            <img
                                                class="mx-2"
                                                :src="abbrechenIcon"
                                                alt
                                                style="filter: brightness(0); height: 15px;"
                                            >
                                            <p class="mr-auto">
                                                Beenden
                                            </p>
                                        </div>
                                    </v-btn>
                                </div>
                            </v-menu>
                        </div>
                    </div>

                    <!-- Search filter bar -->
                    <div
                        v-if="activateInteractiveFilterDisplay"
                        style="max-width: 100%; height: 35px"
                        class="mx-2 mb-1 d-flex justify-start align-start"
                    >
                        <v-badge
                            v-for="(el, index) in displayedFilterList"
                            :key="`displayedFilterFileWidget${el.id}${index}`"
                            :model-value="widgetMode === 'homework' ? el.badges : false"
                            :content="el.badges ? el.badges : 0"
                            color="#ff0000"
                            location="left"
                            style="z-index: 9; width: 100%"
                        >
                            <v-btn
                                elevation="0"
                                class="pb-1 pt-1"
                                :variant="el.id === 'search' ? 'text' : undefined"
                                :theme="el.active ? 'dark':''"
                                style="margin-top: 7px; border-radius: 5px; text-transform: inherit; flex-grow: 1; flex-shrink: 0; flex-basis: auto; height: 26px"
                                :style="el.id === 'todo' ? 'border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-right: 0px !important; width: 100%' :
                                    el.id === 'corrected' ? 'border-top-left-radius: 0px; border-bottom-left-radius: 0px; margin-right: 0px !important; width: 100%' :
                                    el.id === 'done' ? 'border-top-left-radius: 0px; border-bottom-left-radius: 0px; border-top-right-radius: 0px; border-bottom-right-radius: 0px; margin-right: 0px !important; width: 100%' :
                                    el.id === 'search' ? 'min-width: 45%; max-width: 45%; overflow: hidden; margin-right: 5% !important' : ''"
                                :alt="'Filter ' + el.name"
                                @click="() => { toggleFilterIndex(index) }"
                            >
                                <img
                                    v-if="el.id === 'todo' || el.id === 'corrected' || el.id === 'done'"
                                    :id="`displayedFilterFileWidget${index}`"
                                    :src="el.id === 'todo' ? offenIcon : el.id === 'corrected' ? checkIcon : el.id === 'done' ? checkIcon : ''"
                                    class="icon mr-1"
                                    :class="el.active ? 'iconToWhite' : ''"
                                    :style="el.id === 'corrected' ? 'margin-bottom:5px !important' : el.id === 'todo' ? 'margin-bottom:2px !important' : el.id === 'done' ? 'margin-bottom:2px !important' : ''"
                                >
                                <img
                                    v-if="el.id === 'corrected'"
                                    :id="`displayedFilterFileWidget${index}`"
                                    :src="checkIcon"
                                    class="icon mr-1"
                                    :class="el.active ? 'iconToWhite' : ''"
                                    style="margin-bottom: 5px !important; margin-left: -15px"
                                    alt=""
                                >
                                {{ `${el.name}${el.id === 'search' ? `: ${searchString}`:''}` }}
                            </v-btn>
                        </v-badge>
                    </div>
                </div>
                <v-tooltip
                    location="top"

                    z-index="100"
                >
                    <template v-slot:activator="{ props }">
                        <v-progress-linear
                            :model-value="usedStoragePercentage"
                            height="14"
                            :color="usedStorageColor"
                            bg-color="rgb(var(--v-theme-fgrau))"
                            v-bind="props"
                        >
                            <div style="position: absolute !important; right: 0.5rem !important; font-size: small">
                                {{ `Genutzter Speicher ${formatedUsedDiskSpace}/${totalStorage} MB` }}
                            </div>
                        </v-progress-linear>
                    </template>
                    <span>Belegter Speicher: {{ `${formatedUsedDiskSpace}/${totalStorage} MB. ` }}Sollte der Speicherplatz nicht ausreichen, melden Sie sich bitte beim eKlara Team für ein kostenpflichtiges Upgrade</span>
                </v-tooltip>
                <!-- Body of Dateien -->
                <simple-bar
                    :auto-hide="false"
                    style="height: 100% !important; overflow-x: hidden"
                    :style="`max-height: calc(90dvh - ${activateInteractiveFilterDisplay ? 170 : 150}px)`"
                >
                    <keep-alive>
                        <VirtualFileSystemV2
                            v-if="widgetMode === 'files'"
                            ref="groupVFS"
                            :key="vfsKey"
                            class="my-3"
                            folder-type="groupFolder"
                            :folder-depth="folderDepth"
                            :scroll-top="scrollTop"
                            :search-result="searchResultFromBackend"
                            :active-filters="activeFilterList"
                            :submission-to-open="sharingDialogSubmissionToOpen"
                            :trigger-refresh="triggerVFSRefresh"
                            :is-storage-full="isStorageUsed"
                            :sort-by-date="sortByDate"
                            @current-folder="updateCurrentFolder"
                            @mutate-folder-depth="(val) => mutateFolderDepth(val)"
                            @notify-copy-file="handleCopyOrMoveClicked"
                            @notify-move-file="() => {}"
                            @edit-sharing="handleEditSharingClicked"
                            @view-file="(file, params, isAppointmentUpload, appointmentId) => openPrivateAnsicht(file, params, isAppointmentUpload, appointmentId)"
                            @disable-parent-scroll="manipulateParentScroll"
                            @normal-upload="clickGroupUploadButton"
                            @badge-content-changed="setVFSBadges"
                            @mutliselect-started="toggleMultiSelectUi(true)"
                            @mutliselect-stop="toggleMultiSelectUi(false)"
                            @reset-filters="resetFilters"
                            @set-root-v-f-s="(vfs) => rootVfs = vfs"
                            @show-learning-path="showLearningPathUI = true"
                        />
                    </keep-alive>
                    <keep-alive>
                        <HomeworkCategoryContainer
                            v-if="widgetMode === 'homework'"
                            ref="homeworkContainer"
                            class="my-3"
                            style="overflow-x: hidden"
                            :active-filters="activeFilterList"
                            :search-string="searchString"
                            @todo-badges="setTodoBadge"
                            @done-badges="setDoneBadge"
                            @open-learning-path="openingLearningPath"
                            @correction-badge="setCorrectionBadge"
                            @set-total-badges="setHomeworkBadges"
                            @view-file="(file, params, isAppointmentUpload, appointmentId) => openPrivateAnsicht(file, params, isAppointmentUpload, appointmentId)"
                        />
                    </keep-alive>
                </simple-bar>

                <!-- Bottom info bar: Num of Elements, Change Sorting, ToggleView -->
                <div
                    v-if="widgetMode === 'files'"
                    id="bottomInfoBar"
                >
                    <span id="bottomInfoBarElementCount">
                        {{ groupFolderSelection > 1 ? groupFolderSelection + ' Elemente' : groupFolderSelection === 1 ? '1 Element' : 'Keine Elemente' }}
                    </span>
                    <v-spacer />
                    <v-tooltip
                        v-if="vfsGridWidthPct === 100"
                        location="top"
                    >
                        <template v-slot:activator="{ props}">
                            <v-btn
                                class="toolbarButton"
                                color="dunkelgrau"
                                size="small"
                                tile
                                min-width="35px"
                                min-height="35px"
                                max-width="35px"
                                max-height="35px"
                                style="margin-right: 10px; margin-left: 10px"
                                v-bind="props"
                                @click="() => { toggleSortDate() }"
                            >
                                <v-icon
                                    color="white"
                                >
                                    {{ 'mdi-history' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Nach Datum sortieren</span>
                    </v-tooltip>
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props}">
                            <v-btn
                                class="toolbarButton"
                                color="dunkelgrau"
                                elevation="0"
                                size="small"
                                tile
                                min-width="35px"
                                min-height="35px"
                                max-width="35px"
                                max-height="35px"
                                style="margin-right: 10px; margin-left: 10px"
                                v-bind="props"
                                @click="() => { toggleSorting() }"
                            >
                                <v-icon
                                    :aria-label="groupFolderSortDescending ? 'Alphabetisch absteigend' : 'Alphabetisch aufsteigend'"
                                    color="white"
                                >
                                    {{ groupFolderSortDescending ? 'mdi-sort-alphabetical-descending' : 'mdi-sort-alphabetical-ascending' }}
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Alphabetisch {{ groupFolderSortDescending ? 'aufsteigend ': 'absteigend ' }} sortieren</span>
                    </v-tooltip>
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props}">
                            <v-btn
                                class="toolbarButton"
                                color="dunkelgrau"
                                elevation="0"
                                size="small"
                                tile
                                min-width="35px"
                                min-height="35px"
                                max-width="35px"
                                max-height="35px"
                                style="margin-right: 10px; margin-left: 10px"
                                v-bind="props"
                                @click="mutateVfsGridWidthPct()"
                            >
                                <v-icon
                                    v-if="vfsGridWidthPct === 33"
                                    color="white"
                                    aria-label="Kleines Gitter"
                                >
                                    mdi-view-comfy
                                </v-icon>
                                <v-icon
                                    v-else-if="vfsGridWidthPct === 50"
                                    color="white"
                                    aria-label="Großes Gitter"
                                >
                                    mdi-view-grid
                                </v-icon>
                                <v-icon
                                    v-if="vfsGridWidthPct === 100"
                                    color="white"
                                    aria-label="Listen-Ansicht"
                                >
                                    mdi-view-list
                                </v-icon>
                            </v-btn>
                        </template>
                        <span> Ansicht ändern </span>
                    </v-tooltip>
                </div>
            </div>
        </div>

        <SaveToFolderDialog
            v-if="showSaveToUI"
            :file="savingFiles[0] || saveToFile"
            :mode="saveToMode"
            @close="closeUI"
            @copy-file-to-destination="(newFileName, destinationId) => changeFileLocation('copy', newFileName, destinationId)"
            @move-file-to-destination="(newFileName, destinationId) => changeFileLocation('move', newFileName, destinationId)"
        />
        <FolderSharingDialog
            v-if="showSharingUI"
            :item="sharingDialogItem"
            :file-name="sharingDialogFileName"
            :file-id="sharingDialogFileId"
            :interactable="sharingDialogInteractable"
            :is-file="sharingDialogIsFile"
            @open-submissions="handleOpenSubmissions"
            @close="closeUI"
        />
        <LearningPathDialog
            v-if="showLearningPathUI"
            :item="learningPathItem"
            :edit-mode="learningPathEditMode"
            :interactable="sharingLearningPathDialogInteractable"
            @close="closeUI"
        />

        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
    </div>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import * as backend from "../../api/backend";
import VirtualFileSystemV2 from "@/components/FileArchive/VirtualFileSystemV2";
import FileSystemPlusMenu from "@/components/FileArchive/FileSystemPlusMenu";
import SaveToFolderDialog from "@/components/FileArchive/SaveToFolderDialog";
import FolderSharingDialog from "@/components/FileArchive/FolderSharingDialog";
import HomeworkCategoryContainer from "@/components/FileArchive/Homework/HomeworkCategoryContainer";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import EventBus from "../../util/eventBus";

import fileIcon from '@/assets/Icons/file-white.svg'
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import offenIcon from "@/assets/Icons/FaceliftIcons/offen.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/speichern_bestaetigen.svg";
import hochladenIcon from "@/assets/Icons/hochladen-15.svg";
import bearbeitetIcon from '@/assets/Icons/bearbeitet-17.svg'
import abbrechenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import schliessenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import runterladenIcon from '@/assets/Icons/runterladen-14.svg'
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import infoRoundIcon from "@/assets/Icons/info-2-weiß-89.svg";
import leftIcon from "@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg";
import plusIcon from '@/assets/Icons/plus.svg';
import magnifierIcon from '@/assets/Icons/redesignV3/magnifier.svg';
import leeresBlatt from "@/assets/Icons/leeres-blatt-110.svg";
import kariertesBlatt from "@/assets/Icons/kariertes blatt-112.svg";
import liniertesBlatt from "@/assets/Icons/liniertes-blatt-111.svg";
import dateiIcon from "@/assets/Icons/leere-korrektur-78.svg";
import arrowDown from "@/assets/Icons/unten-dropdown-12.svg";
import menuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import penIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import hausaufgabenIconLeer from "@/assets/Icons/FaceliftIcons/hausis_todo.svg";

import bioIcon from '@/assets/Icons/FaceliftIcons/f-biologie-weiss.svg'
import chemieIcon from '@/assets/Icons/FaceliftIcons/f-chemie-weiss.svg'
import deutschIcon from '@/assets/Icons/FaceliftIcons/f-deutsch-weiss.svg'
import englischIcon from '@/assets/Icons/FaceliftIcons/f-englisch-weiss.svg'
import erdkundeIcon from '@/assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg'
import geschichteIcon from '@/assets/Icons/FaceliftIcons/f-geschichte-weiss.svg'
import matheIcon from '@/assets/Icons/FaceliftIcons/f-mathe-weiss.svg'
import musikIcon from '@/assets/Icons/FaceliftIcons/f-musik-weiss.svg'
import kunstIconWeiss from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import physikIcon from '@/assets/Icons/FaceliftIcons/f-physik-weiss.svg'
import politikIcon from '@/assets/Icons/FaceliftIcons/f-politik-weiss.svg'
import religionIcon from '@/assets/Icons/FaceliftIcons/f-religion-weiss.svg'
import sportIcon from '@/assets/Icons/FaceliftIcons/f-sport-weiss.svg'
import wirtschaftIcon from '@/assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg'
import LearningPathDialog from './LearningPathDialog.vue';

import BadgeExplanations from '../Utils/BadgeExplanations.vue';

export default {
    name: "FileWidgetv2",
    components: {
        HomeworkCategoryContainer,
        SaveToFolderDialog,
        FolderSharingDialog,
        FileSystemPlusMenu,
        VirtualFileSystemV2,
        ClickableBadge,
        LearningPathDialog,
        //BadgeExplanations,
    },
    props: {
        showTooltips: { required: false, default: true },
        useWidth: { required: false, type: Boolean, default: false },
        widgetModeHomework: { required: false, type: Boolean, default: false },
        babyView: {required: false, default: false},
        reset: {required: false, default: Boolean},
    },
    data: () => ({
        //#region Icons
        fileIcon,
        bearbeitenIcon,
        runterladenIcon,
        bearbeitetIcon,
        abbrechenIcon,
        offenIcon,
        hochladenIcon,
        hausaufgabenIconLeer,
        papierkorbIcon,
        infoIcon,
        infoRoundIcon,
        schliessenIcon,
        leftIcon,
        plusIcon,
        magnifierIcon,
        arrowDown,
        leeresBlatt,
        kariertesBlatt,
        liniertesBlatt,
        dateiIcon,
        menuIcon,
        penIcon,
        checkIcon,

        bioIcon,
        chemieIcon,
        deutschIcon,
        englischIcon,
        erdkundeIcon,
        geschichteIcon,
        matheIcon,
        musikIcon,
        kunstIconWeiss,
        physikIcon,
        politikIcon,
        religionIcon,
        sportIcon,
        wirtschaftIcon,
        //#endregion
        scrollTop: 0,
        endReached: false,

        folderDepth: 0,
        currentFolder: {},

        searchString: '',
        searchResultFromBackend: {},

        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false
        },

        rootVfs: null,

        switchedFromHomework: false,

        deleteDialog: false,
        elementToDelete: null,
        deleteMethod: '',

        fileBadgeEventList: [],
        homeworkBadgeEventList: [],
        widgetMode: 'files',
        showSaveToUI: false,
        showSharingUI: false,
        sharingDialogItem: {},
        sharingDialogFileName: '',
        sharingDialogFileId: null,
        sharingDialogIsFile: false,
        sharingDialogSubmissionToOpen: {},
        showLearningPathUI: false,
        sharingDialogInteractable: true,
        sharingLearningPathDialogInteractable: true,
        learningPathEditMode: false,
        learningPathItem: null,
        saveToFile: null,
        savingFiles: [],
        saveToMode: 'copy',
        usedDiskSpace: null,
        // a predefined list with filters
        completeFilterList: [
            { id: 'search', name: 'Suchbegriff', active: false, badges: false, filterFunction: () => {}},
            { id: 'todo', name: 'Offen', active: false, badges: false, filterFunction: (el, accountId) => {
                // currently only marks files as true, should be expanded to include folders which have a positive badgevalue
                if (el.seenBy) {
                    if (el.todo || (el.seenBy && !el.seenBy.includes(accountId))) {
                        return true;
                    }
                    return false;
                }
                return false;
            } },
            { id: 'done', name: 'Erledigt', active: false, badges: false, filterFunction: () => {} },
            { id: 'corrected', name: 'Korrigiert', active: false, badges: false, filterFunction: (el) => {
                return el.corrected;
            } },
            // For HausiWidget on lower width
            { id: 'todo', name: '', active: false, badges: false, filterFunction: (el) => {
                if (el.seenByBadge) {
                    if (el.seenByBadge.length > 0 || (typeof el.seenByBadge === 'boolean' && el.seenByBadge && !el.corrected)) {
                        return true;
                    }
                    return false;
                }
                return false;
            } },
            { id: 'done', name: '', active: false, badges: false, filterFunction: () => {} },
            { id: 'corrected', name: '', active: false, badges: false, filterFunction: (el) => {
                return el.corrected;
            } },
        ],
        focusedSearch: false,

        showNameFile: false,
        uploadFileName: "",
        fileNameCallback: () => {
        },
        teacherId: '',
        subjectFiles: [],
        slots: [],

        vfsKey: 0,
        // toggle this bool to refresh currentFolder
        triggerVFSRefresh: false,

        // Quick and dirty by Simon
        // Inital 'path' for the vfs when you click teacherFolder
        vfsInitialFolderIdTree: null,
        // Path to the document registry folder that can be used as a 'vfsInitialFolderIdTree'
        folderIdTreeToDocumentRegistry: null,

        // Drag and drop functionality stuff
        dropZone: '',
        dropUiActive: false,
        multiselectActive: false,

        usedStorage: 0,
        totalStorage: 0,

        rerenderComponent:true,
        sortByDate: false,
    }),
    computed: {
        ...mapState("auth", ["token"]),
        ...mapState('util', ['windowWidth']),
        ...mapState('translation', ['targetLang',]),
        ...mapState('documentRegistry', ['documentRegistryDocuments', 'documentRegistryRootFolder', 'documentRegistryFolders']),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapGetters('util', ['currentUploadGroup']),
        ...mapGetters('vfs', ['groupFolderSelection', 'groupFolderSortDescending', 'vfsGridWidthPct']),

        isStorageUsed() {
            return (this.usedStorage / 1000000) > this.totalStorage;
        },
        formatedUsedDiskSpace() {
            return (this.usedStorage / 1000000).toFixed(2);
        },
        headerIcon() {
            return this.widgetMode === 'files' ? this.fileIcon : this.hausaufgabenIconLeer;
        },
        activateInteractiveFilterDisplay() {
            return this.searchString || this.activeFilterList.length || (this.accountRole === 'pupil' && this.widgetMode === 'homework') || this.focusedSearch;
        },
        activeFilterList() {
            const activeUnsplicedFilterList = this.completeFilterList.filter(item => {
                if (item.id === 'search') {
                    return this.searchString == null;
                }
                return item.active;
            });
            // activeUnsplicedFilterList contains both button entries, one with full name and the shortened one.
            // We need to splice depending on which one is needed with shortenFilterButtonNames()
            if (this.shortenFilterButtonNames) {
                activeUnsplicedFilterList.splice(0, 1);
            } else {
                activeUnsplicedFilterList.splice(1, 1);
            }
            return activeUnsplicedFilterList;
        },
        displayedFilterList() {
            //TODO use proper find and maybe use different defaults per usergroup
            const result = [];
            // For HausiWidget on lower width push entries 4-6 which contain shortened name attributes
            if (this.shortenFilterButtonNames) {
                result.push(this.completeFilterList[4]);
                result.push(this.completeFilterList[5]);
                result.push(this.completeFilterList[6]);
            } else { // Use entries 1-3
                result.push(this.completeFilterList[1]);
                if (this.widgetMode === 'homework') {
                    result.push(this.completeFilterList[2]);
                }
                result.push(this.completeFilterList[3]);
            }
            return result;
        },
        dragDropUploadAllowed() {
            return this.widgetMode === 'files'
                && (this.currentFolder.owner === this.accountId
                    || (this.currentFolder.sharing_Accounts
                        && this.currentFolder.sharing_Accounts.find(el => el === this.accountId)))
        },
        badgeEventList() {
            return this.widgetMode === 'files' ? this.fileBadgeEventList : this.homeworkBadgeEventList;
        },
        shortenFilterButtonNames() { // If windowWidth is too low to fully display all filter button names
            return this.widgetMode && (this.windowWidth < 425 || (!this.babyView && this.windowWidth < 1225 && this.windowWidth > 900));
        },
        usedStorageColor(){
            return (this.usedStoragePercentage >= 0 && this.usedStoragePercentage < 80) ? 'success' : 'error'
        },
        usedStoragePercentage(){
            if (this.totalStorage === 0) {
                return 0;
            }else{
                return ((this.usedStorage / 1000000).toFixed(2) / this.totalStorage) * 100;

            }
        }
    },
    watch:{
        reset(newVal){
            if(newVal && newVal === true ){
                // this.searchString = '';
                // this.currentFolder= {}; 
                // this.folderDepth = 0;
                // this.triggerVFSRefresh = !this.triggerVFSRefresh;
                this.rerenderComponent = false;

                    this.$nextTick(() => {
                    // Add the component back in
                    this.rerenderComponent = true;
                    });
                this.$emit('filesystemRemounted');
             }
        }
    },
    async mounted() {
        const t0 = performance.now();

        EventBus.off('add-new-space');
        EventBus.on('add-new-space', (space) => {
            this.usedStorage += parseInt(space);
        });
        const res = await backend.getUsedDiskSpaceForAccount();
        const diskSpace = await res.json();
        this.usedStorage = diskSpace.usedSpace;
        this.totalStorage = diskSpace.maxSpace;
        if(this.accountRole === "teacher") {
            await this.loadDocumentRegistryDocuments();
            await this.loadDocumentRegistryRootFolder();
            await this.loadDocumentRegistryFolders();
        }

        await this.queryCheck();

        if (this.widgetModeHomework || this.widgetMode == 'homework') {
            this.widgetMode = 'homework';
            this.completeFilterList[1].active = true;
            this.completeFilterList[4].active = true;
        }
        const t1 = performance.now();
         console.debug(`FileSystemMounted in: ${t1 - t0} ms.`);
        this.$emit('filesystemMounted');
    },
    methods: {
        ...mapActions("appointments", ["getAppointments", "patchAppointmentUploadSeenBy", "getTeacherUpload"]),
        ...mapActions("timeslots", ["getSlots"]),
        ...mapActions('documentRegistry', ['loadDocumentRegistryDocuments', 'loadDocumentRegistryRootFolder', 'loadDocumentRegistryFolders']),
        ...mapActions("util", ['toggleOpenAppointment', 'toggleCurrentUploadGroup', 'toggleCurrentUploadFolder']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        ...mapActions('pupils', ['getMePupil']),
        ...mapActions('vfs', ['searchVFS']),
        ...mapActions('files', ['safeNewPrivateFolderFile']),
        ...mapActions('virtualFileSystem', ['getVFSFolderUploadV2', 'postVFSFolderUploadV2', 'updateVFSFolderUploadV2', 'deleteVFSFolderUploadV2', 'markFileSeen']),
        ...mapActions('mimeTypes', ['validateMimeType']),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapMutations('vfs', ['setGroupFolderSortDescending', 'mutateVfsGridWidthPct', 'setVfs', 'triggerReloadOfVfs']),
        ...mapMutations('badges', [ 'setFileWidgetBadge' ]),

        async openingLearningPath(item){
            this.switchedFromHomework = true;
             await this.toggleFilesOrHomework();

             await this.$refs.groupVFS.diveInFolder(item._id,null, false);
        },
        setTodoBadge(amount) {
            this.completeFilterList[1].badges = amount;
            this.completeFilterList[4].badges = amount;
        },

        setDoneBadge(amount) {
            // currently hide done badges to prevent information overload
            this.completeFilterList[2].badges = 0;
            this.completeFilterList[5].badges = 0;
        },

        setCorrectionBadge(amount) {
            this.completeFilterList[3].badges = amount;
            this.completeFilterList[6].badges = amount;
        },

        //#region Query Check for Widget State
        async queryCheck() {
            // Check widget mode
            if (this.$route.query.fwmode
                && (this.$route.query.fwmode === 'files' || this.$route.query.fwmode === 'homework')) {
                this.widgetMode = this.$route.query.fwmode;
            }
        },
        //#endregion Query Check for Widget State

        //#region Multiselect Widget Methods
        toggleMultiSelectUi(wanted) {
            this.multiselectActive = wanted;
        },

        async workOnMultiselectFiles(mode) {
            // pull complete files
            this.savingFiles = await this.$refs.groupVFS.getFilesFromMultiselectIds()

            // open ui
            this.saveToMode = mode;
            this.showSaveToUI = true;
        },
        async deleteMultiselectFiles() {
            // Check if selected files can be deleted
            this.$refs.groupVFS.prepareMultiselectDelete()
        },
        async stopMultiselect() {
            // Give to vfs
            if(this.$refs.groupVFS) {
                this.$refs.groupVFS.stopMultiselect();
            }
        },
        //#endregion Multiselect Widget Methods

        async widgetBadgeClicked(neededEvent) {
            if (this.widgetMode === 'files') {
                // get path
                // call set function in vfs
                // Special version for call from custom badge
                await this.$refs.groupVFS.openFolderFromBadge(neededEvent.folder._id);
                const element = this.$refs.groupVFS.$refs[`filetile${neededEvent.target._id}`][0];
                this.$nextTick(() => {
                    if (element) {
                        element.classList.remove('badgeEventVisualize');
                        element.classList.add('badgeEventVisualize');

                        setTimeout(() => {
                            element.classList.remove('badgeEventVisualize');
                        }, 1050);
                    }
                });
            }
            if (this.widgetMode === 'homework') {
                // scroll to correct element and highlight
                await this.$refs.homeworkContainer.goToUpload(neededEvent);
            }
        },
        setVFSBadges(list) {
            this.fileBadgeEventList = list;
            this.setFileWidgetBadge(list.length);
        },
        setHomeworkBadges(list) {
            this.homeworkBadgeEventList = list;
            this.setFileWidgetBadge(list.length);
        },

        async toggleFilesOrHomework() {
            // Change widgetmode
            if (this.accountRole === 'pupil') {
                this.widgetMode = this.widgetMode === 'files' ? 'homework' : 'files';
                // if no filter is currently active and we swap to homework view -> activate ToDo Filter
                if (this.widgetMode === 'homework' && this.activeFilterList.length === 0) {
                    this.completeFilterList[1].active = true;
                    this.completeFilterList[4].active = true;
                }
                if (this.widgetMode === 'files') {
                    this.completeFilterList.forEach((el) => {
                        el.active = false;
                    });
                }

                // update query on change
                await this.$router.replace({ query: { fwmode: this.widgetMode } })
            }
        },
        toggleFilterIndex(index) {
            this.displayedFilterList[index].active = !this.displayedFilterList[index].active;
            // disable all other filters for better viewing experience
            for (let i = 0; i < this.displayedFilterList.length; i++) {
                if (i != index) {
                    this.displayedFilterList[i].active = false;
                    document.getElementById(`displayedFilterFileWidget${i}`).style.filter = '';
                }
                // incase of hausiwidget: if toggling the currently active filter off (not choosing a new one)
                // ignore the action -> always keep at least one filter active
                else if (this.widgetMode === 'homework' && !this.displayedFilterList[index].active) {
                    this.displayedFilterList[index].active = true;
                }
            }
            if(this.displayedFilterList[index].active) {
                document.getElementById(`displayedFilterFileWidget${index}`).style.filter = 'brightness(1000%)';
            }
        },
        resetFilters() {
            if (this.widgetMode === 'homework') {
                return;
            }
            this.completeFilterList = this.completeFilterList.map((filter) => {
                filter.active = false;
                return filter;
            });
        },
        async executeSearch() {
            if (this.widgetMode === 'files') {
                if (this.searchString.length > 2) {
                    const foundFiles = this.findFilesWithName(this.searchString);
                    this.searchResultFromBackend = await this.searchVFS({
                        folderType: 'groupFolder',
                        query: this.searchString,
                    });
                    foundFiles.forEach((file) => this.searchResultFromBackend.files.push(file));
                }
            } else if (this.widgetMode === 'homework') {
                //
            }
        },
        findFilesWithName(name) {
            const foundFiles = [];
            const findFilesRecursive = (folder) => {
              if (folder.files) {
                  folder.files.forEach((file) => {
                      if (file.appointmentId && file.title.includes(name)) {
                          foundFiles.push(file);
                      }
                  });
              }
              if (folder.folders.length) {
                  folder.folders.forEach((folder) => findFilesRecursive(folder));
              }
            };
            findFilesRecursive(this.rootVfs);
            return foundFiles;
        },
        cancelSearch() {
            this.searchString = '';
            // Call init method on vfs again
            if (this.activeFilterList.length === 0) {
                this.$refs.groupVFS.initRootDir();
            }
        },
        async updateCurrentFolder(vfs, direction) {
            this.currentFolder = vfs;
            this.folderDepth += direction;
        },
        // tracks the current y-offset of the scrollbar to adjust menus in subcomponents (context menu in FileSystemListElement)
        scrollEvent: function (event) {
            this.scrollTop = event.target.scrollTop;
        },
        scrolledToEnd(event) {
            this.endReached = true;
        },
        // when resetting VFS to rootDir, bypass regular depth mutation directly to set value
        mutateFolderDepth(val) {
            if (val >= 0) {
                this.folderDepth = val;
            }
        },
        getSrc(icon) {
            return this[icon];
        },
        fileNotSupported(el) {
            this.showSnackbar({ message: "Dieses Dateiformat kann in eklara nicht bearbeitet werden. Bitte lade die Datei herunter." });
        },
        async clickDownloadTeacherUpload(file) {
            try {
                file.seenByMe = true;
                const res = await this.getTeacherUpload({
                    appointmentId: file.appointment,
                    fileId: file.file
                });
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement('a');
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },
        async requestAppointments() {
            this.appointments = await this.getAppointments();
            this.schoolSubjectGroups = this.groupBy(this.appointments, 'schoolSubject');
        },
        async prepareUploadFromDocumentRegistry(el, url, destination) {
            let blob = await fetch(url).then(res => res.blob());
            let file = new File([blob], el.name, {type: "application/pdf" });
            await this.safeNewPrivateFolderFile({
                data: file,
                fileName: el.name,
            });
            this.showSnackbar({ message: "Die Datei wurde im privaten Ordner hinterlegt." });
        },
        toggleSorting() {
            this.setGroupFolderSortDescending(!this.groupFolderSortDescending);
        },
        toggleSortDate(){
            this.sortByDate = !this.sortByDate;
        },
        openAnsicht(name, id, uploadId, appointmentId, userGroup, fach = null) {
            let group, color;
            if (!fach) {
                group = this.fach[0].schoolSubject.name;
                color = this.fach[0].schoolSubject.color;
            } else {
                group = fach.schoolSubject.name;
                color = fach.schoolSubject.color;
            }
            const fileTitle = name;
            const fileId = id;
            const modes = {
                'teacher': 'creator',
                'pupil': 'viewer',
            }
            this.$router.push({
                name: 'editor',
                params: { mode: modes[this.accountRole] },
                query:
                {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    title: fileTitle,
                    fId: fileId,
                    uploadid: uploadId,
                    ug: userGroup,
                    correction: true,
                }
            });
        },
        handleCopyOrMoveClicked(file, mode) {
            this.saveToFile = file;
            this.saveToMode = mode;
            this.showSaveToUI = true;
        },
        handleLearningPathDialog(item) {
            if (!item) {
                item = this.currentFolder;
                this.learningPathEditMode = false;
            } else {
                this.learningPathEditMode = true;
            }
            this.learningPathItem = item;
            this.showLearningPathUI = true;
        },
        handleEditSharingClicked(item)  {
            this.sharingDialogFileName = '';
            if (!item) {
                item = this.currentFolder;
                this.learningPathEditMode = false;
            } else {
                this.learningPathEditMode = true;
            }
            // if item is a learningPath origin folder or its subfolder (== station) send it to a special dialog
            if (item.learningPath ? (item._id == item.learningPath.pathVFSOrigin || item.learningPath.stations.filter((el) => el.vfs == item._id).length > 0) : false) {
                this.learningPathItem = item;
                this.showLearningPathUI = true;
                return;
            }
            // otherwise proceed to regular sharing dialog
            if (item.type === 'file') {
                this.sharingDialogItem = this.currentFolder;
                this.sharingDialogFileName = item.uploadedName;
                this.sharingDialogFileId = item._id;
                this.sharingDialogIsFile = true;
                // the dialog is view-only when the user does not own the file
                if (!this.accountRole === 'teacher' && this.sharingDialogItem.sharing_Files && this.sharingDialogItem.sharing_Files.length > 0) {
                    this.sharingDialogInteractable = !!this.sharingDialogItem.sharing_Files.find((file) => file.owner == this.accountId);
                } else {
                    this.sharingDialogInteractable = this.accountRole === 'teacher';
                }
            } else {
                this.sharingDialogItem = item;
                this.sharingDialogIsFile = false;
                if (item.owner == this.accountId && this.accountRole === 'teacher') {
                    this.sharingDialogInteractable = true;
                } else {
                    this.sharingDialogInteractable = false;
                }
            }
            this.showSharingUI = true;
        },
        closeUI(res) {
            this.showSharingUI = false;
            this.showLearningPathUI = false;
            this.sharingDialogItem = null;
            this.learningPathItem = null;
            this.saveToFile = null;
            this.showSaveToUI = false;
            this.triggerVFSRefresh = !this.triggerVFSRefresh;
        },
        manipulateParentScroll(value) {
            // console.log('manipulateParentScroll with value', value);
            // this.settings.suppressScrollY = value;
            // this.$nextTick(this.$refs.groupVFS.$el.scrollTop = this.scrollTop);
        },
        async changeFileLocation(mode, newFileName, destinationId) {
            if (mode === 'copy') {
                if (this.savingFiles.length > 0) {
                    await this.copyMultipleFiles(newFileName, destinationId)
                } else {
                    await this.copyFileToDestination(this.saveToFile, newFileName, destinationId) }
            }
            if (mode === 'move') {
                if (this.savingFiles.length > 0) {
                    await this.moveMultipleFiles(newFileName, destinationId)
                } else {
                    await this.moveFileToDestination(this.saveToFile, newFileName, destinationId) }
            }
        },
        async moveFileToDestination(file, newFileName, destinationId) {
            const response = await this.updateVFSFolderUploadV2({ fileId: file._id, parentFolderId: destinationId, name: newFileName });
            if (response.status == 200) {
                this.showSnackbar({ message: 'Datei erfolgreich verschoben!' });
                this.saveToFile = null;
                this.showSaveToUI = false;
                this.triggerVFSRefresh = !this.triggerVFSRefresh;
            } else {
                this.showSnackbar({ message: 'Es ist ein Fehler beim Verschieben aufgetreten.' });
                this.saveToFile = null;
                this.showSaveToUI = false;
            }
        },
        // TODO Quick solution, needs better error handling like single file
        async copyMultipleFiles (newFileName, destinationId) {
            for (let i = 0; i < this.savingFiles.length; i++) {
                await this.copyFileToDestination(this.savingFiles[i], newFileName, destinationId)
            }
            await this.$refs.groupVFS.stopMultiselect()
        },
        // TODO Quick solution, needs better error handling like single file
        async moveMultipleFiles (newFileName, destinationId) {
            for (let i = 0; i < this.savingFiles.length; i++) {
                await this.moveFileToDestination(this.savingFiles[i], newFileName, destinationId)
            }
            await this.$refs.groupVFS.stopMultiselect()
        },
        async copyFileToDestination(file, newFileName, destinationId) {
            // get file absolute path
            const absoluteFilePathRes = await this.getVFSFolderUploadV2(file._id);
            // fetch file
            const filedata = await (await fetch(absoluteFilePathRes.url, {
                method: "GET",
                headers: new Headers({
                    Authorization: "Bearer " + this.token,
                }),
                })).blob();
            // set new file name
            const improvedFile = new File([filedata], newFileName, {type: filedata.type })
            // use normal post upload for file
            const XMLResponse = await this.postVFSFolderUploadV2({ file: improvedFile, destinationId });
            // close popup if it worked,
            if (XMLResponse._id) {
                this.showSnackbar({ message: 'Datei erfolgreich kopiert!' });
                this.saveToFile = null;
                this.showSaveToUI = false;
            }
            // TODO: handle thumbnail gen
        },
        // used from VFS folderType private & group
        openPrivateAnsicht(file, params, appointmentUpload, appointmentId) {
            const fileTitle = file.uploadedName;
            const fileId = file._id;
            const userGroup = 'private';

            let mode = 'creator';
            if (this.accountRole === 'pupil' && !params.sharing ) {
                mode = 'viewer';
            }
            if (this.accountRole === 'pupil' && (params.sharing || file.owner === this.accountId)) {
                mode = 'pupil';
            }

            const query = {
                ...this.$route.query,
                color: '#6996cd',
                aId: appointmentId,
                title: fileTitle,
                fId: fileId,
                ug: userGroup,
                isAppointment: appointmentUpload,
                sharing: false,
            };
            if (!appointmentId) {
                delete query.aId;
            }

            if (params) {
                if (params.sharing) {
                    query.sharing = params.sharing;
                }
            } else {
                delete query.sharing;
            }
            if (file.type === 'draft' && file._id) {
                query.ug = file.type;
                query.draftId = file._id;
            } else {
                delete query.draftId;
            }
            this.$router.push({
                name: 'editor',
                params: { mode },
                query,
            });
        },
        async handleBackButton() {
             if(this.switchedFromHomework && this.currentFolder.learningPath && this.currentFolder.files.length === 0){
                    this.searchString = '';
                    this.folderDepth--;
                
                await this.toggleFilesOrHomework();
                // await this.$router.replace({ query: { ...this.$route.query, fwmode: 'homework' } });
                this.switchedFromHomework = false;
                return
             }

                this.searchString = '';
                this.folderDepth--;
             
           
        },
        handleNewFolder() {
            this.$refs.groupVFS.startFolderCreation();
        },
        handleUploadButtonClick() {
            this.clickGroupUploadButton();
        },
        handleOpenSubmissions(fileId, accountId) {
            this.sharingDialogSubmissionToOpen = { fileId, accountId };
        },
        clickGroupUploadButton() {
            this.toggleOpenAppointment(null);
            this.toggleCurrentUploadGroup('groupFolder');
            this.toggleCurrentUploadFolder(this.currentFolder);
            this.setVfs(this.currentFolder)
            document.getElementById("uploadInput").click();

            // Add Listener to current upload group
        },
        setFocusOn(id) {
            setTimeout(() => {
                document.getElementById(id).focus();
            }, 10);
        },

        /**
         * #region drag and drop in filewidget
         */
        limitEvent(event) {
            event.stopPropagation(); event.preventDefault();
        },
        onDragLeave(e){
            this.limitEvent(e);
            this.$nextTick(() => {
                this.dropUiActive = false;
            });
        },
        dragOver(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                const path = e.path || (e.composedPath && e.composedPath());
                this.dropUiActive = !!path.find(el => el.id === 'fileWidgetContainer');
            }
        },
        designateDropZone(event) {
            this.limitEvent(event);
            if (this.dragDropUploadAllowed) {
                this.dropUiActive = true;
            }
        },
        async dropFile(event) {
            this.limitEvent(event);
            if (this.dragDropUploadAllowed) {
                const fileArray = Array.from(event.dataTransfer.files);
                if (await this.validateMimeType(fileArray)) {
                    this.$refs.groupVFS.dropItLikeItsHot(fileArray);
                }
            }
            this.dropUiActive = false;
        },
        async clearBadges() {
            try {
                this.homeworkBadgeEventList.map(async (event) => {
                    if (event.day) { // Unseen appointment homework
                        await this.patchAppointmentUploadSeenBy({
                            uploadId: event.item,
                            accountId: this.accountId,
                            role: this.accountRole
                        });
                    } else { // Unseen FileWidget homework (e.g. task in learning path)
                        await this.markFileSeen(event.file);
                    }
                });
                await this.$refs.homeworkContainer.refreshContent();
                this.showSnackbar({
                    message: 'Benachrichtigungen wurden erfolgreich als gesehen markiert!',
                    color: 'success'
                });
            } catch(error) {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist aufgetreten.',
                    color: 'error'
                });
                console.error(error);
            }
        }
    },
}
</script>

<style lang="scss" scoped>
.header {
    min-height: 30px;
    background-color: rgb(var(--v-theme-fileArchiveOrange));
    border-radius: 16px 16px 0 0;
    color: white;
    opacity: 1;
}

.icon {
    height: 20px;
}

.toolbarButton {
    border-radius: 4px;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.scroll-area {
    display: flex;
    flex: 1 1 0;
    flex-direction: column;
}

h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    width: 35px !important;
    height: 35px !important;
}

.dropUiContainer {
    z-index: 4;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.dropUi {
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.4);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
        width: 75%;
        text-wrap: normal;
        text-align: center;
    }
}

#bottomInfoBar {
    width: 100%;
    min-height: 50px;
    position: absolute;
    bottom: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: white;
    border-top-style: solid;
    border-top-color: rgba(200, 200, 200, 0.75);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
}

#bottomInfoBarElementCount {
    color: rgb(var(--v-theme-dunkelgrau));
    white-space: nowrap;
    align-items: center;
    margin-left: 10px;
    font-size: small;
    overflow: hidden;
    text-overflow: clip;
}

</style>