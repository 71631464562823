<template>
    <v-card 
        class="contentCard" 
    >
        <v-card-title
            :style="`background-color: ${headerColor}`"
            class="pa-2 px-2 pl-4"
            style="height: 50px"
        >
            <img
                :src="raumIcon"
                class="iconToWhite icon30"
            >
            <p style="color: white">
                Räume verwalten
            </p>

            <v-spacer />

            <v-btn
                icon="$vuetify"
                variant="plain"
                @click="checkDeleteAvailable"
            >
                <img
                    :src="deleteIcon"
                    :class="deleteMode ? 'icon20 iconToRed' : 'icon20 iconToWhite'"
                    alt="Löschen"
                >
            </v-btn>

            <v-btn
                icon="$vuetify"
                variant="plain"
                @click="()=> { $emit('stopEvent') }"
            >
                <img
                    :src="cancelIcon"
                    class="icon20 iconToWhite"
                    alt="schließen"
                >
            </v-btn>
        </v-card-title>
        <v-card-text 
            style="height: 500px; display: flex; padding:0"
        >
            <div style="width: 100%; height: 100%">
                <div style="display: flex; border-radius: 15px 15px 0 0">
                    <v-text-field
                        v-model="search"
                        placeholder="Nach Raum suchen"
                        single-line
                        hide-details
                        class="pl-6 pr-6"
                    >
                        <template #prepend-inner>
                            <div>
                                <img
                                    :src="suchIcon"
                                    style="height: 20px"
                                    alt="Suchen-Symbol"
                                >
                            </div>
                        </template>
                    </v-text-field>
                </div>
                <div 
                    style="display: flex; margin-bottom: 16px; margin-top: 16px"
                    class="pr-6"
                >
                    <v-text-field
                        v-model="roomName"
                        single-line
                        placeholder="Neuen Raum erstellen (max. 10 Zeichen)"
                        hide-details
                        class="pl-6 pr-6"
                        style="padding-top: 0; margin-top:0"
                        @keydown.enter="addRoom"
                    />
                    <v-tooltip
                        location="top"
                        max-width="400px"
                    >
                        <template v-slot:activator="{ props }">
                            <img
                                :src="infoIcon"
                                class="pr-3"
                                style="height: 30px"
                                alt="Erklärung"
                               
                                v-bind="props"
                            >
                        </template>
                        <span>Ab einer Länge von mehr als 10 Zeichen wird der Raumname aus Gründen der Darstellung gekürzt.</span>
                    </v-tooltip>
                    <v-btn
                        class="pa-0"
                        color="success"
                        size="x-small"
                        width="32px"
                        height="32px"
                        elevation="1"
                        @click="addRoom"
                    >
                        <v-icon 
                            class="iconToWhite"
                            style="height: 24px"
                        >
                            mdi-plus
                        </v-icon>
                    </v-btn>
                </div>
                <v-data-table
                    :key="refreshKey"
                    ref="dataTable"
                    :headers="headers"
                    :items="rooms"
                    :search="search"
                    hide-default-footer
                    fixed-header
                    items-per-page="-1"
                    :show-select="deleteMode"
                    sort-by="roomName"
                    class="dataTableStyle"
                    locale="de-DE"
                    no-data-text="Keine Daten verfügbar"
                    no-results-text="Keine übereinstimmenden Aufzeichnungen gefunden"
                    @toggle-select-all="selectAll"
                    @change="requestRooms"
                >
                    <template v-slot:item="row">
                        <tr>
                            <td v-if="deleteMode">
                                <v-checkbox
                                    density="compact"
                                    hide-details
                                    :value="row.item.selected"
                                    style="padding:0; margin:0"
                                    @click="toggleSelectRoom(row.item)"
                                />
                            </td>
                            <td class="d-flex">
                                <img
                                    :src="raumIcon"
                                    class="icon20 mt-1"
                                >
                                <v-text-field
                                    v-model="row.item.roomName"
                                    hide-details
                                    density="compact"
                                    variant="solo"
                                    flat
                                    style="padding-top: 0; margin:0; "
                                    @keydown.enter="editThisRoom(row.item)"
                                    @blur="editThisRoom(row.item)"
                                    @focus="currentRoom = row.item.roomName"
                                >
                                    <template #append>
                                        <div>
                                            <img
                                                :src="bearbeitenIcon"
                                                alt="Bearbeiten"
                                                style="height: 20px"
                                            >
                                        </div>
                                    </template>
                                </v-text-field>
                            </td>
                        </tr>
                    </template>
                </v-data-table>
            </div>
            <!-- Delete dialog-->
            <v-dialog
                v-model="deleteDialog"
                persistent
                max-width="400"
            >
                <v-card
                    class="cardStyle"
                    style="overflow: hidden; border-radius: 16px"
                >
                    <v-card-title 
                        style="background-color: rgb(var(--v-theme-timetableColor)); color: white"
                        class="text-h5 mb-1 d-flex align-center justify-space-between"
                    >
                        <p>Raum löschen?</p>
                        <v-btn
                            icon="$vuetify"
                            variant="plain"
                            color="transparent"
                            elevation="0"
                            style="margin-right: -8px"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                alt="schließen"
                            >
                        </v-btn>
                    </v-card-title>
                    <v-card-text class="pa-4">
                        Sind Sie sicher, dass Sie die ausgewählten Räume löschen möchten?
                    </v-card-text>
                    <v-card-actions class="d-flex justify-center mb-2">
                        <v-btn
                            class="optionBtn text-none"
                            color="#F5F5F5"
                            @click="deleteDialog = false"
                        >
                            <img
                                :src="cancelIcon"
                                class="icon20 mr-2"
                                alt="abbrechen"
                            >
                            Abbrechen
                        </v-btn>
                        <v-spacer />
                        <v-btn
                            class="optionBtn text-none"
                            color="rot"
                            theme="dark"
                            @click="deleteSelectedRooms"
                        >
                            <img
                                :src="deleteIcon"
                                class="icon20 mr-2 iconToWhite"
                            >
                            Löschen
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-overlay
                absolute
                :model-value="showLoadingOverlay"
                style="width: 100% !important;"
            >
                <v-progress-circular
                    v-if="showLoadingOverlay"
                    indeterminate
                    size="100"
                />
            </v-overlay>
        </v-card-text>
    </v-card>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from "vuex";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import bearbeitenIcon from '@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg'
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import suchIcon from '@/assets/Icons/suche-26.svg'
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import raumIcon from "@/assets/Icons/FaceliftIcons/raum.svg";

export default {
    roomName: "CreateRoom",
    props: {
        headerColor: { type: String, required: false, default: '#467abb' },
    },
    data: () => ({
        cancelIcon,
        suchIcon,
        bearbeitenIcon,
        deleteIcon,
        infoIcon,
        raumIcon,

        showLoadingOverlay: false,

        deleteDialog: false,
        deleteMode: false,

        search: '',
        headers: [
            { text: 'Raumname', value: 'roomName', width: '100%' },
        ],

        rooms: [],
        selectedRooms: [],
        selectedCount: 0,
        roomName: '',
        currentRoom: '',
        refreshKey: false,
    }),
    computed: {
        ...mapGetters('physicalRooms', ['getRoomInvalidStatus']),
    },
    watch: {
        getRoomInvalidStatus(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.requestRooms();
            }
        }
    },
    async mounted() {
        await this.requestRooms();
    },
    methods: {   
        ...mapActions("physicalRooms", ["getRooms","createRoom", "editRoom", "deleteRoom", "setRoomListStatus"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        async requestRooms() {
            this.rooms = [];
            this.rooms = await this.getRooms();
            this.rooms = this.rooms.map(el => {
                el.selected = false;
                return el
            });
            this.setRoomListStatus(false);
        },

        async selectAll() {
            if(this.selectedCount === this.rooms.length) {
                this.rooms = this.rooms.map(el => {
                    el.selected = false; 
                    return el
                });
                this.selectedRooms = [];
                this.selectedCount = 0;
            } else {
                // $refs.dataTable workaround necessary because vuetify data-table doesn't offer way of getting the filtered/searched items easily
                this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value = this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.map(el => {
                    el.selected = true;
                    this.selectedCount++;
                    return el
                });
                if (this.selectedRooms.length === 0) {
                    for (let index = 0; 
                            index < this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.length;
                            index++) {
                        this.selectedRooms.push(this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[index]);
                    }
                } else {
                    for (let i = 0; i < this.selectedRooms.length; i++){
                        for (let j = 0;
                            j < this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value.length;
                            j++) {
                            if (!this.selectedRooms[i]._id === this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[j]._id) {
                                this.selectedRooms.push(this.$refs.dataTable.$children[0]._computedWatchers.filteredItems.value[j]);
                            }
                        }
                    }
                }
            }
        },

        toggleSelectRoom(room) {
            let index = -1;
            for (let i = 0; i < this.selectedRooms.length; i++) {
                if (this.selectedRooms[i]._id === room._id) {
                    index = i;
                } else {
                    index = -1;
                }
            }
            if (index === -1) {
                this.selectedRooms.push(room);
                this.selectedCount++;
                this.rooms.forEach(el => {
                    el._id === room._id ? el.selected = true : el.selected = false
                });
            } else {
                this.selectedRooms.splice(index, 1);
                this.selectedCount--;
                this.rooms.forEach(el => {
                    el._id === room._id ? el.selected = false : el.selected = false
                });
            }
        },

        async editThisRoom(room) {
            if (this.currentRoom === room.roomName) {
                return
            } else {
                try {
                    this.showLoadingOverlay = true;

                    if (room.roomName === '') {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        return;
                    }

                    let data = {
                        _id: room._id,
                        roomName: room.roomName,
                    };

                    let response = await this.editRoom(data);

                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen FachroomNamen ein!', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        const index = this.rooms.indexOf(room);
                        if (index > -1) {
                            this.rooms.splice(index, 1);
                        }
                        this.rooms.push(response);
                        this.setRoomListStatus(true);
                        this.showSnackbar({ message: 'Gespeichert!'});
                        this.$emit('requestRooms');
                    }

                    this.showLoadingOverlay = false;

                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay.false;
                }
            } 
        },

        checkDeleteAvailable() {
            if (!this.deleteMode) {
                this.deleteMode = true;
            } else {
                if (this.selectedRooms.length === 0) {
                    this.deleteMode = false;
                } else {
                    this.deleteDialog = true;
                }
            }
        },

        async deleteSelectedRooms() {
            this.showLoadingOverlay = true;
            for (let i = 0; i < this.selectedRooms.length; i++) {
                let response = await this.deleteRoom(this.selectedRooms[i]._id);
                if (response) {
                    const index = this.rooms.indexOf(this.selectedRooms[i]);
                    if (index > -1) {
                        this.rooms.splice(index, 1);
                    }
                    this.setRoomListStatus(true);
                }
                else {
                    this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                    return;
                }
            }
            this.showSnackbar({ message: 'Löschen erfolgreich!'});
            this.$emit('requestRooms');
            this.refreshKey = !this.refreshKey;
            this.selectedRooms = [];
            this.deleteMode = false;
            this.showLoadingOverlay = false;
            this.deleteDialog = false;
        },

        async addRoom() {
            let nameAlreadyTaken = false;
            for (let i = 0; i < this.rooms.length; i++) {
                if(this.rooms[i].roomName === this.roomName) {
                    nameAlreadyTaken = true;
                }
            }
            if (!nameAlreadyTaken) {
                try {
                    this.showLoadingOverlay = true;

                    if (this.roomName === '') {
                        this.showSnackbar({ message: 'Bitte füllen Sie alle benötigten Felder aus!', color: 'error'});
                        this.showLoadingOverlay = false;
                        return;
                    }

                    let data = {
                        roomName: this.roomName,
                    };

                    let response = await this.createRoom(data);

                    if (Number.isInteger(response)) {
                        if (response === 409) {
                            this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen RaumNamen ein!', color: 'error'});
                        } else {
                            this.showSnackbar({ message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!', color: 'error'});
                        }
                    } else {
                        this.rooms.push(response);
                        this.setRoomListStatus(true);
                        this.roomName = '';
                        this.showSnackbar({ message: 'Gespeichert!'});
                        this.$emit('requestRooms');
                    }
                    this.showLoadingOverlay = false;

                } catch (e) {
                    console.error(e);
                    this.showLoadingOverlay.false;
                }
            } else {
                this.showSnackbar({ message: 'Name bereits vergeben. Bitte geben Sie einen anderen Raumnamen ein!', color: 'error'});
                return;
            }
        },
    },
}
</script>
<style lang="scss" scoped>
    .contentCard {
        border-radius: 16px;
        height: 61vh;
        width: 30vw;
        overflow-y: hidden;
    }
    
    .dataTableStyle {
        margin: 12px 24px 0px;
        height: 75% !important;
        overflow-y: scroll;
    }

    @media only screen and (max-width:900px) {
        .dataTableStyle {
            height: 100% !important;
        }
        .contentCard {
            height: 70vh;
            width: 85vw;
            overflow-y: hidden;
        }
    }

    @media only screen and (min-width:900px) and (max-width:1300px) {
        .contentCard {
            height: 85vh;
            width: 85vw;
            overflow-y: hidden;
        }
        .dataTableStyle {
            height: 85% !important;
        }
    }

    .optionBtn {
        border-radius: 8px;
        min-width: 35px !important;
        height: 35px !important;
    }
</style>
