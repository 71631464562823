import * as backend from '../../api/backend'
import {checkResponseStatus} from "@/util/check";

const state = {
    blackboardEntries: [],
    blackboardBuffer: [],
    thumbnails: new Map(),
};

const sortByDate = (a, b) => {
    let keyA = new Date(a.createdOn),
        keyB = new Date(b.createdOn);
    // Compare the 2 dates
    if (keyA > keyB) return -1;
    if (keyA < keyB) return 1;
    return 0;
};
const sortEntriesByTime = (entries) => {
    return entries.sort((a, b) => (a.time > b.time ? -1 : 1));
};
const seenByMe = (item, accountId) => {
    if(item && item.seenBy.length){
        return item.seenBy.includes(accountId)
    }
    return false;
};
const sortByFixed = (a, b) => {
    return (a.isFixed === b.isFixed)? 0 : a.isFixed ? -1 : 1;
};
const mapToEventList=(entries) => {
    return entries.map(blackboard => ({ message: blackboard._id, time: blackboard.createdOn }));
};
/**
 * Adds seenByTargets and iconBlobs to each blackboard entry
 * @param commit part of store context
 * @param rootGetters part of store context
 * @param dispatch part of store context
 * @param blackboardEntries blackboardEntries comming from the backend
 * @returns {Promise<*|*[]>} blackboardEntries enriched with data
 */
// const enrichBlackboardEntries = async ({ rootGetters, dispatch }, blackboardEntries) => {
//     try {
//         const groupsById = rootGetters['groups/groupsById'];
//         const hasAccessPrivileges = rootGetters['auth/accountRole'] === 'teacher'
//             || rootGetters['auth/accountRole'] === 'maintainer';
//         const toDoListsById = rootGetters['todolist/toDoListsById'];
//         const loggedInAccount = rootGetters['accounts/me'].account;

//         return await blackboardEntries.reduce(async (previousPromise, blackboardItem) => {
//             const previous = await previousPromise;
//             const group = groupsById[blackboardItem.group];
//             if (group && hasAccessPrivileges) {
//                 // add all group participants to targetlist
//                 // todo check if pupils always are populated
//                 const pupils = group.participants;
//                 const seenByTargetList = group.participants.slice();
//                 await pupils.reduce(async (pupilPromise, pupil) => {
//                     const prevPupils = await pupilPromise;
//                     if (pupil.parent != null) {
//                         const parent = await dispatch('parents/getParent', pupil.parent, {root: true});
//                         seenByTargetList.push({
//                             role: 'parent',
//                             _id: parent.account,
//                             pupil
//                         });
//                     }
//                     prevPupils.push(pupil);
//                     return prevPupils;
//                 }, Promise.resolve([]));

//                 blackboardItem.seenByTarget = seenByTargetList;
//             } else {
//                 blackboardItem.seenByTarget = [];
//             }
//             //overwrite id of todolist by actual todolist
//             if(blackboardItem.toDoList){
//                 const toDoList = toDoListsById[blackboardItem.toDoList];
//                 if(toDoList){
//                     blackboardItem.toDoList = toDoList;
//                 }
//             }

//             blackboardItem.isFixed = !!(blackboardItem.fixed
//                 && blackboardItem.fixed.length > 0 && blackboardItem.fixed.includes(loggedInAccount));

//             await Promise.all(blackboardItem.uploads.map(async (upload) => {
//                 const stateThumbnail = state.thumbnails.get(upload._id);
//                 if (stateThumbnail && stateThumbnail.hasThumbnail) {
//                     upload.hasThumbnail = stateThumbnail.hasThumbnail;
//                     upload.thumbnail = stateThumbnail.thumbnail;
//                     return upload;
//                 }

//                 if (upload.file) {
//                     const res = await dispatch('getBlackboardUploadThumbnail', {
//                         fileId: upload.file._id || upload.file,
//                         blackboardId: blackboardItem._id,
//                         fileAccessToken: upload.fileAccessToken,
//                     });
//                     upload.thumbnail = window.URL.createObjectURL(res);
//                     upload.hasThumbnail = res.type !== 'text/plain; charset=utf-8';
//                     state.thumbnails.set(upload._id, { thumbnail: upload.thumbnail, hasThumbnail: upload.hasThumbnail, });
//                 }
//                 return upload;
//             }));
//             // add icon in here as well
//             blackboardItem.iconBlob = blackboardItem.uploadedIcon
//                 ? await dispatch('uploadedIcons/getUploadedIcon', blackboardItem.icon, {root: true})
//                 : blackboardItem.profileIcon
//                     ? await dispatch('getBlackboardProfilePicture', blackboardItem)
//                     : null
//             previous.push(blackboardItem);
//             return previous;
//         }, Promise.resolve([]));
//     } catch (e) {
//         // todo handle error more effectively
//         console.error(e);
//         return [];
//     }
// };
const enrichBlackboardEntries = async ({ rootGetters, dispatch }, blackboardEntries) => {
    try {
        const groupsById = rootGetters['groups/groupsById'];
        const hasAccessPrivileges = rootGetters['auth/accountRole'] === 'teacher' || rootGetters['auth/accountRole'] === 'maintainer';
        const toDoListsById = rootGetters['todolist/toDoListsById'];
        const loggedInAccount = rootGetters['accounts/me'].account;
        const enriched = await Promise.all(blackboardEntries.map(async (originalItem) => {
            // Create a deep copy of the blackboardItem to prevent direct state mutation
            const blackboardItem = JSON.parse(JSON.stringify(originalItem));

            const group = groupsById[blackboardItem.group];
            if (group && hasAccessPrivileges) {
                const seenByTargetList = [...group.participants];

                // Parallelize fetching of parent information
                const parentPromises = group.participants
                    .filter(pupil => pupil.parent != null)
                    .map(pupil => dispatch('parents/getParent', pupil.parent, { root: true })
                        .then(parent => ({
                            role: 'parent',
                            _id: parent.account,
                            pupil
                        })));
                const parents = await Promise.all(parentPromises);
                blackboardItem.seenByTarget = seenByTargetList.concat(parents);
            } else {
                blackboardItem.seenByTarget = [];
            }

            if (blackboardItem.toDoList) {
                const toDoList = toDoListsById[blackboardItem.toDoList];
                if (toDoList) {
                    blackboardItem.toDoList = toDoList;
                }
            }

            blackboardItem.isFixed = blackboardItem.fixed?.length > 0 && blackboardItem.fixed.includes(loggedInAccount);

            // Process uploads in parallel
            const uploadPromises = originalItem.uploads.map(async (upload) => {
                let stateThumbnail;
                if(!(typeof upload === 'object')){
                    stateThumbnail = state.thumbnails.get(upload);
                }else{
                    stateThumbnail = state.thumbnails.get(upload._id);
                }
                if (stateThumbnail && stateThumbnail.hasThumbnail && !upload.file) {
                    upload.hasThumbnail = stateThumbnail.hasThumbnail;
                    upload.thumbnail = stateThumbnail.thumbnail;
                } else if (upload.file) {
                    const res = await dispatch('getBlackboardUploadThumbnail', {
                        fileId: upload.file._id || upload.file,
                        blackboardId: blackboardItem._id,
                        fileAccessToken: upload.fileAccessToken,
                    });
                    upload.thumbnail = window.URL.createObjectURL(res);
                    upload.hasThumbnail = res.type !== 'text/plain; charset=utf-8';
                }
                return upload;
            });
            blackboardItem.uploads = await Promise.all(uploadPromises);

            // Process icon
            blackboardItem.iconBlob = blackboardItem.uploadedIcon
                ? await dispatch('uploadedIcons/getUploadedIcon', blackboardItem.icon, { root: true })
                : blackboardItem.profileIcon
                    ? await dispatch('getBlackboardProfilePicture', blackboardItem)
                    : null;

            return blackboardItem;
        }));

        return enriched;
    } catch (e) {
        console.error(e);
        return [];
    }
};



const getters = {
    blackboardEntries: state => state.blackboardEntries,
    blackboardEntriesById: state => state.blackboardEntries.reduce((acc, bB) => {
        acc[bB._id] = bB;
        return acc;
    }, {}),
    prettyBlackboardEntries: state => {
        const entries = JSON.parse(localStorage.getItem('blackboard')) || [];
        return state.blackboardEntries.map(blackboard => {
            return {
                ...blackboard,
                pin: entries.includes(blackboard._id),
            }
        })
            .sort(sortByDate)
            .sort(sortByFixed);
    },
    getThumbnail: state => (blackboardId) => {
        return state.thumbnails.get(blackboardId);
    },
    blackboardBadgeEventList: (state, getters, rootGetters) => {
        if(rootGetters.auth.account){

        const accountId = rootGetters.auth.account._id;
        const filteredEntries = state.blackboardEntries.filter(blackboardEntry => blackboardEntry && !seenByMe(blackboardEntry, accountId));
        const eventList = sortEntriesByTime(mapToEventList(filteredEntries));
        // this.updateEntryCount(eventList);
        return eventList;
    }
    return [];
    },
}

const mutations = {
    setAllBlackboardEntries: (state, entries) => {
        state.blackboardEntries = entries;
    },
    updateSingleEntry: (state, entryData) => {
        // Todo move update of event bus into here
        const index = state.blackboardEntries.findIndex(entry => entry._id === entryData._id);
        if (index >= 0) {
            state.blackboardEntries[index] = entryData;
        }
    },
    spliceSingleEntry: (state, entryId) => {
        // Todo move update of event bus into here
        const index = state.blackboardEntries.findIndex(entry => entry._id === entryId);
        if (index >= 0) {
            state.blackboardEntries.splice(index, 1);
        }
    },
    setEntrySeen: (state, data) => {
        // Todo move update of event bus into here
        const entryIndex = state.blackboardEntries.findIndex(entry => entry._id === data.messageId);
        if (!state.blackboardEntries[entryIndex].seenByNames) {
            state.blackboardEntries[entryIndex].seenByNames = [];
        }

        if (data.pupils && data.pupils.length) {
            // Find the parent for the pupil and push that
            state.blackboardEntries[entryIndex].seenByTarget.forEach((target) => {
                if (target.pupil && data.pupils.includes((target.pupil._id || target.pupil))) {
                    state.blackboardEntries[entryIndex].seenByNames
                        .push(`Eltern von ${target.pupil.name} ${target.pupil.lastName}`)
                }
            });
        } else {
            // Just push the name
            state.blackboardEntries[entryIndex].seenByNames.push(data.name);
        }
        state.blackboardEntries[entryIndex].seenByCount++;
    },
    pushNewBlackboardEntryToBufferState: (state, newEntry) => {
        state.blackboardBuffer.push(newEntry);
    },
    pushNewBlackboardBufferToState: (state, newBuffer) => {
        state.blackboardEntries = state.blackboardEntries.concat(newBuffer);
        state.blackboardBuffer = [];
    },
    addThumbnail: (state, {uploadId, blob}) => {
        const uploadIndex = state.thumbnails.findIndex(item => item._id === uploadId);
        if (uploadIndex >= 0){
           state.accounts[uploadIndex] = {uploadId,blob};
        }else{
            state.thumbnails.push({uploadId,blob});
        }
    },
};

const actions = {
    async getBlackboard ({ commit, dispatch, getters, rootGetters }, update = false) {
        try {
            if (getters.prettyBlackboardEntries && getters.prettyBlackboardEntries.length > 0 && !update) {
                return getters.prettyBlackboardEntries;
            }
            const t2 = performance.now();

            const response = await backend.getBlackboard();
            const blackboardJson = await response.json();
            const t3 = performance.now();

            console.debug(`getting BB from backend performance ${t3-t2} ms`);

            const t0 = performance.now();

            const roundTripBlackboards = await enrichBlackboardEntries({ rootGetters, dispatch }, blackboardJson);
            const t1 = performance.now();
            console.debug(`enriching BB performance ${t1-t0} ms`);
            commit('setAllBlackboardEntries', roundTripBlackboards);
            return blackboardJson;
        } catch (e) {
            console.error(e);
            return e.response.status;
        }
    },
    async getParentBlackboard ({ commit, dispatch, rootGetters }, params) {
        try {
            const response = await backend.getParentBlackboard(params);
            const blackboardJson = await response.json();
            const roundTripBlackboards = await enrichBlackboardEntries({ rootGetters, dispatch }, blackboardJson);
            commit('setAllBlackboardEntries', roundTripBlackboards);
            return blackboardJson;
        } catch (e) {
            console.error(e);
            return e.response.status;
        }
    },

    async emptyBufferIntoState ({ commit, state, dispatch, rootGetters }) {
        const enrichedBuffer = await enrichBlackboardEntries({ rootGetters, dispatch }, state.blackboardBuffer);

        commit('pushNewBlackboardBufferToState', enrichedBuffer);
    },

    async createBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const response = await backend.postBlackboard(params);
            await checkResponseStatus(201, response);
            const newBlackboardEntry = await response.json();
            commit('pushNewBlackboardEntryToBufferState', newBlackboardEntry)
            return newBlackboardEntry;
        } catch (e) {
            return e.response.status;
        }
    },
    async updateBlackboardEntry ({ commit, rootGetters, dispatch }, params) {
        try {
            const id = params._id;
            delete params._id;
            //this needs to be stored and readded below since 'isFixed' isnt stored in db, so the response looses its information
            const isFixed = params.isFixed;
            const response = await backend.patchBlackboard(id, params);
            await checkResponseStatus(200, response);
            const res = await response.json();
            if (res.uploads.length) {
                await Promise.all(res.uploads.map(async (currentUpload) => {
                    const foundThumbnail = await getters.getThumbnail(state)(currentUpload._id);
                    if (foundThumbnail) {
                        currentUpload.hasThumbnail = foundThumbnail.hasThumbnail;
                        currentUpload.thumbnail = foundThumbnail.thumbnail;
                    } else {
                        const thumb = await dispatch('getBlackboardUploadThumbnail', res, currentUpload, currentUpload.fileAccessToken);
                        currentUpload.hasThumbnail = currentUpload.thumb.type !== 'text/plain; charset=utf-8';
                        currentUpload.thumbnail = window.URL.createObjectURL(thumb);
                    }
                }));
            }
            res.isFixed = isFixed;
            const enriched = await enrichBlackboardEntries({rootGetters, dispatch}, [res]);
            commit('updateSingleEntry', enriched[0]);
            return enriched;
        } catch (e) {
            return e.response.status;
        }
    },
    async updateBlackboardEntryWithToDoTask({ commit, dispatch, getters, rootGetters }, blackboardId){
        const entry = getters.blackboardEntriesById[blackboardId];
        if (entry) {
            const toDoListId = entry.toDoList._id;
            const updatedToDoList = rootGetters['todolist/toDoListsById'][toDoListId];
    
            const updatedEntry = {
                ...JSON.parse(JSON.stringify(entry)),
                toDoList: updatedToDoList
            };
    
            commit('updateSingleEntry', updatedEntry);
        }
    },
    async hideBlackboardMessage({ commit, dispatch }, blackboardMessagId) {
        try {
            const response = await backend.hideBlackboardEntry(blackboardMessagId);
            await checkResponseStatus(201, response);
            commit('spliceSingleEntry', blackboardMessagId);
            const res = await response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async toggleFixEntry ({ commit, rootGetters, dispatch }, params) {
        try {
            const response = await backend.toggleFixEntry(params.entryId);
            await checkResponseStatus(201, response);
            const res = await response.json();
              //this needs to be set here since 'isFixed' isnt stored in db, so the response looses its information
            res.isFixed = res.fixed?.includes(params.requestId);
            if (res.uploads.length) {
                await Promise.all(res.uploads.map(async (currentUpload) => {
                    const foundThumbnail = await getters.getThumbnail(state)(currentUpload._id);
                    if (foundThumbnail) {
                        currentUpload.hasThumbnail = foundThumbnail.hasThumbnail;
                        currentUpload.thumbnail = foundThumbnail.thumbnail;
                    } else {
                        const thumb = await dispatch('getBlackboardUploadThumbnail', res, currentUpload, currentUpload.fileAccessToken);
                        currentUpload.hasThumbnail = currentUpload.thumb.type !== 'text/plain; charset=utf-8';
                        currentUpload.thumbnail = window.URL.createObjectURL(thumb);
                    }
                }));
            }
            const enriched = await enrichBlackboardEntries({rootGetters, dispatch}, [res]);
            commit('updateSingleEntry', enriched[0]);
            return enriched;
        } catch (e) {
            return e.response.status;
        }
    },
    async deleteBlackboardEntry ({ commit, dispatch }, params) {
        try {
            const id = params._id;
            delete params._id;

            const response = await backend.deleteBlackboard(id, params);
            await checkResponseStatus(201, response);
            return await response.json();
        } catch (e) {
            return e.response.status;
        }
    },
    async deleteBlackboardUpload ({ commit, dispatch }, params) {
        try {
            const response = await backend.deleteBlackboardUpload(params.blackboardId, params.fileId);
            await checkResponseStatus(204, response);
        } catch (e) {
            return e.response.status;
        }
    },
    async markBlackboardMessageSeen ({ commit,dispatch }, messageId) {
        try {
            const response = await backend.patchBlackboardSeen(messageId);
            await checkResponseStatus(201, response);
            const updated = await response.json();
           await dispatch('enrichAndUpdateSingleEntry', updated);
           return updated;

        } catch (e) {
            console.warn(e);
        }
    },
    async uploadBlackboardAttachment({ commit }, { blackboardId, file }) {
        try {
            const formData = new FormData();
            formData.append('file', file);
            const XmlHttpRequest = await backend.postBlackboardFile(blackboardId, file);

            XmlHttpRequest.addEventListener('load', (e) => {
                return XmlHttpRequest;
            });
            XmlHttpRequest.send(formData)
        } catch (e) {
            return false;
        }
    },

    async getBlackboardUploadAsUrl({ commit }, { blackboardId, fileId }) {
        try {
            // pull normal upload and return directly as URL
            const res = await backend.getBlackboardUpload(blackboardId, fileId);
            // throw error if upload could not be gotten
            await checkResponseStatus(200, res)
            let blob = await res.blob();
            return {
                type: blob.type,
                link: window.URL.createObjectURL(blob)
            };
        } catch (e) {
            console.error(e);
            // return empty blob url
            const blob = new Blob([], { type: 'image/png' });
            return {
                type: 'image/png',
                link: window.URL.createObjectURL(blob)
            };
        }
    },

    async getBlackboardUploadThumbnail({ commit, getters }, { blackboardId, fileId, fileAccessToken}) {
        const foundThumbnail = state.thumbnails.get(fileId);
        if (foundThumbnail) {
            return foundThumbnail;
        }
        try {
            let res;
            // Try getting a thumbnail
            res = await backend.getBlackboardUploadThumbnail(fileId, blackboardId, fileAccessToken);
            // manual response check because as fallback, we get the normal file
            return res.blob();
        } catch (e) {
            console.error(e);
            // return empty blob url
            const blob = new Blob([], { type: 'image/png' });
            return {
                type: 'image/png',
                link: window.URL.createObjectURL(blob)};
        }
    },

    async pushNewSingleEntry({ commit, dispatch, rootGetters }, messageData) {
        try {
            const enrichedBuffer = await enrichBlackboardEntries({rootGetters, dispatch}, [messageData]);
            commit('pushNewBlackboardBufferToState', enrichedBuffer);
        } catch (e) {
            console.error(e);
        }
    },

    async enrichAndUpdateSingleEntry({ commit, dispatch, rootGetters }, messageData) {
        try {
            const enrichedEntries = await enrichBlackboardEntries({rootGetters, dispatch}, [messageData]);
            if (!enrichedEntries.length) {
                console.error('no enriched entry returned');
                return;
            }
            commit('updateSingleEntry', enrichedEntries[0]);
        } catch (e) {
            console.error(e);
        }
    },
    async getBlackboardProfilePicture({dispatch, rootGetters}, item) {
        try {
            let isTeacher = rootGetters['teachers/teachersByAccountId'][item.createdBy];
            if (isTeacher) {
                // teacher profile pic
                if (isTeacher.profilePicture) {
                    let profilePicTeacher = await dispatch('teachers/getProfilePicture', item.icon, {root: true});
                    return profilePicTeacher;
                } else {
                    let fullAcc = rootGetters['accounts/accountsById'][isTeacher.account];
                    return fullAcc.profilePicture?.img;
                }
            } else {
                // maintainer profile pic
                let allMaintainer = rootGetters['maintainers/maintainers'];
                const fullMaintainer = allMaintainer[0];
                if (fullMaintainer.profilePicture) {
                    let profilePicMaintainer = await dispatch('maintainers/getMaintainerProfilePicture', item.icon, {root: true});
                    return profilePicMaintainer;
                } else {
                    let fullAcc = rootGetters['accounts/accountsById'][fullMaintainer.account];
                    return fullAcc.profilePicture?.img;
                }
            }
        } catch (e) {
            console.error(e);
            return e.respnse.status;
        }
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
}
