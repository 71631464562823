<template>
    <div
        id="toolbarContainer"
        v-click-outside="close"
        :shouldBeOpen="shouldBeOpen"
        style="display: flex; align-items: center"
        :style="windowWidth <= 425 ? 'bottom: 65px' : ''"
    >
        <div
            id="stiftSizeMenuContainer"
            style="display: inline !important; "
        >
            <v-menu
                v-model="stiftSizeMenu"
                style="border-radius: 5px !important;"
                :close-on-content-click="false"
                :offset="[0,55]"
                :attach="'#stiftSizeMenuContainer'"
            >
                <template v-slot:activator="{ props:menu }">
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props:tooltip }">
                            <v-btn
                                :class="{ activeTool: currentActiveMethod === 'pen' }"
                                :disabled="isTextLayerVisible"
                                size="x-small"
                                aria-hidden="true"
                                class="mr-3 toolSelector"
                                :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                                tabindex="-1"
                                v-bind="$mergeProps(menu, tooltip)"
                                @click="changeIcon('pen')"
                            >
                                <!--                                <v-icon v-if="currentActiveMethod === 'marker'" style="font-size: 22px !important;">
                                    mdi-format-color-highlight
                                </v-icon>-->
                                <img
                                    alt="Stift"
                                    :src="stiftIcon"
                                    style="max-width: 25px; max-height: 25px; height:25px; width:25px;"
                                >
                            </v-btn>
                        </template>
                        <div style="display: flex; flex-direction: column; text-align: center">
                            <span>Stift</span>
                            <div>
                                <img
                                    v-if="hoeren"
                                    :src="malenMetacom"
                                    width="100"
                                    style="margin: auto"
                                    class="mr-1"
                                >
                                <img
                                    v-if="hoeren"
                                    :src="aussuchenMetacom"
                                    width="100"
                                    style="margin: auto"
                                >
                            </div>
                        </div>
                    </v-tooltip>
                </template>

                <div
                    class="sizeMenu"
                    role="application"
                >
                    <v-btn
                        role="menuitem"
                        variant="text"
                        size="x-small"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        aria-label="Stiftgröße klein"
                        :class="{ activeTool: currentSize === 2 && currentActiveMethod === 'pen'}"
                        @click="localChangeSize('pen', 2)"
                    >
                        <v-icon
                            size="small"
                        >
                            mdi-circle
                        </v-icon>
                    </v-btn>
                    <v-btn
                        variant="text"
                        size="x-small"
                        role="menuitem"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        class="pa-0"
                        aria-label="Stiftgröße mittel"
                        :class="{ activeTool: currentSize === 13 && currentActiveMethod === 'pen'}"
                        @click="localChangeSize('pen', 13)"
                    >
                        <v-icon
                            size="default"
                        >
                            mdi-circle
                        </v-icon>
                    </v-btn>
                    <v-btn
                        variant="text"
                        size="x-small"
                        role="menuitem"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        class="pa-0"
                        aria-label="Stiftgröße groß"
                        :class="{ activeTool: currentSize === 22 && currentActiveMethod === 'pen'}"
                        @click="localChangeSize('pen', 22)"
                    >
                        <v-icon
                            size="large"
                        >
                            mdi-circle
                        </v-icon>
                    </v-btn>
                </div>
            </v-menu>
        </div>

        <!-- Text container (and size menu coming) -->
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <v-btn
                    size="x-small"
                    aria-hidden="true"
                    class="mr-3 toolSelector"
                    :class="{ activeTool: currentActiveMethod === 'text' }"
                    :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                    :disabled="isTextLayerVisible"
                    tabindex="-1"
                    v-bind="props"
                    @click="changeIcon('text')"
                >
                    <img
                        alt="Text einfügen"
                        :src="textIcon"
                        style="max-width: 28px; max-height: 35px; height: 35px; width: 28px;"
                    >
                </v-btn>
            </template>
            <div style="display: flex; flex-direction: column; text-align: center">
                <span>Text einfügen</span>
                <div>
                    <img
                        v-if="hoeren"
                        :src="tastaturTippenMetacom"
                        width="100"
                        style="margin: auto"
                    >
                </div>
            </div>
        </v-tooltip>

        <!-- Textmarker container and size menu -->
        <div
            id="textMarkerSizeMenuContainer"
            style="display: inline !important; "
        >
            <v-menu
                v-model="textmarkerSizeMenu"
                style="border-radius: 5px !important;"
                :close-on-content-click="false"
                :offset="[0,55]"
                :attach="'#textMarkerSizeMenuContainer'"
            >
                <template v-slot:activator="{ props:menu }">
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props:tooltip }">
                            <v-btn
                                :class="{ activeTool: currentActiveMethod === 'marker' }"
                                :disabled="isTextLayerVisible"
                                size="x-small"
                                aria-hidden="true"
                                class="mr-3 toolSelector"
                                :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                                aria-label="Stifttyp Textmarker"
                                tabindex="-1"
                                v-bind="$mergeProps(menu, tooltip)"
                                @click="changeIcon('marker')"
                            >
                                <v-icon
                                    style="color: #706F6F;"
                                    size="30"
                                >
                                    mdi-format-color-highlight
                                </v-icon>
                            </v-btn>
                        </template>
                        <span>Textmarker</span>
                    </v-tooltip>
                </template>
                <div
                    class="sizeMenu"
                    role="application"
                >
                    <v-btn
                        role="menuitem"
                        variant="text"
                        size="x-small"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        aria-label="Stiftgröße klein"
                        :class="{ activeTool: currentSize === 2 && currentActiveMethod === 'marker' }"
                        @click="localChangeSize('marker', 2)"
                    >
                        <v-icon
                            size="x-small"
                        >
                            mdi-square
                        </v-icon>
                    </v-btn>
                    <v-btn
                        variant="text"
                        size="x-small"
                        role="menuitem"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        class="pa-0"
                        aria-label="Stiftgröße mittel"
                        :class="{ activeTool: currentSize === 13 && currentActiveMethod === 'marker' }"
                        @click="localChangeSize('marker', 13)"
                    >
                        <v-icon
                            size="default"
                        >
                            mdi-square
                        </v-icon>
                    </v-btn>
                    <v-btn
                        variant="text"
                        size="x-small"
                        role="menuitem"
                        style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                        class="pa-0"
                        aria-label="Stiftgröße groß"
                        :class="{ activeTool: currentSize === 22 && currentActiveMethod === 'marker' }"
                        @click="localChangeSize('marker', 22)"
                    >
                        <v-icon
                            size="large"
                        >
                            mdi-square
                        </v-icon>
                    </v-btn>
                </div>
            </v-menu>
        </div>

        <!-- Eraser Button -->
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <v-btn
                    :class="{ activeTool: currentActiveMethod === 'eraser', 'mr-1': windowWidth < 600, 'mr-3': windowWidth > 600 }"
                    :disabled="isTextLayerVisible"
                    size="x-small"
                    aria-hidden="true"
                    class="toolSelector"
                    :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                    tabindex="-1"
                    v-bind="props"
                    @click="changeIcon('eraser')"
                >
                    <img
                        alt="Radiergummi"
                        :src="radiererIcon"
                        style="max-width: 25px; max-height: 25px; height:25px; width:25px;"
                    >
                </v-btn>
            </template>
            <div style="display: flex; flex-direction: column; text-align: center">
                <span>Radiergummi</span>
                <div>
                    <img
                        v-if="hoeren"
                        :src="loeschenMetacom"
                        width="100"
                        style="margin: auto"
                    >
                </div>
            </div>
        </v-tooltip>

        <!-- divider -->
        <div
            v-if="windowWidth > 400"
            class="d-inline-flex mr-3"
            style="min-height: 45px !important; border-left: 3px solid white; border-radius: 1px"
        />

        <!--            Color palette    -->
        <div
            id="colorPickerContainer"
            style="display: inline"
        >
            <EditorColorPicker
                :attach="'colorPickerContainer'"
                :hoeren="hoeren"
                :sehen="sehen"
                :change-color="localChangeColor"
                :color="color"
                :window-width="windowWidth"
                :toggle-icon="toggleIcon"
            />
        </div>

        <!-- divider -->
        <div
            v-if="mode !== 'pupil' && windowWidth > 400"
            class="d-inline-flex mr-3"
            style="min-height: 45px !important; border-left: 3px solid white; border-radius: 1px"
        />

        <!--            <v-tooltip bottom v-if="windowWidth > 600">-->
        <!--                <template v-slot:activator="{ on }">-->
        <!--                    <v-btn v-on="on" tabindex="16"  x-small class="mr-3 toolSelector" @click="rotateEditor">-->
        <!--                        <img alt="Um 90° im Uhrzeigersinn drehen" :src="drehIcon" style="max-width: 25px; max-height: 25px"/>-->
        <!--                    </v-btn>-->
        <!--                </template>-->
        <!--                <div style="display: flex; flex-direction: column; text-align: center">-->
        <!--                    <span>Arbeitsblatt drehen</span>-->
        <!--                    <img v-if="hoeren" :src="drehenMetacom" width="100" style="margin: auto">-->
        <!--                </div>-->
        <!--            </v-tooltip>-->

        <!--            Stempel List    -->
        <v-menu
            style="border-radius: 5px !important;"
            :offset="[270,55]"
        >
            <template v-slot:activator="{ props:menu }">
                <v-tooltip location="bottom">
                    <template v-slot:activator="{ props:tooltip }">
                        <v-btn
                            v-if="mode !== 'pupil'"
                           
                            size="x-small"
                            :class="{ activeTool: currentActiveMethod === 'stempel' }"
                            class="toolSelector"
                            :style="windowWidth <= 600 ? 'height: 40px !important; width: 40px !important' : ''"
                            aria-label="Sticker auswählen"
                            v-bind="$mergeProps(menu, tooltip)"
                            @click="changeIcon('stempel')"
                        >
                            <img
                                :src="currentStempel"
                                style="max-width: 25px; max-height: 25px; height:25px; width:25px; filter: none !important;"
                            >
                        </v-btn>
                    </template>
                    <span>Sticker</span>
                </v-tooltip>
            </template>
            <div style="width: 200px; height: 25px; display: inline; border-radius: 0 !important; background-color: rgb(var(--v-theme-hellgrau)); ">
                <v-btn
                    v-for="(item, index) in stempelList"
                    :key="index"
                    variant="text"
                    size="x-small"
                    class="stempelButton"
                    :aria-label="`${item.split('.')[0].substring(5)} Sticker`"
                    @click="setStempel(item)"
                >
                    <img
                        :src="item"
                        style="max-width: 25px; max-height: 25px; height:25px; width:25px;"
                    >
                </v-btn>
            </div>
        </v-menu>
    </div>
</template>

<script>
import stempels from "@/util/editorStempelList";
import radiererIcon from '@/assets/Icons/radierer-73.svg';
import stiftIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import textIcon from '@/assets/Icons/Textfunktion-67.svg';
import EditorColorPicker from "@/components/Editor/EditorColorPicker";
import malenMetacom from "@/assets/METACOM/Editor/malen.png";
import tastaturTippenMetacom from "@/assets/METACOM/Allgemein/tastaturtippen.png";
import loeschenMetacom from "@/assets/METACOM/Allgemein/loeschendaten.png";
import aussuchenMetacom from "@/assets/METACOM/Editor/aussuchen.png";
import {mapActions, mapGetters, mapState} from "vuex";

export default {
    name: 'EditorToolbar',
    components: {EditorColorPicker},
    props: {
        currentActiveMethod: { required: true, type: String },
        color: { required: true, type: String },
        changeColor: { required: true, type: Function },
        changeSize: { required: true, type: Function },
        currentSize: { required: false, type: Number, default: 2 },
        changeStamp: { required: true, type: Function },
        setTool: { required: true, type: Function },
        spawnTextOnCanvas: { required: true, type: Function },
        rotateEditor: { required: true, type: Function },
        shouldBeOpen: { required: false, type: Boolean, default: false },
        isTextLayerVisible: { required: false, type: Boolean, default: false },
        isUndoPossible: { required: false, type: Boolean, default: false },
        displayOtherButtons: { required: false, type: Boolean, default: false },
        hoeren: { required: true, type: Boolean },
        sehen: { required: true, type: Boolean },
        mode: { required: true, type: String },
        toggleIcon: { required: true, type: Function },
        closeToolbar: { required: true, type: Function },
    },
    data: () => ({
        stiftSizeMenu: false,
        textmarkerSizeMenu: false,
        stempelList: stempels,
        currentStempel: stempels[5],

        stayOpenDuringUse: true,
        useTimeoutHandle: null,
        timeoutDuration: 5000,

        // check for first time open in session
        firstTimeOpened: false,

        // Icons
        radiererIcon,
        stiftIcon,
        textIcon,
        malenMetacom,
        tastaturTippenMetacom,
        loeschenMetacom,
        aussuchenMetacom,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapGetters('auth', [ 'accountRole' ]),
        ...mapGetters('editorStore', ['lastTool', 'lastToolSize', 'lastColor']),
        ...mapGetters('pupils', ['currentPupil']),
    },
    watch: {
        shouldBeOpen (newVal, oldVal) {
            this.stayOpenDuringUse = true;
            if (newVal && !oldVal) {
                // set first time open and trigger sequence
                if (!this.firstTimeOpened && oldVal != null) {
                    this.firstStartBehaviour();
                }
                // start timer
                this.useTimeoutHandle = setTimeout(() => {
                    this.stayOpenDuringUse = false;
                    this.closeToolbar({ closedFromTimeout: true });
                }, this.timeoutDuration);
            }
            if (!newVal && oldVal) {
                window.clearTimeout(this.useTimeoutHandle);
            }
        },
    },
    mounted() {
        this.firstTimeOpened = false;
    },
    methods: {
        ...mapActions('editorStore', ['setLastTool', 'setLastColor', 'setLastToolSize']),
        // https://stackoverflow.com/questions/58075400/using-v-tooltip-inside-v-menu-activator-in-vuetify-2-0
        getHandlers() {
            return [...arguments].reduce((seed, item) => {
                const mergedHandlers = { ...item, ...seed };

                return mergedHandlers;
            }, {});
        },

        // todo if users or waldi complain about editor tool start behaviour, this is the method to change
        firstStartBehaviour() {
            this.firstTimeOpened = true;
            if (this.mode === 'teacher' || this.mode === 'pupil') {
                const tool = this.lastTool || 'pen';
                const size = this.lastToolSize || ((this.mode === 'teacher' || this.accountRole === 'pupil') ? 2 : 13);
                const color = this.lastColor || (this.mode === 'teacher' ? '#e6231e' : '#505faa');
                this.localChangeSize(tool, size);
                this.localChangeColor(color);
            } else if (this.mode === 'creator') {
                this.changeIcon('pen');
            }
        },

        setStempel(newStamp) {
            this.resetTimer();
            this.currentStempel = newStamp;
            this.changeStamp(newStamp);
            this.toggleIcon('stempel');
        },

        localChangeSize(tool, size) {
            this.resetTimer();
            this.changeSize(tool, size);
        },

        localChangeColor(color) {
            this.resetTimer();
            this.changeColor(color);
        },

        changeIcon(icon) {
            this.resetTimer();
            this.toggleIcon(icon);
            this.setTool(icon);
        },

        resetTimer() {
            window.clearTimeout(this.useTimeoutHandle);
            this.useTimeoutHandle = setTimeout(() => {
                this.stayOpenDuringUse = false;
                this.closeToolbar({ closedFromTimeout: true });
            }, this.timeoutDuration);
        },

        close(event) {
            if (!this.shouldBeOpen) {
                // console.log('toolbar close');
                window.clearTimeout(this.useTimeoutHandle);
                this.closeToolbar(event);
            }
        }
    }
}
</script>

<style scoped lang="scss">
#toolbarContainer {
    position: fixed;
    bottom: 30px;
    align-self: center;
    background-color: rgba(100, 100, 100, 0.8);
    border-radius: 8px;
    padding: 0.75em;
    display: inline-flex;
    // Animation Stuff
    transform: translateY(150px);
    transition: all 0.25s ease-out;
}

#toolbarContainer[shouldBeOpen=true] {
    transform: translateY(0);
}

.toolSelector {
    background-color: #f8f8f8 !important;
    width: 49px !important;
    height: 49px !important;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;
    }
}

.activeTool {
    background-color: #d8d8d8 !important;
    filter: invert(0.75);
}

.markerActive {
    border: 2px solid rgb(234, 234, 234);
    background-color: rgb(var(--v-theme-dunkelgrau)) !important;
    color: white;
    padding: 10px !important;
}

#smallDisplayMoreButtons {
    display: block;
    background-color: white;
    width: 250px;
    padding: 0.25em;

    button {
        border-radius: 0 !important;
    }
}

#smallDisplayMoreButtonsTrigger {
    width: 49px !important;
    height: 49px !important;
    padding-left: 2px !important;

    @media (max-width: 430px) {
        width: 40px !important;
        height: 40px !important;
        padding-left: 0 !important;
    }
}

.colorPickerMenu {
    width: 216px !important;
    height: 25px;
    display: inline;
    border-radius: 0 !important;
    background-color: rgb(var(--v-theme-hellgrau));
}

.stempelButton {
    background-color: rgb(var(--v-theme-hellgrau));
    border-radius: 0 !important;
    width: 54px;
    height: 49px !important;
}

.sizeMenu {
    width: 200px;
    height: 25px;
    display: inline;
    border-radius: 0 !important;
    background-color: rgb(var(--v-theme-hellgrau));
}
</style>
