<!-- eslint-disable vuetify/no-deprecated-props -->
<template>
    <v-dialog
        :model-value="localShowDialog"
        :persistent="true"
        width="500"
        content-class="elevation-0"
        opacity="0.8"
        @update:model-value="updateShowDialog"
    >
        <v-card
            id="card"
            style="border-radius: 16px; overflow: visible !important;"
        >
            <v-card-title
                :style="`background-color: ${headerColor}`"
                class="d-flex flex-row justify-space-between"
            >
                <h1
                    tabindex="0"
                    style="color: white"
                >
                    Dateiname
                </h1>

                <v-spacer />

                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    color="transparent"
                    elevation="0"
                    size="x-small"
                    style="width: 30px; min-width: 30px; height: 30px"
                    @click="close"
                >
                    <img
                        class="iconToWhite icon30"
                        alt="Hochladen abbrechen"
                        :src="cancelIcon"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text class="pb-0">
                <v-container class="pa-0">
                    <v-row
                        align="center"
                        justify="center"
                    >
                        <v-col>
                            <v-text-field
                                v-model="newFileName"
                                label="Dateiname"
                                class="mt-0"
                                variant="underlined"
                                hide-details
                                @keydown.enter="confirmAndCallback"
                            />
                        </v-col>
                        <v-col cols="auto">
                            <v-btn
                                elevation="0"
                                class="text-none"
                                theme="dark"
                                color="success"
                                :aria-label="'Datei ' + newFileName + ' hochladen bestätigen'"
                                @click="confirmAndCallback"
                            >
                                {{ changeWording ? 'Speichern' : 'Absenden' }}
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row
                        v-if="group && bonusFunctions.subtitle && isSubtitleReady"
                        class="mt-0 pt-0"
                        align="center"
                        justify="center"
                    >
                        <v-col style="">
                            <v-checkbox
                                v-model="createSubtitles"
                                style="margin-top: 0"
                            >
                                <template v-slot:label>
                                    Untertitel aktivieren
                                    <v-tooltip location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <img
                                               
                                                :src="questionMarkCircleIcon"
                                                class="ml-2"
                                                alt="Fragezeichensymbol"
                                                height="22"
                                                v-bind="props"
                                            >
                                        </template>
                                        <p
                                            align="center"
                                            justify="center"
                                            style="margin: auto; max-width: 200px"
                                        >
                                            Hierbei handelt es sich um eine Betafunktion.
                                            Diese ist aktuell nur mit Videos von einer Länge bis zu einer Minute kompatibel.
                                            Bis die Untertitel erstellt und angezeigt werden, kann es einige Minuten und eine Aktualisierung der Seite benötigen.
                                        </p>
                                    </v-tooltip>
                                </template>
                            </v-checkbox>
                        </v-col>
                    </v-row>

                    <div
                        v-if="group && showIsAssignment"
                        class="ma-0 my-4"
                        style="width: 100%"
                    >
                        <div
                            v-if="accountRole === 'teacher' && showIsAssignment"
                            style="display: flex; flex-direction: row; justify-content: space-between; align-items: center"
                        >
                            <VDatePicker
                                v-model="date"
                                :popover="{ visibility: 'click' }"
                                :masks="{ input: 'DD.MM.YYYY' }"
                                expanded
                                locale="de-DE"
                                first-day-of-week="2"
                            >
                                <template #default="{ inputEvents }">
                                    <v-text-field
                                        v-model="formattedDate"
                                        label="Veröffentlichungsdatum"
                                        prepend-icon="mdi-calendar"
                                        variant="underlined"
                                        readonly
                                        hide-details
                                        class="mt-0"
                                        style="max-width: 200px"
                                        v-on="inputEvents"
                                    />
                                </template>
                            </VDatePicker>

                            <!-- Assignment & Teamwork Checkboxes -->
                            <div style="display: flex; flex-direction: row; align-items: center; max-width: 75%">
                                <v-checkbox
                                    v-model="isAssignment"
                                    label="Abgabe"
                                    class="mt-0"
                                    hide-details
                                />
                                <v-tooltip location="top">
                                    <template v-slot:activator="{ props }">
                                        <img
                                            :src="questionMarkCircleIcon"
                                            class="icon20 ml-2"
                                            alt="Erklärung"
                                            v-bind="props"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        Als Abgabe markierte Materialien müssen von Schülern bearbeitet werden.
                                    </p>
                                </v-tooltip>

                                <v-checkbox
                                    v-if="showTeamworkCheckbox"
                                    v-model="isTeamwork"
                                    label="Gruppenarbeit"
                                    class="mt-0"
                                    hide-details
                                />
                                <v-tooltip
                                    v-if="showTeamworkCheckbox"
                                    location="top"
                                >
                                    <template v-slot:activator="{ props }">
                                        <img
                                            :src="questionMarkCircleIcon"
                                            class="icon20 ml-2"
                                            alt="Erklärung"
                                            v-bind="props"
                                        >
                                    </template>
                                    <p style="max-width: 250px">
                                        Wenn aktiviert, können ausgewählte Schüler*innen parallel an der Hausaufgabe arbeiten
                                    </p>
                                </v-tooltip>
                            </div>
                        </div>
                    </div>
                    <v-row
                        v-if="group && showIsAssignment"
                        class="ma-0"
                        align="center"
                        justify="center"
                        style="width: 100%"
                    >
                        <v-data-table
                            :headers="tableHeader"
                            :items="group.participants"
                            hide-default-footer
                            class="ma-0"
                            show-select
                            disable-sort
                            items-per-page="-1"
                            no-data-text="Keine Empfänger verfügbar"
                            style="width: 100%; max-height: 300px; overflow-y: auto"
                            mobile-breakpoint="10"
                        >
                            <!-- eslint-disable-next-line -->
                            <template v-slot:header.data-table-select>
                                <v-checkbox
                                    v-model="allParticipantsSelected"
                                    :indeterminate="atLeastOneButNotAllParticipantsSelected"
                                    rounded
                                    small
                                    light
                                    class="custom-checkbox"
                                    @update:model-value="headerCheckboxChanged"
                                />
                            </template>

                            <template v-slot:item="row">
                                <tr>
                                    <td>
                                        <v-checkbox
                                            v-model="selectedParticipants"
                                            rounded
                                            :value="row.item.account"
                                            small
                                            light
                                            class="custom-checkbox"
                                        />
                                    </td>
                                    <td>{{ row.item.name }}</td>
                                    <td>{{ row.item.lastName }}</td>
                                    <td>
                                        <v-textarea
                                            v-if="userNotes[row.index] !== undefined && userNotes[row.index].note !== undefined"
                                            v-model="userNotes[row.index].note"
                                            :disabled="!selectedParticipants.includes(row.item.account)"
                                            style="padding: 4px 0; min-width: 140px"
                                            variant="underlined"
                                            no-resize
                                            auto-grow
                                            hide-details
                                            density="compact"
                                            placeholder="Notiz"
                                            rows="1"
                                        />
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </v-row>
                </v-container>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import cancelIcon from '../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg'
import questionMarkCircleIcon from '@/assets/Icons/redesignV3/questionmark.svg';
import slugify from 'slugify';

import { mapState, mapGetters, mapActions } from "vuex";
export default {
    name: "NameFile",
    model: {
        prop: 'showDialog',
        event: 'showChange'
    },
    props: {
        showDialog: {type: Boolean, required: true},
        showIsAssignment: {type: Boolean, required: false, default: true},
        originalName: {type: String, required: false, default: ''},
        callback: {type: Function, required: true},
        isSubtitleReady: {type: Boolean, required: false, default: null},
        group: {type: Object, required: false, default: null},
        appointmentDate: {required: false, default: null, type: Date, },
        changeWording: {type: Boolean, required: false, default: false},
        showTeamworkCheckbox: { type: Boolean, required: false, default: false},
        headerColor: {type: String, required: false, default: 'rgb(var(--v-theme-headerblue))'},
        mountedFrom: { type: String, required: false, default:'cryfoem' }
    },
    emits: ['update:showDialog', 'close', 'showChange'],
    data: () => ({
        cancelIcon,
        questionMarkCircleIcon,

        isAssignment: true,
        createSubtitles: false,
        newFileName: '',
        selectedParticipants: [],
        userNotes: [],
        date: new Date(),
        modal: false,
        isTeamwork: false,
        allParticipantsSelected: false,
        tableHeader: [{ title: 'Vorname', key: 'name'}, { title: 'Nachname' }, { title: 'Notiz'}],
        localShowDialog: false, // Local copy of showDialog
    }),
    computed: {
        ...mapGetters("auth",["accountRole"]),
        ...mapState("util", ["bonusFunctions"]),

        atLeastOneButNotAllParticipantsSelected() {
            return this.selectedParticipants.length > 0 && this.selectedParticipants.length < this.group.participants.length;
        },
        formattedDate() {
            if(this.date){
                return this.date.toLocaleDateString('de-DE', {day: '2-digit', month: '2-digit', year: 'numeric'});
           }
            else{
                return ''
            }
        }
    },
    watch: {
        showDialog(newValue, oldValue) {
            this.localShowDialog = newValue; // Sync local value with prop

            if (newValue && !oldValue) {
                this.newFileName = this.originalName;
                if (this.group) {
                this.selectedParticipants = this.group.participants.map(a => a.account);
                }
            }
        },
        localShowDialog(newValue) {
            this.$emit('update:showDialog', newValue); // Emit changes to parent
        },
        group(val) {
            if(val && val.participants) {
                this.selectedParticipants = val.participants.map(a => a.account);
            }
        },
        selectedParticipants() {
            if(this.selectedParticipants.length === this.group.participants.length) {
                this.allParticipantsSelected = true;
            } else if(this.selectedParticipants.length < this.group.participants.length){
                this.allParticipantsSelected = false;
            }
        },

    },
    async beforeMount() {
        this.newFileName = this.originalName;
        if (this.group !== null && this.accountRole !== 'pupil') {
            this.getParticipants();
        }
    },
    mounted() {
        if(this.appointmentDate) {
            const newDate = new Date(this.appointmentDate);
            newDate.setHours(-newDate.getTimezoneOffset() / 60);
            this.date = newDate;
        } else {
            this.date = new Date().toLocaleDateString('de-DE');
        }
    },
    methods: {
        ...mapActions("groups", ["getGroup"]),
         updateShowDialog(newValue) {
            this.localShowDialog = newValue; // Update local value
        },
        async confirmAndCallback() {
            let notes = JSON.parse(JSON.stringify(this.userNotes));
            for(let i=0; i < notes.length; i++) {
                notes[i].account = this.group.participants[i].account;
            }
            notes = notes.filter((el) => {
                return el.note !== "";
            })
            await this.callback(
                this.sanitizeFilename(this.newFileName),
                this.selectedParticipants,
                notes,
                this.date,
                (this.isAssignment && this.showIsAssignment),
                this.createSubtitles,
                this.isTeamwork,);
            this.createSubtitles = false;
        },
        close() {
            document.getElementById('uploadInput').value = null;
            this.$emit('showChange', false);
            this.$emit('update:showDialog', false)
        },
        headerCheckboxChanged() {
            if(this.allParticipantsSelected) {
                this.selectedParticipants = this.group.participants.map((participant) => participant.account);
            } else {
                this.selectedParticipants = [];
            }
        },
        getParticipants(){
            this.group.participants.map(() => this.userNotes.push({ note: "" }));
            this.selectedParticipants = this.group.participants.map(a => a.account);
        },
        sanitizeFilename(filename) {
            return slugify(filename, {
                replacement: '_',    // replace spaces with underscores
                lower: false,          // preserve case
                strict: true,         // strip special characters except replacement
                locale: 'de'          // use German locale to handle ä, ö, ü correctly
            });
        }
    },
}
</script>
<style scoped>
/* Customize the overlay opacity */
.v-overlay__scrim {
    background-color: rgba(0, 0, 0, 0.7) !important; /* Replace 0.7 with your desired opacity */
}
</style>
