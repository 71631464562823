<template>
    <div>
        <v-menu
            :offset="[0, 130]"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    size="small"
                    icon="$vuetify"
                    variant="plain"
                    elevation="0"
                    class="mr-2"
                    v-bind="props"
                    @click.prevent="openTheMenu"
                >
                    <img
                        :src="menuIcon"
                        :style=" imgError ? '':'height: 30px; opacity: 1;'"
                        :class="imgError ? 'iconToDunkelgrau':'iconToWhite'"
                    >
                </v-btn>
            </template>

            <v-list
                density="compact"
                class="pt-1 px-0"
                style="border-radius: 8px; overflow-y: auto; width: 180px;"
            >
                <v-list-item
                    v-if="enableEditing && usage === 'file'"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('edit')"
                >
                    <template #prepend>
                        <img
                            class="previewTileMenuElementIcon"
                            :src="stiftIcon"
                        >
                    </template>
                    <v-list-item-title>Bearbeiten</v-list-item-title>
                </v-list-item>

                <v-divider v-if="enableEditing && usage === 'file'" />

                <v-list-item
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('details')"
                >
                    <template #prepend>
                        <img
                            class="previewTileMenuElementIcon"
                            :src="infoIcon"
                        >
                    </template>
                    <v-list-item-title>Informationen</v-list-item-title>
                </v-list-item>

                <v-divider />

                <v-list-item
                    v-if="enableImageRotation"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('rotate')"
                >
                    <template #prepend>
                        <v-icon class="previewTileMenuElementIcon">
                            mdi-rotate-right
                        </v-icon>
                    </template>
                    <v-list-item-title>Bild drehen</v-list-item-title>
                </v-list-item>

                <v-divider v-if="enableImageRotation" />

                <v-list-item
                    v-if="showSubmissions"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('openSubmissions')"
                >
                    <template #prepend>
                        <v-icon class="previewTileMenuElementIcon">
                            mdi-folder-open
                        </v-icon>
                    </template>
                    <v-list-item-title>Abgaben einsehen</v-list-item-title>
                </v-list-item>

                <v-divider v-if="showSubmissions" />

                <v-list-item
                    v-if="audioCompatible"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('playAudio')"
                >
                    <template #prepend>
                        <img
                            :src="musikIcon"
                            style="height: 25px; opacity: 1"
                            alt=""
                        >
                    </template>
                    <v-list-item-title>Audiodatei anhören</v-list-item-title>
                </v-list-item>

                <v-divider v-if="audioCompatible" />

                <v-list-item
                    v-if="vfsUsage"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('notifyCopyOrMoveFile')"
                >
                    <template #prepend>
                        <v-icon class="previewTileMenuElementIcon">
                            mdi-file-move-outline
                        </v-icon>
                    </template>
                    <v-list-item-title>Kopieren</v-list-item-title>
                </v-list-item>

                <v-divider v-if="vfsUsage" />

                <v-list-item
                    v-if="saveToFolder"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('openSaveToFolderDialog')"
                >
                    <template #prepend>
                        <v-icon class="previewTileMenuElementIcon">
                            mdi-file-move-outline
                        </v-icon>
                    </template>
                    <v-list-item-title>Im Ordner speichern</v-list-item-title>
                </v-list-item>

                <v-divider v-if="saveToFolder" />

                <v-list-item
                    v-if="downloadable"
                    class="previewTileMenuElement"
                    @click.prevent="listItemClicked('download')"
                >
                    <template #prepend>
                        <img
                            :src="herunterladenIcon"
                            style="height: 25px; opacity: 1"
                            alt="Herunterladen"
                        >
                    </template>
                    <v-list-item-title>Herunterladen</v-list-item-title>
                </v-list-item>

                <v-divider v-if="downloadable" />

                <v-list-item
                    class="previewTileMenuElement"
                    @click.prevent="closeDialog"
                >
                    <template #prepend>
                        <v-icon class="previewTileMenuElementIcon">
                            mdi-close
                        </v-icon>
                    </template>
                    <v-list-item-title>Abbrechen</v-list-item-title>
                </v-list-item>
            </v-list>
        </v-menu>
    </div>
</template>

<script>
import menuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import herunterladenIcon from "@/assets/Icons/FaceliftIcons/herunterladen.svg";
import stiftIcon from '@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg'
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik.svg";
import {mapState} from "vuex";

export default {
    name: "FilePreviewTileMenu",
    props: {
        enableImageRotation: { required: false, type: Boolean, default: false },
        emitButtonPress: { required: true, type: Function },
        showSubmissions: { required: false, type: Boolean, default: false },
        audioCompatible: { required: false, type: Boolean, default: false },
        vfsUsage: { required: false, type: Boolean, default: false },
        downloadable: { required: false, type: Boolean, default: true },
        saveToFolder: { required: false, type: Boolean, default: false },
        imgError:{required: false, type: Boolean, default: false, },
        enableEditing: { required: false, type: Boolean, default: false },
        usage: { required: false, type: String, default: '' },
    },
    data: () => ({
        menuIcon,
        herunterladenIcon,
        musikIcon,
        stiftIcon,
        infoIcon,
        openMenu: false
    }),
    computed: {
        ...mapState("util", ["windowWidth"])
    },
    methods: {
        openTheMenu() {
            this.openMenu = true;
            this.$emit('openMenu', true);
        },
        closeDialog(){
            this.openMenu = false;
            this.$emit('openMenu', false);
            this.$emit('closeDialog');
        },
        listItemClicked(emitValue){
            this.emitButtonPress(emitValue);
        }
    }
}
</script>

<style scoped>
.menuPosition {
    right: 5vw;
}

.menuButton {
    height: 25px !important;
    width: 25px !important;
    min-width: 25px !important;
    background-color: transparent;
    position: fixed;
    pointer-events: all;
    box-shadow: 0 1px 1px rgba(0,0,0,0.11),
    0 2px 2px rgba(0,0,0,0.11),
    0 4px 4px rgba(0,0,0,0.11),
    0 8px 8px rgba(0,0,0,0.11),
    0 16px 16px rgba(0,0,0,0.11),
    0 32px 32px rgba(0,0,0,0.11);
    border-radius: 6px;
}

.previewTileMenuContainer {
    width: 90%;
    height: 100%;
    pointer-events: all;
    z-index: 900;
    background-color: rgb(var(--v-theme-dunkelgrau));
    margin-right: 25px;
}

.previewTileMenuElement {
    width: 95%;
    display: inline-flex;
    justify-content: space-around;
    border-radius: 5px;
    margin: 1px;
    color: black;
    font-size: x-small;
}

.previewTileMenuElementIcon {
    width: 20px;
    color: rgb(var(--v-theme-dunkelgrau)) !important;
}

.no-uppercase {
    text-transform: unset !important;
}
</style>