<template>
    <div
        id="canvasContainer"
        ref="canvasContainer"
        class="soft-box-shadow"
    >
        <!-- Drawing Canvas -->
        <canvas
            id="editcanvas"
            ref="editcanvas"
            :tabindex="tabIndex"
            aria-label="Aufgabenblatt"
            role="form"
            @keydown="handleKeyboardInput"
        />
        <!-- Background Canvas -->
        <canvas
            id="backgroundCanvas"
            ref="backgroundCanvas"
        />
        <div
            v-show="isMovementLayerVisible"
            class="textLayer"
            :style="{ width: canvasWidth + 'px', height: canvasHeight + 'px' }"
            @click="clickOnCanvas($event)"
        />
        <div
            v-show="isTextLayerVisible"
            :id="`textLayer0`"
            ref="textLayer"
            class="textLayer"
            :style="{ width: textLayerWidth + 'px', height: textLayerHeight + 'px' }"
        />
        <div
            v-show="isTextLayerVisible"
            :id="`paragraphLayer0`"
            ref="paragraphLayer"
            class="textLayer"
            :style="{ width: textLayerWidth + 'px', height: textLayerHeight + 'px' }"
            style="pointer-events: none"
        >
            <div
                v-for="(paragraph, index) in displayParagraphsList"
                :key="index"
                class="paragraphContainer"
                :style="{ left: (paragraph.left - 3) + 'px', top: (paragraph.top - 3) + 'px',
                          width: (paragraph.width + 3) + 'px', height: (paragraph.height + 3) + 'px' }"
            >
                <v-btn
                    v-if="person && (!person.accessibility || (person.accessibility && !person.accessibility.screenreader))"
                    size="x-small"
                    elevation="1"
                    style="height: 35px; width: 35px"
                    :class="{ 'paragraphButtonActive': paragraph.isActive }"
                    class="readParagraphButton"
                    @click="readParagraph(paragraph)"
                >
                    <img
                        :src="paragraph.speakerIcon"
                        class="readParagraphButtonImage"
                        alt="Notiz vorlesen"
                    >
                </v-btn>

                <p
                    v-else
                    style="height: 35px; width: 35px"
                    class="readParagraphButton visually-hidden"
                >
                    {{ getTextFromParagraph(paragraph) }}
                </p>
            </div>
        </div>
    </div>
</template>

<script>
    import {fabric} from "fabric";
    import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
    import * as pdfjs from "pdfjs-dist/legacy/build/pdf";
    import pdfTextLayer from '../../util/pdfTextLayer'
    import { toRaw, markRaw } from 'vue'
    // import vuescroll from 'vuescroll'

    export default {
        name: "EditingCanvas",
        components: {
            // vuescroll,
        },
        props: {
            canvasWidth: {type: Number, required: true},
            canvasHeight: {type: Number, required: true},
            loadingFunction: { type: Function, required: true },
            currentShownPage: { type: Object, required: true },
            pageIndex: { type: Number, required: false, default: 0 },
            tabIndex: { required: false, type: Number, default: 0 },
            isTextLayerVisible: { type: Boolean, required: true },
            maxWidth: { required: false, type: Number, default: null },
        },
        data: () => ({
            localCanvas: null,
            backgroundImg: null,
            currentActiveTool: 'none',
            currentColor: '#706f6f',
            paintSize: 2,
            isObjectSelected: false,
            history: [],
            textLayerWidth: 0,
            textLayerHeight: 0,
            currentStempel: null,
            scaling: null,
            originalOrientation: null,
            rotationAngle: 0,

            paragraphList: [],
            displayParagraphsList: [],

            // UI Changes
            person: {
                accessibility: {
                    screenreader: false,
                },
            },
            isMovementLayerVisible: true,
        }),
        computed: {
            ...mapGetters("auth",["accountRole"]),
            ...mapGetters('files', ['getWork']),
            ...mapState("editorStore", ["dragndrop"]),
            filterHistoryForAdded() {
                const result = [];

                const addedArr = this.history.filter(item => item.action === 'added').map(item => item.object);
                const removedArr = this.history.filter(item => item.action === 'removed').map(item => item.object);

                addedArr.forEach(addedItem => {
                    if (!removedArr.includes(addedItem)) {
                        result.push(addedItem);
                    }
                });

                return result;
            },
        },
        watch: {
            currentShownPage(newVal, oldVal) {
                if (newVal.background !== oldVal.background
                    || newVal.canvas !== oldVal.canvas) {
                    this.initCanvas();
                }
            },
        },
        beforeMount() {
            this.requestPerson();
        },
        async mounted() {
            // this.initCanvas()
            window.addEventListener("orientationchange", this.handleOrientationChange);

            // Add Click Listener to canvas for active Page change
            this.$refs.canvasContainer.addEventListener('click', (e) => {
                this.$emit('setActivePage');
            });
        },
        beforeUnmount() {
            window.removeEventListener("orientationchange", this.handleOrientationChange);
        },
        methods: {
            ...mapActions('files', ['safeWork']),
            ...mapActions('pupils', ['getMePupil']),
            ...mapMutations("snackbar", ["showSnackbar"]),

            clickOnCanvas(event) {
                if(this.dragndrop) {
                    const options = {
                        // x: event.clientX,
                        // y: event.clientY,
                        x: ((event.layerX || event.offsetX) / this.canvasWidth) * 100,
                        y: ((event.layerY || event.offsetY) / this.canvasHeight) * 100,
                    };
                    this.$emit('addElement', 'dragndropfreetarget', options);
                }
            },

            async requestPerson() {
                if(this.accountRole === 'pupil') {
                    this.person = await this.getMePupil();
                    return this.person;
                }
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            dispose(fully = true, absolutely = false) {
                if (this.localCanvas) {
                    this.localCanvas.clear();
                    if (fully) {
                        this.localCanvas.dispose();
                        this.localCanvas.remove();

                        if (absolutely) {
                            // remove created canvas-container manually
                            const containers = Array.from(document.getElementsByClassName('canvas-container'));
                            const containerToRemove = containers.find(
                                container => Array.from(container.childNodes).find(
                                    child => child.id === 'editcanvas'));
                            if (containerToRemove) {
                                containerToRemove.before(document.getElementById('editcanvas'));
                                containerToRemove.remove();
                            }
                        }
                    }
                }
            },
            handleKeyboardInput(event) {
                switch (event.keyCode) {
                    case 107: //plus zoom
                        this.$emit('updateZoom', 'plus');
                        break;
                    case 109: //minus zoom
                        this.$emit('updateZoom', 'minus');
                        break;
                    case 46: // delete
                        for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                            this.history.push({ action: 'removed', object: this.localCanvas.getActiveObjects()[i] });
                            this.$emit('canvasContentChanged');
                        }

                        for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                            this.localCanvas.remove(this.localCanvas.getActiveObjects()[i]);
                        }
                        break;
                    case 37: // Arrow left
                    case 38: // Arrow up
                    case 39: // Arrow right
                    case 40: // Arrow down
                        this.arrowKeysPressed(event); break;
                    case 32: // Spacebar
                    case 13: // Enter
                        // WIP
                        if (this.localCanvas.getActiveObject() && this.localCanvas.getActiveObject().type === 'i-text') {
                            const elementObj = this.localCanvas.getActiveObject();
                            const hitPoint = new fabric.Point(elementObj.left, elementObj.top);
                            this.localCanvas.fire('mouse:down', {
                                e: event,
                                absolutePointer: hitPoint,
                                button: 1,
                                isClick: false,
                                subTargets: [],
                                target: elementObj,
                                transform: null,
                            });
                            // this.localCanvas.getActiveObject().fire('mouse:dblclick', { target: elementObj });
                        }
                        break;
                }

            },
            arrowKeysPressed(keyEvent) {
                if (keyEvent.shiftKey) { // switch between items on canvas
                    let wantedObject = null;
                    const filteredHistory = this.filterHistoryForAdded;

                    if (!this.localCanvas.getActiveObject()) { // No canvas obj is active
                        if (keyEvent.keyCode === 40) {
                            wantedObject = filteredHistory[0];
                        } else if (keyEvent.keyCode === 38) {
                            wantedObject = filteredHistory[filteredHistory.length - 1];
                        }
                    } else {
                        const currentActiveObj = this.localCanvas.getActiveObject();
                        const activeObjIndex = filteredHistory.findIndex(item => item === currentActiveObj);

                        let newIndex = 0;
                        if (keyEvent.keyCode === 40) {
                            newIndex = activeObjIndex === filteredHistory.length-1 ? 0 : activeObjIndex + 1;
                        } else if (keyEvent.keyCode === 38) {
                            newIndex = activeObjIndex === 0 ? filteredHistory.length-1 : activeObjIndex - 1;
                        }
                        wantedObject = filteredHistory[newIndex];
                    }

                    this.localCanvas.setActiveObject(wantedObject);
                    this.localCanvas.requestRenderAll();

                } else { // move active item on canvas
                    const currentActiveObj = this.localCanvas.getActiveObject();

                    if (currentActiveObj) {
                        switch (keyEvent.keyCode) {
                            case 37: currentActiveObj.left -= 5; break;
                            case 38: currentActiveObj.top -= 5;  break;
                            case 39: currentActiveObj.left += 5; break;
                            case 40: currentActiveObj.top += 5; break;
                        }
                        this.localCanvas.requestRenderAll();
                    }
                }
            },
            addControlsBackToItext(object) {
                var imageCopy = new Image();
                // eslint-disable-next-line no-useless-escape
                imageCopy.src = `data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23${object.cornerColor.substring(1)};\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E`;
                var itextClose = new fabric.Control({
                    x: 0.53,
                    y: -1.15,
                    offsetY: 16,
                    cursorStyle: 'pointer',
                    mouseUpHandler: (eventData, target) => {
                        this.localCanvas.remove(target.target);
                        this.history.push({ action: 'removed', object: target.target });
                        this.$emit('canvasContentChanged');
                    },
                    render: (ctx, left, top, styleOverride, fabricObject) => {
                        var size = 24;
                        ctx.save();
                        ctx.translate(left, top);
                        ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                        ctx.drawImage(imageCopy, -size/2, -size/2, size, size);
                        ctx.restore();
                    },
                    cornerSize: 24,
                    actionHandler: () => {},
                })

                object.controls = { ...object.controls, itextClose };
            },
            changeCanvasDimensions(width = 0, height = 0) {

                const sacale = (this.maxWidth || window.innerWidth * 0.75);

                let returnWidth;
                let returnHeight;

                if (width > sacale) {
                    const scaleH = sacale / width;
                    returnWidth = sacale;
                    returnHeight = height * scaleH;
                } else {
                    returnWidth = width;
                    returnHeight = height;
                }

                this.$emit('dimensionsChanged',{ width: returnWidth, height: returnHeight });

                return { width: returnWidth, height: returnHeight };
            },
            initCanvas(preview = null){
                console.log('ref',this.$refs);
                this.localCanvas = new fabric.Canvas(this.$refs.editcanvas);

                this.localCanvas.backgroundColor = null;
                // console.log('currentShownPage', this.currentShownPage);
                if (this.currentShownPage.canvas) {
                    if (this.currentShownPage.canvas.content) {
                        this.localCanvas.loadFromJSON(
                            this.currentShownPage.canvas.content,
                            this.localCanvas.renderAll.bind(this.localCanvas),
                            (o, object) => {
                                if (preview && preview.offset) {
                                    object.scale(object.scaleX * preview.offset);
                                    object.left = object.left * preview.offset;
                                    object.top = object.top * preview.offset;
                                }

                                if (object.type === 'i-text') {
                                    this.addControlsBackToItext(object)
                                }
                            });

                        let width = this.canvasWidth;
                        let height = this.canvasHeight;
                        if (preview && preview.offset) {
                            const data = this.changeCanvasDimensions(
                                this.maxWidth || this.canvasWidth * preview.offset,
                                this.canvasHeight * preview.offset);
                            width = data.width;
                            height = data.height;
                        }

                        this.localCanvas.setDimensions({ width, height })
                    } else if (this.currentShownPage.canvas.pageType) {
                        const width = this.canvasWidth !== 300 ? this.canvasWidth : 0;
                        const height = this.canvasHeight !== 600 ? this.canvasHeight : 0;
                        this.setEmptyBackground(this.currentShownPage.canvas.pageType, width, height);
                    }
                }

                if (this.currentShownPage.background) {
                    if (this.currentShownPage.fileType === 'pdf') {
                        this.setBackgroundPDF(this.currentShownPage.background);
                        setTimeout(() => {
                            this.setPDFTextLayer(this.currentShownPage.background);
                        }, 750);
                        // Is needed for change of page
                        // TODO: refactor to promise api later
                        setTimeout(() => {
                            if (this.isTextLayerVisible) {
                                this.calculateParagraphPositions();
                            }
                        }, 900)
                    } else {
                        this.setBackgroundImg(this.currentShownPage.background, 'local');
                    }
                } else {
                    this.clearBackgroundCanvas();
                }

                this.localCanvas.isDrawingMode = false;
                this.localCanvas.on('path:created', this.addedDrawing);
                this.localCanvas.on('selection:created', this.onCanvasObjectSelected);
                this.localCanvas.on('selection:cleared', this.onCanvasObjectDeselected);
                this.localCanvas.on('before:selection:cleared ', this.onBeforeCanvasObjectDeselected);
                this.localCanvas.on('mouse:up', this.handleMouseInput);
                this.localCanvas.on('before:transform', this.onObjectMovingStart);
                this.localCanvas.on('text:editing:entered', this.enteredEditingText);

                this.localCanvas.freeDrawingBrush.width = this.paintSize;
                this.localCanvas.freeDrawingBrush.color = this.currentColor;

                this.updateTool();
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            convertCanvasToJSON() {
                let canvasJson = {};
                if (this.localCanvas) {
                    canvasJson = this.localCanvas.toJSON([
                        'padding',
                        'borderColor',
                        'cornerColor',
                        'cornerStyle',
                        'selectionBackgroundColor',
                        'backgroundColor'
                    ]);
                }
                return { fittingPageIndex: this.pageIndex, canvasJson }
            },

            setBackground(img, imgData, canvasId = 'background') {
                try {

                    const element = imgData.path && imgData.path.length > 0 ? imgData.path[0] : imgData.target;

                    let imgWidth = element.naturalWidth;
                    let imgHeight = element.naturalHeight;
                    const { width, height } = this.changeCanvasDimensions(imgWidth, imgHeight);

                    // const imageForCanvases = new Image();
                    // imageForCanvases.src = imgData.path[0].src;
                    const fabricImage = new fabric.Image(element);

                    if (imgWidth !== width || imgHeight !== height) {
                        fabricImage.scaleToWidth(width);
                        fabricImage.scaleToHeight(height);
                    }

                    this.backgroundImg = element;
                    this.$nextTick(() => {

                        if (canvasId === 'background') {
                            this.$refs.backgroundCanvas.width = width;
                            this.$refs.backgroundCanvas.height = height;
                            this.$refs.backgroundCanvas.getContext('2d').drawImage(
                                element,
                                0,
                                0,
                                width,
                                height
                            );
                        }

                        this.localCanvas.setDimensions({ width, height });
                        if (canvasId === 'local') {
                            this.localCanvas.setBackgroundImage(fabricImage, this.localCanvas.renderAll.bind(this.localCanvas));
                        }
                        this.loadingFunction(false);
                    })

                    // this.initCanvas();

                } catch (e) {
                    console.error(e);
                }
            },
            async setBackgroundPDF(pdfPage) {
                const rawPage = toRaw(pdfPage);
                const firstPage = markRaw(rawPage);
                const viewport = firstPage.getViewport({scale: 1.5});

                let createdCanvas = document.createElement('canvas');
                createdCanvas.width = viewport.width;
                createdCanvas.height = viewport.height;

                const canvasContext = createdCanvas.getContext('2d');
                const renderContext = {canvasContext, viewport, renderInteractiveForms: true};
                const renderTask = firstPage.render(renderContext);
                renderTask.promise.then(() => {
                    const zwischenUrl = createdCanvas.toDataURL('image/png');

                    const zwischenImage = new Image();
                    zwischenImage.onload = (el) => {
                        this.setBackground(zwischenImage, el);
                    }
                    zwischenImage.src = zwischenUrl;
                    createdCanvas.remove();
                    createdCanvas = null;
                    firstPage.cleanup();
                });

            },
            async setPDFTextLayer(pdfPage, width = null, height = null) {
                try {
                    this.paragraphList = [];
                    const textContent = await pdfPage.getTextContent();
                    this.textLayerHeight = height || this.canvasHeight;
                    this.textLayerWidth = width || this.canvasWidth;

                    const oldVP = pdfPage.getViewport({ scale: 1 });
                    const viewport = pdfPage.getViewport({ scale: this.textLayerWidth / oldVP.width });

                    const response = pdfjs.renderTextLayer({
                        textContent: textContent,
                        container: this.$refs.textLayer,
                        viewport,
                        textDivs: [],
                        scale: this.textLayerWidth / oldVP.width
                    });
                    // Fills list with paragraphs that belong together
                    this.paragraphList = pdfTextLayer.createTextParagraphs(response._textDivs);

                    if (!response._textDivs.length) {
                        this.$emit('textDivsEmpty');
                    }
                    // Todo: keep, might be needed in future
                    // const annotationData = await pdfPage.getAnnotations();
                    // // Render the annotation layer
                    // pdfjs.AnnotationLayer.render({
                    //     viewport: viewport.clone({dontFlip: true}),
                    //     div: this.$refs.textLayer,
                    //     annotations: annotationData,
                    //     page: pdfPage,
                    //     renderInteractiveForms: true,
                    // });
                } catch (e) {
                    console.warn(e);
                }
            },
            clearBackgroundCanvas() {
                this.$refs.backgroundCanvas.getContext('2d')
                    .clearRect(0,0,this.canvasWidth, this.canvasHeight);
            },

            calculateParagraphPositions() {
                // Computes width and height of paragraphs and adds more elements to the paragraph when fitting
                this.displayParagraphsList = pdfTextLayer.calculateParagraphPositions([...this.paragraphList]);
            },
            readParagraph(paragraph) {

                let text = '';
                for (let i = 0; i < paragraph.listItem.length; i++) {
                    text += paragraph.listItem[i].innerHTML + ' ';
                }
                function changeSpeakerIcon(iconToSet, isActive) {
                    paragraph.speakerIcon = iconToSet;
                    paragraph.isActive = isActive;
                }

                this.$emit('readParagraph', text, changeSpeakerIcon);
            },

            getTextFromParagraph(paragraph) {
                let text = '';
                for (let i = 0; i < paragraph.listItem.length; i++) {
                    text += paragraph.listItem[i].innerHTML + ' ';
                }
                return text;
            },

            async setBackgroundImg(img, canvasId = 'local') {
                if (img) {
                    this.setBackground(null, img, canvasId);
                }
            },
            async setEmptyBackground(type, width = 0, height = 0) {
                const wantedWidth = (width > 0 ? width : window.innerWidth * 0.75);
                const standardHeight = wantedWidth * Math.sqrt(2);
                let wantedHeight = (height > 0 ? height : standardHeight);
                if (type === 'folie') {
                    wantedHeight = wantedWidth * 0.75;
                }

                this.localCanvas.backgroundColor = "#fff";
                this.localCanvas.setDimensions({ width: wantedWidth, height: wantedHeight });
                this.$emit('dimensionsChanged',
                    { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                if (type === 'kariert') {

                    const kantenlaenge = wantedHeight/30;

                    for (let i = 0; i < 30; i++) {

                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let horizontalLine = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * kantenlaenge,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(horizontalLine);
                    }

                    for (let i = 0; i < wantedWidth / kantenlaenge - 1; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let verticalLine = new fabric.Line([0, 0, 0, wantedHeight], {
                            left: i * kantenlaenge,
                            top: 0,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(verticalLine);
                    }

                    this.localCanvas.renderAll();

                } else if (type === 'liniert') {

                    for (let i = 0; i < 20; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2
                        let line = new fabric.Line([0, 0, wantedWidth, 0], {
                            left: 0,
                            top: i * wantedHeight/20,
                            stroke: '#ddd',
                            selectable: false,
                            evented: false
                        });

                        this.localCanvas.add(line);

                    }

                    this.localCanvas.renderAll();

                } else if (type === 'haus') {

                    const lineHeight = (wantedHeight / 15) * 0.75;

                    for (let i = 1; i < 15; i++) {
                        // Numbers are start and end Coords, sorted: x1, y1, x2, y2

                        let topLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(topLine);

                        let topMiddleLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: (i * wantedHeight/15) + (lineHeight * 0.3),
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(topMiddleLine);

                        let bottomMiddleLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: (i * wantedHeight/15) + (lineHeight * 0.7),
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(bottomMiddleLine);

                        let bottomLine = new fabric.Line([0, 0, wantedWidth * 0.8, 0], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15 + lineHeight,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(bottomLine);

                        let leftVerticalLine = new fabric.Line([0, 0, 0, lineHeight], {
                            left: wantedWidth * 0.1,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(leftVerticalLine);

                        let rightVerticalLine = new fabric.Line([0, 0, 0, lineHeight], {
                            left: wantedWidth * 0.9,
                            top: i * wantedHeight/15,
                            stroke: '#555',
                            selectable: false,
                            evented: false
                        });
                        this.localCanvas.add(rightVerticalLine);
                    }

                    this.localCanvas.renderAll();
                }

                this.loadingFunction(false);

            },
            addedDrawing(event) {
                if (this.currentActiveTool === 'marker') {
                    event.path.opacity = 0.3;
                }
                this.history.push({ action: 'added', object: event.path });
                this.$emit('canvasContentChanged');
                this.localCanvas.discardActiveObject();
            },
            spawnText(xCoord, yCoord, text = 'Doppelt tippen zum Schreiben') {
                try {
                    let usableXCoord = xCoord ? xCoord : this.localCanvas.width / 2;
                    let usableYCoord = yCoord ? yCoord : this.localCanvas.height / 2;

                    var imageCopy = new Image();
                    // eslint-disable-next-line no-useless-escape
                    imageCopy.src = `data:image/svg+xml,%3C%3Fxml version=\'1.0\' encoding=\'utf-8\'%3F%3E%3C!DOCTYPE svg PUBLIC \'-//W3C//DTD SVG 1.1//EN\' \'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd\'%3E%3Csvg version=\'1.1\' id=\'Ebene_1\' xmlns=\'http://www.w3.org/2000/svg\' xmlns:xlink=\'http://www.w3.org/1999/xlink\' x=\'0px\' y=\'0px\' width=\'595.275px\' height=\'595.275px\' viewBox=\'200 215 230 470\' xml:space=\'preserve\'%3E%3Ccircle style=\'fill:%23${this.currentColor.substring(1)};\' cx=\'299.76\' cy=\'439.067\' r=\'218.516\'/%3E%3Cg%3E%3Crect x=\'267.162\' y=\'307.978\' transform=\'matrix(0.7071 -0.7071 0.7071 0.7071 -222.6202 340.6915)\' style=\'fill:white;\' width=\'65.545\' height=\'262.18\'/%3E%3Crect x=\'266.988\' y=\'308.153\' transform=\'matrix(0.7071 0.7071 -0.7071 0.7071 398.3889 -83.3116)\' style=\'fill:white;\' width=\'65.544\' height=\'262.179\'/%3E%3C/g%3E%3C/svg%3E`;

                    var itext = new fabric.IText(text, {
                        top: usableYCoord,
                        left: usableXCoord,
                        originX: 'center',
                        fill: this.currentColor,
                        fontFamily: 'eKlara Custom',
                        fontSize: 30,
                        padding: 10,
                        borderColor: this.currentColor,
                        cornerColor: this.currentColor,
                        cornerStyle: 'circle',
                        selectionBackgroundColor: '#FFFF00'
                    });

                    var itextClose = new fabric.Control({
                        x: 0.53,
                        y: -1.15,
                        offsetY: 16,
                        cursorStyle: 'pointer',
                        mouseUpHandler: (eventData, target) => {
                            this.localCanvas.remove(target.target);
                            this.history.push({ action: 'removed', object: target.target });
                            this.$emit('canvasContentChanged');
                        },
                        render: (ctx, left, top, styleOverride, fabricObject) => {
                            var size = 24;
                            ctx.save();
                            ctx.translate(left, top);
                            ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
                            ctx.drawImage(imageCopy, -size/2, -size/2, size, size);
                            ctx.restore();
                        },
                        cornerSize: 24,
                        actionHandler: () => {},
                    })

                    itext.controls = { ...itext.controls, itextClose };

                    this.localCanvas.add(itext);
                    this.history.push({ action: 'added', object: itext });
                    this.$emit('canvasContentChanged');

                    this.$nextTick(() => {
                        this.localCanvas.setActiveObject(itext);
                    })
                } catch (e) {
                    console.error(e);
                }
            },
            handleMouseInput(event) {
                // Beispiel für ein Close Icon

                if (this.currentActiveTool === 'text' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;

                    this.spawnText(pos.x, pos.y);

                } else if (this.currentActiveTool === 'stempel' && this.localCanvas.getActiveObject() == null && !this.isObjectSelected) {
                    let pos = event.pointer;
                    try {

                        fabric.loadSVGFromURL(this.currentStempel, (objects, options) => {
                            var image = fabric.util.groupSVGElements(objects, options);

                            image.set({
                                left: pos.x - 15,
                                top: pos.y - 15,
                            });
                            image.scaleToWidth(75);
                            image.scaleToHeight(75);

                            this.localCanvas.add(image);
                            this.history.push({ action: 'added', object: image });
                            this.$emit('canvasContentChanged');

                        });
                    } catch (e) {
                        console.error(e);
                    }
                }
            },
            onObjectMovingStart(event) {
                if (event.transform.action === 'drag' && event.e.altKey) {
                    let objToDuplicate = null;
                    this.localCanvas.getActiveObject().clone((cloned) => {
                        objToDuplicate = cloned;
                    });

                    if (objToDuplicate) {
                        objToDuplicate.canvas = this.localCanvas;
                        objToDuplicate.setCoords();

                        // Special settings for text because clone() doesnt copy them
                        if (objToDuplicate.type === 'i-text') {
                            objToDuplicate.controls = this.localCanvas.getActiveObject().controls;
                            objToDuplicate.padding = 10;
                            objToDuplicate.borderColor = this.currentColor;
                            objToDuplicate.cornerColor = this.currentColor;
                            objToDuplicate.cornerStyle = 'circle';
                            objToDuplicate.selectionBackgroundColor = '#FFFF00';
                        }

                        this.localCanvas.add(objToDuplicate)
                        this.history.push({ action: 'added', object: objToDuplicate });
                        this.$emit('canvasContentChanged');
                    }

                }
            },
            onCanvasObjectSelected(event) {
                this.isObjectSelected = true;

                // handle eraser
                if (this.currentActiveTool === 'eraser') {

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.history.push({ action: 'removed', object: this.localCanvas.getActiveObjects()[i] });
                        this.$emit('canvasContentChanged');
                    }

                    for (let i = 0; i < this.localCanvas.getActiveObjects().length; i++) {
                        this.localCanvas.remove(this.localCanvas.getActiveObjects()[i]);
                    }

                    this.localCanvas.discardActiveObject();

                }
            },
            onBeforeCanvasObjectDeselected(event) {
                this.isObjectSelected = false;
            },
            onCanvasObjectDeselected(event) {
                this.localCanvas.off('mouse:up', this.handleMouseInput);
                this.isObjectSelected = false;
                setTimeout(() => {
                    this.localCanvas.on('mouse:up', this.handleMouseInput);
                }, 250);
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            changeColor(newColor) {
                this.localCanvas.freeDrawingBrush.color = newColor;
                this.currentColor = newColor;
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            switchTool(newTool) {
                this.isMovementLayerVisible = false;
                this.currentActiveTool = newTool;
                this.updateTool();
            },
            updateTool() {
                if (this.currentActiveTool === 'eraser') {
                    this.localCanvas.isDrawingMode = false;
                } else if (this.currentActiveTool === 'pen' || this.currentActiveTool === 'marker') {
                    this.localCanvas.isDrawingMode = true;
                    this.localCanvas.freeDrawingBrush.color = this.currentColor;
                } else if (this.currentActiveTool === 'text') {
                    // this.showSnackbar({ message: "Tippe auf die Stelle an der der Text später sein soll." });
                    this.textToolActiveSnackbar = true;
                    this.localCanvas.isDrawingMode = false;
                } else if (this.currentActiveTool === 'stempel') {
                    this.localCanvas.isDrawingMode = false;
                }

                if (this.currentActiveTool === 'none') {
                    this.localCanvas.isDrawingMode = false;
                    this.isMovementLayerVisible = true;
                }
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            undo() {
                if (this.history.length) {
                    if (this.history[this.history.length - 1].action === 'added') {
                        const objToRemove = this.localCanvas.getObjects()
                            .find(item => item === this.history[this.history.length - 1].object);

                        this.localCanvas.fxRemove(objToRemove);
                        this.history.splice(this.history.length - 1);
                        this.localCanvas.renderTop();
                    } else if (this.history[this.history.length - 1].action === 'removed') {
                        const objToAdd = this.history[this.history.length - 1].object;
                        this.localCanvas.add(objToAdd);

                        this.history.splice(this.history.length - 1);
                    }
                }

            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            changeSize(pixelCount) {
                if (this.localCanvas.isDrawingMode) {
                    this.localCanvas.freeDrawingBrush.width = parseInt(pixelCount);
                    this.paintSize = parseInt(pixelCount);
                }
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            setStempelImg(stempelImg) {
                this.currentStempel = stempelImg;
            },
            rotate() {
                let orientation = 'landscape';
                window.orientation === 0 || window.orientation === 180 ? orientation = 'portrait' : orientation = 'landscape';

                const containerWidth = window.innerWidth * 0.75
                // if (this.localCanvas.getWidth() >= containerWidth) {
                    const width = containerWidth;
                    const height = this.localCanvas.getHeight() * (containerWidth / this.localCanvas.getWidth());

                    const scalingFactor = containerWidth / this.localCanvas.getWidth();

                    this.localCanvas.setDimensions({width, height});
                    this.$refs.backgroundCanvas.width = width;
                    this.$refs.backgroundCanvas.height = height;
                    if (this.backgroundImg) {
                        this.$refs.backgroundCanvas.getContext('2d').drawImage(
                            this.backgroundImg,
                            0, 0, width, height
                        );
                        this.setPDFTextLayer(this.currentShownPage.background, width, height);
                        // Is needed for mobile device rotation when textlayer is active
                        // TODO: refactor to promise api later
                        setTimeout(() => {
                            if (this.isTextLayerVisible) {
                                this.calculateParagraphPositions();
                            }
                        }, 500)
                    }
                    if (!this.scaling || (this.originalOrientation && this.originalOrientation !== orientation)) {
                        this.scaling = scalingFactor;
                        this.localCanvas.setZoom( scalingFactor);
                    } else {
                        this.localCanvas.setZoom(1);
                    }

                    if (!this.originalOrientation) {
                        orientation === 'portrait'
                            ? this.originalOrientation = 'landscape' : this.originalOrientation = 'portrait'
                        // this.originalOrientation = orientation;
                    }
                // }

            },
            handleOrientationChange() {
                setTimeout(() => {this.rotate()}, 250);
            },
            // vue/no-unused-properties cannot detect methods by $ref, so make a exception
            // eslint-disable-next-line vue/no-unused-properties
            rotateEditor() {
                // Rotating the canvas itsef
                let newCanvasWidth = this.localCanvas.getHeight();
                let newCanvasHeight = this.localCanvas.getWidth();
                const scalingFactor = (window.innerWidth * 0.75) / newCanvasWidth;
                this.scaling = scalingFactor;

                newCanvasHeight = newCanvasHeight * scalingFactor;
                newCanvasWidth = window.innerWidth * 0.75;

                this.localCanvas.setDimensions({ width: newCanvasWidth, height: newCanvasHeight });
                this.$emit('dimensionsChanged',
                    { width: this.localCanvas.getWidth(), height: this.localCanvas.getHeight() });

                // Rotating canvas background
                const previousRotation = this.localCanvas.backgroundImage.angle % 360;
                this.localCanvas.backgroundImage.angle += 90;
                this.rotationAngle = this.localCanvas.backgroundImage.angle;
                this.localCanvas.backgroundImage.scaleToWidth(newCanvasWidth);
                this.localCanvas.backgroundImage.scaleToHeight(newCanvasHeight);

                switch (this.localCanvas.backgroundImage.angle % 360) {
                    case 0:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                    case 90:
                        this.localCanvas.backgroundImage.top = 0;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 180:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = newCanvasWidth;
                        break;
                    case 270:
                        this.localCanvas.backgroundImage.top = newCanvasHeight;
                        this.localCanvas.backgroundImage.left = 0;
                        break;
                }
                this.localCanvas.backgroundImage.setCoords();

                // Rotating canvas objects
                this.localCanvas.getObjects().forEach((obj) => {
                    obj.angle += 90 //rotate each object by the same angle
                    let objOrientation = obj.angle;
                    let localScale =
                        Math.abs(objOrientation) % 360 === 0
                        || Math.abs(objOrientation) % 360 === 180 ? 1 : scalingFactor;
                    // Special case if obj is svg sticker
                    if (obj.svgUid) {
                        const normScale = obj.oldScale ? obj.oldScale : obj.scaleX;
                        obj.oldScale = normScale;
                        localScale = normScale * localScale;
                    }
                    obj.scale(localScale);
                    let oldTop;
                    let oldLeft;
                    oldTop = obj.top;
                    oldLeft = obj.left;

                    obj.top = oldLeft * scalingFactor;
                    obj.left = newCanvasWidth - oldTop * scalingFactor;

                    obj.setCoords();
                });

                this.localCanvas.renderAll()
                return { previousRotation, newRotation: this.localCanvas.backgroundImage.angle};
            },
            enteredEditingText(options) {
                if (options.target.text === 'Doppelt tippen zum Schreiben') {

                    options.target.selectAll();
                    options.target.text = "";
                    if (options.target.hiddenTextarea) {
                        options.target.hiddenTextarea.value = "";
                    }
                    // options.target.backgroundColor = '#FFFF00'

                    this.localCanvas.renderAll();
                }
            },
        },

    }
</script>

<style lang="scss">

    #canvasContainer {
        border-radius: 5px;
        border: 1px solid grey;

        & .canvas-container {
            z-index: 1 !important;
        }
    }

    .textLayer {
        position: absolute !important;
        transform-origin: 0 0;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        opacity: .8;
        line-height: 1.0;
        z-index: 1;

        span {
            display: block;
            position: absolute !important;
            cursor: text;
            color: transparent !important;
            opacity: .4;
            white-space: pre;
            transform-origin: 0% 0%;
        }

        .linkAnnotation > a {
            position: absolute;
            font-size: 1em;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
        section {
            color: transparent;
            position: absolute !important;
            white-space: pre;
            cursor: text;
            transform-origin: 0% 0%;

            /* hide the note-icon, since the image is broken */
            img {
                display: none;
            }

            .popup{
                color: black;

                /* Hide the date and time within the popup, since they are incorrectly displayed */
                span{
                    display: none;
                }
            }
        }
    }

    .paragraphContainer {
        position: absolute;
        border: 2px solid lightskyblue;
        pointer-events: none;

        button {
            position: absolute;
            right: -50px;
            pointer-events: all;
        }
    }

    .readParagraphButton {
        span {
            opacity: 0.8 !important;
        }
    }

    .paragraphButtonActive {
        background-color: rgb(var(--v-theme-rot)) !important;

        img {
            filter: brightness(1000%);
        }
    }

    .readParagraphButtonImage {
        height: 30px;
        filter: brightness(0%);
        color: black;
    }

    #backgroundCanvas {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 0;
    }

</style>
