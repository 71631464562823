<template>
    <v-menu
        v-model="menuOpen"
        :offset="[0, 10]"
    >
        <template v-slot:activator="{ props }">
            <v-btn
                variant="text"
                style="text-transform: inherit;"
                :theme="pupil.status && pupil.status !== 'normal' ? 'dark':'light'"
                v-bind="props"
            >
                {{ readableStatus(pupil.status) }}
                <v-icon
                    end
                    style="font-size: small;"
                    :style="`transform: rotate(${menuOpen ? '180' : '0'}deg);
                        color: var(--v-theme-${pupil.status && pupil.status !== 'normal' ? 'weiss' : 'dunkelgrau'})`"
                >
                    mdi-chevron-down
                </v-icon>
            </v-btn>
        </template>
        <div
            style="background-color: rgb(var(--v-theme-hellgrau))"
            class="d-flex flex-column"
        >
            <v-btn
                variant="text"
                style="text-transform: inherit"
                @click="changePupilStatus('normal')"
            >
                Normale Versetzung
            </v-btn>
            <v-btn
                variant="text"
                style="text-transform: inherit"
                @click="changePupilStatus('notTransferred')"
            >
                Keine Versetzung
            </v-btn>
            <v-btn
                variant="text"
                style="text-transform: inherit"
                @click="changePupilStatus('changeSchools')"
            >
                Schulwechsel
            </v-btn>
            <v-menu
                v-if="otherClasses.length"
                :offset="[10, 0]"
            >
                <template v-slot:activator="{ props }">
                    <v-btn
                        variant="text"
                        style="text-transform: inherit"
                        v-bind="props"
                    >
                        Wechsel in Klasse
                        <v-icon
                            end
                            style="font-size: small; color: rgb(var(--v-theme-dunkelgrau))"
                        >
                            mdi-chevron-right
                        </v-icon>
                    </v-btn>
                </template>
                <div
                    class="d-flex flex-column"
                    style="overflow-y: scroll; max-height: 200px; background-color: rgb(var(--v-theme-hellgrau))"
                >
                    <v-btn
                        v-for="(otherClass, index) in displayableOtherClasses"
                        :key="`${index}${otherClass}`"
                        variant="text"
                        style="text-transform: inherit"
                        :disabled="!!disabledClasses.find(currentClass => currentClass._id === otherClass._id)"
                        @click="differentClassSelected(otherClass)"
                    >
                        {{ otherClass.name }}
                    </v-btn>
                </div>
            </v-menu>
        </div>
    </v-menu>
</template>

<script>
export default {
    name: 'ManageStudentsMenuWithSubmenu',
    props: {
        pupil: { required: true, type: Object },
        otherClasses: { required: false, type: Array, default: () => [] },
        disabledClasses: { required: false, type: Array, default: () => [] },
    },
    data: () => ({
       menuOpen: false,
    }),
    computed: {
        readableStatus() {
            return (status) => {
                let result = 'Normal';
                //TODO Change status to enum

                if (status === 'changeSchools') {
                    result = 'Schulwechsel';
                }
                if (status === 'notTransferred') {
                    result = 'Keine Versetzung';
                }
                if (status === 'changedClass' && this.pupil.targetClass) {
                    result = 'Wechsel in ' + this.pupil.targetClass.name
                }

                return result;
            }
        },
        displayableOtherClasses() {
            return this.otherClasses.filter(group => group.status !== 'discontinued')
        }
    },
    methods: {
        changePupilStatus(newStatus) {
            this.$emit('pupilTransferStatusChanged', newStatus);
        },
        differentClassSelected(otherClass) {

            // this.pupil.status = 'changedClass';
            this.$set(this.pupil, 'status', 'changedClass');
            this.$emit('changedClass', otherClass._id);
            this.menuOpen = false;
        },
    }
}
</script>

<style lang="scss" scoped>

</style>
