<template>
    <v-tooltip location="top">
        <template v-slot:activator="{ props }">
            <v-badge
                v-if="editorFile || libreOfficeFile || draftFile"
                color="#ff0000"
                content="1"
                :model-value="accountRole === 'pupil' && !file.seenByMe"
                location="top left"
                style="z-index: 9; display: flex"
            >
                <v-btn
                    class="fileListButton mx-1 my-1"
                    style="background-color: rgb(var(--v-theme-chatColor)); margin-right: 7px"
                    :style="`width: ${buttonSize}px; height: ${buttonSize}px`"
                    size="x-small"
                    elevation="0"
                    v-bind="props"
                    @click="openFile"
                >
                    <img
                        :src="editIcon"
                        :alt="editorFile ? 'Bearbeiten' : draftFile ? 'Entwurf bearbeiten' : 'Datei im Libre Editor bearbeiten'"
                        style="filter: brightness(100%)"
                        class="fileListButtonIcon"
                    >
                </v-btn>
            </v-badge>
        </template>
        <div style="display: flex; flex-direction: column; text-align: center">
            <span>{{ editorFile ? 'Bearbeiten' : draftFile ? 'Entwurf bearbeiten' : 'Datei im Libre Editor bearbeiten' }}</span>
            <div style="margin: auto">
                <img
                    v-if="person.accessibility && person.accessibility.hoeren"
                    :src="hausaufgabenMetacom"
                    width="100"
                    class="mr-1"
                    alt=""
                >
                <img
                    v-if="person.accessibility && person.accessibility.hoeren"
                    :src="toDoMetacom"
                    width="100"
                    alt=""
                >
            </div>
        </div>
    </v-tooltip>
</template>

<script>
import editIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import hausaufgabenMetacom from "@/assets/METACOM/Hausis/hausaufgaben.png"
import toDoMetacom from "@/assets/METACOM/Hausis/machen.png";
import {allowedExtensionsForEditor, allowedExtensionsForLibre} from "@/constants/allowedMimeTypes";

export default {
    name: "FileListEditButton",
    props: {
        file: { type: Object, required: true },
        primaryColor: { type: String, required: false },
        person: { type: Object, required: false },
        accountRole: { type: String, required: true },
        buttonSize: { type: Number, required: false, default: 36 },
    },
    data: () => ({
        editorFile: false,
        libreOfficeFile: false,
        draftFile: false,

        editIcon,
        hausaufgabenMetacom,
        toDoMetacom,
    }),
    mounted() {
        if(this.file.draft) {
            this.draftFile = true;
        } else if(allowedExtensionsForEditor.includes(this.file.title.split('.').pop().toLowerCase())) {
            this.editorFile = true;
        } else if(allowedExtensionsForLibre.includes(this.file.title.split('.').pop().toLowerCase())) {
            this.libreOfficeFile = true;
        }
    },
    methods: {
        openFile(event) {
            event.stopPropagation();
            this.$emit(this.editorFile ? 'editInEditor' : this.draftFile ? 'editDraft' : 'editInLibre');
        }
    }
}
</script>

<style scoped>
.btnStyle {
    min-height: 36px;
}
</style>
