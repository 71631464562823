<template>
    <div>
        <v-badge
            v-show="countdown !== '' && countdown >=0"
            color="#ff0000"
            :content="countdown"
            :model-value="countdown"
            location="left"
            offset-x="15"
            offset-y="15"
            style="z-index: 9; width:100%"
        >
            <v-tooltip
                v-model="recordingTooltip"
                location="top"
                :open-on-hover="false"
            >
                <template v-slot:activator="{ props}">
                    <v-btn
                        :tabindex="tabOrder"
                        v-bind="props"
                        elevation="0"
                        class="text-capitalize"
                        :color="recordingRunning ? 'grau' : ''"
                        :theme="recordingRunning ? 'dark' : 'default'"
                        aria-label="Sprachaufnahme als Abgabe aufnehmen (gedrückt halten)"
                        role="button"
                        style="background-color: transparent; min-width: 20px; max-width: 25px;"
                        @keypress.enter="startAudioRecording()"
                        @keyup.enter="stopAudioRecording()"
                        @keypress.space="startAudioRecording()"
                        @keyup.space="stopAudioRecording()"

                        @click="
                            clickedAlready && !recordingRunning
                                ? ''
                                : recordingRunning
                                    ? stopAudioRecording()
                                    : startAudioRecording()
                        "
                    >
                        <img
                            :src="recordingRunning ? recording : micIcon"
                            style="height:20px"
                        >
                    </v-btn>
                </template>
                <span> {{ recordingTime }}</span>
            </v-tooltip>
        </v-badge>
        <v-dialog
            v-if="showDialog"
            v-model="showDialog"
            scrim="rgba(0,0,0,0.7="
            width="500"
        >
            <v-card
                id="card"
                class="pb-3"
            >
                <v-card-title
                    class="pa-0 ma-0"
                    style="background-color: rgb(var(--v-theme-timetableColor))"
                >
                    <v-container class="pa-0 ma-0">
                        <div class="d-flex flex-row align-center justify-space-between pt-1 px-2">
                            <h1
                                tabindex="0"
                                style="width: fit-content; color: white"
                            >
                                Dateiname
                            </h1>
                            <v-btn
                                elevation="2"
                                size="x-small"
                                class="pa-0 mb-2"
                                style="color: white;background-color:rgb(var(--v-theme-secondary));width: 30px; min-width: 30px; height: 30px"
                                :aria-label="'Datei ' + newFileName + ' hochladen'"
                                @click="finishProcess"
                            >
                                <v-icon
                                    size="x-small"
                                    theme="dark"
                                >
                                    mdi-check
                                </v-icon>
                            </v-btn>
                        </div>
                    </v-container>
                </v-card-title>
                <v-card-text class="pb-0">
                    <v-container class="pa-0">
                        <v-row
                            align="center"
                            justify="center"
                        >
                            <v-col>
                                <v-text-field v-model="newFileName" />
                            </v-col>
                        </v-row>
                        <v-row>
                            <audio
                                :src="audioUrl"
                                controls
                                style="width: 100%"
                            >
                                Your browser does not support the audio tag.
                            </audio>
                        </v-row>

                        <v-row
                            v-if="groupPopulated"
                            class="ma-0 mt-3"
                            align="center"
                            justify="center"
                            style="width: 100%"
                        >
                            <simple-bar
                                class="scroll-area"
                                :settings="settings"
                                style="width: 100%"
                            >
                                <v-data-table
                                    :headers="tableHeader"
                                    :items="groupPopulated.participants"
                                    hide-default-footer
                                    class="ma-0 custom-table"
                                    show-select
                                    items-per-page="-1"
                                    disable-sort
                                    no-data-text="Keine Empfänger verfügbar"
                                    style="width: 100%; max-height: 300px; overflow-y: auto"
                                >
                                    <!-- eslint-disable-next-line -->
                                    <template v-slot:header.data-table-select>
                                        <div class="d-flex flex-row align-center justify-space-around">
                                            <v-checkbox
                                                v-model="allParticipantsSelected"
                                                :indeterminate="atLeastOneButNotAllParticipantsSelected"
                                                rounded
                                                small
                                                light
                                                @update:model-value="headerCheckboxChanged"
                                            />
                                            <p> Alle </p>
                                        </div>
                                    </template>
                                    <template v-slot:item="row">
                                        <tr>
                                            <td>
                                                <v-checkbox
                                                    v-model="selectedParticipants"
                                                    rounded
                                                    :value="row.item.account"
                                                    small
                                                    light
                                                />
                                            </td>
                                            <td style="padding: 0 4px !important">
                                                <p>{{ row.item.name }}</p><p>{{ row.item.lastName }}</p>
                                            </td>
                                            <td>
                                                <v-textarea
                                                    v-if="userNotes[row.index] !== undefined && userNotes[row.index].note !== undefined"
                                                    v-model="userNotes[row.index].note"
                                                    :disabled="!selectedParticipants.includes(row.item.account)"
                                                    style="padding: 4px 0"
                                                    variant="outlined"
                                                    no-resize
                                                    auto-grow
                                                    hide-details
                                                    density="compact"
                                                    placeholder="Notiz"
                                                    rows="1"
                                                />
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </simple-bar>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Recorder from "js-audio-recorder";
import startSound from "../assets/Sound/startAufnahme.mp3";
import stopSound from "../assets/Sound/exchanged.mp3";
import * as backend from '@/api/backend';

import cancelIcon from "../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import recording from "../assets/Icons/recording-dot.svg";
import micIcon from "../assets/Icons/FaceliftIcons/mikrofon_an.svg";

export default {
  name: "AudioUpload",

  props: {
    appointmentId: { type: String, required: true },
    upload: { type: Function, required: true },
    group: { required: false, default: null },
    fab: { type: Boolean, required: false, default: false },
    color: { type: String, required: false, default: "" },
    tabOrder: { required: false, default: "0" },
  },
  data: () => ({
      cancelIcon,
      recording,
      micIcon,

      clickedAlready: false,
      recordingRunning: false,
      audioUrl: null,
      showDialog: false,
      newFileName: "Sprachaufnahme",
      file: null,
      settings: {
          suppressScrollY: false,
          suppressScrollX: true,
          wheelPropagation: false,
      },
      recordingTime: "",
      elapsedTimeIntervalRef: "",
      recordingTooltip: false,

      selectedParticipants: [],
      allParticipantsSelected: false,
      tableHeader: [{ text: 'Name', width: '30%'},  { text: 'Notiz', width: '70%'}],
      userNotes: [],

      audioBlobs: [], /*of type Blob[]*/
      /** Stores the reference of the MediaRecorder instance that handles the MediaStream when recording starts*/
      mediaRecorder: null, /*of type MediaRecorder*/
      /** Stores the reference to the stream currently capturing the audio*/
      streamBeingCaptured: null, /*of type MediaStream*/

      audioStart: new Audio(startSound),
      audioStop: new Audio(stopSound),
      countdown: '',

      // prevent prop group from being string and not populated object
      // this can happen when traveling into fachInfoLehrer unconventionally like through fullscreen mode
      groupPopulated: null,
  }),
  computed: {
    ...mapState("util", ["keyboard"]),
    ...mapGetters("files", ["getCurrentFileType"]),

      atLeastOneButNotAllParticipantsSelected() {
          return this.selectedParticipants.length > 0 && this.selectedParticipants.length < this.groupPopulated.participants.length;
      }
  },
    watch: {
        groupPopulated(newVal, oldVal) {
            if (!oldVal || newVal._id !== oldVal._id) {
                this.populateGroup();
                if (newVal && newVal.participants) {
                    this.selectedParticipants = newVal.participants.map(a => a.account);
                }
            }
        },
        selectedParticipants() {
            if(this.selectedParticipants.length === this.groupPopulated.participants.length) {
                this.allParticipantsSelected = true;
            } else if(this.selectedParticipants.length < this.groupPopulated.participants.length){
                this.allParticipantsSelected = false;
            }
        },
        // recordingTime : {
        //     handler() {
        //         if (this.recordingTime === "02:00") {
        //         this.stopAudioRecording();
        //         }
        //     }
        // },
    },
    async beforeMount() {
        await this.populateGroup();
        if (this.groupPopulated) {
            this.groupPopulated.participants.map(() => this.userNotes.push({ note: "" }));
            this.selectedParticipants = this.groupPopulated.participants.map(a => a.account);
        }
    },
  async unmounted() {
    URL.revokeObjectURL(this.audioUrl);
  },
  methods: {
      ...mapActions('groups', ['getGroup']),
    headerCheckboxChanged() {
        if(this.allParticipantsSelected) {
            this.selectedParticipants = this.groupPopulated.participants.map((participant) => participant.account);
        } else {
            this.selectedParticipants = [];
        }
    },

    async populateGroup() {
        if (typeof this.group == 'string') {
            this.groupPopulated = await this.getGroup(this.group);
        } else if (typeof this.group == 'object') {
            this.groupPopulated = this.group;
        }
    },

    async startAudioRecording() {
        this.clickedAlready = true;
        try {
        if(navigator.mediaDevices === undefined) {
            this.showSnackbar({ message: "Mikrofonaufnahmen werden in diesem Browser nicht unterstützt.",  color: "error"});
            return;
        }
        await Recorder.getPermission();
        } catch(error) {
        console.error(error);
        this.showSnackbar({ message: "Fehler: Bitte aktiveren Sie Mikrofonaufnahmen für diese Seite.",  color: "error"});
        return;
        }


        //start recording using the audio recording API
        if (this.recordingRunning) {
        return; // Recording already running
        }

        const userMediaStream = await navigator.mediaDevices.getUserMedia(
        { audio: true } /*of type MediaStreamConstraints*/
        );

        //save the reference of the stream to be able to stop it when necessary
        this.streamBeingCaptured = userMediaStream;

        //create a media recorder instance by passing that stream into the MediaRecorder constructor
        this.mediaRecorder = new MediaRecorder(
        userMediaStream
        ); /*the MediaRecorder interface of the MediaStream Recording
        API provides functionality to easily record media*/

        //clear previously saved audio Blobs, if any
        this.audioBlobs = [];

        //add a dataavailable event listener in order to store the audio data Blobs when recording
        this.mediaRecorder.addEventListener("dataavailable", (event) => {
        //store audio Blob object
        this.audioBlobs.push(event.data);
        });

        this.audioStart.play();

        let intervalCount = 0;
        this.countdown = 3 - intervalCount;
        const countdownInterval = setInterval(() => {
            intervalCount++;
            this.countdown = 3 - intervalCount;

            if (this.countdown === 0) {
                //start the recording by calling the start method on the media recorder
                this.mediaRecorder.start();
                this.countdown = '';
                clearInterval(countdownInterval);
                this.startTimer();
                this.recordingRunning = true;
                this.recordingTooltip = true;
            }
        }, 1000);
    },

    stopAudioRecording(source) {
      if (!this.recordingRunning) {
        return; // Recording not started yet
      }

      const mediaRecorder = this.mediaRecorder;

      //listen to the stop event in order to create & return a single Blob object
      mediaRecorder.addEventListener("stop", () => {
        //create a single blob object, as we might have gathered a few Blob objects that needs to be joined as one
        let audioBlob = new Blob(this.audioBlobs, {
          type: mediaRecorder.mimeType,
        });

        this.saveAudioRecording(audioBlob);

      });

      //stop the recording feature
      this.recordingTooltip = false;
      this.recordingRunning = false;
      this.audioStop.play();
      this.mediaRecorder.stop();

      //stop all the tracks on the active stream in order to stop the stream
      this.streamBeingCaptured
        .getTracks() //get all tracks from the stream
        .forEach((track) /*of type MediaStreamTrack*/ => track.stop()); //stop each one      //reset API properties for next recording

      this.mediaRecorder = null;
      this.streamBeingCaptured = null;
      this.clickedAlready = false;
    },

    async saveAudioRecording(audioAsBlob) {
      //stop the recording using the audio recording API
      // const audioAsBlob = await this.stopRecording();

      this.file = audioAsBlob;
      this.audioUrl = URL.createObjectURL(this.file);
      this.showDialog = true;
      setTimeout(() => {
        this.stopTimer();
      }, 500);
      // console.error(error);
      // //Error handling structure
      // switch (error.name) {
      //     case 'InvalidStateError': //error from the MediaRecorder.stop
      //         console.log("An InvalidStateError has occured.");
      //         break;
      //     default:
      //         console.log("An error occured with the error name " + error.name);
      // }
    },
    finishProcess() {
      /*//setting reopenAppointment to reopen popup after recording is done
      this.$route.query.appointment = this.appointmentId;*/
        let notes = JSON.parse(JSON.stringify(this.userNotes));
        for(let i=0; i < notes.length; i++) {
            notes[i].account = this.groupPopulated.participants[i].account;
        }
        notes = notes.filter((el) => {
            return el.note !== "";
        })

      this.upload(
        this.appointmentId,
        this.newFileName,
        "wav",
        this.file,
        this.selectedParticipants,
          notes,
      );

      this.showDialog = false;
      this.newFileName = 'Sprachaufnahme'
    },
    startTimer() {
      var startTime = new Date();
      this.recordingTime = this.getElapsedTime(startTime);
      this.elapsedTimeIntervalRef = setInterval(() => {
        this.recordingTime = this.getElapsedTime(startTime);
      }, 1000);
    },
    stopTimer() {
      if (typeof this.elapsedTimeIntervalRef !== "undefined") {
        clearInterval(this.elapsedTimeIntervalRef);
        this.elapsedTimeIntervalRef = undefined;
      }
      this.recordingTime = "";
    },
    getElapsedTime(startTime) {
      // Record end time
      let endTime = new Date();
      // Compute time difference in milliseconds
      let timeDiff = endTime.getTime() - startTime.getTime();
      // Convert time difference from milliseconds to seconds
      timeDiff = timeDiff / 1000;
      // Extract integer seconds that dont form a minute using %
      let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
      // Pad seconds with a zero if neccessary
      let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
      // Convert time difference from seconds to minutes using %
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer minutes that don't form an hour using %
      let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
      // Pad minutes with a zero if neccessary
      let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
      // Convert time difference from minutes to hours
      timeDiff = Math.floor(timeDiff / 60);
      // Extract integer hours that don't form a day using %
      let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
      // Convert time difference from hours to days
      timeDiff = Math.floor(timeDiff / 24);
      // The rest of timeDiff is number of days
      let days = timeDiff;
      let totalHours = hours + days * 24; // add days to hours
      let totalHoursAsString =
        totalHours < 10 ? "0" + totalHours : totalHours + "";
      if (totalHoursAsString === "00") {
        return minutesAsString + ":" + secondsAsString;
      } else {
        return (
          totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>

.iconToWhite {
  filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.micIcon {
  background-image: url("../assets/Icons/FaceliftIcons/mikrofon_an.svg");
  width: 20px;
  height: 20px;
}

.micIcon--recording {
  background-image: url("../assets/Icons/recording-dot.svg");
  width: 30px;
  height: 30px;
}
.custom-table .v-data-table::v-deep td{
padding:0 4px;
}
</style>
