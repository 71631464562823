<template>
    <v-menu
        location="top"
        :offset="[0, calculateOffset()]"
        :z-index="50"
    >
        <template v-slot:activator="{ props }">
            <v-btn
                v-if="usage === 'list' || usage === 'BabyList'"
                variant="plain"
                size="large"
                color="black"
                v-on="on"
            >
                <v-icon 
                    class="mr-5"
                >
                    mdi-emoticon-happy-outline 
                </v-icon>
                <p style="color:black; text-transform:none!important;">
                    Emoji
                </p>
            </v-btn>
            <v-icon
                v-else
                class="ml-1"
                style="opacity: 0.5"
                v-bind="props"
            >
                mdi-emoticon-happy-outline 
            </v-icon>
        </template>
        <v-card
            class="pa-0 ma-0"
        >
            <simple-bar
                :auto-hide="false"
                class="scrollContainer px-4 py-2"
            >
                <div
                    v-for="(emoji, index) in emojis"
                    :key="index"
                    class="pa-1 icon30 emoji"
                    style="cursor: pointer"
                    @click="selectEmoji(emoji)"
                >
                    {{ emoji.character }}
                </div>
            </simple-bar>
        </v-card>
    </v-menu>
</template>

<script>
export default {
  name: 'EmojiPicker',
  props: {
    usage: {required: false, type: String, default: ''}
  },
  data() {
    return {
      emojis: [
      ],
      showList: false,
    };
  },
  watch: {
  },
  created() {
    // Generate array of emoji objects
    const emojiRange = [0x1F600,0x1F64F];
    for (let i = emojiRange[0]; i <= emojiRange[1]; i++) {
      this.emojis.push({
        codePoint: i,
        character: String.fromCodePoint(i)
      });
    }
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("selectedEmoji", emoji.character);
      this.showList = false;
    },
     calculateOffset() {
      switch (this.usage) {
        case 'list':
          return 140;
        case 'BabyList':
          return 110;
        default:
          return 90;
      }
  },
  }
}
</script>

<style lang="scss" scoped>

.emoji-selector {
  display: flex;
  align-items: center;
}
.emoji {
   display: inline-flex;
   justify-content: center;
   align-items: center;
   font-size: 30px; /* Match the size of the previous icons */
   margin: 4px;
}
.emoji-selector v-icon {
  font-size: 18px;
  margin-left: 5px;
}

.scrollContainer {
	height: auto;
	max-height: 15vh; 
  width: auto;
  min-width: 28vw;
  max-width: 28vw;
  background-color: white;
}
@media only screen and (max-width: 900px) {
    .scrollContainer {
        width: auto;
        min-width: 300px;
    }
}



</style>
