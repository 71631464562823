<template>
    <v-dialog
        width="400"
        :model-value="showDialog"
        @update:model-value="onShowDialogChange"
    >
        <v-card
            class="cardStyle"
            style="min-height: 90dvh; height: 90dvh; overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="d-flex align-center justify-space-between"
                style="height: 56px; background-color: rgb(var(--v-theme-chatColor))"
            >
                <div class="d-flex align-center">
                    <img
                        :src="chatIcon"
                        class="icon20 iconToWhite mr-2 mb-1"
                    >
                    <p style="color: white">
                        Chat Einstellungen
                    </p>
                </div>
                <v-btn
                    size="x-small"
                    variant="text"
                    elevation="0"
                    class="pa-0"
                    @click="closeDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text
                class="group-list-container"
                style="height: calc(80vh - 55px - 60px)"
            >
                <div
                    class="d-flex flex-row"
                    style="align-items: center"
                >
                    <icon-picker
                        :current-color="selectedColor"
                        :callback="(color) => { this.selectedColor = color; }"
                        :current-icon="this.selectedIcon"
                        :callback-icon="(icon) => { this.selectedIcon = icon; }"
                        :group-icons="icons"
                        color-picker-usage="createGroupChat"
                        @change-icon="(icon) => { this.selectedIcon = icon; }"
                    />
                    <div
                        class="d-flex flex-column pl-4 pt-2"
                    >
                        <h1>
                            Gruppenname<span class="text-red bold">*</span>
                        </h1>
                        <v-text-field
                            v-model="newChatRoomName"
                            variant="outlined"
                            density="compact"
                            hide-details
                            class="mb-2"
                            style="max-width: 500px; max-height:32px;"
                        />
                    </div>
                </div>
                <h1 class="pa-2">
                    Moderatoren
                </h1>
                <v-select
                    v-model="moderators"
                    variant="outlined"
                    density="compact"
                    hide-details
                    multiple
                    :items="selectableModerators"
                    item-title="displayName"
                    :item-value="item => item._id"
                    label="Moderatoren"
                    class="inputField mb-2 "
                    style="max-height:32px;"
                    @update:model-value="() => { searchModerators = ''}"
                >
                    <template v-slot:prepend-item>
                        <v-list-item
                            class="px-2"
                            @mousedown.prevent
                        >
                            <v-text-field
                                v-model="searchModerators"
                                style="background-color: white;max-height:32px"
                                variant="solo"
                                density="compact"
                                flat
                                placeholder="suchen"
                                hide-details
                            >
                                <template v-slot:prepend-inner>
                                    <img
                                        :src="searchIcon"
                                        class="icon20"
                                        alt="suchen"
                                    >
                                </template>
                            </v-text-field>
                        </v-list-item>
                    </template>
                </v-select>
                <h1 class="pt-2">
                    Teilnehmer hinzufügen (mind. 2)<span class="text-red bold">*</span>
                </h1>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="accountSetSearchValue"
                        variant="outlined"
                        density="compact"
                        hide-details
                        clearable
                        style="max-width: 500px"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon20 mr-2"
                            >
                        </template>
                    </v-text-field>
                </div>

                <v-treeview
                    v-model:selected="selectedAccountSetsIds"
                    :items="selectableAccountSets"
                    item-title="displayName"
                    item-key="_id"
                    item-value="_id"
                    :search="accountSetSearchValue"
                    selected-color="rgb(var(--v-theme-chatColor))"
                    selectable
                    dense
                    class="mt-2"
                    style="font-size: 16px; min-height: 50%"
                    light
                    select-strategy="classic"
                />
            </v-card-text>
            <v-card-actions>
                <DeleteChatButtonV2
                    @delete-chat-room="deleteChatRoom"
                />
                <v-spacer />
                <v-btn
                    id="eK-button-disabled"
                    class="text-none"
                    style="background-color:rgb(var(--v-theme-gruen)); color: white"
                    elevation="0"
                    theme="dark"
                    :disabled="isChatroomSavable"
                    @click="saveGroupChat"
                >
                    Speichern
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import {mapActions, mapGetters, mapState, mapMutations} from "vuex";
import DeleteChatButtonV2 from "@/components/ChatV2/Components/DeleteChatButtonV2";
import IconPicker from "@/components/Utils/IconPicker";

import searchIcon from "@/assets/Icons/lupe-18.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import chatIcon from '@/assets/Icons/FaceliftIcons/chat_spechblase.svg';

import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";
import groupIcon from "@/assets/Icons/group.svg";

export default {name: "ChatRoomSettingsDialog",
    components: {
        DeleteChatButtonV2,
        IconPicker,
    },
    props: {
        chatRoom: { required: true, type: Object },
    },
    data: () => ({
        searchIcon,
        cancelIcon,
        chatIcon,
        showDialog: true,
        newChatRoomName: "",
        accountSetSearchValue: "",
        selectedAccountSetsIds: [],
        moderators: [],
        searchModerators: '',
        selectedColor: 'rgb(var(--v-theme-chatColor))',
        selectedIcon: groupIcon,

        icons: [
            {icon: groupIcon, text: "Gruppen-Icon"},
            {icon: avatar1, text: "avatar1"},
            {icon: avatar2, text: "avatar2"},
            {icon: avatar3, text: "avatar3"},
            {icon: avatar4, text: "avatar4"},
            {icon: avatar5, text: "avatar5"},
            {icon: avatar6, text: "avatar6"},
            {icon: avatar7, text: "avatar7"},
            {icon: avatar8, text: "avatar8"},
            {icon: avatar9, text: "avatar9"},
            {icon: avatar10, text: "avatar10"},
            {icon: avatar11, text: "avatar11"},
            {icon: avatar12, text: "avatar12"},
            {icon: avatar13, text: "avatar13"},
            {icon: avatar14, text: "avatar14"},
        ],
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
        isChatroomSavable() {
            return (
                this.newChatRoomName.trim() === "" ||
                this.selectedAccountSetsIds.length < 1
            );
        },
        selectableAccountSets() {
            return this.accountSets
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: this.getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => !accountSet.displayName.includes("PersonAlle") && !accountSet.type.includes("allMaintainers"));
        },
        selectableModerators(){
            return this.accountSets.filter((accountSet) => this.accountsById[accountSet.account]?.role === 'teacher' ||
                this.accountsById[accountSet.account]?.role === 'maintainer')
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: this.getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => !accountSet.displayName.includes("PersonAlle") && accountSet.displayName.toLowerCase().includes(this.searchModerators.toLowerCase()))
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
    },
    mounted() {
        this.newChatRoomName = this.chatRoom.name;
        this.selectedAccountSetsIds = this.chatRoom.memberships.map(
            (membership) => membership.accountSet
        );
        this.initMembershipRoles();
        if (this.chatRoom.color) {
            this.selectedColor = this.chatRoom.color;
        }
        if (this.chatRoom.icon) {
            this.selectedIcon = this.chatRoom.icon;
        }
    },
    methods: {
        ...mapActions("chat", ["patchChatRoom"]),
         ...mapMutations("snackbar", ["showSnackbar"]),
        initMembershipRoles(){
            this.chatRoom.memberships.map((member) => {
                const mod = this.selectableModerators.filter((set) => member.accountSet === set._id && member.role === 'moderator' )
                if(mod.length){
                    this.moderators.push(mod[0])
                }
            })
        },
        getAccountSetDisplayName(accountSet) {
            if (accountSet.type === "account") {
                const account = this.accountsById[accountSet.account];
                if(account){
                    return account ? account.displayName : account._id;
                }else {
                    return 'Unbekannter Account';
                }
            } else if (accountSet.type === "groupLeaders") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Leiter`;
            } else if (accountSet.type === "groupAppointmentLeaders") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Fachlehrer`;
            } else if (accountSet.type === "groupParticipants") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Mitglieder`;
            } else if (accountSet.type === "groupParticipantsParents") {
                const group = this.groupsById[accountSet.group];
                return `Gruppe "${group?.name}" Eltern`;
            } else if (accountSet.type === "allTeachers") {
                return "Alle Lehrer";
            } else if (accountSet.type === "allMaintainers") {
                return "Gesamte Verwaltung";
            } else if (accountSet.type === "allPupils") {
                return "Alle Schüler";
            } else if (accountSet.type === "allParents") {
                return "Alle Eltern";
            } else {
                // For debugging if there is a new type
                console.log('Problematic new type:', accountSet.type);
                return 'Unbekannt'
            }
        },
        async deleteChatRoom() {
            this.$emit("deleteChatRoom");
        },
        async saveGroupChat() {
            const memberships = this.selectedAccountSetsIds.map((accountSet) => ({
                accountSet: accountSet,
                role: "member",
            }))
            const moderatorSets = this.moderators.map((accountSet) => ({
                accountSet: typeof accountSet === 'object' ? accountSet._id:accountSet,
                role: "moderator"
            }))

            const myMembership = {
                accountSet: this.myAccountSet._id,
                role: "moderator",
            };
            //merge all arrays and remove doubles
            let merged = moderatorSets.concat(memberships).concat(myMembership);
            merged = merged.filter((value,index,array)=>array.findIndex(v2=>(v2.accountSet===value.accountSet))===index)
            await this.patchChatRoom({ chatRoomId: this.chatRoom._id, body: {
                    memberships: [...merged],
                    name: this.newChatRoomName,
                    description: "",
                    isOpen: true,
                    icon: this.selectedIcon,
                    color: this.selectedColor,
                }});

            //   await this.createCustomRoom({
            //     groupName: this.newChatRoomName,
            //     members: this.newGroupChatMembers,
            //   });
            this.closeDialog();
                this.showSnackbar({
                    message: 'Änderungen erfolgreich gespeichert',
                    color: 'success'
                });
        },
        onShowDialogChange(newValue) {
            if (!newValue) {
                this.closeDialog();
            }
        },
        closeDialog() {
            this.$emit("close");
        },
    },
};
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>
<style scoped lang="scss">
.group-list-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
}

.scroll-area {
    max-height: calc(80vh - 230px);
    overflow-x: hidden
}

.formHeader {
    font-size: 16px;
    margin-top: 40px !important;
    margin-bottom: 20px !important;
}

#eK-button-disabled.v-btn--disabled {
    opacity: 0.5;
    background-color: rgb(var(--v-theme-grau)) !important;
    color: white !important;
}
</style>
