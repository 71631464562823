<template>
    <v-menu
        :model-value="showColorMenu"
        style="border-radius: 5px !important;"
        max-width="277px"
        location="bottom"
        :offset="[227,-3]"
        :attach="'#' + attach"
        aria-haspopup="menu"
    >
        <template v-slot:activator="{ props:menu }">
            <v-tooltip location="top">
                <template v-slot:activator="{ props:tooltip }">
                    <v-btn
                        icon="$vuetify"
                        variant="plain"
                        class="buttonWindow"
                        aria-label="Farbe auswählen"
                        aria-hidden="true"
                        tabindex="-1"
                        v-bind="$mergeProps(menu, tooltip)"
                        @click="openColorPopup"
                    >
                        <v-icon
                            class="buttonWindow"
                            :style="'color:' + cardColor"
                            size="27"
                            style="margin-left: 3px; margin-top: 3px; cursor: pointer"
                        >
                            mdi-square-rounded
                        </v-icon>
                    </v-btn>
                </template>
                <div style="display: flex; flex-direction: column; text-align: center">
                    <span>Hintergrundfarbe ändern</span>
                </div>
            </v-tooltip>
        </template>
        <v-card
            role="menu"
            style="color: rgb(var(--v-theme-hellgrau));"
        >
            <div
                style="display: flex; justify-content: space-between;"
            >
                <v-btn
                    id="firstColor"
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="preventColorInvert"
                    aria-label="Farbe beige"
                    @click="changeColor('#D1BC8A')"
                >
                    <v-icon style="font-size: 22px !important; color: #D1BC8A">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe türkis"
                    @click="changeColor('#40E0D0')"
                >
                    <v-icon style="font-size: 22px !important; color: #40E0D0">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe hell-rosa"
                    @focusout="showColorMenu = false"
                    @click="changeColor('#F3a4e7')"
                >
                    <v-icon style="font-size: 22px !important; color: #F3a4e7">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    aria-label="Farbe gelb"
                    class="preventColorInvert"
                    @click="changeColor('#fac837')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-gelb))">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe grün"
                    @click="changeColor('#3ba934')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-chatColor))">
                        mdi-circle
                    </v-icon>
                </v-btn>
            </div>
            <div
                style="display: flex; justify-content: space-between;"
            >
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe hellblau"
                    @click="changeColor('#6996cd')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-primary))">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe dunkelgrün"
                    @click="changeColor('#3cb371')"
                >
                    <v-icon style="font-size: 22px !important; color: #3cb371">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe rosa"
                    @click="changeColor('#D47479')"
                >
                    <v-icon style="font-size: 22px !important; color: #D47479">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe orange"
                    @click="changeColor('#f07d32')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-orange))">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe grau"
                    @click="changeColor('#706f6f')"
                >
                    <v-icon style="font-size: 22px !important; color: #706f6f">
                        mdi-circle
                    </v-icon>
                </v-btn>
            </div>
            <div
                style="display: flex; justify-content: space-between;"
            >
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe rot"
                    @click="changeColor('#e6231e')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-error))">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe dunkelblau"
                    @click="changeColor('#505faa')"
                >
                    <v-icon style="font-size: 22px !important; color: rgb(var(--v-theme-blau))">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe braun"
                    @click="changeColor('#815a30')"
                >
                    <v-icon style="font-size: 22px !important; color: #815a30">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe eKlara-violett"
                    @click="changeColor('#8d4c93')"
                >
                    <v-icon style="font-size: 22px !important; color: #8d4c93">
                        mdi-circle
                    </v-icon>
                </v-btn>
                <v-btn
                    variant="text"
                    size="x-small"
                    role="menuitem"
                    style="background-color: rgb(var(--v-theme-hellgrau)); border-radius: 0 !important; width: 54px; height: 49px"
                    class="pa-0 preventColorInvert"
                    aria-label="Farbe lila"
                    @click="changeColor('#8800FF')"
                >
                    <v-icon style="font-size: 22px !important; color: #8800FF">
                        mdi-circle
                    </v-icon>
                </v-btn>
            </div>
        </v-card>
    </v-menu>
</template>

<script>
export default {
    name: "PinboardColorPicker",
    props: {
        attach: { required: false, type: String },
        changeCardColor: { required: true, type: Function },
        cardColor: { required: true, type: String },
    },
    data: () => ({
        showColorMenu: false,
    }),
    methods: {
        // https://stackoverflow.com/questions/58075400/using-v-tooltip-inside-v-menu-activator-in-vuetify-2-0
        getHandlers() {
            return [...arguments].reduce((seed, item) => {
                const mergedHandlers = { ...item, ...seed };

                return mergedHandlers;
            }, {});
        },

        openColorPopup() {
            this.showColorMenu = true;
        },

        changeColor(color) {
            this.changeCardColor(color);
        }
    }
}
</script>

<style scoped lang="scss">
.colorPickerMenu {
    width: 216px !important;
    height: 25px;
    display: inline;
    border-radius: 0 !important;
    background-color: rgb(var(--v-theme-hellgrau));
}
</style>
