<template>
    <v-tooltip
        :disabled="!showTooltips"
        location="top"
        style="z-index: 9;"
    >
        <template v-slot:activator="{props: tooltip }">
            <v-menu
                :close-on-content-click="false"
                :offset="[10,20]"
                style="z-index: 9"
            >
                <template v-slot:activator="{ props: menu }">
                    <v-btn
                        tabindex="0"
                        size="x-small"
                        variant="text"
                        elevation="0"
                        min-width="20"
                        class="pa-0 mr-4"
                        style="cursor: pointer; height: 20px; width: 20px;"
                        v-bind="$mergeProps(menu, tooltip)"
                    >
                        <v-icon
                            size="24"
                            color="white"
                        >
                            mdi-dots-vertical
                        </v-icon>
                    </v-btn>
                </template>
                <div
                    class="d-flex justify-center flex-column "
                    style="box-shadow: 1px 5px 5px silver; width: 240px; border-radius: 6px;"
                >
                    <LeaveChatRoomButton
                        v-if="canUserLeaveChatRoom"
                        :big="true"
                        :chat-room="currentRoom"
                        @leave-chat-room="() => { $emit('leaveChatRoom') }"
                    />
                    <OpenChatRoomSettingsButton
                        v-if="settings && (chatRoomMembershipRole === 'moderator' || accountRole === 'maintainer')"
                        :big="true"
                        :chat-room="currentRoom"
                        @update-room-list="() => { $emit('updateRoomList') }"
                        @delete-chat-room="() => { $emit('deleteChatRoom') }"
                    />
                    <ToggleChatRoomOpenButton
                        v-if="chatRoomMembershipRole === 'moderator' || (currentRoom.type === 'privateChat' && ['teacher','maintainer'].includes(accountRole))"
                        style="border-radius: 0px;"
                        :chat-room="currentRoom"
                    />
                    <v-btn
                        v-if="windowWidth > 900 && windowWidth < 1150 "
                        class="pl-1"
                        style="text-transform: inherit; height: 40px"
                        elevation="0"

                        @click="() => { $emit('triggerReload') }"
                    >
                        <div
                            class="d-flex align-center"
                            style="width: 100%"
                        >
                            <v-icon color="grey">
                                mdi-reload
                            </v-icon>
                            <span>Chat neu laden</span>
                        </div>
                    </v-btn>
                    <v-btn
                        v-if="currentRoom.type === 'privateChat' && !parentPupilChat && windowWidth < 1150"
                        class="pl-1"
                        style="text-transform: inherit; height: 40px"
                        elevation="0"
                        @click="() => { $emit('deletePrivateChat') }"
                    >
                        <div
                            class="d-flex align-center"
                            style="width: 100%"
                        >
                            <img
                                class="icon20"
                                :src="deleteIcon"
                                alt="Löschen"
                            >
                            <p>Chat löschen</p>
                        </div>
                    </v-btn>
                    <v-btn
                        class="pl-1"
                        style="text-transform: inherit; height: 40px"
                        elevation="0"
                        @click="() => { $emit('startSearchMode') }"
                    >
                        <div
                            class="d-flex align-center"
                            style="width: 100%"
                        >
                            <img
                                class="icon20"
                                :src="suchIcon"
                                alt="durchsuchen"
                            >
                            <p>Chat durchsuchen</p>
                        </div>
                    </v-btn>

                    <v-btn
                        v-if="windowWidth > 900 && windowWidth < 1150 "
                        class="pl-1"
                        style="text-transform: inherit; height: 40px"
                        elevation="0"
                        @click="() => { $emit('muteorunmute') }"
                    >
                        <div
                            class="d-flex align-center"
                            style="width: 100%"
                        >
                            <img
                                class="icon20 "
                                :style="chatMuted ? 'opacity: 1.0' : 'opacity: 0.4'"
                                :src="bellIcon"
                                alt="Stummschalten"
                            >
                            <p>{{ !chatMuted ? 'Chat stummschalten' : 'Stummschaltung aufheben' }}</p>
                        </div>
                    </v-btn>
                </div>
            </v-menu>
        </template>
        <span>Menü</span>
    </v-tooltip>
</template>

<script>
import {mapActions, mapGetters, mapState} from "vuex";
import LeaveChatRoomButton from "@/components/ChatV2/LeaveChatRoomButton";
import OpenChatRoomSettingsButton from "@/components/ChatV2/Components/OpenChatRoomSettingsButton";
import ToggleChatRoomOpenButton from "@/components/ChatV2/ToggleChatRoomOpenButton.vue";
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import bellIcon from'@/assets/Icons/FaceliftIcons/benachrichtigung_aus.svg';
import suchIcon from '@/assets/Icons/suche-26.svg';


export default {
    name: "ChatWidgetMenu",
    components: {
        LeaveChatRoomButton,
        OpenChatRoomSettingsButton,
        ToggleChatRoomOpenButton,
    },
    props: {
        currentRoom: { required: false, type: Object, default: null },
        chatRoomMembershipRole: {required: true, type: String},
        showTooltips: { required: false, type: Boolean, default: true },
        settings: { required: false, type: Boolean, default: false },
        canUserLeaveChatRoom: { required: false, type: Boolean, default: false },
        chatMuted: {required: false, type: Boolean, default: false},
        parentPupilChat: { required: false, type: Boolean, },
    },
    data: () => ({
        // Todo pull current person
        person: {
            accessibility: {
                screenreader: false,
            }
        },
        bellIcon,
        deleteIcon,
        suchIcon,
    }),
    computed: {
        ...mapState('translation', ['targetLang']),
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState("util", ["keyboard", "windowWidth"]),

        menuContentHeight() {
            let height = 0;

            if (this.settings) {
                height += 50;
            }

            if (this.canUserLeaveChatRoom) {
                height += 50;
            }

            return height;
        }
    },
    methods: {
        ...mapActions('translation', ['setTranslatedText', 'showTranslation', 'translateToTargetLang']),
        async readWidgetInfoText() {
            if (!this.person.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                let text;
                if (this.accountRole === 'pupil') {
                    text = 'Im Chat kannst du Kontakt zu deinen Lehrern aufnehmen.';
                } else if (this.accountRole === 'teacher') {
                    text = 'Im Chat können Sie Kontakt zu Schülern und Eltern aufnehmen.';
                } else if (this.accountRole === 'parent') {
                    text = 'Im Chat können Sie zu zuständigen Lehrer Ihres Kindes Kontakt aufnehmen.';
                } else {
                    text = 'Im Chat können Sie sich mit Ihren Kontakten austauschen.'
                }

                if (this.targetLang !== 'de') {
                    text = await this.translateToTargetLang({
                        targetLang: this.targetLang, textToTranslate: text
                    });
                }

                if (window.speechSynthesis.speaking) {
                    window.speechSynthesis.cancel();
                    this.showTranslation(false);
                } else {
                    this.setTranslatedText(text);
                    this.showTranslation(true);

                    if (this.showTextTimeout) {
                        clearTimeout(this.showTextTimeout);
                        this.showTextTimeout = null;
                    }

                    this.showTextTimeout = setTimeout(() => {
                        this.showTranslation(false);
                        this.showTextTimeout = null;
                    }, 15000)

                    let msg = new SpeechSynthesisUtterance();
                    msg.text = text;
                    if (this.isLangPackageAvailable()) {
                        msg.lang = this.targetLang;
                        window.speechSynthesis.speak(msg);
                    }
                }
            }
        },
        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },
    }
}
</script>

<style scoped>

</style>
