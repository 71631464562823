<template>
    <div style="width: 100%">
        <progress-bar
            v-if="showTeacherUploadProgress"
            title="Hochladen"
            :show-progress="showTeacherUploadProgress"
            :progress="teacherUploadProgress"
            :abort-progress="teacherXmlHttpRequest ? () => { teacherXmlHttpRequest.abort() } : () => { }"
            :header-color="'rgb(var(--v-theme-timetableColor))'"
            @update:show-progress="showTeacherUploadProgress = $event"
            @update:progress="teacherUploadProgress = $event"
        />
        <!-- Menu that opens on click on fach in table -->
        <v-card
            id="fachPopup"
            role="dialog"
            tile
            :flat="!isPopup"
            :style="isPopup ? '' : 'background-color: unset'"
            style="color: rgb(var(--v-theme-dunkelgrau))"
        >
            <v-card-title
                v-if="isPopup"
                class="pa-0 ma-0"
                style="z-index: 1"
            >
                <div
                    class="d-flex"
                    style="box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.3); font-size: large; align-items: center; padding: 0 20px; height: 59px; width: 100%;"
                >
                    <v-col
                        cols="6"
                        class="d-flex align-center pl-0"
                    >
                        <img
                            :src="getColorIcon()"
                            alt="Fachsymbol"
                            style="position: absolute; right: 20%; top: 0; height: 25px"
                        >
                        <img
                            v-if="getSrc()"
                            alt="Fachsymbol"
                            class="iconSelect mr-2"
                            :src="hasUploadedIcon ? getSrc() : getSrcWhite()"
                            style="margin-bottom: 0"
                            :style="getBackgroundColor()"
                        >
                        <div style="flex: auto">
                            {{ appointment.schoolSubject.name }}
                        </div>
                    </v-col>

                    <v-col
                        cols="4"
                        class="pa-0 pr-6 d-flex flex-column justify-start align-end"
                        :style="`font-size: ${windowWidth < 400 ? 'small' : 'medium'}`"
                    >
                        <v-tooltip location="bottom">
                            <template v-slot:activator="{ props }">
                                <div
                                   
                                    style="text-decoration: underline;"
                                    v-bind="props"
                                >
                                    {{ appointment.group.name }}
                                </div>
                            </template>
                            <div style="max-height: 80vh; overflow: hidden;">
                                <span
                                    v-for="(item, index) in groupMembers"
                                    :key="item.lastName + index"
                                >
                                    {{
                                        index === groupMembers.length - 1 ? item.name + ' ' + item.lastName.slice(0, 1)
                                            + '.' : item.name + ' ' + item.lastName.slice(0, 1) + '.' + ', '
                                    }} <br v-if="0 === (index+1) %4">
                                </span>
                            </div>
                        </v-tooltip>
                    </v-col>

                    <v-col
                        cols="2"
                        align-self="end"
                        :class="windowWidth >= 900 ? 'd-flex flex-row-reverse' : ''"
                    >
                        <v-btn
                            elevation="2"
                            size="x-small"
                            class="ml-2 pa-0 btnStyle"
                            style="width: 30px; height: 30px"
                            @click="$emit('closeMenu')"
                        >
                            <img
                                :src="schliesenIcon"
                                alt="Schließen"
                                style="height: 20px;"
                            >
                        </v-btn>
                    </v-col>
                </div>
            </v-card-title>

            <v-card-text
                class="pa-0 ma-0"
                style="overflow: hidden;"
            >
                <simple-bar
                    :auto-hide="false"
                    class="scroll-area-fachPopup mb-2"
                >
                    <!--#region Info -->
                    <!-- <div
                        v-if="!diaryDeactivated"
                        :class="isPopup ? 'mx-5' : 'whiteBackgroundTile my-4'"
                    >
                        <div :class="isPopup ? 'mt-2' : ''">
                            <v-row>
                                <v-col
                                    class="font-weight-bold py-2 d-flex align-center justify-space-between"
                                    style="align-items: flex-end"
                                >
                                    <div class="d-flex align-center">
                                        <img
                                            :src="bookIcon"
                                            style="height: 20px;"
                                            alt
                                        >
                                        <p class="px-2 ma-0 mt-2">
                                            Klassenbucheintrag
                                        </p>
                                        <v-tooltip bottom>
                                            <template v-slot:activator="{ on, attrs }">
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 20px;"
                                                    alt
                                                    v-bind="attrs"
                                                    v-on="on"
                                                >
                                            </template>

                                            <p
                                                justify="center"
                                                style="max-width: 200px"
                                            >
                                                Eingabe ist nicht zwingend, der Eintrag wird automatisch in das Klassenbuch
                                                übernommen und kann dort ebenfalls bearbeitet werden.
                                            </p>
                                        </v-tooltip>
                                    </div>
                                </v-col>
                            </v-row>
                            <div
                                id="fachDiary"
                                style="background-color: rgba(112,112,112, 0.15); border-radius: 8px; height: 175px"
                            >
                                <v-textarea
                                    v-model="diaryNote"
                                    maxlength="150"
                                    auto-grow
                                    hide-details
                                    dense
                                    class="ma-0 px-2 py-0"
                                    placeholder="Tragen Sie hier den Klassenbucheintrag(max 150 Zeichen) ein"
                                    @blur="saveDiaryNote(diaryNote)"
                                />
                            </div>
                        </div>
                    </div> -->
                    <div :class="isPopup ? 'mx-5' : 'whiteBackgroundTile my-4'">
                        <div :class="isPopup ? 'mt-2' : ''">
                            <v-row>
                                <v-col
                                    class="font-weight-bold py-2 d-flex align-center justify-space-between"
                                    style="align-items: flex-end"
                                >
                                    <div class="d-flex align-center">
                                        <v-tooltip
                                            location="top"
                                            :offset="[100,0]"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 20px;"
                                                    alt
                                                   
                                                    v-bind="props"
                                                >
                                            </template>
                                            <p
                                                style="max-width: 200px"
                                            >
                                                Notiz zur Stunde (z.B HA: AB Seite 1/2 etc ), der Eintrag wird automatisch in das Klassenbuch
                                                übernommen und kann dort ebenfalls bearbeitet werden.
                                            </p>
                                        </v-tooltip>
                                        <p class="pl-2 ma-0 mt-2">
                                            Klassenbucheintrag
                                        </p>
                                    </div>
                                </v-col>
                            </v-row>
                            <div
                                id="fachInfo"
                                tabindex="0"
                                style="background-color: rgba(112,112,112, 0.15); border-radius: 8px"
                            >
                                <advanced-quill-editor
                                    v-if="updateNote"
                                    v-model="diaryNote"
                                    :active-toolbar="true"
                                    placeholder="Fügen Sie den Aufgaben eine Notiz bei."
                                    style="padding-bottom: 20px"
                                    @link-clicked="showLinkExplanation"
                                />
                                <!-- <v-textarea
                                    v-if="updateNote"
                                    v-model="diaryNote"
                                    maxlength="150"
                                    auto-grow
                                    hide-details
                                    dense
                                    class="ma-0 px-2 py-0"
                                    placeholder="Fügen Sie den Aufgaben eine Notiz bei."
                                /> -->
                                <simple-bar
                                    v-else-if="diaryNote"
                                    :auto-hide="false"
                                    style="font-size: medium; font-weight: normal; line-height: 25px; cursor: pointer"
                                    class="mb-4 mt-1 scroll-area"
                                    @click="update"
                                >
                                    <!-- Disable vue/no-v-html because appointment note is sanitized on backend-->
                                    <!-- eslint-disable vue/no-v-html -->
                                    <p
                                        v-if="diaryNote"
                                        class="ql-editor pa-3"
                                        v-html="sanitizeHtml(urlify(diaryNote))"
                                    />
                                    <!-- eslint-enable vue/no-v-html -->
                                </simple-bar>
                                <div
                                    v-else
                                    class="pa-3"
                                    style="font-size: medium; color: rgb(var(--v-theme-fgrau)); cursor: pointer"
                                    @click="update"
                                >
                                    Fügen Sie den Aufgaben eine Notiz bei.
                                </div>
                            </div>
                        </div>
                    </div>

                    <!--#endregion-->

                    <hr
                        v-if="isPopup"
                        aria-hidden="true"
                        class="eHr"
                    >

                    <!--#region Material -->
                    <div
                        class="mx-0 mb-4"
                        :class="isPopup ? '' : 'whiteBackgroundTile'"
                    >
                        <div
                            class="d-flex justify-space-between"
                            style="align-items: flex-end"
                        >
                            <!-- Todo fix this properly, causes 'contains of undefined' error because checks globally -->
                            <!-- v-click-outside="{ handler: closeDialog, include: include }"-->
                            <v-tooltip
                                v-model="supFormats"
                                max-width="350"
                                location="bottom"
                            >
                                <template v-slot:activator="{props}">
                                    <div
                                        class="font-weight-bold py-2 pt-0 d-flex align-center justify-flex-start"
                                        v-bind="props"
                                    >
                                        <div class="d-flex">
                                            <img
                                                :src="materialIcon"
                                                style="height: 20px"
                                                alt="Material"
                                            >
                                            <p
                                                class="pl-1 ma-0"
                                            >
                                                Aufgaben
                                            </p>
                                        </div>

                                        <img
                                            :src="infoIcon"
                                            alt
                                            class="icon20 ml-1"
                                        >
                                    </div>
                                </template>

                                <span>Unterstützte Formate:</span>
                                <div class="d-flex align-center">
                                    <img
                                        :src="microfonIcon"
                                        alt
                                        class="tinyIcon iconToWhite mr-1"
                                    >
                                    <span>Audio: AAC, MP3, M4A, OGG, WAV, WMA</span>
                                </div>
                                <div class="d-flex align-center">
                                    <img
                                        :src="cameraIcon"
                                        alt
                                        class="tinyIcon iconToWhite mr-1"
                                    >
                                    <span>Bilder/Texte: BMP, JPG, PDF, PNG, TIFF</span>
                                </div>
                                <div class="d-flex align-center">
                                    <img
                                        :src="videoIcon"
                                        alt
                                        class="tinyIcon iconToWhite mr-1"
                                    >
                                    <span>Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV</span>
                                </div>
                                <div class="d-flex align-center">
                                    <img
                                        :src="wordIcon"
                                        alt
                                        class="tinyIcon iconToWhite mr-1"
                                    >
                                    <span style="line-height: 16px">
                                        MS Office Dateien: DOCX, PPTX, XLSX, TXT, etc.
                                    </span>
                                </div>
                            </v-tooltip>

                            <div class="d-flex justify-start align-center mr-2">
                                <AudioUpload
                                    :upload="uploadAudioFile"
                                    :appointment-id="appointment._id"
                                    :teacher="true"
                                    :group="appointment.group"
                                />
                                
                                <FileListViewChangeButton
                                    :list-view="listViewMaterial"
                                    @change-list-view="(view) => { changeListViewMaterial(view) }"
                                />

                                <v-menu
                                    v-model="openMenu"
                                    :offset="[0,10]"
                                    style="z-index: 11 !important"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            elevation="0"
                                            style="background-color: transparent; min-width: 20px; max-width: 25px;"
                                           
                                            aria-label="Datei hochladen, unterstütze Formate, welche als Abgabe hochgeladen werden können: Audio: AAC, MP3, M4A, OGG, WAV, WMA. Bilder oder Texte: BMP, JPG, PDF, PNG, TIFF. Video: FLV, MOV, MPG, MP4, M4V, WEBM, WMV. MS Office Dateien: DOCX, PPTX, XLSX, TXT, etc."
                                            v-bind="props"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="square20 iconToDunkelgrau"
                                                alt=""
                                            >
                                            <!-- <p class="ml-2">
                                                Hochladen
                                            </p> -->
                                        </v-btn>
                                    </template>
                                    <div class="small-drop-down-menu">
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: white; background-color: rgb(var(--v-theme-headerblue))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="openEmptyEdit('empty')"
                                        >
                                            <img
                                                :src="neuesABIcon"
                                                class="iconSize mr-1"
                                                style="filter: brightness(1000%);"
                                            >
                                            Datei im Editor erstellen
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-timetableColor))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="openLibreTemplate('word')"
                                        >
                                            <img
                                                :src="wordIcon"
                                                class="iconSize mr-1"
                                            >
                                            Text Dokument erstellen
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-fileArchiveOrange))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="openLibreTemplate('powerpoint')"
                                        >
                                            <img
                                                :src="powerpointIcon"
                                                class="iconSize mr-1"
                                            >
                                            Präsentation erstellen
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-chatColor))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="openLibreTemplate('excel')"
                                        >
                                            <img
                                                :src="excelIcon"
                                                class="iconSize mr-1"
                                            >
                                            Tabelle erstellen
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-dunkelgrau))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="() => { fileWidgetUploadDialog = true; }"
                                        >
                                            <img
                                                :src="eklaraDateiIcon"
                                                class="iconSize mr-1"
                                            >
                                            Datei aus eKlara hochladen
                                        </v-btn>
                                        <v-btn
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-dunkelgrau))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="clickTeacherUploadButton"
                                        >
                                            <img
                                                :src="isDesktopSize ? dateiIcon : cameraIcon"
                                                class="iconSize mr-1"
                                                alt="Datei hochladen"
                                            >
                                            {{ uploadText }}
                                        </v-btn>
                                        <v-btn
                                            v-if="isDesktopSize"
                                            tabindex="0"
                                            style="text-transform: inherit; color: rgb(var(--v-theme-dunkelgrau))"
                                            variant="text"
                                            class="d-flex justify-start"
                                            @click="clickImageCapture"
                                        >
                                            <img
                                                :src="cameraIcon"
                                                class="iconSize mr-1"
                                                alt="Kamera öffnen"
                                            >
                                            Bild mit Kamera hochladen
                                        </v-btn>
                                    </div>
                                </v-menu>

                                <ImageCapture
                                    :toggle-dialog="toggleImageCapture"
                                    @handle-image-captured="handleImageCaptured"
                                />
                            </div>
                        </div>

                        <div
                            v-if="materialUploads.length === 0"
                            class="mt-5 mb-5 pa-3"
                            style="font-size: medium; background-color: rgba(112,112,112, 0.15); border-radius: 8px; color: rgb(var(--v-theme-fgrau));"
                            @click="openMenu = true"
                        >
                            {{ materialUploadText }}
                        </div>

                        <!-- Upload buttons and input -->
                        <div class="my-2">
                            <input
                                id="teacherUploadInput"
                                ref="teacherUploadInput"
                                type="file"
                                hidden
                                @change="() => teacherUploadInputChange(appointment._id)"
                            >
                        </div>

                        <FileList
                            :files="materialUploadDrafts.concat(materialUploads)"
                            :preview-urls="urls"
                            :view="listViewMaterial"
                            :primary-color="'#706f6f'"
                            :account-role="'teacher'"
                            :person="person"

                            @title-clicked="(file) => { openFilePreview(file, true, true) }"
                            @thumbnail-clicked="(file) => { openFilePreview(file, true, true) }"
                            @play-video="(file) => { play(file) }"
                            @play-audio="(file) => { playAudio(file) }"
                            @video-in-video-clicked="(file) => { showUploadVideoInVideoPopup(file) }"
                            @edit-in-editor="(file) => { openAnsicht(file.title, file.file, file._id, file.uploader, file.isDeleted) }"
                            @edit-in-libre="(file) => { openLibreEditor(file) }"
                            @edit-draft="(file) => { openDraftForEdit(file) }"
                            @download-file="(file) => { clickDownloadTeacherUpload(file) }"
                            @delete-file="(file) => { deleteDialog = true; elementToDelete = file }"
                            @delete-draft="(file) => { deleteDraft(file.draft._id) }"
                        />
                    </div>
                    <!--#endregion -->

                    <hr
                        v-if="isPopup"
                        aria-hidden="true"
                        class="eHr"
                    >

                    <div
                        class="mx-0"
                        :class="isPopup ? '' : 'whiteBackgroundTile'"
                    >
                        <div class="mt-2">
                            <div
                                style="display: flex; flex-direction: row; justify-content: space-between"
                                class="mb-2"
                            >
                                <div
                                    class="font-weight-bold pb-0 pt-0 d-flex align-center"
                                >
                                    <img
                                        :src="abgabenIcon"
                                        style="height: 20px;"
                                        alt="Abgabensymbol"
                                    >
                                    <p class="pl-2 ma-0">
                                        Abgaben
                                    </p>
                                </div>
                                <FileListViewChangeButton
                                    :list-view="listViewAbgaben"
                                    @change-list-view="(view) => { changeListViewAbgaben(view) }"
                                />
                            </div>

                            <div>
                                <!-- if atleast one pupil has uploaded homework -->
                                <div v-if="appointment.pupilUpload.length > 0 || isCorrectionAvailable(appointment.pupilUpload) || missingAppointments.length > 0 || anyUploadWasMoved">
                                    <!-- Zu korrigieren-section -->

                                    <div class="font-weight-bold mb-2 pb-0 mx-5 pt-0 d-flex align-center">
                                        <img
                                            :src="offenIcon"
                                            style="height: 20px;"
                                            alt=""
                                        >
                                        <p class="pl-2 ma-0">
                                            Zu korrigieren
                                        </p>
                                    </div>


                                    <div
                                        v-if="appointment && !pupilUploadsToCorrect.length && !correctionUploadDrafts.length"
                                        class="ma-0 fileUploadPlaceholderText"
                                    >
                                        <p class="mb-0">
                                            Aktuell sind keine Aufgaben bereit zum Korrigieren.
                                        </p>
                                    </div>
                                    <div v-else>
                                        <div
                                            v-for="(el, index) in pupilUploadsToCorrectWithPupil"
                                            :key="el + index + rerenderPupilUploadsToCorrectWithPupil"
                                            :class="{ brighterToCorrectRow: index % 2 === 1 }"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                            style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(128, 128, 128, 0.25)"
                                        >
                                            <v-badge
                                                color="#ff0000"
                                                :content="unseenUploads(el._id)"
                                                :model-value="unseenUploads(el._id)"
                                                location="left"
                                                :offset-x="unseenUploads(el._id) < 10 ? -4 : 2"
                                                offset-y="6"
                                                style="z-index: 9; width: inherit"
                                            >
                                                <div style="width: inherit">
                                                    <div
                                                        style="width: 100%; cursor: pointer"
                                                        @click="showAndHideAppointments(el._id, 'AppointmentsToCorrectShort')"
                                                    >
                                                        {{ el.name + ' ' + el.lastName.slice(0,1) }}
                                                        <v-icon
                                                            :ref="`showAppointmentsToCorrectShort${el._id}`"
                                                            style="display: none"
                                                        >
                                                            mdi-chevron-up
                                                        </v-icon>
                                                        <v-icon :ref="`hideAppointmentsToCorrectShort${el._id}`">
                                                            mdi-chevron-down
                                                        </v-icon>
                                                    </div>
                                                    <div
                                                        :ref="`showAppointmentsToCorrectShort${el._id}`"
                                                        style="display: none"
                                                    >
                                                        <FileList
                                                            :files="el.uploads"
                                                            :preview-urls="urls"
                                                            :view="listViewAbgaben"
                                                            :primary-color="'#706f6f'"
                                                            :account-role="'teacher'"
                                                            :person="person"
                                                            :usage="'toCorrectTeacher'"

                                                            @title-clicked="(file) => { openFilePreview(file, true, true) }"
                                                            @thumbnail-clicked="(file) => { openFilePreview(file, true, true) }"
                                                            @play-video="(file) => { playPupil(file) }"
                                                            @play-audio="(file) => { playAudioPupil(file) }"
                                                            @video-in-video-clicked="(file) => { showUploadVideoInVideoPopup(file) }"
                                                            @edit-in-editor="(file) => { openEdit(file.title, file.file, file._id, file.uploader, file.isDeleted) }"
                                                            @edit-in-libre="(file) => { openLibreEditor(file, true) }"
                                                            @download-file="(file) => { isCorrectionAvailable(file) ? clickDownloadPupilCorrection(file) : clickDownloadPupilUpload(file) }"
                                                            @delete-file="(file) => { deleteDialog = true; elementToDelete = file }"
                                                            @edit-draft="(file) => { openDraftForEdit(file, 'teacher') }"
                                                            @delete-draft="(file) => { deleteDraft(file.draft._id) }"
                                                        />
                                                    </div>
                                                </div>
                                            </v-badge>
                                        </div>
                                    </div>

                                    <!--Korrigiert-section-->
                                    <v-row class="mb-2">
                                        <v-col
                                            class="font-weight-bold pb-0 mx-5 d-flex align-center"
                                        >
                                            <img
                                                :src="bearbeitetIcon"
                                                style="height: 20px;"
                                                alt="Korrigiert Symbol"
                                            >
                                            <p class="pl-2 ma-0">
                                                Korrigiert
                                            </p>
                                        </v-col>
                                    </v-row>
                                    <v-row
                                        v-if="!pupilUploadsCorrected.length"
                                        dense
                                        class="ma-0 fileUploadPlaceholderText"
                                        align-content="center"
                                    >
                                        <p class="mb-0">
                                            Noch ist nichts korrigiert.
                                        </p>
                                    </v-row>

                                    <!-- jump -->
                                    <div class="correctedShort">
                                        <div
                                            v-for="(el, index) in pupilUploadsCorrectedWithPupil"
                                            :key="el + index"
                                            :class="{ brighterCorrectedRow: index % 2 === 1 }"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                            style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(60, 170, 105, 0.25)"
                                        >
                                            <div
                                                style="cursor: pointer; width: inherit"
                                            >
                                                <div
                                                    style="width: 100%; cursor: pointer"
                                                    @click="showAndHideAppointments(el._id, 'CorrectedAppointmentsShort')"
                                                >
                                                    {{ el.name + ' ' + el.lastName.slice(0,1) }}
                                                    <v-icon
                                                        :ref="`showCorrectedAppointmentsShort${el._id}`"
                                                        style="display: none"
                                                    >
                                                        mdi-chevron-up
                                                    </v-icon>
                                                    <v-icon :ref="`hideCorrectedAppointmentsShort${el._id}`">
                                                        mdi-chevron-down
                                                    </v-icon>
                                                </div>
                                                <div
                                                    :ref="`showCorrectedAppointmentsShort${el._id}`"
                                                    style="display: none"
                                                >
                                                    <FileList
                                                        :files="el.uploads"
                                                        :preview-urls="urls"
                                                        :view="listViewAbgaben"
                                                        :primary-color="'#706f6f'"
                                                        :account-role="'teacher'"
                                                        :person="person"
                                                        :usage="'correctedTeacher'"
                                                        :pupil-account-id-correction="el.account"

                                                        @title-clicked="(file) => { openFilePreview(file, false, false) }"
                                                        @thumbnail-clicked="(file) => { openFilePreview(file, false, false) }"
                                                        @play-video="(file) => { playPupil(file) }"
                                                        @play-audio="(file) => { playAudioPupil(file) }"
                                                        @open-in-editor="(file) => { handleOpenAnsichtClick(file, file.isMoved) }"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <!--Nicht abgegeben-section-->
                                    <v-row class="mb-2">
                                        <v-col
                                            class="font-weight-bold pb-0 mx-5 d-flex align-center"
                                        >
                                            <img
                                                :src="keinDocument"
                                                style="height: 20px;"
                                                alt="Nicht abgegeben Symbol"
                                            >
                                            <p class="pl-2 ma-0">
                                                Nicht abgegeben
                                            </p>
                                        </v-col>
                                    </v-row>

                                    <div class="toDoShort">
                                        <div
                                            v-for="(el, index) in missingAppointments"
                                            :key="el + index"
                                            :class="getRowStyling(index, el)"
                                            class="py-2 px-5 d-flex justify-space-between align-center"
                                            style="font-size: medium; font-weight: normal; line-height: 25px; width: 100%; background-color: rgba(230, 35, 30, 0.25)"
                                        >
                                            <div
                                                style="cursor: pointer"
                                                @click="showAndHideAppointments(el._id, 'MissingAppointmentsShort')"
                                            >
                                                {{ el.name + ' ' + el.lastName.slice(0,1) }}
                                                <v-icon
                                                    :ref="`showMissingAppointmentsShort${el._id}`"
                                                    style="display: none"
                                                >
                                                    mdi-chevron-up
                                                </v-icon>
                                                <v-icon :ref="`hideMissingAppointmentsShort${el._id}`">
                                                    mdi-chevron-down
                                                </v-icon>
                                                <ul
                                                    :ref="`showMissingAppointmentsShort${el._id}`"
                                                    style="display: none"
                                                >
                                                    <div
                                                        v-for="(app, i) in el.undoneFiles"
                                                        :key="app + i"
                                                        class="fileNameUndoneFiles"
                                                    >
                                                        <li>{{ el.undoneFiles[i] }}</li>
                                                    </div>
                                                </ul>
                                            </div>
                                            <!--Schüler haben noch keine Abgabe abgegeben-->
                                            <div style="display: flex">
                                                <v-tooltip location="top">
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn
                                                            variant="text"
                                                            elevation="0"
                                                            size="x-small"
                                                            class="btnStyle"
                                                            color="rgba(0,0,0,0)"
                                                            v-bind="props"
                                                        >
                                                            <img
                                                                :src="keinDocument"
                                                                alt="fehlende Abgabe-Symbol"
                                                                style="height: 20px;"
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span>Der/Die Schüler/-in hat noch nicht alle Abgaben erledigt</span>
                                                </v-tooltip>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!--there are no pupil uploads to view or correct-->
                                <div
                                    v-else
                                    class="mx-5"
                                    style="color: rgb(var(--v-theme-fgrau)); font-size: medium"
                                >
                                    Es hat noch kein Schüler eine Abgabe getätigt.
                                </div>
                            </div>
                        </div>
                    </div>
                </simple-bar>
            </v-card-text>
        </v-card>
        <FileWidgetSelectionDialog
            v-if="fileWidgetUploadDialog"
            v-model:show="fileWidgetUploadDialog"
            :subject-apponintment="appointment"
            :item-selected-callback="prepareUploadViaFileWidget"
            @close="fileWidgetUploadDialog = false;"
        />
        <!-- name file and set parameters to upload from FileWidget -->
        <NameFile
            v-if="fileWidgetNameDialog"
            :show-dialog="fileWidgetNameDialog"
            :group="getGroupObject(appointment.group)"
            :show-teamwork-checkbox="showTeamworkCheckbox"
            :original-name="newFileName"
            :callback="uploadViaFileWidget"
            @update:show-dialog="fileWidgetNameDialog = $event"
            @close="fileWidgetNameDialog = false"
        />
        <NameFile
            v-if="showNameFile"
            :show-dialog="showNameFile"
            :original-name="uploadFileName"
            :callback="fileNameCallback"
            :is-subtitle-ready="isSubtitleReady"
            :show-teamwork-checkbox="showTeamworkCheckbox"
            :group="getGroupObject(appointment.group)"
            :show-is-assignment="!(currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder')"
            @update:show-dialog="showNameFile = $event"
        />

        <!-- Upload Video in Video Popup -->
        <UploadVideoInVideoPopup
            ref="uploadVideoInVideo"
            :on-close="onUploadVideoDone"
        />

        <!--#region Popups, overlays and snackbars -->
        <v-dialog
            v-if="deleteDialog"
            v-model="deleteDialog"
            max-width="350px"
            content-class="rounded-4"
        >
            <v-card
                class="cardStyle"
                style="overflow: hidden; border-radius: 16px"
            >
                <v-card-title
                    style="background-color: rgb(var(--v-theme-timetableColor))"
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                >
                    <p style="color: white">
                        Löschen bestätigen
                    </p>
                    <v-btn
                        icon="$vuetify"
                        variant="plain"
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon30 iconToWhite"
                            alt="schließen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text>
                    Wollen Sie die Datei wirklich
                    <strong>unwiderruflich</strong> löschen?
                </v-card-text>
                <v-card-actions class="d-flex justify-center mb-2">
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="#F5F5F5"
                        @click="deleteDialog = false"
                    >
                        <img
                            :src="schliesenIcon"
                            class="icon20 mr-2"
                            alt="abbrechen"
                        >
                        Abbrechen
                    </v-btn>
                    <v-spacer />
                    <v-btn
                        class="optionBtn text-none ml-2"
                        color="rot"
                        theme="dark"
                        @click="clickDeleteUpload"
                    >
                        <img
                            :src="papierkorbIcon"
                            class="icon20 mr-2 iconToWhite"
                        >
                        Löschen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <!-- Normal Video Popup -->
        <v-dialog
            v-if="pupilVideoOverlay || (videoUrl && (subtitleURL || noSubtitle))"
            :model-value="pupilVideoOverlay ? videoUrl : videoUrl && (subtitleURL || noSubtitle)"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop(); subtitleURL = null; noSubtitle = false"
                    >
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                        :fullscreen-mode="true"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <!-- Video in Video Popup -->
        <v-dialog
            v-if="primaryVideoUrl && secondaryVideoUrl && (subtitleURL || noSubtitle)"
            :model-value="primaryVideoUrl && secondaryVideoUrl && (subtitleURL || noSubtitle)"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="primaryVideoUrl = null; secondaryVideoUrl = null; $refs.videoInVideo.stop(); subtitleURL = null; noSubtitle = false"
                    >
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <video-in-video
                        ref="videoInVideo"
                        :primary-video-src="primaryVideoUrl"
                        :secondary-video-src="secondaryVideoUrl"
                        :subtitle-src="subtitleURL"
                    />
                </v-col>
            </v-row>
        </v-dialog>
        <!--#endregion -->
        <FilePreviewDialog
            v-if="showFilePreview"
            :enable-fullscreen-preview="showFilePreview"
            :preview-selection="previewSelection"
            :preview-information="previewSelection.file"
            :files="[...appointment.teacherUploads, ...appointment.pupilUpload]"
            :enable-editing="allowEditingForPreview"
            :person="person"
            :account-role="'teacher'"
            :fach-info="true"
            :usage="'plan'"
            @open-editor="openAnsicht(previewSelection.name, previewSelection.file, previewSelection._id, previewSelection.uploader)"
            @open-libre="openLibreEditor(previewSelection, isCorrectur)"
            @download="handlePreviewDownloadClicked"
            @close="showFilePreview = false"

            @delete-file="(file) => { deleteDialog = true; elementToDelete = file }"
            @delete-draft="(file) => { deleteDraft(file.draft._id) }"

            @edit-in-editor="(file) => { openAnsicht(file.title, file.file, file._id, file.uploader) }"
            @edit-in-libre="(file) => { openLibreEditor(file, isCorrectur) }"
            @edit-draft="(file) => { openDraftForEdit(file) }"
            @download-file="(file) => { clickDownloadTeacherUpload(file) }"
            @correct-in-editor="(file) => { openEdit(file.title, file.file._id, file._id, file.uploader) }"
            @correct-in-libre="(file) => { openLibreEditor(file, true) }"
            @download-correction-file="(file) => { isCorrectionAvailable(file) ? clickDownloadPupilCorrection(file) : clickDownloadPupilUpload(file) }"
            @edit-correction-draft="(file) => { openDraftForEdit(file, 'teacher') }"

            @open-in-editor="(file) => { handleOpenAnsichtClick(file, file.isMoved) }"
        />

        <VideoTutorial
            v-if="videoTutorial && showVideoTutorial"
            :video-tutorial="videoTutorial"
            @close="onVideoTutorialClose"
        />
    </div>
</template>

<script>
import * as backend from "@/api/backend";
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import {editButtonCondition, doneButtonCondition } from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListConditions";

import {
    allowedExtensionsForAudioPlayer,
    allowedExtensionsForEditor,
    allowedExtensionsForLibre,
    allowedExtensionsForVideoPlayer
} from "../../constants/allowedMimeTypes";
import { isFileThumbnailCompatibleExtension } from "../../util/MimetypeHelper.js"
import AudioUpload from "@/components/AudioUpload";
import FileWidgetSelectionDialog from "@/components/Utils/FileWidgetSelectionDialog";
import ProgressBar from "@/components/ProgressBar";
import NameFile from "@/components/NameFile";
import UploadVideoInVideoPopup from "@/components/Lehrer/UploadVideoInVideoPopup";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import FileListViewChangeButton
    from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileListViewChangeButton";

import keinDocument from '@/assets/Icons/FaceliftIcons/datei_nicht_abgegeben.svg';
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import microfonIcon from '@/assets/Icons/FaceliftIcons/mikrofon_an.svg';
import videoIcon from '@/assets/Icons/video.svg';
import schliesenIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import stiftIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import bearbeitetIcon from "@/assets/Icons/FaceliftIcons/datei_korrigiert.svg";
import offenIcon from "@/assets/Icons/FaceliftIcons/datei_offen.svg";
import runterladenIcon from "@/assets/Icons/FaceliftIcons/datei_herunterladen.svg";
import plusIcon from "@/assets/Icons/plus.svg";
import playIcon from "@/assets/Icons/rechts-filled-11.svg";
import materialIcon from "@/assets/Icons/FaceliftIcons/material_dateien.svg";
import abgabenIcon from "@/assets/Icons/FaceliftIcons/hausis.svg";
import arrowDown from "@/assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import infoIcon from "@/assets/Icons/FaceliftIcons/info_hinweis.svg";
import cameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import dateiIcon from "@/assets/Icons/FaceliftIcons/ordner_lokal.svg";
import eklaraDateiIcon from "@/assets/Icons/FaceliftIcons/ordner_eklara.svg";
import neuesABIcon from "@/assets/Icons/neues-interaktives-arbeitsblatt.svg";
import wordIcon from "@/assets/Icons/FaceliftIcons/doc_libre_fachinfo.svg";
import powerpointIcon from "@/assets/Icons/FaceliftIcons/ppt_libre_fachinfo.svg";
import excelIcon from "@/assets/Icons/FaceliftIcons/xls_libre_fachinfo.svg";
import saveIcon from "@/assets/Icons/FaceliftIcons/speichern_bestaetigen.svg";
import documentIcon from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import musicIcon from '@/assets/Icons/f-musik-84.svg';
import pdfIcon from '@/assets/Icons/FaceliftIcons/pdf_libre_fachinfo.svg';
import imageIcon from '@/assets/Icons/FaceliftIcons/bild.svg';
import img_eklaraLogo from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import bookIcon from "../../assets/Icons/FaceliftIcons/klassenbuch.svg";

import ImageCapture from "@/components/Utils/ImageCapture.vue";
import { generateThumbnailForPdf } from "@/util/tempThumbnailGen";
import FileList from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/FileList/FileList";
import sanitizeHtml from '../../util/sanitizeHtml';
import urlify from '../../util/urlify';

export default {
    name: "FachInfoLehrer",
    components: {
        FileList,
        AudioUpload,
        FileWidgetSelectionDialog,
        ProgressBar,
        VideoInVideo,
        UploadVideoInVideoPopup,
        AdvancedQuillEditor,
        VideoTutorial,
        NameFile,
        ImageCapture,
        FilePreviewDialog,
        FileListViewChangeButton,
    },
    props: {
        // Variables
        isPopup: {required: true},
        appointment: {required: true, type: Object },
        tabOrderPopup: {required: false},
        planVollbild: {required: false, default: false},
        person: {required: true},
        sehen: {required: false, default: false},
        hoeren: {required: false, default: false},
        motorik: {required: false, default: false},
        urls: {required: true},
        isJoinable: {required: true},
        hasUploadedIcon: {required: false, default: false},
        groups: {required: true},

        // Functions
        getSrc: {type: Function, required: true},
        getColorIcon: {type: Function, required: true},
        getSrcWhite: {type: Function, required: true},
        getBackgroundColor: {type: Function, required: true},
        requestAppointments: {required: true},
        uploadFileObject:{ default: null, required: false},
        uploadFileCallback: { type: Boolean, required: false, default: false}
    },
    data: () => ({
        //#region Icons
        schliesenIcon,
        stiftIcon,
        bearbeitetIcon,
        offenIcon,
        runterladenIcon,
        plusIcon,
        playIcon,
        materialIcon,
        abgabenIcon,
        arrowDown,
        infoIcon,
        cameraIcon,
        dateiIcon,
        eklaraDateiIcon,
        neuesABIcon,
        keinDocument,
        papierkorbIcon,
        microfonIcon,
        videoIcon,
        wordIcon,
        powerpointIcon,
        excelIcon,
        saveIcon,
        documentIcon,
        musicIcon,
        pdfIcon,
        imageIcon,
        img_eklaraLogo,
        optionsIcon,
        bookIcon,
        //#endregion
        sanitizeHtml,
        urlify,
        showTeamworkCheckbox: false,
        fileWidgetUploadDialog: false,
        showPupilUploadProgress: false,
        pupilUploadProgress: 0.0,
        pupilXmlHttpRequest: null,
        audioUrl: null,
        videoUrl: null,
        primaryVideoUrl: null,
        secondaryVideoUrl: null,
        toggleImageCapture: false,
        showNameFile: false,
        uploadFileName: '',
        subtitleURL: null,
        noSubtitle: false,
        groupMembers: '',
        fileNameCallback: () => {},
        isFileThumbnailCompatibleExtension,
        uploadText: 'Datei vom Gerät hochladen',
        materialUploadText: 'Hier reinziehen oder + (Plus) klicken um Aufgaben hochzuladen.',
        pupilVideoOverlay: false,
        openMenu: false,

        showFilePreview: false,
        previewSelection: null,
        // true if teacherUpload, false if pupilUpload
        isPreviewSelectionTeacherUpload: false,
        allowEditingForPreview:  false,
        isCorrectur: false,
        //Libre
        libreDocId: '',
        libreAccessToken: '',
        libreFile: null,
        libreDoc: null,
        allowedExtensionsForLibre,
        allowedExtensionsForEditor,

        fileType: '',

        updateNote: false,
        updateDiaryNote: false,
        supFormats: false,      // Supported formats tooltip next to Hochladen-btn
        deleteDialog: false,
        elementToDelete: null,
        missingAppointments: [],
        pupilUploadsCorrected: [],
        fileWidgetNameDialog: false,
        fileToUpload: null,
        newFileName: '',
        selectedUsers: [],
        startDate: new Date().toISOString().substr(0, 10),
        isAssignment: null,
        showTeacherUploadProgress: false,
        teacherUploadProgress: 0.0,
        teacherXmlHttpRequest: null,
        // Show video Tutorial is set to true when button is clicked
        videoTutorial: false,
        showVideoTutorial: false,
        appointmentNote: null,
        appointmentNoteSaveTimeout: null,
        appointmentNoteSaveWatcherBlocker: true,
        diaryNote: null,
        diaryNoteSaveTimeout: null,
        diaryNoteSaveWatcherBlocker: true,

        pupils: null,
        listViewMaterial: 'list',
        listViewAbgaben: 'list',
        // This flag is needed to rerender the pupilUploadsToCorrectWithPupil List after deleting a draft
        rerenderPupilUploadsToCorrectWithPupil: false,

        editButtonCondition,
    }),
    computed: {
        ...mapState('util', ['windowWidth', 'windowHeight']),
        ...mapGetters('util', ['currentlyOpenAppointment', 'currentUploadGroup', 'currentUploadFolder', 'currentlyOpenTeacherUploadId', 'getTeacherUploadCallback']),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapState('auth', ['token', 'account']),
        ...mapGetters('groups', ['groupsById']),
        ...mapGetters('deactivatedFeatures', ['diaryDeactivated', 'messagesDeactivated',]),
        
        pupilUploadsToCorrect() {
            return this.appointment.pupilUpload.filter(item => (!item.editLeafs || item.editLeafs.length === 0) && !this.draftOfGivenUpload(item._id));
        },
        pupilUploadsToCorrectWithPupil() {
            if(this.pupils) {
                let pupilUploadsToCorrectWithPupil = [];
                const pupilUploadsToCorrect = this.pupilUploadsToCorrect;
                const uniqueUploaders = [...new Set(pupilUploadsToCorrect.map(upload => upload.uploader))];

                uniqueUploaders.map((uploader) => {
                    if (this.pupils) {
                        let pupil = this.pupils.find((student) => student.account === uploader);
                        let obj = { ...pupil, uploads: [] };
                        pupilUploadsToCorrect.map(upload => {
                            if (upload.uploader === pupil.account) {
                                obj.uploads.push(upload);
                            }
                        });
                        pupilUploadsToCorrectWithPupil.push(obj);
                    }
                });
                // We also want to put the drafts in this list for the unique pupils
                this.correctionUploadDrafts.map((draft) => {
                    // Find the pupilUpload the draft ist based on
                    const draftReference = this.appointment.pupilUpload.find((pupilUpload) => {
                        return pupilUpload._id === draft.reference;
                    })
                    if(draftReference) {
                        // If the pupil is already in the list pupilUploadsToCorrectWithPupil then concat the draft to the
                        // beginning of his list otherwise put the pupil in the list with the draft in the uploads array
                        if(!uniqueUploaders.some((uploader) => {
                            if(uploader === draftReference.uploader) {
                                pupilUploadsToCorrectWithPupil.some((pupilUploadPair) => {
                                    if(pupilUploadPair.account === uploader) {
                                        pupilUploadPair.uploads = [draft].concat(pupilUploadPair.uploads);
                                        return true;
                                    }
                                })
                                return true;
                            }
                        })) {
                            // Do this when the pupil isn't already in pupilUploadsToCorrectWithPupil
                            let pupil = this.pupils.find((student) => student.account === draftReference.uploader);
                            let obj = { ...pupil, uploads: [] };
                            obj.uploads.push(draft);
                            pupilUploadsToCorrectWithPupil.push(obj);
                        }
                    }
                })
                pupilUploadsToCorrectWithPupil.forEach((pupil) => {
                    pupil.uploads.forEach((upload) => {
                        upload.usage = 'toCorrectTeacher';
                    })
                })
                return pupilUploadsToCorrectWithPupil.sort(this.alphabetically);
            }
            return [];
        },
        pupilUploadsCorrectedWithPupil() {
            let pupilUploadsCorrectedWithPupil = [];
            const pupilUploadsCorrected = this.pupilUploadsCorrected;
            const uniqueUploaders = [...new Set(pupilUploadsCorrected.map(upload => upload.uploader))];

            uniqueUploaders.map((uploader) => {
                let pupil = this.pupils.find((student) => student.account === uploader);
                let obj = { ...pupil, uploads: [] };
                pupilUploadsCorrected.map(upload => {
                    if (upload.uploader === pupil.account) {
                        obj.uploads.push(upload);
                    }
                });
                pupilUploadsCorrectedWithPupil.push(obj);
            });
            pupilUploadsCorrectedWithPupil.forEach((pupil) => {
                pupil.uploads.forEach((upload) => {
                    this.$set(upload, 'usage', 'correctedTeacher');
                })
            });
            return pupilUploadsCorrectedWithPupil.sort(this.alphabetically);
        },
        materialUploads() {
            return this.appointment ? this.appointment.teacherUploads.filter((item) => !item.editParent && !this.draftOfGivenUpload(item._id)) : [];
        },
        materialUploadDrafts() {
            if (this.appointment.drafts) {
                const allMaterialUploads = this.appointment.teacherUploads
                    .filter((item) => !item.editParent)
                    .map(item => item._id);
                return this.appointment.drafts
                    .filter(entry => !entry.reference || allMaterialUploads
                        .find((upload) => upload === entry.reference));
            }
            return [];
        },
        correctionUploadDrafts() {
            if (this.appointment.drafts) {
                const allCorrectionUploads = this.appointment.pupilUpload
                    .filter(item => (!item.editLeafs || item.editLeafs.length === 0))
                    .map(item => item._id);
                const result = this.appointment.drafts
                    .filter(entry => allCorrectionUploads.find((upload) => upload === entry.reference));
                return result;
            }
            return [];
        },
        draftOfGivenUpload() {
            return (uploadId) => {
                if (this.appointment.drafts) {
                    return this.appointment.drafts.find(entry => entry.reference === uploadId);
                }
                return null;
            }
        },
        anyUploadWasMoved() {
            return this.appointment.teacherUploads.filter((upload) => upload.isMoved.length > 0).length > 0;
        },
        isDesktopSize() {
            // height on my 1080p screen is about 762px - Jan
            return this.windowWidth > 900 && this.windowHeight > 700
        }
    },
    watch: {
        uploadFileCallback(){
            if(this.uploadFileCallback === true) {
                this.dragAndDropFile(this.uploadFileObject);
            }
        },
    },
    beforeMount() {
        if(this.account.accountPreferences) {
            if(this.account.accountPreferences.listViewMaterial) {
                this.listViewMaterial = this.account.accountPreferences.listViewMaterial;
            }
            if(this.account.accountPreferences.listViewAbgaben) {
                this.listViewAbgaben = this.account.accountPreferences.listViewAbgaben;
            }
        }
    },
    async mounted() {
        this.$emit("loadImagePreviews");
        this.pupils = await this.getPupils();
        await this.getMissingMembers();
        this.appointmentNote = this.appointment.note;
        if(this.appointment.appointmentDiaryEntryNote){
            const appointmentDiaryEntryRes = await backend.getAppointmentDiaryEntryNote(
                this.appointment.appointmentDiaryEntryNote._id);
            const diaryNote = await appointmentDiaryEntryRes.json();
            this.initialDiaryNote = diaryNote.text;
        }else{
            // this.initialDiaryNote = '';
            this.initialDiaryNote = this.appointmentNote || '';
        }
        this.diaryNote = this.initialDiaryNote;
        // this.initialDiaryNote = this.appointment.appointmentDiaryEntryNote.text || '';

        // Checking for mobile devices
        if (!this.isDesktopSize) {
            this.uploadText = 'Foto, Datei hochladen';
            this.materialUploadText = 'Laden Sie hier Ihre Materialien hoch.';
        }
    },
    methods: {
        ...mapActions('appointments', ['createTeacherUploadViaFileId']),
        ...mapActions("teachers", ["deleteUpload"]),
        ...mapActions("pupils", ['getPupils']),
        ...mapActions("util", ['toggleLoading', 'toggleOpenAppointment', 'toggleCurrentUploadGroup', "toggleFachInfoUploadCallback",'toggleOpenTeacherUploadId',]),
        ...mapActions("groups", ["getGroup"]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions('mimeTypes', ['isCollaboraCompatible']),
        ...mapMutations("util", ["setNameFileExisting"]),
        ...mapActions("documentDrafts", ["deleteDocumentDraft"]),
        ...mapActions('auth', ['getCurrentAccount']),

        getGroupObject(group) {
            if (typeof group === 'object') {
                return group;
            }
            const requestedGroup = this.groups.find((el) => {
                return el._id === group
            });
            return requestedGroup;
        },
        async changeListViewMaterial(view) {
            this.listViewMaterial = view;
            await backend.patchPreferences(this.person.account, { name: 'listViewMaterial', value: view });
        },
        async changeListViewAbgaben(view) {
            this.listViewAbgaben = view;
            await backend.patchPreferences(this.person.account, { name: 'listViewAbgaben', value: view });
        },
        handleThumbnailClicked(upload) {
            const fileExtension = upload.title.split('.').pop().toLowerCase();
            if(['png', 'jpg', 'jpeg', 'bmp'].includes(fileExtension)) {
                this.openAnsicht(upload.title, upload.file, upload._id, upload.uploader);
            } else if (this.allowedExtensionsForLibre.includes(fileExtension)) {
                this.openLibreEditor(upload, false);
            } else if (this.allowedExtensionsForEditor.includes(fileExtension)) {
                this.openEdit(upload.title,upload.file, upload._id, upload.uploader);
            } else {
                this.play(upload);
            }
        },
        handlePreviewDownloadClicked() {
          if (this.isPreviewSelectionTeacherUpload) {
            this.clickDownloadTeacherUpload(this.previewSelection);
          } else if (!this.isPreviewSelectionTeacherUpload && this.allowEditingForPreview){
            this.clickDownloadPupilUpload(this.previewSelection);
          } else {
            this.clickDownloadPupilCorrection(this.previewSelection);
          }
        },
        async openFilePreview(el, allowEdit, isTeacherUpload) {
            if(el.draft) {
                return;
            }
            const isFileDeleted = this.checkForDeletion(el);
            if (isFileDeleted) return;
            let isMovedPupilUpload;
            if(el.editParent && typeof el.isMoved === 'boolean' && el.isMoved === true) {
                isMovedPupilUpload = JSON.parse(JSON.stringify(el));
                el = this.appointment.teacherUploads.find((teacherUpload) => el.editParent === teacherUpload._id );
                if(!el) {
                    this.showSnackbar({
                        message: 'Die Ursprungsdatei wurde gelöscht!',
                        color: 'error'
                    });
                } else {
                    el.showEyeButton = true;
                }
            }
            if(allowedExtensionsForVideoPlayer.includes(el.title.split('.').pop().toLowerCase())) {
                if(el.usage === 'toCorrectTeacher') {
                    await this.playPupil(el);
                } else {
                    await this.play(el);
                }
                return;
            } else if(allowedExtensionsForAudioPlayer.includes(el.title.split('.').pop().toLowerCase())) {
                if(el.usage === 'toCorrectTeacher') {
                    await this.playAudioPupil(el);
                } else {
                    await this.playAudio(el);
                }
                return;
            }
            const file = el.file._id ? el.file._id : el.file;
            const res = await backend.getFile(file, el.fileAccessToken);
            const resJson = await res.json();
            // This is necessary otherwise the file property of an upload is populated and nothing works after opening the FilePreview
            this.previewSelection = JSON.parse(JSON.stringify(el));
            if(isMovedPupilUpload) {
                this.$set(this.previewSelection, 'isMovedPupilUpload', isMovedPupilUpload);
            }
            if(el.usage) {
                this.$set(this.previewSelection, 'usage', el.usage);
            }
            if (resJson.file) {
                this.previewSelection.file = resJson.file;
            }
            this.previewSelection.thumbnail = this.urls[this.previewSelection._id];
            this.allowEditingForPreview = allowEdit;
            this.isPreviewSelectionTeacherUpload = isTeacherUpload;
            this.showFilePreview = true;
        },
        async openLibreTemplate(fileType) {
            const currentAcc = await this.getCurrentAccount();
            const res = await backend.createLibreTemplate(fileType, currentAcc._id);
            const jsonRes = await res.json();
            const libreDoc = jsonRes.libreDoc;
            this.libreFile = jsonRes.file;
            this.libreDoc = libreDoc;
            this.libreAccessToken = jsonRes.accessToken;
            this.libreDocId = libreDoc._id;
            this.fileType = fileType;

            const query = {
                ... this.$route.query,
                fileId: this.libreFile._id,
                libreDocId: this.libreDocId,
                accessToken: this.libreAccessToken,
                fileAccessToken: jsonRes.file.accessToken,
                isTemplate: true,
                fileType: this.fileType,
                subjectName: this.appointment.schoolSubject.name,
                parentComponent: 'plan',
                mode: 'material',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },

        async openLibreEditor(uploadMaterial, isCorrectur) {
            const isFileDeleted = this.checkForDeletion(uploadMaterial);
            if (isFileDeleted) return;
            let fileId = uploadMaterial.file._id ? uploadMaterial.file._id : uploadMaterial.file;
            let fileAccessToken;
            if (isCorrectur) {
              let res = await backend.getCopyOfFile(fileId, uploadMaterial.fileAccessToken);
              const copyOfFile = await res.json();
              fileId = copyOfFile._id
                fileAccessToken = copyOfFile.accessToken;
            }
            // unused backend call?
            let res = await backend.getPupilUpload(this.appointment._id, fileId);
            const libreDoc = await backend.getLibreDocFromFile(fileId);
            const libreDocJson = await libreDoc.json();
            this.libreAccessToken = libreDocJson.accessToken;
            this.libreDocId = libreDocJson.libreDoc._id;

            const file = libreDocJson.file;
            if (file.originalname.includes('.doc')) {
              this.fileType = 'word';
            } else if (file.originalname.includes('.ppt')) {
              this.fileType = 'powerpoint';
            } else if (file.originalname.includes('.xls')) {
              this.fileType = 'excel';
            } else if (file.originalname.includes('.txt')) {
                this.fileType = 'word';
            }

          const query = {
            ...this.$route.query,
            fileId,
            libreDocId: this.libreDocId,
            isTemplate: false,
            accessToken: this.libreAccessToken,
            fileType: this.fileType,
            fileAccessToken: fileAccessToken ? fileAccessToken : uploadMaterial.fileAccessToken,
              parentComponent: 'plan',
            isCorrectur,
            mode: 'material',
          };

          await this.$router.push({
            name: 'libreoffice',
            query,
          });
        },

        async saveNote(appointmentNote) {
            //filter out <p> </p> tags
            const cleanedNote = appointmentNote.replace(/<\/?p>/gi, "");
            let data = {
                _id: this.appointment._id,
                note: cleanedNote,
                groupId: this.appointment.group._id ? this.appointment.group._id : this.appointment.group
            };
            try {
                const res = await backend.patchSchoolSubjectAppointment(data._id, data);
                if (res.status === 200){
                    this.showSnackbar({message: 'Gespeichert!'});
                    this.updateNote = false;
                } else {
                    this.showSnackbar({
                        message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                        color: 'error'
                    });
                }
            } catch (e) {
                this.showSnackbar({message: "Die Notiz ist zu groß. Bitte benutzen Sie für große Dateien den Material-Upload."});
                console.error(e);
            }
            this.requestAppointments(this.appointment._id);
        },
        async saveDiaryNote(diaryNote) {
            //filter out <p> </p> tags
            const cleanedNote = diaryNote;
            if(cleanedNote.trim() !== this.initialDiaryNote.trim()){
                const appointmentDiaryEntryRes = await backend.getDiaryEntryFromAppointment(this.appointment._id);
                const diaryEntry = await appointmentDiaryEntryRes.json();
                if(this.appointment.appointmentDiaryEntryNote){
                    try {
                        const res = await backend.editAppointmentDiaryEntryNote(this.appointment.appointmentDiaryEntryNote._id, { text: cleanedNote } )
                        if (res.status === 200){
                            this.showSnackbar({message: 'Notiz gespeichert!'});
                            this.updateDiaryNote = false;
                        } else {
                            this.showSnackbar({
                                message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                                color: 'error'
                            });
                        }
                    } catch (e) {
                        this.showSnackbar({message: "Die Notiz ist zu groß. Bitte benutzen Sie für große Dateien den Material-Upload."});
                        console.error(e);
                    }
                
                }
                else{ 
                    try {
                        const res = await backend.postAppointmentDiaryEntryNote({
                                diaryEntry: diaryEntry._id,
                                appointment: this.appointment._id,
                                text: cleanedNote.trim(),
                            })
                            if (res.status === 200){
                                this.showSnackbar({message: 'Klassenbucheintrag gespeichert!'});
                                this.updateDiaryNote = false;
                            } else {
                                this.showSnackbar({
                                    message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
                                    color: 'error'
                                });
                            }
                        } catch (e) {
                            this.showSnackbar({message: "Beim Eintragen des Klassenbucheintrages ist etwas schief gelaufen. Bitte Laden Sie die Seite neu."});
                            console.error(e);
                        }
                }
            }
            // let data = {
            //     _id: this.appointment._id,
            //     diaryNote: cleanedNote,
            //     groupId: this.appointment.group._id ? this.appointment.group._id : this.appointment.group
            // };
            // try {
            //     const res = await backend.patchSchoolSubjectAppointment(data._id, data);
            //     if (res.status === 200){
            //         this.showSnackbar({message: 'Gespeichert!'});
            //         this.updateDiaryNote = false;
            //     } else {
            //         this.showSnackbar({
            //             message: 'Etwas ist schiefgelaufen - Bitte aktualisieren Sie die Seite!',
            //             color: 'error'
            //         });
            //     }
            // } catch (e) {
            //     this.showSnackbar({message: "Die Notiz ist zu groß. Bitte benutzen Sie für große Dateien den Material-Upload."});
            //     console.error(e);
            // }
            this.requestAppointments(this.appointment._id);
        },
        showLengthException(length){
            this.showSnackbar({message: `Die maximale Länge beträgt ${length} Zeichen!`, color:"error"} );
        },
        showLinkExplanation(){
            this.showSnackbar({message: 'Markieren Sie einen Text, klicken Sie auf das Link-Symbol und fügen Sie Ihren Link hinzu.'} );
        },

        async update(event) {
            this.updateNote = !this.updateNote;
            if(this.updateNote) {
                if(event) {
                    event.stopPropagation();
                }
                document.addEventListener('click', this.closeNoteEditing);
            }
        },

        closeNoteEditing(event) {
            if(!event.target.closest('#fachInfo')) {
                this.saveDiaryNote(this.diaryNote);
                this.update();
                document.removeEventListener('click', this.closeNoteEditing);
            }
        },

        updateDiary(event) {
            this.updateDiaryNote = !this.updateDiaryNote;
            if(this.updateDiaryNote) {
                if(event) {
                    event.stopPropagation();
                }
                // document.addEventListener('click', this.closeDiaryEditing);
            }
        },

        closeDiaryEditing(event) {
            this.saveDiaryNote(this.diaryNote);
                this.updateDiary();
            // if(!event.target.closest('#fachDiary')) {
                
            //     document.removeEventListener('click', this.closeDiaryEditing);
            // }
        },


        onVideoTutorialClose() {
            this.videoTutorial = false;
            this.joinBBB();
        },

        async play(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            file.seenByMe = true;
            let pipFile = null;
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            } else {
                this.noSubtitle = true;
            }
            if (file.pipReference)
                pipFile = this.appointment.teacherUploads.find(value => value._id === file.pipReference);
            if (pipFile) {
                // PiP Video
                const primary = await backend.getTeacherUpload(this.appointment._id, file.file);
                const secondary = await backend.getTeacherUpload(this.appointment._id, pipFile.file);
                this.primaryVideoUrl = window.URL.createObjectURL(await primary.blob());
                this.secondaryVideoUrl = window.URL.createObjectURL(await secondary.blob());
            } else {
                const res = await backend.getTeacherUpload(this.appointment._id, file.file);
                this.videoUrl = window.URL.createObjectURL(await res.blob());
            }
        },

        async playPupil(file) {
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.appointment._id, file.file);
            this.videoUrl = window.URL.createObjectURL(await res.blob());
            this.pupilVideoOverlay = true;
        },

        async playAudio(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            file.seenByMe = true;
            const res = await backend.getTeacherUpload(this.appointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
            this.$emit('audioOverlay', this.audioUrl);
        },

        async playAudioPupil(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            file.seenByMe = true;
            const res = await backend.getPupilUpload(this.appointment._id, file.file);
            this.audioUrl = window.URL.createObjectURL(await res.blob());
            this.$emit('audioOverlay', this.audioUrl);
        },
        async showUploadVideoInVideoPopup(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            this.$refs.uploadVideoInVideo.show(file, this.appointment);
        },
        onUploadVideoDone() {
            this.requestAppointments(this.appointment._id);
        },

        //renamed since this was only used for Audio
        //TODO:remove unnecessary code
        async uploadAudioFile(appointmentId, newFileName, fileExtension, file, selectedUser, userNotes) {
            if (!file) {
                const input = this.$refs.teacherUploadInput;
                file = input.files[0];
            }
            if (file) {

                if (file.size > 1000000000) {
                    this.showSnackbar({message: "Datei zu groß, max. 1 GB", color: "error"});
                    return;
                }

                const formData = new FormData();
                formData.append("file", file, `${newFileName}.${fileExtension}`);
                formData.append('visibleFor', JSON.stringify(selectedUser));
                if(userNotes) {
                    formData.append("userNotes", JSON.stringify(userNotes));
                }
                this.teacherXmlHttpRequest = backend.postTeacherUpload(
                    appointmentId,
                    file
                );

                this.teacherXmlHttpRequest.onerror = (e) => {
                    console.error("Teacher upload error:", e);
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.onabort = (e) => {
                    console.warn("Teacher upload aborted");
                    this.showTeacherUploadProgress = false;
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                };

                this.teacherXmlHttpRequest.upload.addEventListener("progress", (e) => {
                    this.teacherUploadProgress = (e.loaded / e.total) * 100;
                });

                this.teacherXmlHttpRequest.addEventListener("load", (e) => {
                    if (this.teacherXmlHttpRequest.status !== 201)
                        console.error(
                            "Teacher upload failed:",
                            this.teacherXmlHttpRequest.response
                        );
                    this.showTeacherUploadProgress = false;

                    this.showSnackbar({message: "Gespeichert!", color: "success"});
                    this.requestAppointments(this.appointment._id);
                    this.teacherXmlHttpRequest = null;
                    this.$refs.teacherUploadInput.value = "";
                });
                this.teacherUploadProgress = 0.0;
                this.showTeacherUploadProgress = true;
                this.teacherXmlHttpRequest.send(formData);
            }
        },



        /**
         * #Region drag and drop stuff
         */

        async dragAndDropFile(file){
            this.toggleOpenAppointment(this.appointment);
            this.toggleCurrentUploadGroup('teacher');
            await this.uploadInputChange(file[0]);

        },
        async uploadInputChange(parsedFile) {
            const fileExtension = parsedFile.name.split('.').pop();
            this.showTeamworkCheckbox = allowedExtensionsForLibre.includes(fileExtension);
            if (this.currentUploadGroup === 'teacher') {
                let appointment = JSON.parse(JSON.stringify(this.currentlyOpenAppointment));
                let group = await this.getGroup(this.currentlyOpenAppointment.group);
                appointment.group = group ? group : null;
                this.toggleOpenAppointment(appointment);
            }
            let files;
            if (!parsedFile) {
                // a file was set as input.value
                files = Array.from(this.$refs.uploadInput.files);

            }else {
                //a file was programmatically parsed
                files = [parsedFile];
            }

            // Create tmp variables because in uploadFile these variables get reset to null
            // and we want to call uploadFile with the initial variables
            const tmpCurrentlyOpenAppointment = this.currentlyOpenAppointment;
            const tmpCurrentGroup = this.currentUploadGroup;
            const tmpOpenTeacherUploadId = this.currentUploadFolder;
            // Upload files sequentially

                const fileUploadResults = await files.reduce(async (previousFileUploadResultsPromise, file) => {
                    const previousFileUploadResults = await previousFileUploadResultsPromise;

                    // Show name file widget and upload files when appropriate
                    const prepareFileUpload = (file) => new Promise((resolve, reject) => {
                        if (this.isVideo(file.name ? file.name : file.originalname)) // ToDo check Video duration
                        {
                            this.isSubtitleReady = true;
                        } else {
                            this.isSubtitleReady = false;
                        }

                        const name = file.name ? file.name : file.originalname;
                        const lastDot = name.lastIndexOf('.');
                        const fileName = name.substring(0, lastDot);
                        const fileExtension = name.substring(lastDot + 1);
                        this.uploadFileName = fileName;

                        this.fileNameCallback = (newFileName, selectedUser, userNotes, startDate, isAssignment, createSubtitles, isTeamwork) => {
                            this.showNameFile = false;
                            this
                                .uploadFile(file, newFileName, fileExtension, selectedUser, userNotes, startDate, isAssignment, createSubtitles, tmpCurrentlyOpenAppointment, tmpCurrentGroup, tmpOpenTeacherUploadId)
                                .then(() => {
                                    if (this.currentUploadGroup === 'pupil' || this.currentUploadGroup === 'teacher') {
                                        /*this.$route.query.appointment = localStorage.getItem('reopenAppointment');
                                    localStorage.removeItem('reopenAppointment');*/
                                    }
                                    resolve();
                                })
                        }
                        this.showNameFile = true;
                    });

                    const fileUploadResult = await prepareFileUpload(file);

                    return Promise.resolve([...previousFileUploadResults, fileUploadResult]);
                }, Promise.resolve([]))

            this.toggleFachInfoUploadCallback(this.teacherUploadDoneCallback);
        },
        async uploadFile(file,
                         fileName,
                         fileExtension,
                         selectedUser,
                         userNotes,
                         startDate,
                         isAssignment,
                         createSubtitles,
                         currentlyOpenAppointment = this.currentlyOpenAppointment,
                         currentUploadGroup = this.currentUploadGroup,
                         currentUploadFolder = this.currentUploadFolder,
                         isTeamwork,
        ) {
            const localAppointment = currentlyOpenAppointment;
            const localTeacherUploadId = this.currentlyOpenTeacherUploadId;
            if (file) {
                const formData = new FormData();
                formData.append('file', file, `${fileName}.${fileExtension}`);

                if (currentUploadGroup === 'teacher') {
                    formData.append('visibleFor', JSON.stringify(selectedUser));
                    formData.append('userNotes', JSON.stringify(userNotes));
                    formData.append('startDate', JSON.stringify(startDate));
                    formData.append('isAssignment', JSON.stringify(isAssignment));
                    formData.append('createSubtitles', JSON.stringify(createSubtitles));
                    formData.append('isTeamwork', JSON.stringify(isTeamwork));

                    this.pupilXmlHttpRequest = await backend.postTeacherUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'pupil') {
                    this.pupilXmlHttpRequest = await backend.postPupilUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'subject') {
                    this.pupilXmlHttpRequest = await backend.postSubjectFolderUpload(localAppointment._id, file);
                } else if (currentUploadGroup === 'baby' && localTeacherUploadId) {
                    this.pupilXmlHttpRequest = await backend.postTeacherUploadEdit(localAppointment._id, localTeacherUploadId, file).then((response) => {
                        if (response.status === 201) {
                            this.showSnackbar({ message: "Gespeichert!" });
                        } else {
                            this.showSnackbar({ message: "Etwas ist schiefgelaufen", color: "error" })
                        }
                        this.toggleOpenAppointment(null);
                        this.toggleCurrentUploadGroup(null);
                        this.toggleOpenTeacherUploadId(null);
                        this.pupilXmlHttpRequest = null;
                        this.$refs.uploadInput.value = '';
                    });
                } else if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                    this.pupilXmlHttpRequest = await backend.postVFSFolderUpload(file, currentUploadGroup, currentUploadFolder._id);
                }

                this.pupilXmlHttpRequest.onerror = (e) => {
                    console.error('Pupil upload error:', e)
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                };

                this.pupilXmlHttpRequest.onabort = (e) => {
                    console.warn('Pupil upload aborted');
                    this.showPupilUploadProgress = false;
                    this.pupilXmlHttpRequest = null;
                    this.$refs.uploadInput.value = '';
                    this.toggleOpenAppointment(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.toggleCurrentUploadGroup(null);
                }

                this.pupilXmlHttpRequest.upload.addEventListener('progress', (e) => {
                    this.pupilUploadProgress = (e.loaded / e.total) * 100;
                });

                this.pupilXmlHttpRequest.addEventListener('load', async (e) => {
                    if (this.pupilXmlHttpRequest.status !== 201) {
                        console.error('Pupil upload failed:', this.pupilXmlHttpRequest.response);
                        if (this.pupilXmlHttpRequest.status === 409) {
                            this.showSnackbar({ message: "Speicher überschritten. Datei konnte nicht gespeichert werden", color: "error" });
                        }
                    }
                    this.showPupilUploadProgress = false;
                    this.showSnackbar({ message: "Gespeichert!" });
                    this.toggleOpenAppointment(null);
                    if (currentUploadGroup === 'teacherFolder' || currentUploadGroup === 'privateFolder' || currentUploadGroup === 'groupFolder') {
                        // generate thumbnail if file is pdf
                        if (fileExtension === 'pdf') {
                            const dataForThumbnail = await generateThumbnailForPdf(
                                JSON.parse(this.pupilXmlHttpRequest.response)._id,
                                file
                            );
                            // Push thumbnail
                            const thumbnailResponse = await backend.postThumbnail(
                                dataForThumbnail.originFileId,
                                dataForThumbnail.thumbnailFile
                            );
                        }
                        // Trigger reload of vfs in fileWidget
                        this.triggerReloadOfVfs();
                    }
                    this.toggleCurrentUploadGroup(null);
                    this.toggleOpenTeacherUploadId(null);
                    this.requestAppointments(this.appointment._id);
                    this.$emit("fileUploaded");
                    // Optional Callback set in util/teacherUploadCallback to have a callback when upload is done
                    if(this.getTeacherUploadCallback) {
                        this.getTeacherUploadCallback();
                    }
                    this.pupilXmlHttpRequest = null;
                });

                this.pupilUploadProgress = 0.0;
                this.showPupilUploadProgress = true;
                this.pupilXmlHttpRequest.send(formData);
                this.showTeamworkCheckbox = false;
            }
        },
        getExtension(filename) {
            var parts = filename.split('.');
            return parts[parts.length - 1];
        },
        isVideo(filename) {
            var ext = this.getExtension(filename);
            switch (ext.toLowerCase()) {
                case 'm4v':
                case 'avi':
                case 'mpg':
                case 'mp4':
                case 'mkv':
                    return true;
            }
            return false;
        },


        async teacherUploadInputChange(appointmentId) {
            const input = this.$refs.teacherUploadInput;
            const file = input.files[0];

            if (file) {
                const name = file.name;
                const lastDot = name.lastIndexOf(".");
                const fileName = name.substring(0, lastDot);
                const fileExtension = file[0].name.split('.').pop();
                this.showTeamworkCheckbox = allowedExtensionsForLibre.includes(fileExtension);
                this.uploadFileName = fileName;
                this.fileNameCallback = (newFileName) => {
                    this.showNameFile = false;
                    this.uploadFile(appointmentId, newFileName, fileExtension);
                };
                this.showNameFile = true;
            }
        },

        fileNotSupported() {
            this.showSnackbar({message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte lade die Datei herunter."});
        },

        async clickDownloadTeacherUpload(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                const res = await backend.getTeacherUpload(
                    this.appointment._id,
                    typeof file.file === 'object' ? file.file._id : file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },

        async clickDeleteUpload() {
            /*//setting reopenAppointment to open popup after deleting is done
            this.$route.query.appointment = this.appointment._id;*/

            let file = this.elementToDelete;
            const res = await backend.deleteUpload((file._id).toString());

            if (res.status === 204) {
                this.showSnackbar({message: `Datei ${file.title} erfolgreich gelöscht`, color: "success"});
            } else {
                this.showSnackbar({message: `Beim Löschen ist ein Fehler aufgetreten`, color: "error"});
            }
            this.elementToDelete = null; //clear temp reference to file
            this.deleteDialog = false;
            // Re-fetching the appointments using the store to update the remaining uploads
            // Causes instant deleting of uploads now instead of waiting 3s.. :D
            await this.requestAppointments(this.appointment._id);
        },

        async clickDownloadPupilUpload(file) {
            try {
                const isFileDeleted = this.checkForDeletion(file);
                if (isFileDeleted) return;
                file.seenByMe = true;
                const res = await backend.getPupilUpload(
                    this.appointment._id,
                    file.file
                );
                let blob = await res.blob();
                var url = window.URL.createObjectURL(blob);
                var a = document.createElement("a");
                a.href = url;
                a.download = file.title;
                document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                a.click();
                setTimeout(function () {
                    a.remove();
                    window.URL.revokeObjectURL(url);
                }, 1000);
            } catch (e) {
                console.error(e);
            }
        },

        clickTeacherUploadButton() {
            const groupMutatedAppointment = JSON.parse(JSON.stringify(this.appointment));
            if (typeof this.appointment.group._id !== 'undefined') {
                groupMutatedAppointment.group = this.appointment.group._id;
            } else {
                groupMutatedAppointment.group = this.appointment.group;
            }
            this.toggleOpenAppointment(groupMutatedAppointment);
            this.toggleCurrentUploadGroup('teacher');
            this.toggleFachInfoUploadCallback(this.teacherUploadDoneCallback);
            /*localStorage.setItem('reopenAppointment', this.appointment._id);*/
            document.getElementById("uploadInput").click();
        },

        teacherUploadDoneCallback() {
            this.toggleFachInfoUploadCallback(null);
            this.requestAppointments(this.appointment._id);
        },

        handleImageCaptured() {
            const groupMutatedAppointment = JSON.parse(JSON.stringify(this.appointment));
            if (typeof this.appointment.group._id !== 'undefined') {
                groupMutatedAppointment.group = this.appointment.group._id;
            } else {
                groupMutatedAppointment.group = this.appointment.group;
            }
            this.toggleOpenAppointment(groupMutatedAppointment);
            this.toggleCurrentUploadGroup('teacher');
            this.toggleFachInfoUploadCallback(this.teacherUploadDoneCallback);
        },

        clickImageCapture() {
            this.toggleImageCapture = !this.toggleImageCapture;
        },

        openEdit(name, id, uploadId, uploader, isDeleted) {
            if (!allowedExtensionsForEditor.includes(name.split('.').pop().toLowerCase())) {
                return;
            }
            if (isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar",
                    color: "error"
                });
                return;
            }
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const userGroup = this.accountsById[uploader].role;

            this.$router.push({
                name: 'editor',
                params: {mode: 'teacher'},
                query: {
                    group: group,
                    ug: userGroup,
                    color: color,
                    aId: appointmentId,
                    title: name,
                    fId: id,
                    uploadid: uploadId,
                    showPupilName: true
                },
            });
        },
        openEmptyEdit(newPageType) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            let openAppointment = this.appointment;
            openAppointment.group = this.appointment.group;
            this.toggleOpenAppointment(openAppointment);

            this.$router.push({
                name: 'editor',
                params: {mode: 'creator'},
                query:
                    {
                        group: group,
                        color: color,
                        aId: appointmentId,
                        title: group + ' ' + new Date().toLocaleDateString(),
                        pagetype: newPageType,
                    }
            });
        },
        handleOpenAnsichtClick(file, isMoved) {
            // If the exercise was moved by the pupil without an answer and the teacher still want to see which file
            // it was, it should open the teacherUpload file that wasn't answered by the pupil when clicking the button
            if((typeof isMoved === 'boolean' && isMoved) || file.isMovedPupilUpload) {
                if(file.isMovedPupilUpload) {
                    file = file.isMovedPupilUpload;
                }
                file = this.appointment.teacherUploads.find((el) => el._id === file.editParent);
                this.openAnsichtClick(file.title, typeof file.file === 'object' ? file.file._id : file.file, file._id, 'teacher', true)
                return;
            }

            if (this.isCorrectionAvailable(file)) {
                const fileToOpen = this.getCurrentCorrection(file);

                if (fileToOpen) {
                    this.openAnsichtClick(fileToOpen.title, fileToOpen.file, fileToOpen._id, 'teacher', true);
                }
            } else {
                this.openAnsichtClick(file.title, typeof file.file === 'object' ? file.file._id : file.file, file._id, 'pupil')
            }
        },

        openAnsicht(name, id, uploadId, uploader, correction, isDeleted) {
            if (isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return;
            }
            if(typeof id === 'object') {
                id = id._id;
            }
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;
            const userGroup = this.accountsById[uploader].role;

            const query = {
                group: group,
                color: color,
                aId: appointmentId,
                title: name,
                fId: id,
                uploadid: uploadId,
                ug: userGroup
            };

            if (correction) {
                query.correction = correction;
            }

            this.$router.push({
                name: 'editor',
                params: {mode: correction ? 'viewer' : 'creator'},
                query,
            });

        },

        openAnsichtClick(name, id, uploadId, uploader, correction) {
            const group = this.appointment.schoolSubject.name;
            const color = this.appointment.schoolSubject.color;
            const appointmentId = this.appointment._id;

            const query = {
                group: group,
                color: color,
                aId: appointmentId,
                title: name,
                fId: id,
                uploadid: uploadId,
                ug: uploader
            };

            if (correction) {
                query.correction = correction;
            }

            this.$router.push({
                name: 'editor',
                params: {mode: correction ? 'viewer' : 'creator'},
                query,
            });

        },

        async joinBBB() {
            const res = await backend.joinSchoolSubjectAppointmentBbbMeetingUrl(
                this.appointment._id
            );
            this.toggleLoading(true);
            const {redirectUrl} = await res.json();
            if (res.status === 422) {
                this.showSnackbar({ message: "Aktuell ist der Raum geschlossen!", color: "error" });
                this.toggleLoading(false);
                return;
            }
            if (this.videoTutorial) {
                // Open video tutorial and after try to exec joinClassroom again.
                this.showVideoTutorial = true;
                this.toggleLoading(false);
                return;
            }

            window.location.href = redirectUrl;
            this.toggleLoading(false);
        },

        isCorrectionAvailable(pupilFile) {
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(
                        this.appointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        return true;
                    }
                }
            }
            return false;
        },

        getCurrentCorrection(pupilFile) {
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = pupilFile.editLeafs.indexOf(this.appointment.teacherUploads[i]._id);
                    if (index !== -1) {
                        return this.appointment.teacherUploads[i];
                    }
                }
            }
            return null;
        },

        async clickShowSnackbarFileNotSupported() {
            this.showSnackbar({
                message: "Dieses Dateiformat kann in eKlara nicht bearbeitet werden. Bitte laden Sie die Datei herunter.",
                color: "error"
            });
        },

        async clickDownloadPupilCorrection(file) {
            const isFileDeleted = this.checkForDeletion(file);
            if (isFileDeleted) return;
            let teacherCorrection = null;

            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                if (this.appointment.teacherUploads[i].editParent) {
                    const index = file.editLeafs.indexOf(
                        this.appointment.teacherUploads[i]._id
                    );
                    if (index !== -1) {
                        teacherCorrection = this.appointment.teacherUploads[i];
                    }
                }
            }

            if (teacherCorrection) {
                teacherCorrection.seenByMe = true;
                try {
                    const res = await backend.getTeacherUpload(
                        this.appointment._id,
                        teacherCorrection.file
                    );
                    let blob = await res.blob();
                    var url = window.URL.createObjectURL(blob);
                    var a = document.createElement("a");
                    a.href = url;
                    a.download = teacherCorrection.title;
                    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
                    a.click();
                    setTimeout(function () {
                        a.remove();
                        window.URL.revokeObjectURL(url);
                    }, 1000);
                } catch (e) {
                    console.error(e);
                }
            }
        },

        // Don't simplify it, "return isMoved.length > 0" will not do the same
        wasMoved(isMoved) {
            return isMoved.length <= 0 ? false : true;
        },

        // Prepares the file lists for not done (Nicht abgegeben) and corrected (abgegeben)
        // Checks which homework has not been done yet and saves it in missingAppointments
        // Puts done homework in pupilUploadsCorrected
        async getMissingMembers() {
            this.missingAppointments = [];
            this.pupilUploadsCorrected = [];
            let pupilUploadData = this.appointment.pupilUpload;
            let idListUploads = [];

            //IDs of pupils who have done homework
            for (let k = 0; k < pupilUploadData.length; k++) {
                idListUploads.push(pupilUploadData[k].uploader);
            }

            //get all members of the group
            if (Object.keys(this.appointment.group).length) {
                if (typeof this.appointment.group === 'string') { // Only ID is available
                    const appointmentGroup = this.groupsById[this.appointment.group];
                    this.groupMembers = appointmentGroup.participants;
                } else { // Group is already populated
                    this.groupMembers = this.appointment.group.participants;
                }
            }

            let teacherUploads = this.appointment.teacherUploads;
            let visibleFor = [];

            //go through teacherUploads to collect the visibleFor array but only if the upload is flagged as Assignment
            for (let i = 0; i < teacherUploads.length; i++) {
                if (teacherUploads[i].isAssignment || teacherUploads[i].isAssignment === undefined) {
                    visibleFor = visibleFor.concat(teacherUploads[i].visibleFor);
                }
            }

            //remove done homework (idListUploads) from visibleFor, so only the undone homework is left
            //result is in visibleFor array
            idListUploads.forEach(function (val) {
                var foundIndex = visibleFor.indexOf(val);
                if (foundIndex !== -1) {
                    visibleFor.splice(foundIndex, 1);
                }
            });
1
            // Find the files that were only moved and add them into pupilUploadsCorrected
            for (let k = 0; k < this.appointment.teacherUploads.length; k++) {
                const teacherUpload = this.appointment.teacherUploads[k]
                if (teacherUpload.isMoved) {
                    const pupilThatMoved = this.appointment.teacherUploads[k].isMoved;
                    for (let i = 0; i < pupilThatMoved.length; i++) {
                        let pupil = this.groupMembers.find(obj => obj.account === pupilThatMoved[i]);
                        let push = {
                            isMoved: true,
                            title: '' + pupil.name + '_' + teacherUpload.title,
                            uploader: pupil.account,
                            editParent: teacherUpload._id
                        };
                        this.pupilUploadsCorrected.push(push);
                    }
                }
            }
            // Add the regular corrected files to pupilUploadsCorrected
            let corrected = this.appointment.pupilUpload.filter(item => item.editLeafs && item.editLeafs.length > 0);
            if (corrected.length > 0) {
                corrected.forEach(el => this.pupilUploadsCorrected.push(el));
            }

            //groupMembers = all pupils of this appointment's group with ID and name
            //visibleFor = IDs of pupils who haven't done homework (includes duplicates
            //e.g. pupilA hasn't done homework apples and coins)
            for (let i = 0; i < visibleFor.length; i++) {
                let el = this.groupMembers.find(obj => obj.account === visibleFor[i]);
                if (el && !this.missingAppointments.find(e => e._id === el._id)) {
                    this.missingAppointments.push(el);
                }
            }
            this.missingAppointments = [...new Set(this.missingAppointments)]; //remove duplicate names

            // Add field undoneFiles for each pupil
            this.missingAppointments.forEach(el => el.undoneFiles = []);

            // Find the files which the pupil has not done yet. Add the title of each undone file to undoneFiles
            // this is used to display each undoneFile's title for each pupil in the fachPopup
            for (let i = 0; i < this.appointment.teacherUploads.length; i++) {
                const teacherUpload = this.appointment.teacherUploads[i];
                if (teacherUpload.editParent) {
                    // If teacherUpload has editParent it's a correction and not relevant for us here -> skip iteration
                    continue;
                }
                for (let j = 0; j < this.missingAppointments.length; j++) {
                    let currPup = this.missingAppointments[j];
                    let fileToCorrect = this.appointment.pupilUpload.filter(up => up.uploader === currPup.account && up.editParent === teacherUpload._id);
                    let fileCorrected = this.pupilUploadsCorrected.filter(up => up.uploader === currPup.account && up.editParent === teacherUpload._id);
                    let fileMoved = this.pupilUploadsCorrected.filter(up => up.uploader === currPup.account && up.isMoved && up.editParent === teacherUpload._id);
                    if (fileToCorrect.length > 0) {
                        // Homework is either to correct, corrected or moved (handed in by pupil without working on it)
                    } else if (fileCorrected.length > 0) {
                        //
                    }
                    else if (fileMoved.length > 0) {
                        //
                    }
                    else {
                        this.missingAppointments[j].undoneFiles.push(teacherUpload.title);
                    }
                }
            }
            // Filter out entries where undoneFiles is empty, this can happen for isMoved files,
            // which will be put into missingAppointments, but should be considered done homework
            this.missingAppointments = this.missingAppointments.filter(el => el.undoneFiles.length > 0);
            //sort array by firstname
            this.missingAppointments.sort(this.alphabetically);
        },

        hideEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "none";
            }
        },

        showEl(element) {
            let x = [];
            x = document.getElementsByClassName(element);
            for (let i = 0; i < x.length; i++) {
                x[i].style.display = "block";
            }
        },

        //used for supFormats tooltip
        closeDialog() {
            this.supFormats = false;
        },
        include() {
            return [document.querySelector('.included')]
        },

        async prepareUploadViaFileWidget(file) {
            let name;
            // teacherFolder uses nested file.file.*, privateFolder uses file.*
            if (file.uploadedName) {
                name = file.uploadedName;
                this.fileToUpload = file;
            } else if (file.file.uploadedName) {
                name = file.file.uploadedName;
                this.fileToUpload = file.file;
            } else {
                console.error('invalid file format');
            }
            let lastDot = name.lastIndexOf('.');
            this.newFileName = name.substring(0, lastDot);
            this.fileWidgetNameDialog = true;
        },

        // Method that sends request to backend
        async uploadViaFileWidget(newFileName, selectedUsers, notes, date, isAssignment, createSubtitles, isTeamwork) {
            let data = {
                appointmentId: this.appointment._id,
                fileName: newFileName,
                fileId: this.fileToUpload._id,
                visibleFor: JSON.stringify(selectedUsers),
                startDate: date,
                isAssignment: isAssignment,
            };
            let res = await this.createTeacherUploadViaFileId(data);
            if (res === true) {
                this.fileWidgetNameDialog = false;
                this.fileWidgetUploadDialog = false;
                this.showSnackbar({message: "Gespeichert", color: 'success'});
            }
            await this.requestAppointments(this.appointment._id);
            await this.getMissingMembers();
        },

        getRowStyling(index, el) {
            if (index % 2 === 1 && !el.isMoved) {
                return 'brighterToDoRow'
            } else if (index % 2 === 1 && el.isMoved) {
                return 'brighterEmptyToDoRow'
            }
        },

        whitenIconBasedOnBackground(backgroundColor) {
            var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(backgroundColor);
            const rgb = [];
            rgb[0] = parseInt(result[1], 16);
            rgb[1] = parseInt(result[2], 16);
            rgb[2] = parseInt(result[3], 16);
            const brightness = Math.round(((parseInt(rgb[0]) * 299) +
                (parseInt(rgb[1]) * 587) +
                (parseInt(rgb[2]) * 114)) / 1000);
            // return (brightness < 160);
            return true;
        },

        /**
         * #region Document Drafts
         */
        async openDraftForEdit({ draft, reference }, mode = 'creator') {
            try {
                const isFileDeleted = this.checkForDeletion(draft);
                if (isFileDeleted) return;
                const group = this.appointment.schoolSubject.name;
                const color = this.appointment.schoolSubject.color;
                const appointmentId = this.appointment._id;

                if (!draft.uploadMessage) {
                    await this.requestAppointments();
                }

                const query = {
                    group: group,
                    color: color,
                    aId: appointmentId,
                    fId: draft.file || draft.uploadMessage.file,
                    title: draft.name,
                    uploadid: reference,
                    draftId: draft._id,
                    ug: 'draft',
                    showPupilName: true
                };

                this.$router.push({
                    name: 'editor',
                    params: { mode },
                    query,
                });
            } catch (e) {
                console.error(e);
                this.showSnackbar({ message: "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche die Seite neu zu laden.", color: "error" })
            }
        },

        updateDraftDetails(draftId, payload) {
            if (this.appointment.drafts) {
                const draftToUpdate = this.appointment.drafts.find(draft => draft._id === draftId);
                draftToUpdate.draft = {
                    ...draftToUpdate.draft,
                    ...payload
                }
            }
        },

        async deleteDraft(draftId) {
            await this.deleteDocumentDraft(draftId);
            this.rerenderPupilUploadsToCorrectWithPupil = !this.rerenderPupilUploadsToCorrectWithPupil;
            for (let index = 0; index < this.appointment.drafts.length; index++) {
                if (this.appointment.drafts[index].draft._id === draftId) {
                    this.appointment.drafts.splice(index, 1);
                }
            }
        },

        uploadIcon(name) {
            var icon;
            if (['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.imageIcon;
            }
            if (['doc', 'docx'].includes(name.split('.').pop().toLowerCase())) {
                icon = wordIcon;
            }
            if (['ppt', 'pptx'].includes(name.split('.').pop().toLowerCase())) {
                icon = powerpointIcon;
            }
            if (['xls', 'xlsx'].includes(name.split('.').pop().toLowerCase())) {
                icon = excelIcon;
            }
            if (['wav', 'mp3', 'wma', 'm4a', 'acc', 'ogg'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.musicIcon;
            }
            if (['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(name.split('.').pop().toLowerCase())) {
                icon = videoIcon;
            }
            if (['pdf'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.pdfIcon;
            }
            if (['txt'].includes(name.split('.').pop().toLowerCase())) {
                icon = this.documentIcon;
            }
            return icon;
        },

        showAndHideAppointments(id, refName) {
            const showIcon = this.$refs[`show${refName}${id}`][0];
            const showAppointments = this.$refs[`show${refName}${id}`][1];
            const hideIcon = this.$refs[`hide${refName}${id}`][0];
            if (window.getComputedStyle(showAppointments).getPropertyValue('display') === 'block') {
                showIcon.$el.style.display = 'none';
                showAppointments.style.display = 'none';
                hideIcon.$el.style.display = '';
            } else {
                showIcon.$el.style.display = '';
                showAppointments.style.display = 'block';
                hideIcon.$el.style.display = 'none';
            }
        },
        unseenUploads(id) {
            let counter = 0;
            const pupilUploadsToCorrect = this.pupilUploadsToCorrectWithPupil.find((pupilUploads) => pupilUploads._id === id);
            pupilUploadsToCorrect.uploads.forEach((upload) => {
                if (!upload.seenByMe) {
                    counter++;
                }
            });
            return counter;
        },
        alphabetically(a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },
        checkForDeletion(file) {
            if (file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return true;
            }
            return false;
        },
    },
}
</script>

<style>
.ql-container {
    font-size: 16px !important;
    padding: 12px !important;
}

.ql-editor {
    padding: 0 !important;
}

</style>

<style lang="scss" scoped>
.iOS_ResponsiveHack {
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}
.whiteBackgroundTile {
    background-color: white;
    border-radius: 8px;
    padding: 2px 8px 8px 8px;
}

.iconSelect {
    display: inline-flex;
    width: 59px;
    height: 59px;
    padding: 4px;
    border-radius: 4px 4px 4px 0;
    background-color: rgba(234, 234, 234, 0.7);
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}


.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: rgb(var(--v-theme-hellgrau));
    background-color: rgb(var(--v-theme-hellgrau));
}

.iconSize {
    height: 20px;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 200px;
    overflow-x:hidden;
}

.scroll-area-fachPopup {
    position: relative;
    margin: auto;
    height:calc(70vh - 130px);
    overflow-x: hidden;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

#createEditorABsButton {
    color: white;
    background-color: rgb(var(--v-theme-dunkelgrau));
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

@media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
    .scroll-area-fachPopup {
        /*250px = 59px header, 48px join btn, 70px btmNavBar and paddings,margins*/
        max-height: calc(100vh - 250px);
    }
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
}
::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 6px;
}

.fach {
    cursor: pointer;
}

.whiteBg {
    background-color: white !important;
}

.tinyIcon {
    width: 15px;
}

.iconSize {
    height: 20px;
}

.iconToWhite {
    filter: brightness(1000%);
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.squareBtn {
    min-width: 20px !important;
    min-height: 20px !important;
    width: 36px;
    height: 36px;
}

.scroll-area {
    position: relative;
    margin: auto;
    max-height: 200px;
}

//border-radius of v-menu
.v-menu__content {
    border-radius: 12px;
}

.iconSelect {
    display: inline-flex;
    width: 24px;
    height: 24px;
    padding: 2px;
    margin-bottom: -6px;
    border-radius: 5px;
    margin-right: 2px;
    background-color: rgba(234, 234, 234, 0.7);
}

.fileUploadPlaceholderText {
    p {
        color: #bbbbbb;
        width: 100%;
        text-align: center;
    }
}

.eHr {
    width: 90%;
    margin: auto;
    border-width: 0;
    height: 2px;
    color: rgb(var(--v-theme-hellgrau));
    background-color: rgb(var(--v-theme-hellgrau));
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-top: -3px;
}

.fileName {
    max-width: 75%;
    min-width: 75%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

// as these elements contain multiple file names joined together a line break is needed instead of ellipsis
.fileNameUndoneFiles {
    max-width: 95%;
    min-width: 95%;
    display: inline-block;
    word-wrap: break-word;
}


.toCorrectLong,
.toDoLong,
.correctedLong {
    display: none;
}

.brighterFileRow {
    background-color: rgba(224, 224, 224, 0.15) !important;
}

.brighterToCorrectRow {
    background-color: rgba(128, 128, 128, 0.15) !important;
}

.brighterToDoRow {
    background-color: rgba(230, 35, 30, 0.15) !important;
}

.brighterEmptyToDoRow {
    background-color: rgba(230, 103, 30, 0.2) !important;
}

.brighterCorrectedRow {
    background-color: rgba(60, 170, 105, 0.15) !important;
}

.small-drop-down-menu {
    display: block;
    background-color: #F8F8F8;

    & button {
        width: 100%;
    }
}

#createEditorABsButton {
    color: white;
}

.fileDiv {
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-radius: 10px;
    box-shadow: 1px 2px 3px silver;
    width: 99%;
    cursor: pointer;
}

@media only screen and (max-width: 399px) {
    #fachPopup {
        max-width: 100vw !important;
    }

    #dialog {
        margin: 0;
    }
}


.v-snack.v-snack--top {
    bottom: initial; /* or auto */
}

.v-snack.v-snack--bottom {
    top: initial; /* or auto */
}

@media only screen and (max-width: 450px) {
    .responsiveDisplayNone {
        display: none;
    }
}

@media only screen and (max-width: 337px), (min-width: 901px) and (max-width: 1011px) {
    #materialParagraph {
        display: none;
    }
}
</style>
