<!-- eslint-disable vuetify/no-deprecated-props -->
<template>
    <v-overlay
        :model-value="diaryDialog"
        opacity="0.8"
        style="height: 100vh;"
        class="d-flex flex-row justify-center align-center "
        :scrollable="false"
        persistent
        @click:outside="handleClickOutside"
    >
        <!-- diary title page -->
        <v-card
            v-if="!selectedDiaryEntry"
            light
            class="diarySize"
            style="background-color: rgb(var(--v-theme-dunkelgruen)); border-radius: 8px; z-index: 40 !important;"
            @click.stop="handleClickContent($event)"
        >
            <div
                class="d-flex ml-6"
                style="height: 100%; justify-content: flex-end;"
            >
                <v-col
                    class="responsiveMargin"
                    style="display: flex; flex-direction: column;justify-content:space-between"
                >
                    <div
                        class=""
                        style="margin-top: 45%"
                    >
                        <p
                            class="fontSize"
                            style="font-family: 'eKlara Custom'; color: white;"
                        >
                            Klassenbuch
                        </p>
                        <div
                            class="d-flex classDateSize"
                            style="background-color: white; border-radius: 8px; flex-direction: column; justify-content: center; align-items: center"
                        >
                            <!-- Select class/group -->
                            <v-tooltip
                                location="top"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-autocomplete
                                        v-model="group"
                                        :items="groups"
                                        item-title="name"
                                        label="Gruppe auswählen"
                                        single-line
                                        hide-details
                                        return-object
                                        class="classSize smallerFontSize diaryClassVAutocomplete"
                                        style=" padding-top: 5px; z-index: 200 !important;"
                                        no-data-text="Gruppen konnten nicht geladen werden"
                                        variant="underlined"
                                        v-bind="props"      
                                    />
                                </template>
                                <span class="smallerFontSize">Gruppe auswählen</span>
                            </v-tooltip>
                            <div
                                class="d-flex justify-center"
                                style="min-width: 250px;"
                            >
                                <v-tooltip
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            icon="$vuetify"
                                            variant="plain"
                                            elevation="0"
                                           
                                            v-bind="props"
                                            @click="subDay"
                                        >
                                            <img
                                                :src="arrowIcon"
                                                class="dateArrowSize"
                                                alt="Tag zurück"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Vorheriger Tag</span>
                                </v-tooltip>
                                <VDatePicker
                                    v-model="pickerDate"
                                    :popover="{ visibility: 'click' }"
                                    :masks="{ input: 'DD.MM.YYYY' }"
                                    expanded
                                    :locale="{ firstDayOfWeek: 2}"
                                    :disabled-dates="disabledWeekdays"
                                    @update:model-value="confirmDateSelection"
                                >
                                    <template #default="{ inputEvents }">
                                        <p
                                            class="datePickerFont"
                                            style="white-space: nowrap; cursor: pointer"
                                            v-on="inputEvents"
                                        >
                                            {{ getDisplayDate() }}
                                        </p>
                                    </template>
                                </VDatePicker>
                                <v-tooltip
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            icon="$vuetify"
                                            variant="plain"
                                            elevation="0"
                                           
                                            v-bind="props"
                                            @click="addDay"
                                        >
                                            <img
                                                :src="arrowIcon"
                                                alt="Tag vor"
                                                class="dateArrowSize"
                                                style="transform: rotate(180deg)"
                                            >
                                        </v-btn>
                                    </template>
                                    <span>Nächster Tag</span>
                                </v-tooltip>
                            </div>
                            <hr
                                class="solid lineSize"
                                aria-hidden="true"
                            >
                        </div>
                    </div>

                    <div
                        class="d-flex flex-row align-baseline"
                    >
                        <p
                            class="fontSizeTeacher"
                            style="color: white;"
                        >
                            {{ accountRole === teacher ? 'Klassenlehrer*in' : '' }}
                        </p>
                        <div
                            class="d-flex "
                        >
                            <Avatar
                                v-if="showAvatar"
                                :diary-front="true"
                                :profile="accountRole === 'teacher' ? teacher?.account : maintainers[0]?.account"
                            />
                            <p
                                class="pl-2 pt-2 teacherNameSize"
                                style="color: white;"
                            >
                                {{ `${this.teacher ? this.teacher.name : 'Verwaltung'} ${this.teacher ? this.teacher.lastName.slice(0,1) + '.' : ''}` }}
                            </p>
                        </div>
                    </div>
                </v-col>
                <v-col style="display: flex; justify-content: flex-end; padding: 0 !important; margin-left: 40px">
                    <div style="position: absolute; display: flex;">
                        <img
                            :src="faehnchenIcon"
                            alt="Fähnchen"
                            class="faenchenSize"
                            style="margin-right: 40px"
                        >
                        <!-- <v-tooltip
                            bottom
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <img
                                    :src="cancelIcon"
                                    class="iconToWhite closeBtnSize"
                                    style="margin: 5px; cursor: pointer;"
                                    alt="Klassenbuch schließen"
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="resetDialog"
                                >
                            </template>
                            <span class="smallerFontSize">Klassenbuch schließen</span>
                        </v-tooltip> -->
                    </div>
                    <v-tooltip
                        location="right"
                    >
                        <template v-slot:activator="{ props }">
                            <v-card
                                class="openBookSize"
                                style="display: flex; align-self: center;"
                                light
                               
                                v-bind="props"
                                @click="openDiaryEntry($event)"
                            >
                                <!-- <v-btn
                                    fab
                                    icon
                                    color="#eaeaea"
                                    class="align-self-center mx-1 mb-8"
                                > -->
                                <img
                                    class="openBookBtnSize"
                                    :class="accountPinIsValid ? 'iconToGreen': 'iconToRed'"
                                    style="position: relative; margin: auto;"
                                    alt="Pfeil nach rechts"
                                    :src="accountPinIsValid ? unlockIcon : lockIcon"
                                >
                                <!-- </v-btn> -->
                            </v-card>
                        </template>
                        <span class="smallerFontSize">Klassenbuch öffnen</span>
                    </v-tooltip>
                </v-col>
            </div>
        </v-card>
        <!-- diary -->

        <v-dialog
            v-if="selectedDiaryEntry"
            v-model="selectedDiaryEntry"
            :scrollable="false"
            max-width="90vw"
            @click:outside="closeDiary"
        >
            <!-- region late Dialog -->
            <v-card
                v-if="lateDialog"
                v-model="lateDialog"
                style="min-height:75vh;"
            >
                <div
                    id="headerBar"
                    class="d-flex justify-space-between px-2 align-center"
                >
                    <div class="d-flex align-center">
                        <v-tooltip>
                            <template v-slot:activator="{ props }">
                                <img
                                    :src="getIcon(lateDialogAppointment.appointment.schoolSubject.icon)"
                                    alt="Fachsymbol"
                                    :style="getBackgroundColor(lateDialogAppointment.appointment.schoolSubject.color)"
                                    style="border-radius: 6px; padding: 1px;"
                                    class="icon30 mr-2"
                                   
                                    v-bind="props"
                                >
                            </template>
                            <span>{{ lateDialogAppointment.appointment.schoolSubject.name }}</span>
                        </v-tooltip>
                        <div class="d-flex flex-column py-0 my-0">
                            <div class="d-flex flex-row justify-start py-0 ">
                                {{ firstOpen ? 'An, -Abwesenheiten' : lateDialogAppointment.appointment.schoolSubject.name + ' ' }} {{
                                    germanDayStrings[date.getDay() - 1].slice(0,2) + ', ' + date.toLocaleDateString('de-DE', {
                                        month: '2-digit',
                                        day: '2-digit',
                                    })
                                }}
                            </div>
                            <div
                                class="d-flex flex-row justify-start py-0 "
                                style="margin-top: -8px !important;"
                            > 
                                {{ getAppointmentTimeslotTime( lateDialogAppointment.appointment.timeslot) }}
                            </div>
                        </div>
                    </div>
                    <v-tooltip
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <img
                                :src="cancelIcon"
                                class="icon30 iconToWhite"
                                style="cursor: pointer"
                               
                                v-bind="props"
                                @click="()=>{
                                    lateDialog = false; closeLateDialog();}"
                            >
                        </template>
                        <span>Bemerkungen schließen</span>
                    </v-tooltip>
                </div>
                <simple-bar
                    :auto-hide="false"
                    class="pb-0 pt-2 px-1 scroll-area-lateDialog"
                >
                    <div class="tableContainer diaryNoteTable">
                        <v-data-table
                            :key="pupilRefreshKey"
                            :headers="diaryNoticeHeaders"
                            :items="diaryEntriesOfDay[diaryEntryIndex].pupils"
                            items-per-page="-1"
                            hide-default-footer
                            fixed-header
                            :mobile-breakpoint="0"
                            responsive="false"
                            locale="de-DE"
                            :no-data-text="noDataText"
                        >
                            <template v-slot:[`header.pupil`]>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="windowWidth > 600 ? true : false"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                class="mr-2"
                                                v-bind="props"
                                            >
                                                mdi-account
                                            </v-icon>
                                        </template>
                                        <span>Schüler/*in</span>
                                    </v-tooltip>
                                    Schüler/*in
                                </div>
                            </template>

                            <template v-slot:[`header.time`]>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="windowWidth > 600 ? true : false"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                class="mr-2"
                                                v-bind="props"
                                            >
                                                mdi-clock-outline
                                            </v-icon>
                                        </template>
                                        <span>Fehlzeit</span>
                                    </v-tooltip>
                                    Fehlzeit
                                </div>
                            </template>

                            <template v-slot:[`header.excused`]>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="windowWidth > 600 ? true : false"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                class="mr-2"
                                                v-bind="props"
                                            >
                                                mdi-check
                                            </v-icon>
                                        </template>
                                        <span>entschuldigt</span>
                                    </v-tooltip>
                                    entschuldigt
                                </div>
                            </template>

                            <template v-slot:[`header.unexcused`]>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="windowWidth > 600 ? true : false"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                class="mr-2"
                                                v-bind="props"
                                            >
                                                mdi-circle-outline
                                            </v-icon>
                                        </template>
                                        <span>unentschuldigt</span>
                                    </v-tooltip>
                                    unentschuldigt
                                </div>
                            </template>

                            <template v-slot:[`header.note`]>
                                <div class="d-flex justify-center align-center">
                                    <v-tooltip
                                        location="bottom"
                                        :disabled="windowWidth > 600 ? true : false"
                                    >
                                        <template v-slot:activator="{ props }">
                                            <v-icon
                                                class="mr-2"
                                                v-bind="props"
                                            >
                                                mdi-pencil
                                            </v-icon>
                                        </template>
                                        <span>Bemerkung</span>
                                    </v-tooltip>
                                    Bemerkung
                                </div>
                            </template>
                            <template v-slot:item="pupil">
                                <tr
                                    style="height: 78px"
                                >
                                    <td>
                                        <div style="display: flex; align-items: center;">
                                            <div
                                                class="d-flex justify-flex-start align-center mr-2"
                                                :style="`background-color: ${getUserIcon(pupil.item).color}; border-radius: 8px;`"
                                            >
                                                <Avatar
                                                    v-if="pupil && pupil.item.account"
                                                    :profile="pupil.item.account"
                                                    :size="24"
                                                />
                                            </div>
                                            <p class="sixteenPx ml-1">
                                                {{ pupil.item.name + ' ' + pupil.item.lastName }}
                                            </p>
                                        </div>
                                    </td>
                                    <td>
                                        <div style="display: flex; flex-direction: row;align-items: center;">
                                            <v-text-field
                                                v-if="pupil.item.lateTime.isFullday"
                                                model-value="0"
                                                label="Minuten"
                                                type="number"
                                                min="0"
                                                disabled
                                            />
                                            <v-text-field
                                                v-else
                                                v-model="pupil.item.lateTime.missingTime"
                                                label="Minuten"
                                                type="number"
                                                min="0"
                                                :disabled="pupil.item.lateTime.isFullday"
                                                @update:model-value="pupil.item.lateTime.missingTime = parseInt(pupil.item.lateTime.missingTime)"
                                            />
                                            <div style="display: flex; flex-direction: column;align-items: center;">
                                                <p>ganztägig</p>
                                                <v-checkbox-btn
                                                    v-model="pupil.item.lateTime.isFullday"
                                                    class="d-flex justify-center"
                                                    @input="toggleFullDay(diaryEntryIndex,pupil.item._id)"
                                                />
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <v-checkbox-btn
                                            v-model="pupil.item.lateTime.excused"
                                            class="d-flex justify-center"
                                            @input="toggleExcused(diaryEntryIndex,pupil.item._id)"
                                        />
                                    </td>
                                    <td>
                                        <v-checkbox-btn
                                            v-model="pupil.item.lateTime.unexcused"
                                            class="d-flex justify-center"
                                            @input="toggleUnexcused(diaryEntryIndex, pupil.item._id)"
                                        />
                                    </td>
                                    <td
                                        class="d-flex flex-row align-center mt-4"
                                        style="height: auto !important;"
                                    >
                                        <v-textarea
                                            v-model="pupil.item.lateTime.note"
                                            rows="1"
                                            auto-grow
                                            hide-details
                                            class="ma-0 pa-0"
                                            placeholder="Notiz eingeben"
                                            @change="updateSeenBy(pupil.item)"
                                        />
                                        <div class="d-flex justify-end align-baseline">
                                            <img
                                                :src="editIcon"
                                                alt="Notiz Bearbeiten"
                                                class="square25 mt-4 ml-2"
                                            >
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </div>
                </simple-bar>
            </v-card>
            <!-- region end -->
            <!-- region absenceListDialog -->
            <v-card
                v-else-if="absenceListDialog"
                v-model="absenceListDialog"
                style="min-height:75vh;"
            >
                <v-card-title
                    id="headerBar"
                    class="dialogHeader"
                    style="font-size: 20px;"
                >
                    <div class="d-flex align-center">
                        <Avatar
                            v-if="absenceListPupil?.account"
                            :profile="absenceListPupil.account"
                            :size="30"
                            class="mr-2"
                        />

                        <p class="absenceListTitle">
                            {{ absenceListPupil?.name + ' ' + absenceListPupil?.lastName.slice(0,1) + '. ' }}Fehlzeiten
                        </p>
                    </div>
                    <div class="d-flex justify-space-between">
                        <v-menu
                            :offset="[0,10]"
                            location="left"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                   
                                    icon="$vuetify"
                                    variant="plain"
                                    v-bind="props"
                                >
                                    <img
                                        :src="kebabMenuIcon"
                                        alt="Optionen"
                                        class="icon30 iconToWhite"
                                    >
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    v-if="!createNewAbsence"
                                    :disabled="editingAbsence || selectToDelete"
                                    @click="() => { if(!editingAbsence) { createNewAbsence = true; } }"
                                >
                                    <template #prepend>
                                        <img
                                            :src="plusIcon"
                                            class="icon20 iconToDunkelgrau"
                                        >
                                    </template>
                                    <v-list-item-title>Fehlzeit erstellen</v-list-item-title>
                                </v-list-item>
                                <div v-else>
                                    <v-list-item
                                        @click="() => { saveAbsence(); }"
                                    >
                                        <template #prepend>
                                            <img
                                                :src="doneIcon"
                                                class="icon20 iconToDunkelgrau"
                                            >
                                        </template>
                                        <v-list-item-title>Fehlzeit speichern</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="() => { resetNewAbsence(); }"
                                    >
                                        <template #prepend>
                                            <img
                                                :src="exportIcon"
                                                class="icon20 iconToDunkelgrau"
                                            >
                                        </template>
                                        <v-list-item-title>Fehlzeiten exportieren</v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        @click="() => { resetNewAbsence(); }"
                                    >
                                        <template #prepend>
                                            <img
                                                :src="cancelIcon"
                                                class="icon20 iconToDunkelgrau"
                                            >
                                        </template>
                                        <v-list-item-title> Abbrechen </v-list-item-title>
                                    </v-list-item>
                                </div>
                                <!-- <v-divider /> -->
                                <!-- <v-list-item
                                    :disabled="selectedAbsenceListItems.length !== 1"
                                    @click="editSelecetdAbsence"
                                >
                                    <v-list-item-title>Bearbeiten bestätigen</v-list-item-title>
                                </v-list-item>
                                <v-divider /> -->
                                <v-list-item
                                    v-if="!editingAbsence && !selectToDelete"
                                    :disabled="createNewAbsence"
                                    @click="clickDeleteButton"
                                >
                                    <template #prepend>
                                        <img
                                            :src="deleteIcon"
                                            class="icon20"
                                        >
                                    </template>
                                    <v-list-item-title>Fehlzeiten löschen</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-else-if="!editingAbsence && selectToDelete && selectedAbsenceListItems.length > 0"
                                    @click="deleteSelected"
                                >
                                    <template #prepend>
                                        <img
                                            :src="deleteIcon"
                                            class="icon20 iconToRed"
                                        >
                                    </template>
                                    <v-list-item-title>Löschen bestätigen</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    v-else
                                    @click=" () =>{
                                        selectedAbsenceListItems = [];
                                        selectToDelete = false;
                                    }"
                                >
                                    <template #prepend>
                                        <img
                                            :src="deleteIcon"
                                            class="icon20 iconToGrey"
                                        >
                                    </template>
                                    <v-list-item-title>Löschen abbrechen</v-list-item-title>
                                </v-list-item>
                                <v-list-item

                                    @click="closePersonalAbsence"
                                >
                                    <template #prepend>
                                        <img
                                            :src="arrowIcon"
                                            class="icon20 iconToDunkelgrau"
                                            alt="zurück"
                                        >
                                    </template>
                                    <v-list-item-title>Zurück</v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-card-title>
                <div
                    v-if="showAbsenceLoading"
                    class="d-flex flex-column align-center"
                    style="margin-top: 25vh"
                >
                    <v-progress-circular
                        indeterminate
                        color="rgb(var(--v-theme-chatColor))"
                    />
                    <p class="text-center">
                        Fehlzeiten werden geladen
                    </p>
                </div>
                <div
                    v-else
                >
                    <v-card-text class="dialogChild">
                        <v-card-text class="dialogChild">
                            <div
                                class="tableContainer personalAbsenceTable"
                            >
                                <v-data-table
                                    :key="tableKey"
                                    :headers="personalAbsenceHeaders"
                                    :items="personalAbsenceData"
                                    items-per-page="-1"
                                    hide-default-footer
                                    fixed-header
                                    :mobile-breakpoint="0"
                                    responsive="false"
                                    locale="de-DE"
                                    :no-data-text="createNewAbsence ? '' : noDataText"
                                >
                                    <template v-slot:[`header.dateFrom`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-calendar
                                                    </v-icon>
                                                </template>
                                                <span>Datum von</span>
                                            </v-tooltip>
                                            Datum von
                                        </div>
                                    </template>

                                    <template v-slot:[`header.dateTill`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-calendar
                                                    </v-icon>
                                                </template>
                                                <span>Datum bis</span>
                                            </v-tooltip>
                                            Datum bis
                                        </div>
                                    </template>
                                    <template v-slot:[`header.isFullday`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <span v-bind="props">ganztägig</span>
                                                </template>
                                                <span>ganztägig</span>
                                            </v-tooltip>
                                        </div>
                                    </template>
                                    <template v-slot:[`header.time`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-clock-outline
                                                    </v-icon>
                                                </template>
                                                <span>Fehlzeit</span>
                                            </v-tooltip>
                                            Fehlzeit
                                        </div>
                                    </template>

                                    <template v-slot:[`header.excused`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-check
                                                    </v-icon>
                                                </template>
                                                <span>entschuldigt</span>
                                            </v-tooltip>
                                            entschuldigt
                                        </div>
                                    </template>

                                    <template v-slot:[`header.unexcused`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-circle-outline
                                                    </v-icon>
                                                </template>
                                                <span>unentschuldigt</span>
                                            </v-tooltip>
                                            unentschuldigt
                                        </div>
                                    </template>

                                    <template v-slot:[`header.note`]>
                                        <div class="d-flex justify-center align-center">
                                            <v-tooltip
                                                location="bottom"
                                                :disabled="windowWidth > 600 ? true : false"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        class="mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-pencil
                                                    </v-icon>
                                                </template>
                                                <span>Bemerkung</span>
                                            </v-tooltip>
                                            Bemerkung
                                        </div>
                                    </template>

                                    <template v-slot:header>
                                        <tr v-show="createNewAbsence">
                                            <th style="max-width: 40px; !important">
                                                <div v-if="createNewAbsence">
                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn
                                                                elevation="0"
                                                                color="white"
                                                                class="mr-2"
                                                                style="min-width: 30px !important; padding: 0px"
                                                                width="30"
                                                                height="30"
                                                               
                                                                v-bind="props"
                                                                @click="saveAbsence"
                                                            >
                                                                <img
                                                                    :src="doneIcon"
                                                                    class="iconToDunkelgrau square25"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Fehlzeit speichern</span>
                                                    </v-tooltip>
                                                </div>
                                            </th>
                                            <th class="px-2">
                                                <VDatePicker
                                                    v-model="absenceDateFrom"
                                                    :popover="{ visibility: 'click' }"
                                                    :masks="{ input: 'DD.MM.YYYY' }"
                                                    expanded
                                                    color="blue"
                                                    :locale="{ firstDayOfWeek: 2}"
                                                    :disabled-dates="disabledWeekdays"
                                                >
                                                    <template #default="{ inputEvents }">
                                                        <v-text-field
                                                            v-model="formattedAbsenceDateFrom"
                                                            placeholder="auswählen"
                                                            readonly
                                                            v-on="inputEvents"
                                                        />
                                                    </template>
                                                </VDatePicker>
                                            </th>
                                            <th>
                                                <v-checkbox-btn
                                                    v-model="newAbsenceIsFullday"
                                                />
                                            </th>
                                            <th class="px-2">
                                                <VDatePicker
                                                    v-model="absenceDateTill"
                                                    :popover="{ visibility: 'click' }"
                                                    :masks="{ input: 'DD.MM.YYYY' }"
                                                    expanded
                                                    color="blue"
                                                    :locale="{ firstDayOfWeek: 2}"
                                                    :disabled-dates="disabledWeekdays"
                                                >
                                                    <template #default="{ inputEvents }">
                                                        <v-text-field
                                                            v-model="formattedAbsenceDateTill"
                                                            placeholder="auswählen"
                                                            readonly
                                                            v-on="inputEvents"
                                                        />
                                                    </template>
                                                </VDatePicker>
                                            </th>
                                            <th class="px-2">
                                                <v-text-field
                                                    v-model="newAbsenceMinutes"
                                                    label="Minuten"
                                                    type="number"
                                                    min="0"
                                                    style="font-weight: normal;"
                                                    @update:model-value="newAbsenceMinutes = parseInt(newAbsenceMinutes)"
                                                />
                                            </th>
                                            <th>
                                                <v-checkbox-btn
                                                    v-model="newAbsenceExcused"
                                                />
                                            </th>
                                            <th>
                                                <v-checkbox-btn
                                                    v-model="newAbsenceUnexcused"
                                                />
                                            </th>
                                            <th class="px-2">
                                                <v-textarea
                                                    v-model="newAbsenceNote"
                                                    rows="1"
                                                    auto-grow
                                                    hide-details
                                                    class="ma-0 pa-0"
                                                    style="font-weight: normal;"
                                                    placeholder="Notiz eingeben"
                                                />
                                            </th>
                                        </tr>
                                    </template>
                                    <template v-slot:item="absenceData">
                                        <tr
                                            style="height: 78px"
                                            :style="absenceData.item.deleted ? 'background-color: lightgrey !important; opacity: 0.6':''"
                                            @click="() => {
                                                if(absenceData.item.deleted){
                                                    return
                                                }
                                                if(!editingAbsence && selectToDelete){
                                                    onRowClick(absenceData);
                                                }else{
                                                    editClickedAbsence(absenceData);
                                                }
                                            }"
                                        >
                                            <td style="max-width: 40px; !important; justify-content: center;">
                                                <div
                                                    v-if="!(editingAbsence && editAbsenceItem?._id === absenceData.item._id) && !selectToDelete"
                                                    class="d-flex justify-center align center"
                                                >
                                                    <div
                                                        v-if="absenceData.item.deleted"
                                                        class="ml-2"
                                                        style="font-size: 12px;"
                                                    >
                                                        gelöscht:
                                                    </div>

                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn
                                                                v-if="!absenceData.item.deleted"
                                                                elevation="0"
                                                                color="white"
                                                                style="min-width: 30px !important; padding: 0px"
                                                                width="30"
                                                                height="30"
                                                               
                                                                @click="async ()=>{
                                                                    if (editAbsenceItem && currentEditIndex !== absenceData.index) {
                                                                        await this.saveAbsence();
                                                                    }
                                                                    editClickedAbsence(absenceData);
                                                                }"
                                                            >
                                                                <img
                                                                    :src="editIcon"
                                                                    class="iconToDunkelgrau square25"
                                                                    alt="Bearbeiten"
                                                                    v-bind="props"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Fehlzeit bearbeiten</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    v-else-if="!absenceData.item.deleted && (editingAbsence && editAbsenceItem?._id === absenceData.item._id)"
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn
                                                                icon="$vuetify"
                                                                variant="plain"
                                                               
                                                                @click="() => {
                                                                    saveAbsence();
                                                                }"
                                                            >
                                                                <img
                                                                    :src="doneIcon"
                                                                    class="icon"
                                                                    alt="Abbrechen"
                                                                    v-bind="props"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Speichern</span>
                                                    </v-tooltip>
                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <v-btn
                                                                icon="$vuetify"
                                                                variant="plain"
                                                                @click.stop="cancelAbsenceEdit"
                                                            >
                                                                <img
                                                                    :src="closeIcon"
                                                                    class="icon"
                                                                    alt="Abbrechen"
                                                                    v-bind="props"
                                                                >
                                                            </v-btn>
                                                        </template>
                                                        <span>Bearbeiten abbrechen</span>
                                                    </v-tooltip>
                                                </div>
                                                <div
                                                    v-else-if="selectToDelete && !absenceData.item.deleted"
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <v-checkbox-btn
                                                        :value="isSelected(absenceData)"
                                                        color="#3caa69"
                                                        @click="() => {
                                                            if(!editingAbsence && selectToDelete){
                                                                onRowClick(absenceData);
                                                            }
                                                        }"
                                                    />
                                                </div>
                                            </td>
                                            <td style="text-align:right">
                                                <div v-if="editAbsenceItem?._id === absenceData.item._id">
                                                    <VDatePicker
                                                        v-model="absenceDateFrom"
                                                        :popover="{ visibility: 'click' }"
                                                        :masks="{ input: 'DD.MM.YYYY' }"
                                                        expanded
                                                        color="blue"
                                                        :locale="{ firstDayOfWeek: 2}"
                                                        :disabled-dates="disabledWeekdays"
                                                    >
                                                        <template #default="{ inputEvents }">
                                                            <v-text-field
                                                                v-model="formattedAbsenceDateFrom"
                                                                placeholder="auswählen"
                                                                readonly
                                                                v-on="inputEvents"
                                                            />
                                                        </template>
                                                    </VDatePicker>
                                                </div>
                                                <div
                                                    v-else
                                                    class="d-flex justify-space-between"
                                                >
                                                    <p class="sixteenPx ml-1">
                                                        {{
                                                            new Date(absenceData.item.dateStart).toLocaleDateString('de-DE', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                                year:'2-digit'
                                                            })
                                                        }}
                                                    </p>
                                                    <v-spacer />
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="editAbsenceItem?._id === absenceData.item._id">
                                                    <VDatePicker
                                                        v-model="absenceDateTill"
                                                        :popover="{ visibility: 'click' }"
                                                        :masks="{ input: 'DD.MM.YYYY' }"
                                                        expanded
                                                        color="blue"
                                                        :locale="{ firstDayOfWeek: 2}"
                                                        :disabled-dates="disabledWeekdays"
                                                    >
                                                        <template #default="{ inputEvents }">
                                                            <v-text-field
                                                                v-model="formattedAbsenceDateTill"
                                                                placeholder="auswählen"
                                                                readonly
                                                                v-on="inputEvents"
                                                            />
                                                        </template>
                                                    </VDatePicker>
                                                </div>
                                                <p
                                                    v-else
                                                    class="sixteenPx ml-1"
                                                >
                                                    {{
                                                        new Date(absenceData.item.dateEnd).toLocaleDateString('de-DE', {
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                            year:'2-digit'
                                                        })
                                                    }}
                                                </p>
                                            </td>
                                            <td align="right">
                                                <div
                                                    v-if="editAbsenceItem?._id === absenceData.item._id"
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <v-checkbox-btn
                                                        v-model="newAbsenceIsFullday"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <p
                                                        v-if="absenceData.item.isFullday"
                                                        class="d-flex justify-space-around sixteenPx ml-1 align-center"
                                                    >
                                                        X
                                                        <!--entschuldigt-->
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="editAbsenceItem?._id === absenceData.item._id">
                                                    <v-text-field
                                                        v-model="newAbsenceMinutes"
                                                        label="Minuten"
                                                        type="number"
                                                        min="0"
                                                        @update:model-value="newAbsenceMinutes = parseInt(newAbsenceMinutes)"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <p
                                                        class="sixteenPx ml-1"
                                                    >
                                                        {{ absenceData.item.missingDays > 0 ? absenceData.item.missingDays + ' Tag(e)' : '' }}
                                                        {{
                                                            absenceInDHM(absenceData.item.missingTime).hours === '0 Std.' ? '' : absenceInDHM(absenceData.item.missingTime).hours
                                                        }}
                                                        {{
                                                            absenceInDHM(absenceData.item.missingTime).minutes === '0 Min.' ? '' : absenceInDHM(absenceData.item.missingTime).minutes
                                                        }}
                                                    </p>
                                                </div>
                                            </td>
                                           
                                            <td align="right">
                                                <div
                                                    v-if="editAbsenceItem?._id === absenceData.item._id"
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <v-checkbox-btn
                                                        v-model="newAbsenceExcused"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <p
                                                        v-if="absenceData.item.excused"
                                                        class="d-flex justify-space-around sixteenPx ml-1 align-center"
                                                    >
                                                        X
                                                        <!--entschuldigt-->
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div
                                                    v-if="editAbsenceItem?._id === absenceData.item._id"
                                                    class="d-flex justify-center align-center"
                                                >
                                                    <v-checkbox-btn
                                                        v-model="newAbsenceUnexcused"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <p
                                                        v-if="!absenceData.item.excused"
                                                        class="d-flex justify-space-around sixteenPx ml-1 align-center"
                                                    >
                                                        X
                                                        <!--unentschuldigt-->
                                                    </p>
                                                </div>
                                            </td>
                                            <td>
                                                <div v-if="editAbsenceItem?._id === absenceData.item._id">
                                                    <v-textarea
                                                        v-model="newAbsenceNote"
                                                        rows="1"
                                                        auto-grow
                                                        hide-details
                                                        class="ma-0 pa-0"
                                                        placeholder="Notiz eingeben"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <p class="sixteenPx ml-1">
                                                        {{ absenceData.item.note }}
                                                    </p>
                                                </div>
                                            </td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </div>
                        </v-card-text>
                    </v-card-text>
                </div>
            </v-card>
            <!-- region end -->
            <v-card
                v-else
                :key="mainDialogKey"
                style="min-height:75vh;"
            >
                <v-card-title
                    id="headerBar"
                    class="dialogHeader"
                >
                    <div
                        class="d-flex justify-space-between"
                        style="align-items: center; flex-shrink: 1.5;"
                    >
                        <img
                           
                            :src="showAbsence ? abwesendIcon : bookIcon"
                            :alt="showAbsence ? 'Fehlzeiten':'Klassenbuch'"
                            :class="showAbsence ? '':'iconToWhite'"
                            style="max-width: 30px; max-height: 30px; margin-left: 0.25rem;"
                        >
                        <div class="d-flex flex-row justify-space-around align-center">
                            <!-- Select class/group -->
                            <v-tooltip
                                location="top"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-autocomplete
                                        :key="groups"
                                        v-model="group"
                                        :items="groups"
                                        item-title="name"
                                        label="Gruppe auswählen"
                                        class="pa-0 ma-0 ml-3"
                                        theme="dark"
                                        single-line
                                        hide-details
                                        return-object
                                        style="min-width: 170px;"
                                        no-data-text="Gruppen konnten nicht geladen werden"
                                        variant="underlined"
                                        v-bind="props"
                                    />
                                </template>
                                <span>Gruppe auswählen</span>
                            </v-tooltip>
                            <v-menu
                                location="bottom right"
                                :offset="[30,0]"
                                
                                style="z-index: 99"
                            >
                                <template v-slot:activator="{ props }">
                                    <img
                                        :src="infoIcon"
                                        class="icon iconToWhite"
                                        style="flex-basis: 40px; cursor: default;"
                                        alt="Klasseninfo"
                                        v-bind="props"
                                    >
                                </template>
                                <div>
                                    <v-list
                                        density="compact"
                                        class="darkMenuList"
                                        style="min-width: 150px !important;"
                                    >
                                        <p
                                            class="mx-2"
                                            style="font-weight: bold; color: white"
                                        >
                                            Klassenlehrer:
                                        </p>
                                              
                                        <template
                                            v-for="element in group.leaders"
                                            :key="element._id"
                                        >
                                            <div
                                                style="background-color: transparent; min-width: 150px !important;"
                                            >
                                                <div
                                                    class="d-flex flex-row justify-space-between px-2"
                                                    style="max-width: 28vw;"
                                                >
                                                    <div
                                                        class="justify-flex-start"
                                                        style="font-size: 14px; color: white"
                                                    >
                                                        {{ `${teachersById[element].name} 
                                                                    ${teachersById[element].lastName}` }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                        <p 
                                            class="mx-2"
                                            style="font-weight: bold; color: white"
                                        >
                                            Schüler:
                                        </p>
                                        <template
                                            v-for="element in group.participants"
                                            :key="element._id"
                                        >
                                            <div
                                                style="background-color: transparent; min-width: 150px !important;"
                                            >
                                                <div
                                                    class="d-flex flex-row justify-space-between px-2"
                                                    style="max-width: 28vw;"
                                                >
                                                    <div
                                                        class="justify-flex-start"
                                                        style="font-size: 14px; color: white"
                                                    >
                                                        {{ `${pupilsById[element].name} 
                                                                    ${pupilsById[element].lastName}` }}
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </v-list>
                                </div>
                            </v-menu>
                            <VDatePicker
                                v-if="!showAbsence"
                                v-model="pickerDate"
                                :popover="{ visibility: 'click' }"
                                :masks="{ input: 'DD.MM.YYYY' }"
                                expanded
                                :locale="{ firstDayOfWeek: 2 }"
                                :disabled-dates="disabledWeekdays"
                                @update:model-value="changeDateThroughDatePicker"
                            >
                                <template #default="{ inputEvents }">
                                    <v-tooltip location="bottom">
                                        <template v-slot:activator="{ props:tooltip }">
                                            <img
                                                :src="calendarIcon"
                                                alt="Datum auswählen"
                                                class="ml-1 icon20 iconToWhite"
                                                style="cursor: pointer;"
                                                v-bind="tooltip"
                                                v-on="inputEvents"
                                            >
                                        </template>
                                        <span>Datum auswählen</span>
                                    </v-tooltip>
                                </template>
                            </VDatePicker>
                        </div>
                    </div>
                 
               
                    <div>
                        <v-menu
                            :offset="[0,10]"
                            location="left"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                   
                                    icon="$vuetify"
                                    variant="plain"
                                    v-bind="props"
                                >
                                    <img
                                        :src="kebabMenuIcon"
                                        alt="Optionen"
                                        class="icon30 iconToWhite"
                                    >
                                </v-btn>
                            </template>
                            <v-list>
                                <v-list-item
                                    @click="displayAbsence()"
                                >
                                    <template #prepend>
                                        <img
                                            :src="showAbsence ? bookIcon : abwesendIconGrau"
                                            :alt="showAbsence ? 'Klassenbuch' : 'Fehlzeiten'"
                                        >
                                    </template>
                                    <v-list-item-title>{{ showAbsence ? "Klassenbuch" : "Fehlzeiten" }}</v-list-item-title>
                                </v-list-item>
                                <!-- Todo auch auf mobile der export dialog? -->
                                <v-list-item
                                    v-if="!showAbsence"
                                    @click="() => { clickOpenExport(); }"
                                >
                                    <template #prepend>
                                        <img
                                            :src="exportIcon"
                                            class="icon20 iconToDunkelgrau"
                                        >
                                    </template>
                                    <v-list-item-title>Exportieren</v-list-item-title>
                                </v-list-item>
                                <v-list-item
                                    @click="clickMenuBackButton"
                                >
                                    <template #prepend>
                                        <img
                                            :src="arrowIcon"
                                            class="icon20"
                                            alt="zurück"
                                        >
                                    </template>
                                    <v-list-item-title>
                                        Zurück
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </v-card-title>
                <v-card-text class="dialogChild">
                    <div
                        v-if="showAbsence"
                    >
                        <div class="tableContainer absenceListTable">
                            <v-data-table
                                :headers="absenceHeaders"
                                :items="pupilsLateTimesList"
                                items-per-page="-1"
                                hide-default-footer
                                fixed-header
                                :mobile-breakpoint="0"
                                responsive="false"
                                locale="de-DE"
                                :no-data-text="noDataText"
                            >
                                <template v-slot:item="pupilWithLateTime">
                                    <tr
                                        style="height: 78px"
                                        @click="displayAbsenceList(pupilWithLateTime.item)"
                                    >
                                        <td>
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-btn
                                                        elevation="0"
                                                        color="white"
                                                        class="mr-2"
                                                        style="min-width: 30px !important; padding: 0px"
                                                        width="30"
                                                        height="30"
                                                       
                                                        alt="Listenansicht öffnen"
                                                        v-bind="props"
                                                        @click="displayAbsenceList(pupilWithLateTime.item)"
                                                    >
                                                        <img
                                                            :src="editIcon"
                                                            class="iconToDunkelgrau square25"
                                                            alt="Listenansicht öffnen"
                                                        >
                                                    </v-btn>
                                                </template>
                                                <span> Listenansicht </span>
                                            </v-tooltip>
                                        </td>
                                        <td>
                                            <div style="display: flex; align-items: center;">
                                                <div
                                                    class="d-flex justify-flex-start align-center mr-2"
                                                    :style="`background-color: ${getUserIcon(pupilWithLateTime.item.pupil).color}; border-radius: 8px;`"
                                                >
                                                    <Avatar
                                                        v-if="pupilWithLateTime && pupilWithLateTime.item.pupil.account"
                                                        :profile="pupilWithLateTime.item.pupil.account"
                                                        :size="24"
                                                    />
                                                </div>
                                                <p class="sixteenPx ml-1">
                                                    {{ pupilWithLateTime.item.pupil.name + ' ' + pupilWithLateTime.item.pupil.lastName }}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="display: flex; align-items: center; justify-content: space-between;">
                                                <p class="sixteenPx ml-1">
                                                    {{ pupilWithLateTime.item.totalMissingDays > 0 ? pupilWithLateTime.item.totalMissingDays + ' Tag(e)' : '' }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalMissingTime).hours }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalMissingTime).minutes }}
                                                </p>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="display: flex; align-items: center; justify-content: space-between;">
                                                <span class="sixteenPx ml-1">
                                                    {{ pupilWithLateTime.item.totalExcusedMissingDays > 0 ? pupilWithLateTime.item.totalExcusedMissingDays + ' Tag(e)' : '' }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalExcusedMissingTime).hours }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalExcusedMissingTime).minutes }}
                                                </span>
                                            </div>
                                        </td>
                                        <td>
                                            <div style="display: flex; align-items: center; justify-content: space-between;">
                                                <span class="sixteenPx ml-1">
                                                    {{ pupilWithLateTime.item.totalUnexcusedMissingDays > 0 ? pupilWithLateTime.item.totalUnexcusedMissingDays + ' Tag(e)' : '' }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalUnexcusedMissingTime).hours }}
                                                    {{ absenceInDHM(pupilWithLateTime.item.totalUnexcusedMissingTime).minutes }}
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                    <div
                        v-else
                    >
                        <div class="tableContainer diaryTable">
                            <div
                                v-if="noAppointmentsAvailable"
                                class="sixteenPx ma-4 d-flex justify-center align-center"
                            >
                                Kein Stundenplan für den ausgewählten Tag verfügbar.
                            </div>

                            <v-data-table
                                v-else
                                :key="diaryReloadKey"
                                :headers="headers"
                                :items="diaryEntriesOfDay"
                                items-per-page="-1"
                                hide-default-footer
                                :mobile-breakpoint="0"
                                locale="de-DE"
                                :no-data-text="noDataText"
                            >
                                <template v-slot:[`header.schoolSubject.name`]>
                                    <div class="d-flex flex-column justify-space-between align-start">
                                        <div>
                                            <VDatePicker
                                                v-model="pickerDate"
                                                :popover="{ visibility: 'click' }"
                                                :masks="{ input: 'DD.MM.YYYY' }"
                                                expanded
                                                :locale="{ firstDayOfWeek: 2}"
                                                :disabled-dates="disabledWeekdays"
                                                @update:model-value="changeDateThroughDatePicker"
                                            >
                                                <template #default="{ inputEvents }">
                                                    <span
                                                        class="d-flex flex-row responsiveDatepicker"
                                                        style="font-weight: bold; font-size: 14px; cursor: pointer;"
                                                        :aria-label="germanDayStrings[date.getDay() - 1] + ', ' + date.toLocaleDateString('de-DE', {
                                                            month: '2-digit',
                                                            day: '2-digit',
                                                        })"
                                                        v-on="inputEvents"
                                                    >
                                                        <img
                                                            style="width: 20px; margin: -4px 2px 0 0"
                                                            :src="calendarIcon"
                                                            alt="Datum auswählen"
                                                            class="mr-2"
                                                        > 
                                                        <u>
                                                            KW {{ getMondayOfWeek(date).getWeek() }}/
                                                            {{ germanDayStrings[date.getDay() - 1].slice(0, 2) + ', ' + date.toLocaleDateString('de-DE', {
                                                                month: '2-digit',
                                                                day: '2-digit',
                                                            }) }}
                                                        </u>
                                                    </span>
                                                </template>
                                            </VDatePicker>
                                        </div>
                                        <p> Fach/Lehrer</p>
                                    </div>
                                </template>
                                <template v-slot:[`header.appointmentDiaryNote`]>
                                    <div class="d-flex flex-row justify-start align-center">
                                        <p class="mr-1">
                                            Klassenbucheintrag
                                        </p>
                                        <v-tooltip
                                            location="top"
                                            :offset="[100,0]"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <img
                                                    :src="infoIcon"
                                                    style="height: 20px;"
                                                    alt
                                                   
                                                    v-bind="props"
                                                >
                                            </template>
                                            <p
                                                style="max-width: 300px"
                                            >
                                                Notiz zur Stunde (z.B HA: AB Seite 1/2 etc ), der Eintrag wird automatisch in den Stundenplan
                                                übernommen und kann dort ebenfalls bearbeitet werden. Achtung: Beim Bearbeiten werden Formatierungen aus dem Stundenplan(unterstrichen, Farbe, etc) entfernt.
                                            </p>
                                        </v-tooltip>
                                    </div>
                                </template>
                                <template v-slot:item="diaryEntry">
                                    <tr style="height: 78px">
                                        <td>
                                            <div class="appointmentItem">
                                                <div style="display: flex; flex-direction: row;">
                                                    <div
                                                        style="height: 56px; width: 56px; position: relative;"
                                                    >
                                                        <div
                                                            style="background-color:white;"
                                                        >
                                                            <div
                                                                id="carouselItem1"
                                                                :class="!deactivatedAnimation ? ' carouselItem fachView' : 'noAnimationItem'"
                                                            >
                                                                <img
                                                                    :src="getIcon(diaryEntry.item.appointment.schoolSubject.icon)"
                                                                    alt="Fachsymbol"
                                                                    :style="getBackgroundColor(diaryEntry.item.appointment.schoolSubject.color)"
                                                                    style="padding: 10px"
                                                                >
                                                            </div>

                                                            <div
                                                                v-if="!deactivatedAnimation"
                                                                id="carouselItem2"
                                                                class="carouselItem fachView"
                                                                style="background-color:#6995cd"
                                                            >
                                                                <div
                                                                    class="numberStyle"
                                                                >
                                                                    {{ diaryEntriesOfDay.indexOf(diaryEntry.item) + 1 }}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div
                                                        class="fachTextContainer"
                                                        aria-hidden="true"
                                                    >
                                                        <div>
                                                            <p style="font-size: x-small">
                                                                {{ getTimeslotTimes(diaryEntry.item.appointment.timeslot) }}
                                                            </p>
                                                            <p style="font-size: large; margin-top: -5px; padding-bottom: 2px">
                                                                {{ diaryEntry.item.appointment.schoolSubject.name }}
                                                            </p>
                                                            <v-tooltip
                                                                location="top"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <p
                                                                        class="roomEllipsis"
                                                                        style="font-size: x-small; margin-top: -5px"
                                                                        v-bind="props"
                                                                    >
                                                                        {{ getRoomNames(diaryEntry.item.appointment.rooms) }}
                                                                    </p>
                                                                </template>
                                                                <p style="max-width: 180px; overflow-wrap: break-word; word-break: break-word;">
                                                                    {{ getRoomNames(diaryEntry.item.appointment.rooms) }}
                                                                </p>
                                                            </v-tooltip>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div style="display: flex; flex-direction: column; justify-content: space-between;">
                                                    <v-tooltip
                                                        location="top"
                                                        :disabled="!(getSubstitutionTeacher(diaryEntry.item.appointment) && !hasSickTeacherButNoSubstitution(diaryEntry.item))"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <div
                                                                style="display: flex; flex-direction: row; align-items: center"
                                                                v-bind="props"
                                                            >
                                                                <img
                                                                    v-if="getSubstitutionTeacher(diaryEntry.item.appointment)"
                                                                    :src="wechselKreisIcon"
                                                                    class="iconToSuccessGreen mr-1"
                                                                    style="height: 15px;"
                                                                >
                                                                <img
                                                                    v-else-if="hasSickTeacherButNoSubstitution(diaryEntry.item.appointment)"
                                                                    :src="lehrerAbwesendIcon"
                                                                    class="iconToSick mr-1"
                                                                    style="height: 15px;"
                                                                >
                                                                <span
                                                                    class="teacherName"
                                                                    style="font-size: x-small; padding: 6px 0; padding-right: 10px"
                                                                    :style="getSubstitutionTeacher(diaryEntry.item.appointment) ? 'color: rgb(var(--v-theme-success))' : hasSickTeacherButNoSubstitution(diaryEntry.item) ? 'color: rgb(var(--v-theme-sickColor)); text-decoration: line-through' : ''"
                                                                >
                                                                    {{
                                                                        hasSickTeacherButNoSubstitution(diaryEntry.item.appointment) ? getTeacherName(diaryEntry.item.appointment.teachers[0]) : getSubstitutionTeacher(diaryEntry.item.appointment) ?
                                                                            getTeacherName(getSubstitutionTeacher(diaryEntry.item.appointment)) : getTeacherName(diaryEntry.item.appointment.teachers[0])
                                                                    }}
                                                                </span>
                                                            </div>
                                                        </template>
                                                        <span style="color: rgb(var(--v-theme-sickColor)); text-decoration: line-through">
                                                            {{ diaryEntry.item.appointment.substitutions && diaryEntry.item.appointment.substitutions[0] && getSickTeacher(diaryEntry.item.appointment) ? getTeacherName(getSickTeacher(diaryEntry.item.appointment)) : '' }}
                                                        </span>
                                                    </v-tooltip>
                                                    <v-tooltip
                                                        location="top"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <img
                                                                v-if="diaryEntry.item.appointment.teacherUploads.length > 0"
                                                                :src="paperclipIcon"
                                                                style="opacity: 60%; height: 20px; padding-bottom: 5px; padding-right: 5px; align-self: end;"
                                                                v-bind="props"
                                                            >
                                                        </template>
                                                        <span
                                                            v-for="(upload, index) in diaryEntry.item.appointment.teacherUploads.filter(item => !item.editParent)"
                                                            :key="upload._id"
                                                            class="d-flex"
                                                            style="padding-right: 15px; margin-left: -10px"
                                                            :class="index === 0 ? 'mt-2' : ''"
                                                        >
                                                            <img
                                                                :src="materialIcon"
                                                                class="icon mr-2"
                                                            >
                                                            {{ upload.title }}
                                                        </span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            :class="expandInfo ? '' : 'ellipsis'"
                                            :style="windowWidth < 900 ? 'max-width: 108px' : 'max-width: 200px'"
                                        >
                                            <div
                                                :class="editNotes.includes(diaryEntry.item.appointment._id) ? '' : 'ellipsis'"
                                                class="d-flex align-center"
                                                style="cursor: pointer;"
                                                @click="editNote(diaryEntry.item.appointment, $event)"
                                            >
                                                <div
                                                    v-if="editNotes.includes(diaryEntry.item.appointment._id)"
                                                    style="display: flex; align-items: center; flex-grow: 1; flex-basis: 0;"
                                                >
                                                    <v-textarea
                                                        v-model="override[override.findIndex(el => el._id === diaryEntry.item.appointment._id)].diaryNote"
                                                        auto-grow
                                                        hide-details
                                                        class="ma-0 pa-0"
                                                        placeholder="Klassenbucheintrag (max. 150 Zeichen)"
                                                        maxlength="150"
                                                        :style="windowWidth < 900 ? 'max-width: 108px' : expandInfo ? '' : 'max-width: 200px'"
                                                    />
                                                </div>
                                                <div
                                                    v-else
                                                    :key="reloadNoteKey + diaryEntry.item.appointment._id"
                                                    style="display: flex; align-items: center; flex-grow: 1; flex-basis: 0; "
                                                    @click="editNote(diaryEntry.item.appointment)"
                                                >
                                                    <p
                                                        :key="reloadNoteKey + diaryEntry.item.appointment._id"
                                                        class="sixteenPx pointer d-flex align-center text-truncate"
                                                        v-html="sanitizeHtml(urlify(diaryEntry.item?.appointment?.appointmentDiaryEntryNote?.text))"
                                                    />
                                                </div>

                                                <div style="display: flex; align-items: center; flex-basis: 40px;">
                                                    <div v-if="editNotes.includes(diaryEntry.item.appointment._id)">
                                                        <v-btn
                                                            icon="$vuetify"
                                                            variant="plain"
                                                            @click="stopEditNote(diaryEntry.item.appointment, diaryEntriesOfDay.indexOf(diaryEntry.item), $event)"
                                                        >
                                                            <img
                                                                :src="doneIcon"
                                                                class="icon iconToDunkelgrau"
                                                                alt="bearbeiten beenden"
                                                            >
                                                        </v-btn>
                                                    </div>
                                                    <div v-else>
                                                        <v-tooltip
                                                            location="bottom"
                                                        >
                                                            <template v-slot:activator="{ props }">
                                                                <v-btn
                                                                    icon="$vuetify"
                                                                    variant="plain"
                                                                   
                                                                    v-bind="props"
                                                                    @click="editNote(diaryEntry.item.appointment)"
                                                                >
                                                                    <img
                                                                        :src="editIcon"
                                                                        class="icon"
                                                                        alt="Bearbeiten"
                                                                    >
                                                                </v-btn>
                                                            </template>
                                                            <span>Klassenbucheintrag bearbeiten</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                <div v-if="!editNotes.includes(diaryEntry.item.appointment._id)">
                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <img
                                                                v-if="diaryEntry.item?.appointment.note !== ''"
                                                                :src="infoIcon"
                                                                class="icon"
                                                                style="flex-basis: 40px; cursor: default;"
                                                                alt="Fachinfo"
                                                               
                                                                v-bind="props"
                                                            >
                                                        </template>
                                                        <span v-html="sanitizeHtml(urlify('Fachinfo: '+diaryEntry.item?.appointment.note))" />
                                                    </v-tooltip>
                                                    <v-tooltip
                                                        location="bottom"
                                                    >
                                                        <template v-slot:activator="{ props }">
                                                            <v-icon
                                                                v-if="appointmentDiaryEntryNoteHistory(diaryEntry.item.appointment)"
                                                                class="ma-0 pa-0"
                                                               
                                                                v-bind="props"
                                                                @click.stop="showEditHistory(diaryEntry.item.appointment)"
                                                            >
                                                                mdi-script-text
                                                            </v-icon>
                                                        </template>
                                                        <span>Bearbeitungen anzeigen</span>
                                                    </v-tooltip>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-center ml-2 justify-space-between">
                                                <div
                                                    class="d-flex align-center"
                                                    style="flex-wrap: wrap; cursor: pointer"
                                                    @click="()=>{
                                                        openLateDialog(diaryEntriesOfDay.indexOf(diaryEntry.item), diaryEntry.item);
                                                    }"
                                                >
                                                    <div
                                                        v-for="(pupil) in diaryEntry.item.pupils.filter(pupil => pupil.lateTime.note || pupil.lateTime.missingTime > 0 )"
                                                        :key="pupil._id"
                                                    >
                                                        <v-badge
                                                            class="excusedLateTimeBadgeDiary"
                                                            :color="excusedForAppointment(pupil._id, diaryEntry.item.appointment._id) ? '#3ba934' : '#ff0000'"
                                                            offset-x="17"
                                                            bordered
                                                        >
                                                            <v-tooltip
                                                                location="bottom"
                                                            >
                                                                <template v-slot:activator="{ props }">
                                                                    <div
                                                                        class="d-flex justify-flex-start align-center mr-2"
                                                                        :style="`background-color: ${getUserIcon(pupil).color}; border-radius: 8px;`"
                                                                        v-on="on"
                                                                    >
                                                                        <Avatar
                                                                            v-if="pupil && pupil.account"
                                                                            :profile="pupil.account"
                                                                            :size="24"
                                                                            v-bind="props"
                                                                        />
                                                                    </div>
                                                                </template>
                                                                <span>{{ showMissingTimeTooltip(pupil) }}</span>
                                                            </v-tooltip>
                                                        </v-badge>
                                                    </div>
                                                </div>
                                                <p
                                                    v-if="diaryEntry.item.pupils.filter(pupil => pupil.lateTime?.note || pupil.lateTime?.missingTime > 0).length === 0"
                                                    style="color: grey; cursor: pointer; opacity: 0.5;"
                                                    @click="()=>{
                                                        openLateDialog(diaryEntriesOfDay.indexOf(diaryEntry.item), diaryEntry.item);
                                                    }"
                                                >
                                                    Keine Fehlzeiten vorhanden
                                                </p>
                                                <v-tooltip
                                                    location="bottom"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn
                                                            icon="$vuetify"
                                                            variant="plain"
                                                           
                                                            v-bind="props"
                                                            @click="()=>{
                                                                openLateDialog(diaryEntriesOfDay.indexOf(diaryEntry.item), diaryEntry.item);
                                                            }"
                                                        >
                                                            <img
                                                                :src="editIcon"
                                                                class="icon"
                                                                alt="bearbeiten"
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span>Fehlzeit eintragen</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex align-center ml-2 justify-space-between">
                                                <div
                                                    class="d-flex align-center"
                                                    style="flex-wrap: wrap; cursor: pointer"
                                                >
                                                    <div
                                                        v-for="(pupil) in diaryEntry.item.pupils.filter(pupil => pupil.diaryNote !== '')"
                                                        :key="pupil._id"
                                                    >
                                                        <v-tooltip
                                                            location="bottom"
                                                        >
                                                            <template v-slot:activator="{ props }">
                                                                <div
                                                                    class="d-flex justify-flex-start align-center mr-2"
                                                                    :style="`background-color: ${getUserIcon(pupil).color}; border-radius: 8px;`"
                                                                    v-on="on"
                                                                    @click="() => {
                                                                        createDiaryNote(diaryEntry.item, pupil);
                                                                    }"
                                                                >
                                                                    <Avatar
                                                                        v-if="pupil && pupil.account"
                                                                        :profile="pupil.account"
                                                                        :size="24"
                                                                        v-bind="props"
                                                                    />
                                                                </div>
                                                            </template>
                                                            <span>{{ pupil.name + ': ' + pupil.diaryNote }}</span>
                                                        </v-tooltip>
                                                    </div>
                                                </div>
                                                <p
                                                    v-if="diaryEntry.item.pupils.filter(pupil => pupil.diaryNote !== '').length === 0"
                                                    style="color: grey; cursor: pointer; opacity: 0.5;"
                                                    @click="() => {
                                                        createDiaryNote(diaryEntry.item);
                                                    }
                                                    "
                                                >
                                                    Keine Bemerkungen vorhanden
                                                </p>
                                                <v-tooltip
                                                    location="bottom"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn
                                                            icon="$vuetify"
                                                            variant="plain"
                                                           
                                                            v-bind="props"
                                                            @click="() => {
                                                                createDiaryNote(diaryEntry.item);
                                                            }
                                                            "
                                                        >
                                                            <img
                                                                :src="editIcon"
                                                                class="icon"
                                                                alt="bearbeiten"
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <span>Bemerkung eintragen</span>
                                                </v-tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- confirm close dialog -->
        <v-dialog
            v-if="confirmCloseDialog"
            v-model="confirmCloseDialog"
            max-width="337"
        >
            <v-card style="overflow: hidden; border-radius: 16px;">
                <v-card-title
                    class="text-h5 mb-1 d-flex align-center justify-space-between"
                    style="background-color: rgb(var(--v-theme-dunkelgruen))"
                >
                    <p style="color: white">
                        Klassenbuch schließen?
                    </p>
                    <v-btn
                        icon="$vuetify"
                        variant="plain"
                        color="transparent"
                        elevation="0"
                        style="margin-right: -8px"
                        @click="confirmCloseDialog = false"
                    >
                        <img
                            :src="closeIcon"
                            class="iconToWhite icon30"
                            alt="abbrechen"
                        >
                    </v-btn>
                </v-card-title>
                <v-card-text class="mt-4">
                    Wollen Sie das Klassenbuch wirklich schließen und die Eingaben verwerfen?
                </v-card-text>
                <v-card-actions class="d-flex justify-space-between px-6">
                    <v-btn
                        elevation="0"
                        class="optionBtn text-none ml-2"
                        color="rgb(245, 245, 245)"
                        @click="confirmCloseDialog = false"
                    >
                        <img
                            :src="closeIcon"
                            class="icon mr-2"
                        >
                        Fortsetzen
                    </v-btn>

                    <v-btn
                        elevation="0"
                        class="optionBtn text-none mr-2"
                        color="rot"
                        theme="dark"
                        @click="resetDialog"
                    >
                        <img
                            :src="deleteIcon"
                            class="icon iconToWhite mr-2"
                        >
                        Verwerfen
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog
            v-if="diaryNoteDialog"
            v-model="diaryNoteDialog"
            max-width="300"
            max-height="600"
            height="600"
            @click:outside="savePupilDiaryNote"
        >
            <v-card>
                <v-card-title
                    class="pa-2 d-flex flex-row justify-space-between align-center"
                    style="background-color: rgb(var(--v-theme-dunkelgruen)); color: white;"
                >
                    <div class="d-flex flex-row align-center">
                        <img
                            :src="getIcon(currentAppointment.schoolSubject.icon)"
                            alt="Fachsymbol"
                            :style="getBackgroundColor(currentAppointment.schoolSubject.color)"
                            style="border-radius: 6px; padding: 1px;"
                            class="icon30 mr-2"
                        >
                        <div
                            class="d-flex flex-column justify-space-around align-start"
                            style="max-height: 40px; margin-top: -5px;"
                        > 
                            <div
                                class="ma-0 pa-0"
                                style="font-size: 14px; max-height: 20px;"
                            >
                                {{ currentAppointmentTimeslotDate }}
                            </div>
                            <div
                                class="ma-0 pa-0"
                                style="font-size: 14px; max-height: 20px;"
                            >
                                {{ currentAppointment.schoolSubject.name }}
                            </div>
                        </div>
                    </div>

                    <v-btn
                        variant="text"
                        elevation="0"
                        style="margin-right: -8px; min-width: 30px!important;"
                        @click="savePupilDiaryNote"
                    >
                        <img
                            :src="cancelIcon"
                            class="icon iconToWhite icon20"
                            alt="Schließen"
                        >
                    </v-btn>
                </v-card-title>
                <div
                    class="d-flex flex-column pa-2"
                    style="min-height: 450px;max-height: 450px; max-width:290px"
                >
                    <div class="d-flex flex-row justify-space-between">
                        <v-checkbox
                            v-model="diaryNoteForMultiplePupils"
                            class="mt-0 pt-0"
                            hide-details
                        >
                            <template v-slot:label>
                                <span
                                    style="color: black"
                                    aria-hidden="true"
                                    class="ml-1"
                                >
                                    Bemerkung für mehrere SuS erstellen
                                </span>
                            </template>
                        </v-checkbox>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <img
                                    :src="infoIcon"
                                    class="icon pl-1"
                                    alt="Erklärung"
                                   
                                    v-bind="props"
                                >
                            </template>
                           
                            <p>
                                Durch das Auswählen mehrerer Schüler werden bereits bestehende Bemerkungen für diesen überschrieben.
                            </p>
                        </v-tooltip>
                    </div>
                    <div class="d-flex flex-row justify-space-between align-center">
                        <v-select
                            v-model="selectedPupil" 
                            :items="availableDiaryNotePupils"
                            :menu-props="{offsetY: true,}"
                            :item-title="item => item.name + '. ' + Array.from(item.lastName)[0]"
                            no-data-text="Keine Schüler in Gruppe"
                            style="max-width: 200px; height: 50px; max-height: 50px;"
                            label="Schüler"
                            :multiple="diaryNoteForMultiplePupils"
                            return-object
                        >
                            <template v-slot:selection="{ item, index }">
                                <!-- Show the first selected item -->
                                <span v-if="index === 0">{{ item.name + '. ' + Array.from(item.lastName)[0] }}</span>
                                <!-- If more than one item is selected, show count of remaining -->
                                <span v-if="index === 1"> &nbsp;{{ `(+ ${selectedPupil.length - 1} weitere)` }}</span>
                            </template>
                        </v-select>
                        <div v-if=" selectedPupil && selectedPupil.diaryEntryNoteOnAppointment && selectedPupil.diaryEntryNoteOnAppointment.editHistory.length >0">
                            <v-tooltip
                                location="bottom"
                            >
                                <template v-slot:activator="{ props }">
                                    <v-icon
                                        class="ma-0 pa-0 icon20 ml-2 iconToBlack"
                                       
                                        v-bind="props"
                                        @click.stop="showEditHistory(selectedPupil.diaryEntryNoteOnAppointment, true)"
                                    >
                                        mdi-script-text
                                    </v-icon>
                                </template>
                                <span>Berbeitungen anzeigen</span>
                            </v-tooltip>
                        </div>
                    </div>
                    <div class="d-flex flex-row justify-start align-center">
                        Bemerkung:
                    </div>

                    <div v-if="!diaryNoteForMultiplePupils">
                        <v-textarea
                            :key="selectedPupil._id"
                            v-model="selectedPupil.diaryNote"
                            density="compact"
                            flat
                            rows="10"
                            variant="solo"
                            hint="max. 200 Zeichen"
                            persistent-hint
                            :rules="selectedPupil.diaryNote !== undefined ? diaryNoteRules : []"
                            placeholder="Keine Bemerkung vorhanden"
                            :validate-on="selectedPupil.diaryNote !== undefined ? 'blur' : undefined"
                            @update:model-value="validateInput"
                            @blur="changedDiaryNote(selectedPupil)"
                        />
                    </div>
                    <div v-else>
                        <v-textarea
                            v-model="multiplePupilDiaryEntryNoteText"
                            density="compact"
                            flat
                            rows="10"
                            variant="solo"
                            hint="max. 200 Zeichen"
                            persistent-hint
                            placeholder="Keine Bemerkung vorhanden"
                            validate-on="blur"
                            @update:model-value="validateInput"
                        />
                    </div>
                </div>
            </v-card>
        </v-dialog>
        <AreYouSurePopup
            v-if="deleteDialog"
            v-model:show-dialog="deleteDialog"
            :content-text-prop="'Sollen alle ausgewählten Fehlzeiten endgültig gelöscht werden?'"
            ok-btn-color="frot"
            :header-color="'#3caa69'"
            @close="() => { deleteDialog = false; selectToDelete = false; }"
            @ok="deleteSelectedAbsences"
        />

        <DiaryExportDialog
            v-if="exportPupilLateTimesDialog"
            ref="pupilLateTimesExportPopup"
            :mode="'latetimes'"
            :open="exportPupilLateTimesDialog"
            :current-group="group._id"
            :export-data-function="(start, end) => exportPupilLateTimesForDates(absenceListPupil, start, end)"
            :account-role="accountRole"
            :show-exporting-overlay="showExportingOverlay"
            @close="() => { exportPupilLateTimesDialog = false; }"
        />

        <DiaryExportDialog
            v-if="openExportDialog"
            ref="diaryExportPopup"
            :open="openExportDialog"
            :current-group="group._id"
            :export-data-function="exportMultipleDiaryWeeks"
            :account-role="accountRole"
            :show-exporting-overlay="showExportingOverlay"
            @close="() => { openExportDialog = false; }"
        />
        <PinDialog
            v-model:show-pin-dialog="pinDialog"
            :account="account"
            :usage="'diary'"
            @close="pinDialog = false"
            @account-pin-valid="()=>{accountPinIsValid = true; pinDialog = false}"
        />
        <v-dialog
            v-if="editHistoryDialog"
            v-model="editHistoryDialog"
            max-width="95vw"
            @click:outside="closeEditHistoryDialog"
        >
            <v-card
                style="display: flex; flex-direction: column; "
            >
                <v-card-title
                    class="d-flex flex-row justify-space-between pa-0 ma-0"
                >
                    <div class="d-flex flex-row justify-space-around">
                        <v-icon>
                            mdi-script-text
                        </v-icon>
                        <p> Bearbeitungsverlauf </p>
                    </div>
                    <v-btn
                        elevation="0"
                        style="min-width: 25px; max-width: 25px; min-height: 25px; max-height: 25px; background-color: transparent"
                        @click="closeEditHistoryDialog"
                    >
                        <img
                            :src="closeIcon"
                            alt="Fenster schließen"
                            style="height: 25px;"
                        >
                    </v-btn>
                </v-card-title>
               
                
                <table class="table my-2 mx-6">
                    <thead>
                        <tr>
                            <th>ursprünglicher Eintrag</th>
                            <th>Datum</th>
                            <th>Bearbeiter</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            v-for="history in editHistory"
                            :key="history._id"
                        >
                            <td>{{ history.previousText }}</td>
                            <td>{{ formatDate(history.editDate) }}</td>
                            <td>{{ getAuthor(history.author) }}</td>
                        </tr>
                        <tr v-if="editHistoryAppointment">
                            <td>{{ editHistoryAppointment.appointmentDiaryEntryNote.text }}</td>
                            <td>{{ formatDate(editHistoryAppointment.appointmentDiaryEntryNote.updatedAt) }}</td>
                            <td>{{ getAuthor(editHistoryAppointment.appointmentDiaryEntryNote.author) }}</td>
                        </tr>
                        <tr v-else-if="pupilEditHistory">
                            <td>{{ pupilEditHistory.text }}</td>
                            <td>{{ formatDate(pupilEditHistory.updatedAt) }}</td>
                            <td>{{ getAuthor(pupilEditHistory.author) }}</td>
                        </tr>
                    </tbody>
                </table>
            </v-card>
        </v-dialog>
    </v-overlay>
</template>

<script>
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Avatar from "@/components/SharedViews/Profile/Avatar";

//#region import icons
import editIcon from '../../assets/Icons/FaceliftIcons/stift_bearbeiten_.svg';
import closeIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import materialIcon from '../../assets/Icons/FaceliftIcons/material_dateien.svg';
import arrowIcon from '../../assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import faehnchenIcon from '@/assets/Icons/FaceliftIcons/faehnchen.svg';
import rightIcon from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import leftIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import bookIcon from "../../assets/Icons/FaceliftIcons/klassenbuch.svg";
import exportIcon from '@/assets/Icons/drucken-filled.svg';
import abwesendIcon from "@/assets/Icons/FaceliftIcons/schueler_abwesend.svg";
import abwesendIconGrau from "@/assets/Icons/FaceliftIcons/lehrer_abwesend_melden_grau.svg";
import listIcon from "@/assets/Icons/FaceliftIcons/menu_lang_weiss.svg";
import plusIcon from "@/assets/Icons/plus.svg";
import doneIcon from '@/assets/Icons/fertig-74.svg';
import deleteIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import paperclipIcon from "@/assets/Icons/FaceliftIcons/anhang_senkrecht.svg";
import wechselKreisIcon from '@/assets/Icons/FaceliftIcons/wechsel_kreis.svg';
import lehrerAbwesendIcon from "@/assets/Icons/FaceliftIcons/lehrer_abwesend.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import lockIcon from '@/assets/Icons/lock-closed.svg';
import unlockIcon from '@/assets/Icons/lock-open.svg';

//#endregion

//#region import subject icons
import bioIcon from "../../assets/Icons/FaceliftIcons/f-biologie-weiss.svg";
import chemieIcon from "../../assets/Icons/FaceliftIcons/f-chemie-weiss.svg";
import deutschIcon from "../../assets/Icons/FaceliftIcons/f-deutsch-weiss.svg";
import englischIcon from "../../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import erdkundeIcon from "../../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg";
import geschichteIcon from "../../assets/Icons/FaceliftIcons/f-geschichte-weiss.svg";
import matheIcon from "../../assets/Icons/FaceliftIcons/f-mathe-weiss.svg";
import musikIcon from "@/assets/Icons/FaceliftIcons/f-musik-weiss.svg";
import kunstIcon from '@/assets/Icons/FaceliftIcons/f-kunst-weiss.svg';
import physikIcon from "../../assets/Icons/FaceliftIcons/f-physik-weiss.svg";
import politikIcon from "../../assets/Icons/FaceliftIcons/f-politik-weiss.svg";
import religionIcon from "../../assets/Icons/FaceliftIcons/f-religion-weiss.svg";
import sportIcon from "../../assets/Icons/FaceliftIcons/f-sport-weiss.svg";
import wirtschaftIcon from "../../assets/Icons/FaceliftIcons/f-wirtschaft-weiss.svg";
import kebabMenuIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg"
import calendarIcon from '@/assets/Icons/FaceliftIcons/kalender_datum_zeit.svg'
//#endregion

import sanitizeHtml from "@/util/sanitizeHtml";
import exports from "@/util/diaryExport";
import * as backend from "@/api/backend";
import DiaryExportDialog from "@/components/Lehrer/DiaryExportDialog";
import PinDialog from "@/components/PinDialog.vue"
import EventBus from '@/util/eventBus.js'
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup";
import urlify from '../../util/urlify';

export default {
    name: "DiaryComponent",
    components: {
        DiaryExportDialog,
        Avatar,
        PinDialog,
        AreYouSurePopup,
    },
    props: {
        diaryDialog: { required: false, type: Boolean, default: false },
        teacher: { required: false, type: Object, default: null }
    },
    emits: ['close'],
    data: () => ({
        urlify,
        sanitizeHtml,
        //#region Icons
        lockIcon,
        unlockIcon,
        editIcon,
        closeIcon,
        materialIcon,
        arrowIcon,
        faehnchenIcon,
        rightIcon,
        leftIcon,
        cancelIcon,
        bookIcon,
        exportIcon,
        abwesendIcon,
        abwesendIconGrau,
        listIcon,
        plusIcon,
        doneIcon,
        deleteIcon,
        paperclipIcon,
        wechselKreisIcon,
        lehrerAbwesendIcon,
        infoIcon,
        kebabMenuIcon,
        calendarIcon,
        //#endregion

        firstOpen: false,
        pinDialog: false,
        accountPin: null,
        // accountHasPin: false,
        accountPinIsValid: false,
        me: null,                           // result of getMeTeacher
        dialog: false,                      // local version of diaryDialog
        lateDialog: false,
        diaryEntryIndex: 0,
        lateDialogAppointment: null,
        initialLateDialogAppointment: null,
        initialDiaryEntry: null,
        lateTimesWithAppointmentDrafts: [],
        confirmCloseDialog: false,
        selectedDiaryEntry: false,
        teacherImage: null,
        pickerDate: new Date().toISOString(),

        editingAbsence: false,
        editAbsenceItem: null,
        currentEditIndex: null,
        absenceDateFrom: null,
        absenceDateTill: null,
        absenceDateFromMenu: false,
        absenceDateTillMenu: false,
        formattedAbsenceDateFrom: '',
        formattedAbsenceDateTill:'',
        createNewAbsence: false,
        newAbsenceNote: '',
        newAbsenceMinutes: '',
        selectedAbsenceListItems: [],
        newAbsenceExcused: false,
        newAbsenceIsFullday: false,
        newAbsenceUnexcused: true,
        deleteDialog: false,
        tableKey: 0,
        selectToDelete: false,
        lateDialogExcused: [],

        pupilRefreshKey: 0,
        showAbsence: false,

        personalAbsenceData: [],
        pupilsWithLateTotal: [],
        absenceListDialog: false,
        showAbsenceLoading : false,
        absenceListPupil: null,

        date: null,
        groups: [],                         // to select which groups diary to display
        group: null,                        // selected group
        allTeachers: [],                    // all teachers that exist in db
        diaryIn: [],                        // data pulled from backend
        // newDiary: [],
        noAppointmentsAvailable: false,     // if no appointments are available for provided day
        diaryOut: [],                       // data that is to be posted to backend (e.g. topic, sick notes, late times)
        override: [],                       // teacher or fachinfo that is to be overridden in schoolSubjectAppointment
        maintainers: [],

        showFullNote: '',
        editNotes: [],                      // id of which notes are in edit state
        expandInfo: false,                  // if notes in Fachinfo column are expanded or one lined
        diaryNoteDialog: false,
        currentAppointment: null,
        currentDiaryEntry: null,
        selectedPupil: [],
        diaryNoteRules: [ v =>  v.length <= 200 || 'max. 200 Zeichen!' ],
        pupilDiaryNoteOverride: [],

        mainDialogKey: 0,
        rerenderKey: 0,                     // late attribute didn't want to update correctly :/
        rerenderTeachers: 0,                // teachers v-autocomplete wouldn't display correctly without force rerender
        noDataText: 'Keine Daten verfügbar',// Text in v-data-table to display if there is no data
        diaryReloadKey: 0,
        headers: [
            { text: 'Fach/Lehrer', value: 'schoolSubject.name', sortable: false, width: '15%' },
            { text: 'Klassenbucheintrag', value: 'appointmentDiaryNote', sortable: false, width: '25%' },
            { text: 'Fehlzeit', value: '', sortable: false, width: '25%' },
            { text: 'Bemerkungen', value: '', sortable: false, width: '25%' },
        ],

        diaryNoticeHeaders: [
            { text: 'Schüler', value: 'pupil', sortable: false, width: '150px'  },
            { text: 'Fehlzeit', value:'time',  sortable: false, width: '150px'  },
            { text: 'entschuldigt',  value: 'excused',  sortable: false, width: '150px'  },
            { text: 'unentschuldigt',value: 'unexcused',  sortable: false, width: '150px'  },
            { text: 'Bemerkung', value:'note',  sortable: false, width: '150px'  },
        ],

        absenceHeaders: [
            { text: '', value: 'icon', sortable: false,  width: '40px'  },
            { text: 'Schüler*in', value: '', sortable: false, width: '25%' },
            { text: 'Insg. Fehlzeiten', value: '', sortable: false, width: '150px' },
            { text: 'entschuldigt', value: '', sortable: false, width: '25%' },
            { text: 'unentschuldigt', value: '', sortable: false, width: '25%' },
        ],

        personalAbsenceHeaders: [
            { text: '', value: 'icon', sortable: false, width: '40px'  },
            { text: 'Datum von', value: 'dateFrom', sortable: false, width: '150px'  },
            { text: 'Datum bis', value: 'dateTill', sortable: false, width: '150px' },
            { text: 'Ganztägig', value: 'isFullday', sortable: false, width: '150px' },
            { text: 'Fehlzeit', value: 'time', sortable: false, width: '150px' },
            { text: 'entschuldigt', value: 'excused', sortable: false, width: '150px' },
            { text: 'unentschuldigt', value: 'unexcused', sortable: false, width: '150px' },
            { text: 'Bemerkung', value: 'note', sortable: false, width: '150px' },
        ],

        germanDayStrings: ["Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag", "Sonntag"],
        rooms: [],
        substitutions: [],

        openExportDialog: false,
        exportPupilLateTimesDialog: false,

        editHistoryDialog: false, 
        editHistory: {},
        editHistoryAppointment: false,
        pupilEditHistory: null,

        reloadNoteKey: 0,
        showLoadingEntries: false,

        diaryNoteForMultiplePupils: false,
        multiplePupilDiaryEntryNoteText: '',
        pupilsLateTimesList: [],
        personalAbsenceEdited: false,
        showExportingOverlay: false,
    }),
    computed: {
        ...mapState('util', ['windowWidth']),
        ...mapState('auth', ['account']),
        ...mapGetters('auth', ['accountRole','accountHasPin','diaryPinActivated','deactivatedAnimation']),
        ...mapGetters('diary', ['diaries','diaryByDayAndGroup','diaryEntriesByDiary']),
        ...mapGetters('diary', {'vuexDiaryEntries':'diaryEntries'}),
        ...mapGetters('accounts', ['accountsById']),
        ...mapGetters('teachers', ['teachersByAccountId', 'allTeacherGroups']),
        ...mapGetters('groups', ['groupsById']),
        ...mapGetters('groups', {'vuexGroups':'groupsInfo'}),
        ...mapGetters('appointments', ['appointments']),
        ...mapGetters('timeslots', ['timeslots', 'timeslotsById']),
        ...mapGetters('teachers', ['teachersById']),
        ...mapGetters('pupils', ['pupilsById']),
        ...mapGetters('lateTimes',[ 'getLateTimesByAppointmentId',]),
        
        usePin(){
            return this.diaryPinActivated;
        },
        showAvatar(){
            return this.accountRole === 'teacher' ? this.teacher?.account : this.maintainers[0]?.account;
        },
        // pupilsLateTimesList() {
        //     // out of current group get all pupils that have lateTimes
        //     const pupilsWithLateTimes = this.getPupilsWithLateTimes.filter((pupilWithLateTime) =>
        //         this.group.participants.includes(pupilWithLateTime.pupil._id));
        //     // get the other participants aswell since they need to be displayed aswell so lateTimes can be created in this list
        //     const pupilIds = this.group.participants.filter(participantId =>
        //         !pupilsWithLateTimes.some(item => item.pupil._id === participantId));
        //     // since pupilWithoutLateTime has no lateTimes but will be displayed in same data-table, object sturcture needs to be adjusted accordingly
        //     const pupilsWithoutLateTimes = pupilIds.map(id => ({
        //         latetimes:{},
        //         pupil:{ ...this.pupilsById[id] },
        //         totalLateTime: 0,
        //         missingDays: 0,
        //     }));
        //     return pupilsWithLateTimes.concat(pupilsWithoutLateTimes);
        // },
        availableDiaryNotePupils(){
            return this.currentDiaryEntry.pupils;
        },
        currentAppointmentDay(){
            return new Date(this.currentAppointment?.day).toLocaleDateString('de-DE', {
                                            month: '2-digit',
                                            day: '2-digit',
                                            year: '2-digit'
                                        })
        },
        currentAppointmentTimeslotDate(){
            return this.currentAppointment ? this.getTimeslotTimes(this.currentAppointment.timeslot) : ''
        },

        isGroupLeader(){
            if(this.accountRole === 'teacher' && this.group && this.teacher){
             return this.groupLeaders.includes(this.teacher._id);
            }else{
                return true;
            }
        },
        groupLeaders(){
            if(this.groups.length >0){
                return this.groups[0].leaders.map((leader)=>leader._id)
            }else{
                return [];
            }
        },
        currentDiary() {
            const wantedDate = this.formatDateForDiaryRequest(this.date);
            const requestingGroupId = (this.group && this.group._id) ? this.group._id :
                 (this.groups[0] && this.groups[0]._id) ? this.groups[0]._id : '';

            return requestingGroupId !== '' ? this.diaryByDayAndGroup(wantedDate, requestingGroupId) : {}; 
        },
        diaryEntriesOfDay() {
            if (this.currentDiary && this.currentDiary._id) {
                const enhanced = this.diaryEntriesByDiary(this.currentDiary._id).map(entry =>
                    this.enhanceDiaryEntry(entry)
                );
                return [...enhanced].sort((a, b) => {
                    return new Date(a.appointment.timeslot.start) - new Date(b.appointment.timeslot.start);
                });
            }
            return [];
        },
        disabledWeekdays() {
            return [{ repeat: { weekdays: [1, 7] } }]; 
        },
    },
    watch: {
        group() {
            this.requestAppointments(false);
            this.$nextTick(() => {
                console.log("Diary updated, diaryEntriesOfDay:", this.diaryEntriesOfDay);
            });
            this.diaryReloadKey +=1;
        },
        async date(newVal) {
            this.requestAppointments(false);
            this.$nextTick(() => {
                console.log("Diary updated, diaryEntriesOfDay:", this.diaryEntriesOfDay);
            });
            this.diaryReloadKey +=1;

        },
        absenceDateFrom(newVal) {
            if(newVal && newVal !== null){
                this.formattedAbsenceDateFrom = new Date(newVal).toLocaleDateString('de-DE', {
                                                    month: '2-digit',
                                                    day: '2-digit',
                                                    year: '2-digit'
                                                });
            }
        },
        absenceDateTill(newVal) {
            if(newVal && newVal !== null){
                this.formattedAbsenceDateTill = new Date(newVal).toLocaleDateString('de-DE', {
                                                month: '2-digit',
                                                day: '2-digit',
                                                year: '2-digit'
                                            });
            }
        },
        newAbsenceExcused(newVal){
            if(newVal && this.newAbsenceUnexcused){
                this.newAbsenceUnexcused  = false;
            }
            else if(newVal === false){
                this.newAbsenceUnexcused  = true;
            }
        },

        newAbsenceUnexcused(newVal){
            if(newVal && this.newAbsenceExcused){
                this.newAbsenceExcused  = false;
            }else if(newVal === false){
                this.newAbsenceExcused  = true;
            }
        },
        createNewAbsence(newVal){
            if(newVal){
                //setting default date
               this.absenceDateFrom = new Date(this.date).toISOString().substring(0, 10);
               this.absenceDateTill = new Date(this.date).toISOString().substring(0, 10);
            }
        },
        diaryNoteForMultiplePupils(newVal){
            this.selectedPupil = [];
            this.multiplePupilDiaryEntryNoteText = '';
        },
        async showAbsence(newVal, oldVal){
            if(newVal && (newVal === true) && (oldVal === false) && this.group){
                const lateTimesList = await this.getGroupLateTimesList({groupId: this.group._id});
                this.pupilsLateTimesList = lateTimesList;
            }
        }
    },
    beforeCreate() {
        // Returns the ISO week of the date.
        Date.prototype.getWeek = function () {
            var date = new Date(this.getTime());
            date.setHours(0, 0, 0, 0);
            // Thursday in current week decides the year.
            date.setDate(date.getDate() + 3 - (date.getDay() + 6) % 7);
            // January 4 is always in week 1.
            var week1 = new Date(date.getFullYear(), 0, 4);
            // Adjust to Thursday in week 1 and count number of weeks from date to week1.
            return 1 + Math.round(((date.getTime() - week1.getTime()) / 86400000
                - 3 + (week1.getDay() + 6) % 7) / 7);
        }
    },
    created() {
        this.date = this.getDay();
        // this.$store.dispatch("lateTimes/getLateTimes");
    },
    async mounted() {
        // pull timeslots if necessary
        await this.getSlots();
        if (!this.teacher) {
            this.maintainers = await this.getMaintainers()
        }
        await this.getInfo();
      
        
        if (this.accountRole === 'maintainer' && this.group) {
            await this.loadTeacherImage();
        }
        if(!this.usePin){
            this.accountPinIsValid = true;
        }      
 
        EventBus.off('diaryEntry-update');
            EventBus.on('diaryEntry-update', async (body) => {
                await this.updateDiaryEntry(body.diaryEntryId);
            });
        EventBus.off('diaryEntriesOnDay-update');
        EventBus.on('diaryEntriesOnDay-update', async (body) => {
            await this.updateDiaryEntriesOnDay(body);
        });
        EventBus.off('diaryEntriesFullDay-update');
        EventBus.on('diaryEntriesFullDay-update', async (body) => {
            console.log('updateFullDay',body);
            await this.updateDay(body);
        });
    },
    methods: {
        ...mapActions('teachers', ['getTeachers', 'getMeTeacher', 'getProfilePicture','getMyTeacherGroups']),
        ...mapActions('diary', ['getDiary', 'postDiary', 'getDiaryV2','getDiaryExport', 'getDiaryEntryUpdate']),
        ...mapActions('sickNote', ['toggleSickNote']),
        ...mapActions('groups', ['getGroupsInfo', 'getGroupsInfoWithLeaders']),
        ...mapActions('timeslots', ['getSlots']),
        ...mapMutations('snackbar', ['showSnackbar']),
        ...mapMutations('diaries', ['setDiaryEntries']),
        ...mapActions('lateTimes', [
            'getGroupLateTimesList',
            'getPupilLateTimes',
            'getPupilLateTimesForDates',
            'postLateTime',
            'updateLateTime',
            'toggleExcuseLateTime',
            'deleteLateTime',
            'getLateTimesForGroupOnDay',
            'getPupilsWithLateTimes',
            'getAllLateTimesForGroup'
        ]),
        ...mapActions("physicalRooms", ["getRooms"]),
        ...mapActions("substitution", ['getSubstitutions']),
        ...mapActions('maintainers', ['getMaintainers']),
        ...mapActions("util", ["loadServerTime"]),
        
        cancelAbsenceEdit() {
            this.resetNewAbsence(); 
            this.forceRerender(1)
        },
        confirmDateSelection(selectedDate) {
            this.pickerDate = selectedDate;
            this.date = new Date(selectedDate); // Store selected date
        },
         removeHtml(text) {
            if(text){
                let returntext = new DOMParser().parseFromString(text, 'text/html');
                return returntext.body.textContent || '';
            }
            return '';
        },
        async clickMenuBackButton(){
            if(this.showAbsence){
                if(this.personalAbsenceEdited){
                    const lateTimesList = await this.getGroupLateTimesList({groupId: this.group._id});
                    this.pupilsLateTimesList = lateTimesList;
                    this.personalAbsenceEdited = false;
                }
                this.showAbsence = false;
            }else{
                this.closeDiary();
            }
        },
        getMondayOfWeek(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day === 0 ? -6 : 1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        changeDateThroughDatePicker(){
            this.date = new Date(this.pickerDate)
        },
        getAppointmentTimeslotTime(timeslot){
            const start = new Date(timeslot.start);
            const end = new Date(timeslot.end);
            return start.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit'
                }) + ' - ' + end.toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit'
                })

        },
        async updateDiaryEntry(diaryEntryId){
            const enhancedDiaryEntry = await this.getDiaryEntryUpdate({diaryEntryId});
            this.diaryReloadKey +=1;
        },
        async updateDiaryEntriesOnDay(body){
            const appointmentIds = body.updateEntriesAppointments;
            const updated = await Promise.all(appointmentIds.map(async (appointmentId)=>{
                const diaryIndex = this.diaries.findIndex(diary => diary.diaryEntries.some((entry)=> 
                    entry.appointment._id.toString() === appointmentId.toString()));
                if(diaryIndex > -1){
                    const entries = this.diaries[diaryIndex]
                    const entry = entries[entries.findIndex(entry =>entry.appointment._id.toString() === appointmentId.toString())]
                    const updatedEntry = await this.getDiaryEntryUpdate({diaryEntryId: entry._id});
                }
            }));
            this.$nextTick(() => {
                console.log("Diary updated, diaryEntriesOfDay:", this.diaryEntriesOfDay);
            });
            this.diaryReloadKey +=1;

        },
        async updateDay(body){
            let startOfDay = new Date(new Date(body.dayToUpdate).getFullYear(),
             new Date(body.dayToUpdate).getMonth(), new Date(body.dayToUpdate).getDate(), 0, 0, 0, 0);

            await this.getDiaryV2({groupId: body.groupId, startOfDay:startOfDay.toISOString(), update: true});
             this.$nextTick(() => {
                console.log("Diary updated, diaryEntriesOfDay:", this.diaryEntriesOfDay);
            });
            this.diaryReloadKey +=1;
        },
        changedDiaryNote(selectedPupil){
            if(selectedPupil && selectedPupil.diaryNote){
                const initialPupil = this.initialDiaryEntry.pupils[this.availableDiaryNotePupils.findIndex(pupil => pupil._id === selectedPupil._id)];
                if(initialPupil.diaryNote !== selectedPupil.diaryNote && selectedPupil.diaryNote.trim !== ''){
                    const index = this.pupilDiaryNoteOverride.findIndex(ov => ov.entryId.toString() === this.initialDiaryEntry._id.toString() && ov.pupilId.toString() === selectedPupil._id.toString());

                    if (index !== -1){
                        // Object already exists, so exchange it
                        this.pupilDiaryNoteOverride[index] = { entryId: this.initialDiaryEntry._id, pupilId: selectedPupil._id, text: selectedPupil.diaryNote };
                    } else {
                    // Object doesn't exist, push it to the list
                    this.pupilDiaryNoteOverride.push({ entryId: this.initialDiaryEntry._id, pupilId: selectedPupil._id, text: selectedPupil.diaryNote })
                    }
                }
            }
        },
         formatDate(date) {
            return new Date(date).toLocaleDateString('de-DE') + ' ' + new Date(date).toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit'
                });
        },
        getAuthor(account){
            const accountObject = this.accountsById[account];
            if(accountObject.role === 'teacher'){
                const teacher = this.teachersByAccountId[account];
                return `${teacher.name}  ${teacher.lastName}`;
            }
            return 'Verwaltung';
        },
        closeEditHistoryDialog(){
            this.editHistoryDialog = false;
            this.editHistory = {};
            this.editHistoryAppointment = null;
            this.pupilEditHistory = null;

        },
        showEditHistory(entry, pupilEntry){

            if(pupilEntry){
                this.editHistory = entry.editHistory;
                this.pupilEditHistory = entry;
            }else{
                this.editHistory = entry.appointmentDiaryEntryNote.editHistory;
                this.editHistoryAppointment = entry;
            }
            this.editHistoryDialog = true;

        },
        appointmentDiaryEntryNoteHistory(appointment){
            if(appointment && appointment.appointmentDiaryEntryNote && appointment.appointmentDiaryEntryNote.editHistory.length > 0 ){
                return true
            }
            return false;
        },
        isToday(checkDate){
            const today = new Date();

            // Extract the day, month, and year from both dates
            const todayDay = today.getDate();
            const todayMonth = today.getMonth();
            const todayYear = today.getFullYear();

            const checkDateDay = checkDate.getDate();
            const checkDateMonth = checkDate.getMonth();
            const checkDateYear = checkDate.getFullYear();

            // Compare the day, month, and year of both dates
            return todayDay === checkDateDay && todayMonth === checkDateMonth && todayYear === checkDateYear;
        },
        clickOpenExport(){
            if(this.isGroupLeader){
                this.openExportDialog = true;
            }else if(this.group && this.teacher && this.group.leaders.includes(this.teacher._id)){
                this.openExportDialog = true;
            }
            else{
            this.showSnackbar({message:'Sie sind nicht der/die LeiterIn dieser Gruppe, und haben daher keinen Zugang zur vollständigen Fehlzeitenübersicht.', color:'error', timeout:'10000'});
            }
        },
         async isCurrentlyRunningAppointment(){
            const today = new Date(await this.loadServerTime());
            if(this.diaryEntriesOfDay.length > 0){
                let currentlyRunning = null;
                const map = await Promise.all(this.diaryEntriesOfDay.map(async (diaryIn) => {
                    const running = this.timeInTimeSlot(today, diaryIn.timeslot);
                    if(running){
                        currentlyRunning = diaryIn;
                    }
                }
                ));
                return currentlyRunning;
            }else{
                return null;
            }
        },
        timeInTimeSlot(time, timeslot) {
            const currentHourMinutes = (new Date(time).getHours() * 60 + new Date(time).getMinutes());
            const timeslotStartHoursAndMinutes = (new Date(timeslot.start).getHours() * 60 + new Date(timeslot.start).getMinutes());
            const timeslotEndHoursAndMinutes = (new Date(timeslot.end).getHours() * 60 + new Date(timeslot.end).getMinutes())

            return (currentHourMinutes >= timeslotStartHoursAndMinutes) && (currentHourMinutes <= timeslotEndHoursAndMinutes)
        },
     
       openPinDialog(){
        this.pinDialog = true;
        this.accountPinIsValid = false;
        this.accountPin = null;
       },
      
       async savePupilDiaryNote(){
        if(this.diaryNoteForMultiplePupils && this.selectedPupil.length && this.multiplePupilDiaryEntryNoteText.trim() !== '' ){
            const diaryNoteResponses = await Promise.all(this.selectedPupil.map(async (selected) =>{
                let res;
                const altered = this.initialDiaryEntry.pupils[this.availableDiaryNotePupils.findIndex(pupil => pupil._id === selected._id)];
                if(altered.diaryEntryNoteOnAppointment){
                    res = await backend.editDiaryEntryNote(altered.diaryEntryNoteOnAppointment._id, { text: this.multiplePupilDiaryEntryNoteText } )
                }
                else(
                    res = await backend.postDiaryEntryNote({
                                diaryEntry: this.currentDiaryEntry._id,
                                pupil: selected._id,
                                text: this.multiplePupilDiaryEntryNoteText,
                            })
                )
                return res.status;
            }))
            this.resetDiaryNoteDialog();
            if(!diaryNoteResponses.includes(500)){
                this.showSnackbar({message:'Bemerkungen führ mehrere Schüler erfolgreich erstellt.'})
            }
            return
        }
        const responses = await Promise.all(this.pupilDiaryNoteOverride.map(async (overrideEntry) => {
            const initialPupil = this.initialDiaryEntry.pupils[this.availableDiaryNotePupils.findIndex(pupil => pupil._id === overrideEntry.pupilId)];
            let res;
            if(initialPupil.diaryEntryNoteOnAppointment){
               res = await backend.editDiaryEntryNote(initialPupil.diaryEntryNoteOnAppointment._id, { text: overrideEntry.text } )
            }else{
                res = await backend.postDiaryEntryNote({
                                diaryEntry: overrideEntry.entryId,
                                pupil: overrideEntry.pupilId,
                                text: overrideEntry.text,
                            })
            }
        }))
        this.resetDiaryNoteDialog();
       },
       resetDiaryNoteDialog(){
        this.currentAppointment = null;
        this.currentDiaryEntry = null;
        this.diaryNoteDialog = false;
        this.selectedPupil = [];
        this.initialDiaryEntry = null;
        this.diaryNoteForMultiplePupils = false;
        this.multiplePupilDiaryEntryNoteText = '';
       },
       createDiaryNote(entry, pupil){
         this.initialDiaryEntry = JSON.parse(JSON.stringify( entry));
        if(pupil){
            this.selectedPupil = pupil;
        }

        this.currentAppointment = entry.appointment;
        this.currentDiaryEntry = entry;

        this.diaryNoteDialog = true;
        
        },

        //#region get needed data
        async getInfo() {
            // if (this.accountRole === 'teacher') {
            //     this.me = await this.getMeTeacher();
            // }
             this.requestGroups();
            // this.allTeachers = await this.getTeachers();
            // await this.requestAppointments();
        },

        getDisplayDate() {
            let displayDate = this.date.toLocaleDateString('de-DE', { weekday: this.windowWidth > 600 ? 'long': 'short', year: '2-digit', month: '2-digit', day: '2-digit' });
            return displayDate;
        },

        async requestGroups() {
            if (this.accountRole === 'maintainer') {
                this.groups = this.vuexGroups.filter(group=> group.name !== 'Alle');
            } 

            else if (this.accountRole === 'teacher') {

                // const myAppointments = this.appointments.filter((appointment) => appointment.teachers.find((teacher) => teacher._id === this.me._id));
                // let myGroupSet = new Set(myAppointments.map((appointment) => { return appointment.group }));
                // let myGroupIds = Array.from(myGroupSet);
                // const leaderGroups =  (await this.getGroupsInfo()).filter(group => (group.name !== 'Alle') && (group.leaders.includes(this.me._id)));
                // const appointmentGroups = this.groups.filter((group) => myGroupIds.some((myGroup) => myGroup === group._id));
                // this.groups = [...appointmentGroups, ...leaderGroups];
                const groupsWithoutMap = await this.getMyTeacherGroups(true);

                this.groups = await Promise.all(groupsWithoutMap.map(async (group) => ({ 
                    ...group,
                    profilePicture: this.requestProfilePictures(group.leaders[0]),
                })
                ));
      
            this.groups.sort(this.alphabetically);
            
            if(this.groups.length > 0){
                this.group = this.groups[0];
            }
            }
        },

        alphabetically(a, b) {
            var x = a.name.toLowerCase();
            var y = b.name.toLowerCase();
            if (x < y) {return -1;}
            if (x > y) {return 1;}
            return 0;
        },

        toggleUnexcused(index, pupilId) {
            const pupilIndex = this.diaryEntriesOfDay[index].pupils.findIndex(pupil => pupil._id === pupilId)
            if (this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.excused) {
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.excused = false;
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.unexcused = true;
            }else{
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.excused = true;
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.unexcused = false;
            }
            this.forceRerender(2);
        },

        toggleExcused(index, pupilId) {
            const pupilIndex = this.diaryEntriesOfDay[index].pupils.findIndex(pupil => pupil._id === pupilId)
            if (this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.unexcused) {
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.excused = true;
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.unexcused = false;
            }else{
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.unexcused = true;
                this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.excused = false;
            }
            this.forceRerender(2);
        },
        toggleFullDay(index,pupilId){
            const pupilIndex = this.diaryEntriesOfDay[index].pupils.findIndex(pupil => pupil._id === pupilId);
              const pupilsCopy = JSON.parse(JSON.stringify(this.diaryEntriesOfDay[index].pupils[pupilIndex]));
              
            if (this.diaryEntriesOfDay[index].pupils[pupilIndex].lateTime.isFullday) {
                pupilsCopy[pupilIndex].latetime.isFullday = false;
                this.$set(this.diaryEntriesOfDay.pupils, index, pupilsCopy);

            }else{
                pupilsCopy[pupilIndex].latetime.isFullday = true;
                this.$set(this.diaryEntriesOfDay.pupils, index, pupilsCopy);

            }
            this.forceRerender(2);
        },

        validateInput(){
         if(this.diaryNoteForMultiplePupils){
            if(this.multiplePupilDiaryEntryNoteText.length > 200 ){
                this.multiplePupilDiaryEntryNoteText = this.multiplePupilDiaryEntryNoteText.substring(0, 200);
            }
         }
         else{
            if(this.selectedPupil.diaryNote.length > 200 ){
                this.selectedPupil.diaryNote = this.selectedPupil.diaryNote.substring(0, 200);
            }
         }
        },
      enhanceDiaryEntry(entry) {
        const lateTimesByAppointmentId = this.getLateTimesByAppointmentId;
        const enhancedEntry = JSON.parse(JSON.stringify(entry)); // Create a deep copy

        const enhancedPupils = enhancedEntry.pupils.map((pupil) => {
            const participant = { ...pupil }; // Create a shallow copy of each pupil

            // Default diaryNote if not set
            if (!participant.diaryNote) {
                participant.diaryNote = '';
            }

            // Set diaryEntryNoteOnAppointment and diaryNote
            if (enhancedEntry.diaryEntryNotes) {
                const index = enhancedEntry.diaryEntryNotes.findIndex(
                    note => note.pupil.toString() === participant._id.toString()
                );
                if (index > -1) {
                    participant.diaryEntryNoteOnAppointment = enhancedEntry.diaryEntryNotes[index];
                    participant.diaryNote = enhancedEntry.diaryEntryNotes[index].text;
                    if(enhancedEntry.diaryEntryNotes[index].editHistory && enhancedEntry.diaryEntryNotes[index].editHistory.length >0){
                        participant.diaryNoteEditHistory = enhancedEntry.diaryEntryNotes[index].editHistory;
                    }
                }
            }

            // Late time logic
            if (lateTimesByAppointmentId[enhancedEntry.appointment._id]) {
                const lateTimeObject = lateTimesByAppointmentId[enhancedEntry.appointment._id]
                    .filter(lateTime => lateTime.pupil === participant._id);
                if (lateTimeObject.length > 0) {
                    participant.lateTime = JSON.parse(JSON.stringify(lateTimeObject[0]));
                    participant.lateTime.unexcused = !participant.lateTime.excused;
                } else {
                    participant.lateTime = {
                        pupil: participant._id,
                        note: '',
                        missingTime: 0,
                        excused: false,
                        unexcused: true,
                        isFullday: false,
                    };
                }
            } else {
                participant.lateTime = {
                    pupil: participant._id,
                    note: '',
                    missingTime: 0,
                    excused: false,
                    unexcused: true,
                    isFullday: false,
                };
            }

            return participant;
        });

        enhancedEntry.pupils = enhancedPupils;

        // Update appointment timeslot dates
        const dateOldStart = new Date(enhancedEntry.appointment.timeslot.start);
        dateOldStart.setFullYear(2020, 11, 18);
        const dateOldEnd = new Date(enhancedEntry.appointment.timeslot.end);
            dateOldEnd.setFullYear(2020, 11, 18);

            enhancedEntry.appointment.timeslot.start = dateOldStart.toISOString();
            enhancedEntry.appointment.timeslot.end = dateOldEnd.toISOString();

            return enhancedEntry;
    },
    async requestAppointments(update) {
        let startOfDay = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0, 0, 0);
        // Teacher may not even be the leader of any group

        if(this.group && this.group !== undefined) {
        // this.$store.dispatch("lateTimes/getLateTimesForGroupOnDay",{groupId: this.group._id, day: startOfDay})
            // this.diaryIn = await this.getDiary(body);
            const newDiary = await this.getDiaryV2({groupId: this.group._id, startOfDay: startOfDay.toISOString(), update});
            if(this.diaryEntriesOfDay.length < 1){
                this.noAppointmentsAvailable = true;
            } 
            else {
                // this.newDiary = this.vuexDiaryEntries;
                this.noAppointmentsAvailable = false;
            }
        } else {
            this.noDataText = "Sie sind nicht der/die LeiterIn einer Gruppe."
        }
    },
        formatDateForDiaryRequest(date){
            const wantedDate = new Date(date)
            return new Date(wantedDate.getFullYear(), wantedDate.getMonth(), wantedDate.getDate(), 0, 0, 0, 0);
        },
        async requestDiaryForDate(wantedDate, groupId) {
            if (!groupId) {
                return [];
            }
            const datedDate = new Date(wantedDate);
            const formattedDate = this.formatDateForDiaryRequest(wantedDate);
            const diary = await this.getDiaryExport({groupId: this.group._id, startOfDay: formattedDate.toISOString()});
            if (diary.diaryEntries.length < 1) {
                return [];
            }
            const diaryEntries = diary.diaryEntries;
         
            const sortedAndEnhanced = diaryEntries.sort((a,b) => {
                return new Date(a.appointment.timeslot.start) - new Date(b.appointment.timeslot.start);
            });
            const returnValue = sortedAndEnhanced.map(entry => {
               return {
                   appointment: entry.appointment,
                   pupils: entry.pupils,
                   seenBy: entry.seenBy,
               }
            });
            return returnValue;
        },

        getDay() {
            let date = new Date();
            while (!(date.getDay() % 6)) {
                date = new Date(date.getFullYear(), date.getMonth(), date.getDate()-1);
            }
            return date;
        },
        //#endregion

        //#region dialog methods
        closeDialogs() {
            this.confirmCloseDialog = false;
            this.$emit('close');
        },

        handleClickOutside() {
            if (this.confirmedPassword) {
                this.confirmCloseDialog = true;
            } else {
                this.$emit('close');
            }
        },

        handleClickContent(event){
            event.stopPropagation();
        },

        resetDialog() {
            this.diaryIn = [];
            this.diaryOut = [];
            this.closeDialogs();
        },

        async openLateDialog(index, entry, firstOpen) {
            this.diaryEntryIndex = index;
            this.lateDialogAppointment = entry;
            //copy initial state, so when closing lateTime dialog, modified lateTimes can be handled correctly
            this.initialLateDialogAppointment = JSON.parse(JSON.stringify( entry));
            // Update seenBy
            // this.diaryOut[this.diaryEntryIndex].pupils.forEach((pupil) => {
            //     if (pupil.seenBy) {
            //         pupil.seenBy.push(this.account._id);
            //     }
            // })
            if(firstOpen){
                this.firstOpen = true;
            }
            this.lateDialog = true;
        },
        closeLateDialog(){
            const modified = this.lateDialogAppointment.pupils; //this is the modified object
            const original = this.initialLateDialogAppointment.pupils; //this is the copy of the list, created before opening dialog
            const appointmentId = this.lateDialogAppointment.appointment._id;
            const day = this.lateDialogAppointment.appointment.day;
            // check if lateTimes were modified and if yes push them into list so if diary is saved, they can be created in backend
            for (let i = 0; i < original.length; i++) {
                const originalObj = original[i];
                const modifiedObj = modified[i];

                if (JSON.stringify(originalObj.lateTime) !== JSON.stringify(modifiedObj.lateTime)) {
                    const timeModified = originalObj.lateTime.missingTime !== modifiedObj.lateTime.missingTime;
                    const noteModified = originalObj.lateTime.note !== modifiedObj.lateTime.note;
                    // mark lateTime as new if the original object had both no note and time
                    const isNewLateTime = originalObj.lateTime.note === "" && originalObj.lateTime.missingTime === 0;
                    const data = {
                        ...modifiedObj.lateTime,
                        appointment: appointmentId,
                        timeModified,
                        noteModified,
                        day,
                        isNewLateTime,
                        dateStart: new Date(day),
                        dateEnd:  new Date(day),
                    }
                    //check if same appointment has been mutated multiple times before saving
                    const index = this.lateTimesWithAppointmentDrafts.findIndex(draft => draft.appointment === data.appointment && draft.pupil === data.pupil)
                    if (index !== -1){
                        // Object already exists, so exchange it
                        this.lateTimesWithAppointmentDrafts[index] = data;
                    } else {
                    // Object doesn't exist, push it to the list
                        this.lateTimesWithAppointmentDrafts.push(data);
                    }

                }
            }
            this.saveDiary();
            this.firstOpen = false;
        },

        //#endregion

        //#region icon/Fach methods
        getIcon(icon) {
            switch (icon) {
                case 'bioIcon': return bioIcon;
                case 'chemieIcon': return chemieIcon;
                case 'deutschIcon': return deutschIcon;
                case 'englischIcon': return englischIcon;
                case 'erdkundeIcon': return erdkundeIcon;
                case 'geschichteIcon': return geschichteIcon;
                case 'matheIcon': return matheIcon;
                case 'musikIcon': return musikIcon;
                case 'kunstIcon': return kunstIcon;
                case 'physikIcon': return physikIcon;
                case 'politikIcon': return politikIcon;
                case 'religionIcon': return religionIcon;
                case 'sportIcon': return sportIcon;
                case 'wirtschaftIcon': return wirtschaftIcon;
            }
        },

        getBackgroundColor(color) {
            let returnString = "background-color: ";
            return returnString + color;
        },
        //#endregion
        teachersExists(id) {
            if (this.override.some(el => el._id === id)) {
                if (this.override[this.override.findIndex(el => el._id === id)].teachers) {
                    return true;
                }
            }
        },
        //#endregion

        //#region note/Aufgabe methods
        clickToggleNote(elId) {
            if (this.showFullNote === elId) {
                this.showFullNote = '';
            } else {
                this.showFullNote = elId;
            }
            this.expandInfo = true;
        },

        async editNote(appointment, event) {
            if(event){
                event.stopPropagation();
            }
            
            // see if override already has an entry with this id
            if (this.override.some(el => el._id === appointment._id)) {
                let indexOfEl = this.override.findIndex(el => el._id === appointment._id);
                // if this entry doesn't have a note field create it now
                if (!this.override[indexOfEl].diaryNote) {
                    this.override[indexOfEl].diaryNote = this.removeHtml(appointment.appointmentDiaryEntryNote?.text) || '';
                }
            } 
            else {
                this.override.push({ _id: appointment._id, diaryNote: this.removeHtml(appointment.appointmentDiaryEntryNote?.text) || '' });  
            }
            // // put id into editNote array
            if (!this.editNotes.includes(appointment._id)) {
                this.editNotes.push(appointment._id);
            }
            
            // forces a rerender and thus show the text-area correctly
            this.clickToggleNote(appointment._id);
        },

        async stopEditNote(appointment, index, event) {
            if(event){
                event.stopPropagation();
            }
            // if the override array contained a note for this id, delete it from array
            if (this.override.some(el => el._id === appointment._id)) {
                let indexOfEl = this.override.findIndex(el => el._id === appointment._id);
                const overriden = this.override[indexOfEl]
                const entry = this.diaryEntriesOfDay.filter(diaryEntry => diaryEntry.appointment._id === appointment._id);

                if (this.editNotes.includes(appointment._id)) {
                    this.editNotes.splice(this.editNotes.indexOf(appointment._id), 1);
                }
                this.expandInfo = false;
                if(!entry[0].appointment.appointmentDiaryEntryNote){
                    if(overriden.diaryNote.trim() !== '') {
                        const createdRes = await backend.postAppointmentDiaryEntryNote({
                            diaryEntry: entry[0]._id, text: overriden.diaryNote, appointment: appointment._id
                            });
                        const created = await createdRes.json();
                        this.override.splice(indexOfEl, 1);
                        return created;
                    }                   
                    this.override.splice(indexOfEl, 1);
                }
                else{
                    if(overriden.diaryNote === entry[0].appointment.appointmentDiaryEntryNote.text){
                        this.editNotes.splice(this.editNotes.indexOf(appointment._id), 1);
                        return 'nothing to update';
                                }                    
                    const updatedRes = await backend.editAppointmentDiaryEntryNote(entry[0].appointment.appointmentDiaryEntryNote._id,{text: overriden.diaryNote});
                    const updated = await updatedRes.json();

                    this.override.splice(indexOfEl, 1);

                    return updated;
                }
            }
            this.diaryReloadKey +=1;
        },
        //#endregion

        //#region notice/Bemerkung methods
        displayPupilName(pupil) {
            return pupil.name + ' ' + pupil.lastName.slice(0,1) + '.';
        },
        //#endregion

        //#region header methods
        async saveDiary(keepDiaryOpen) {
            let datum = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate(), 0, 0);

            // let body = {
            //     day: datum,
            //     groupId: this.group._id,
            //     content: this.diaryOut,
            //     override: this.override,
            // }

            // let res = await this.postDiary(body);

            // if (res.status === 201) {
                    //this.showSnackbar({ message: "Klassenbucheinträge erfolgreich gespeichert.", color: "success" });


                let createNewLateTimes = this.lateTimesWithAppointmentDrafts.filter(draft => draft.isNewLateTime);
                let updateLateTimes = this.lateTimesWithAppointmentDrafts.filter(draft => !draft.isNewLateTime);
                let createResponses = [];
                let updateResponses = [];
                if(createNewLateTimes.length > 0){
                   createResponses = await Promise.all(
                        createNewLateTimes.map(async (lateTime) => {
                            const dateStart = new Date(lateTime.day);
                            const dateEnd = new Date(lateTime.day);
                            const appointment = lateTime.appointment;
                            const missingTime = lateTime.missingTime;
                            const pupil = lateTime.pupil;
                            const note = lateTime.note;
                            const excused = lateTime.excused;
                            const isFullday = lateTime.isFullday;
                            const body = {
                                dateStart,
                                dateEnd,
                                appointment,
                                missingTime,
                                pupil,
                                note,
                                excused,
                                isFullday,
                                group: this.group._id,
                            };
                            const newLateTime = await this.postLateTime(body);
                            return newLateTime;
                        })
                    );
                }
                if(updateLateTimes.length > 0){
                    updateResponses = await Promise.all(
                        updateLateTimes.map(async (lateTime) => {
                            let lateTimeId = lateTime._id;
                            //if lateTime was created without requesting appointments new we pull it from store
                            // so we dont need to request the whole appointments again
                            if(!lateTime._id){
                                const lateTimeObj = this.getLateTimesByAppointmentId[lateTime.appointment].find((store)=> store.pupil === lateTime.pupil);
                                lateTimeId = lateTimeObj._id
                            }
                            const dateStart = new Date(lateTime.dateStart);
                            const dateEnd = new Date(lateTime.dateEnd);
                            const appointment = lateTime.appointment;
                            const missingTime = lateTime.missingTime;
                            const note = lateTime.note;
                            const excused = lateTime.excused;
                            const body = {
                                lateTimeId,
                                dateStart,
                                dateEnd,
                                appointment,
                                missingTime,
                                note,
                                isFullday: lateTime.isFullday,
                                pupil: lateTime.pupil,
                                excused,
                                group: this.group._id,
                            };
                            const updatedLateTime = await this.updateLateTime(body);
                            return updatedLateTime;
                        })
                    );
                }

                if(this.override.length > 0){
                    updateResponses = await Promise.all(
                        this.override.map(async (override) => {
                            //override appointmentDiaryEntries, if non existing, we create new one
                           const entry = this.diaryEntriesOfDay.filter(diaryEntry => diaryEntry.appointment._id === override._id);

                            if(!entry[0].appointment.appointmentDiaryEntryNote){
                                if(override.diaryNote.trim() !== '') {
                                    const createdRes = await backend.postAppointmentDiaryEntryNote({
                                        diaryEntry: entry[0]._id, text: override.diaryNote, appointment: entry[0].appointment._id
                                        });
                                    const created = await createdRes.json();
                                    return created;
                                }
                            }
                            else{
                                if(override.diaryNote === entry[0].appointment.appointmentDiaryEntryNote.text){
                                    return 'nothing to update';
                                }
                                const updatedRes = await backend.editAppointmentDiaryEntryNote(entry[0].appointment.appointmentDiaryEntryNote._id,{text: override.diaryNote});
                                const updated = await updatedRes.json();
                                return updated;
                            }
                        })
                    );
                }
                if(createResponses.length || updateResponses.length || this.editNotes.length){
                    this.showSnackbar({ message: "Änderungen erfolgreich gespeichert", color: "success" });
                    this.editNotes = [];
                    this.lateTimesWithAppointmentDrafts = [];
                }
                //this.resetDialog();
            // }

        },

        /**
         * Export diary method for multiple weeks, called from dialog
         * @param groupId of the current selected group
         * @param exportDiary
         * @param exportLateTimes
         * @param exportAppointmentDiaryHistories flag to add all histories of edited entries
         * @param startDate of export (must be monday of the selected start week)
         * @param endDate of export (must be friday of the selected start week)
         * @param returnForPreview flag to check if the document is only for preview in dialog
         * @returns {Promise<void>}
         */
        async exportMultipleDiaryWeeks(groupId, exportDiary, exportLateTimes, 
                                        exportPupilDiaryNotes, 
                                        exportAppointmentDiaryHistories,
                                        startDate = new Date().toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', }), 
                                        endDate = new Date().toLocaleDateString('de-DE', { day: 'numeric', month: 'numeric', }), 
                                        returnForPreview = false) {
            // get the correct timeslots for the group
            this.showExportingOverlay = true;
            const currentGroup = this.groupsById[groupId];
            let startString;
            if(!startDate){
                 startString = new Date(Date.now()).toUTCString();
            }else{
                startString = new Date(startDate).toUTCString();
            }
            let endString;
            if(!endDate){
                 endString = startString;
            
            }else{
                 endString = new Date(endDate).toUTCString(); 
            }
            const timeslots = currentGroup.timeslots.map(slot => {
                return this.timeslotsById[slot._id || slot]
            });
            const groupName = currentGroup ? currentGroup.name : '';

            const sortedDiaryEntries = {};
            const neededDates = [];
            const weeks = [[]];

            // if we want the diary entries
            let weekIndex = 0;
            // fill needed dates for backend requests
            const currentDate = startDate;
            while (currentDate <= endDate) {
                neededDates.push(currentDate.toUTCString());
                sortedDiaryEntries[`${currentDate.toUTCString()}`] = {};
                weeks[weekIndex].push(currentDate.toUTCString());
                // dont count weekends
                if (currentDate.getDay() === 5 && currentDate.toUTCString() !== endDate.toUTCString()) {
                    weekIndex++;
                    weeks[weekIndex] = [];
                }
                // update current date
                do {
                    currentDate.setDate(new Date(currentDate).getDate() + 1);
                } while (currentDate.getDay() === 6 || currentDate.getDay() === 0)
            }
            let entriesWithHistories = []
            if (exportDiary) {
                // get the current diary entry
              await this.getAllLateTimesForGroup({groupId:groupId});

                await neededDates.reduce(async (previousPromise, currentDate) => {
                    const previous = await previousPromise;
                    const entries = await this.requestDiaryForDate(currentDate, groupId);
                    if(exportAppointmentDiaryHistories){
                        const editedAppointmentDiaryEntries = entries.filter(
                            entry => entry.appointment.appointmentDiaryEntryNote?.editHistory?.length > 0
                        )
                        if(editedAppointmentDiaryEntries.length >0){
                            entriesWithHistories.push(editedAppointmentDiaryEntries.map(entry=> entry.appointment));
                        }
                    }
                    previous.push(entries);
                    sortedDiaryEntries[`${currentDate}`] = entries;
                    return previous;
                }, Promise.resolve([]));
            }

            // if we want the lateTime entries
            let pupilLateTimes = [];
            if (exportLateTimes) {
                // get Late times for pupils
                pupilLateTimes = await currentGroup.participants.reduce(async (previousPromise, pupil) => {
                    const previous = await previousPromise;
                    const entry = {
                        _id: pupil._id,
                        name: `${pupil.name} ${pupil.lastName}`,
                        missingDays: { excused: 0, unexcused: 0 },
                        missingTime: { excused: 0, unexcused: 0 },
                        missingTimeString: { excused: '', unexcused: '' }
                    }

                    const localLateTimes = await this.getPupilLateTimesForDates({
                        pupilId: pupil._id,
                        startDate: startString,
                        endDate: endString,
                        deleted: 'false',
                    });
                    localLateTimes.forEach(lateTime => {
                        const excuseKey = `${!lateTime.excused ? 'un' : ''}excused`;
                        entry.missingTime[excuseKey] = entry.missingTime[excuseKey] + lateTime.missingTime;
                        entry.missingDays[excuseKey] = entry.missingDays[excuseKey] + lateTime.missingDays;
                    });
                    // calculate missing time excused string
                    const hoursExcused = Math.floor(entry.missingTime.excused/60);
                    const minutesExcused = entry.missingTime.excused % 60;
                    entry.missingTimeString.excused = `${hoursExcused < 10 ? '0': ''}${hoursExcused}:${minutesExcused < 10 ? '0': ''}${minutesExcused}`;

                    // calculate missing time NOT excused string
                    const hoursUnexcused = Math.floor(entry.missingTime.unexcused/60);
                    const minutesUnexcused = entry.missingTime.unexcused % 60;
                    entry.missingTimeString.unexcused = `${hoursUnexcused < 10 ? '0': ''}${hoursUnexcused}:${minutesUnexcused < 10 ? '0': ''}${minutesUnexcused}`;

                    previous.push(entry);

                    return previous;
                }, Promise.resolve([]));
                pupilLateTimes.sort((a, b) => { return a.name.localeCompare(b.name) });
            }
            let pupilsWithDiaryNotes = [];
            if(exportPupilDiaryNotes){
                const result = [];
               Object.keys(sortedDiaryEntries).forEach((date) => {
                const appointments = sortedDiaryEntries[date];
                    if(Object.keys(appointments).length !== 0){
                        appointments.forEach((appointment) => {
                        appointment.pupils.forEach((pupil) => {
                            if (pupil.diaryNote !== "") {
                                // Merge pupil with appointment and add to the result
                                const mergedPupil = {
                                ...pupil,
                                appointment: appointment.appointment,
                                };
                                result.push(mergedPupil);
                            }
                        });
                    });
                    }
                    
                });
                if(result.length >0){
                    pupilsWithDiaryNotes = result.reduce((acc, item) => {
                                    const { _id } = item;
                                    if (!acc[_id]) {
                                        acc[_id] = [];
                                    }
                                    acc[_id].push(item);
                                    return acc;
                                    }, {});

                }
                
                

            }
            // call export in util to compute pdf bytearray
            try{
            const pdfBytes = await exports.exportMultipleDiaryWeeks({
                weeks,
                sortedDiaryEntries,
                timeslots,
                groupName,
                pupilLateTimes,
                exportDiary, exportLateTimes,
                exportPupilDiaryNotes,
                exportAppointmentDiaryHistories,
                pupilsWithDiaryNotes,
                entriesWithHistories,
            });

            // download created pdf
            let blob = new Blob([pdfBytes], { type: "application/pdf" });
            let link = document.createElement("a");
            // link.href = window.URL.createObjectURL(blob);
            let calendarWeeksInName = `KW${new Date(weeks[0][0]).getWeek()}${weeks.length > 1 ? '-'+new Date(weeks[weeks.length-1][0]).getWeek():''}`;
            
                const download = URL.createObjectURL(blob);
                link.setAttribute('download', `Klassenbuch ${groupName} ${calendarWeeksInName}.pdf`);
                link.href = download;
                document.body.appendChild(link);
                this.showExportingOverlay = false;
                link.click();
                setTimeout(function () {
                link.remove();
                    window.URL.revokeObjectURL(download);
                }, 1000);

            this.$nextTick(() => {
               this.$refs.diaryExportPopup.finishExportSuccessfully();
            });
            }catch(err){
                console.error(err);
                this.showExportingOverlay = false;
                this.showSnackbar({ message: "Beim erstellen des Exports ist etwas schiefgelaufen. Laden Sie die Seite Neu und versuchen sie es erneut, oder melden dies beim Eklara Support!", color: "error", timeout: 15000 });
            }
        },

        /**
         * Extra method for on exporting latetimes of pupil
         * @param absenceListPupil pupil to export the latetimes for
         * @param start start date of latetimes
         * @param end end date of latetimes
         * @returns {Promise<void>}
         */
        async exportPupilLateTimesForDates(absenceListPupil, start, end) {
            const startString = start.toUTCString();
            const endString = end.toUTCString();

            const lateTimes = await this.getPupilLateTimesForDates({
                pupilId: absenceListPupil._id,
                startDate: startString,
                endDate: endString,
                deleted: 'false',
            });
            lateTimes.sort((a, b) => { return a.dateStart.localeCompare(b.dateStart) });

            // call export in util to compute pdf bytearray
            const pdfBytes = await exports.exportLateTimesForPupil({
                pupil: absenceListPupil,
                startDate: start,
                endDate: end,
                lateTimes
            });

            // download created pdf
            let blob = new Blob([pdfBytes], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Fehlzeiten ${absenceListPupil.lastName} ${absenceListPupil.name}.pdf`;
            link.click();

            // in teh eeeeeeeeeeeeeeend
            this.$nextTick(() => {
                this.$refs.pupilLateTimesExportPopup.finishExportSuccessfully();
            });
        },

        async exportDiaryWeek(groupId, givenDate = this.date) {
            // get dates open week
            const currentWeekDay = new Date(givenDate).getDay();
            const sortedDiaryEntries = {};
            const neededDates = [];
            for (let i = 1; i < 6; i++) {
                // Todo dirty hack, need to fix the date problem
                const weekDayDate = new Date(givenDate.getFullYear(), givenDate.getMonth(), givenDate.getDate(), 3, 0, 0, 0);
                weekDayDate.setDate(new Date(givenDate).getDate() + (i - currentWeekDay));
                neededDates.push(weekDayDate.toUTCString());
                sortedDiaryEntries[`${weekDayDate.toUTCString()}`] = {};
            }

            // basically get the current diary entry
            await neededDates.reduce(async (previousPromise, currentDate) => {
                const previous = await previousPromise;
                const entry = await this.requestDiaryForDate(currentDate, groupId);
                previous.push(entry);
                sortedDiaryEntries[`${currentDate}`] = entry;
                return previous;
            }, Promise.resolve([]));
            // get the correct timeslots for the group
            const currentGroup = this.groupsById[groupId];
            const timeslots = currentGroup.timeslots.map(slot => {
                return this.timeslotsById[slot._id || slot]
            });
            // push all of them into a util for export
            const groupName = currentGroup ? currentGroup.name : '';
            const fileToDownload = await exports.diaryWeekExport(sortedDiaryEntries, timeslots, groupName);

            // download created pdf
            let blob = new Blob([fileToDownload], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `Klassenbuch KW${new Date(givenDate).getWeek()} ${groupName}.pdf`;
            link.click();
        },

        //only allow weekdays to be selected in date picker
        weekdaysOnly(val) {
            let date = new Date(val);
            //saturday is weekday 0, sunday is weekday 6, so monday-friday is 1-5
            return date.getDay() > 0 && date.getDay() < 6;
        },

        async subDay() {
            await this.saveDiary(true);
            //await this.requestAppointments();
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-1);
            while (!(this.date.getDay() % 6)) {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()-1);
            }
        },

        async addDay() {
            await this.saveDiary(true);
            //await this.requestAppointments();
            this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            while (!(this.date.getDay() % 6)) {
                this.date = new Date(this.date.getFullYear(), this.date.getMonth(), this.date.getDate()+1);
            }
        },
        //#endregion

        // Utility Methods for
   
        async openCurrentAppointmentLateTimes(firstOpen){
            if(firstOpen){
                this.requestAppointments(true);
            }
            if(this.isGroupLeader && this.accountRole === 'teacher'){
                const running = await this.isCurrentlyRunningAppointment();
                if(running){
                    this.openLateDialog(this.diaryEntriesOfDay.indexOf(running), running, firstOpen)
                }
            }
        },
        async openDiaryEntry(event) {
            if(this.usePin){
                if(this.accountPinIsValid){
                    event.stopPropagation();
                    this.selectedDiaryEntry = true;
                    this.rooms = await this.getRooms();
                    this.substitutions = await this.getSubstitutions();
                    await this.openCurrentAppointmentLateTimes(true);
                }else{
                    this.openPinDialog();
                }
            }else{
                 event.stopPropagation();
                this.selectedDiaryEntry = true;
                this.rooms = await this.getRooms();
                this.substitutions = await this.getSubstitutions();
                await this.openCurrentAppointmentLateTimes();
            }
        },

        async requestProfilePictures(user) {
            if (user.profilePicture) {
                // const profilePictureBlob = await this.getProfilePicture(user._id);
                // return URL.createObjectURL(profilePictureBlob);
                console.log('picture temporarily disabled');
            } else {
                // return profile icon or take standard icon
                const teacherAcc = this.accountsById[user.account];
                const iconObject = {
                    color: teacherAcc && teacherAcc.profileColor ? teacherAcc.profileColor : '#ff6633',
                    icon: teacherAcc && teacherAcc.profilePicture ? teacherAcc.profilePicture : 'mdi-emoticon-happy-outline'
                }
                return iconObject;
            }
        },

        async loadTeacherImage() {
            if (this.group.leaders[0].profilePicture) {
                //todo: fix timing issue since teacher wont load correctly
                if (this.groups) {
                    this.teacherImage = this.group.profilePicture;
                }
            }
        },

        getUserIcon(userObj){
            let user = userObj;
            // return profile icon or take standard icon
            if(typeof userObj === 'string'){
                user = this.accountsById[userObj];
            }
            const userAcc = this.accountsById[user.account];
            const iconObject = { color: userAcc.profileColor ? userAcc.profileColor : '#ff6633',
                icon: userAcc.profilePicture && userAcc.profilePicture.img ? userAcc.profilePicture.img
                    : userAcc.profilePicture && userAcc.profilePicture.text ? 'mdi-'+ userAcc.profilePicture.text
                        : 'mdi-emoticon-happy-outline' }
            return iconObject;
        },

        closeDiary() {
            //this.confirmCloseDialog = true;
            if(this.lateDialog){
                this.closePersonalAbsence();
            }
            this.saveDiary(true);
            this.resetDialog();
        },

        excusedForAppointment(pupil, appointment) {
            let excused = false;
            const appointmentsLateTimes = this.getLateTimesByAppointmentId[appointment];
            if(appointmentsLateTimes){
                const lateTime = appointmentsLateTimes.find((late) => late.pupil === pupil);
                excused = lateTime && lateTime.excused;
            }
            return excused;
        },

        updateSeenBy(pupil) {
            pupil.seenBy = [];
            pupil.seenBy.push(this.account._id);
        },

        async displayAbsence() {   
            if(!this.isGroupLeader && !(this.group && this.teacher && this.group.leaders.includes(this.teacher._id)) ){
                this.showSnackbar({message:'Sie sind nicht der/die LeiterIn dieser Gruppe, und haben daher keinen Zugang zur vollständigen Fehlzeitenübersicht.', color:'error', timeout:'10000'});
                return
            }
            this.showAbsence = !this.showAbsence;
            if (this.showAbsence) {
                this.noDataText = "Keine Daten verfügbar"
                await this.saveDiary(true);
            } else {
                this.noDataText = "Sie sind nicht der/die LeiterIn einer Gruppe."
            }
            //this.mainDialogKey += 1;
        },

        showMissingTimeTooltip(pupil){
            if(pupil.lateTime.isFullday){
                const days = pupil.lateTime.missingDays;
                let dateStart = '';
                if(days && days > 1){
                    dateStart = `für ${days} Tage ab ${new Date(pupil.lateTime.dateStart).toLocaleDateString('de-DE', {
                                month: '2-digit',
                                day: '2-digit',
                            })} `
                }
                return `${pupil.name} Fehlzeit: ganztägig ${dateStart}Notiz: ${pupil.lateTime.note.slice(0, 25)}`
            }else{
                return `${pupil.name} Fehlzeit: ${this.absenceInDHM(pupil.lateTime.missingTime).hours} ${this.absenceInDHM(pupil.lateTime.missingTime).minutes} Notiz: ${pupil.lateTime.note.slice(0, 25)}`
            }
        },
        absenceInDHM(totalMinutes) {
            const hours = Math.floor(totalMinutes / 60);
            const minutes = totalMinutes % 60;

            // Formatting hours
            const hourWording = hours > 0 ? `${hours} Std.` : '';
            // Formatting minutes
            const minuteWording = minutes > 0 ? `${minutes} Min.` : '';

            return { 
                hours: hourWording.trim(), // trim to remove any extra spaces
                minutes: minuteWording.trim() // trim to remove any extra spaces
            };
        },
        toggleExpand(item) {
        if (item.isGroup) {
            this.$set(item, 'expanded', !item.expanded);
        }
        },
        transformLateTimeObjects(lateTimeObjects) {
             const groupedLateTimes = [];
            const groupedMap = new Map();

            lateTimeObjects.forEach((item) => {
                // Group by full-day absences with the same start and end date
                if (item.isFullday) {
                    const groupKey = `${item.dateStart}-${item.dateEnd}-${item.pupil}-${item.group}`;
                    if (!groupedMap.has(groupKey)) {
                        // Initialize the group item using the first occurrence values, 
                        // when editing, it is ensured in backend that if is fullDay is changed then it handles the rest
                        groupedMap.set(groupKey, {
                            ...item,
                            missingTime: 0,
                            missingDays: 1, // Set missing days to 1 since it’s full-day
                            note: item.note, // Take the first note
                            children: [], // To hold individual time slots for expansion
                            isGroup: true,
                            expanded: false // For expanded view in table
                        });
                    }
                    // Push the individual full-day item into children array for later expansion
                    groupedMap.get(groupKey).children.push(item);
                } else {
                    // Directly add non-full-day items to the groupedLateTimes array
                    groupedLateTimes.push({
                        ...item,
                        isGroup: false,
                        expanded: false
                    });
                }
            });

            // Add grouped full-day items to final array
            groupedMap.forEach((groupedItem) => {
                groupedLateTimes.push(groupedItem);
            });

            return groupedLateTimes;
        },
        async displayAbsenceList(pupilWithLateTime) {
            this.showAbsenceLoading = true;
            this.absenceListDialog = true;
            const list = await this.getPupilLateTimes(pupilWithLateTime.pupil._id);
            const updatedList = this.transformLateTimeObjects(list);
            this.absenceListPupil = pupilWithLateTime.pupil;
            const temp = [...updatedList];
            this.personalAbsenceData = temp.sort((a, b) => b.dateStart.localeCompare(a.dateStart))
            this.showAbsenceLoading = false;
        //     const res = await backend.getPupilsLateTimes(pupil._id);
        //     const newAbsence = await this.getPupilLateTimes(pupil._id);

        //     const absenceData = await res.json();
        //     this.personalAbsenceData = absenceData.sort((a, b) => new Date(b.date) - new Date(a.date));
        //    // data for absenceList per pupil
        //    const finalAbsenceData = newAbsence.map((data) => {
        //         const time = (new Date(data.timeEnd).getTime() - new Date(data.timeStart).getTime()) / 60000;
        //                 return {
        //                     dateStart: new Date(data.dateStart).toLocaleDateString('de-DE'),
        //                     dateEnd: new Date(data.dateStart).toLocaleDateString('de-DE'),
        //                     time: time,
        //                     excused: data.excused,
        //                     note: data.note ? data.note : '',
        //                 }
        //     })

        //    this.personalAbsenceData = finalAbsenceData.sort((a, b) => b.dateStart.localeCompare(a.dateStart));

        },
        calculateAbsentDays(date1, date2) {
            const oneDay = 1000 * 60 * 60 * 24; // milliseconds in one day

            let diffDays = 0;
            let currentDate = new Date(date1);

            while (currentDate <= date2) {
                const dayOfWeek = currentDate.getDay();
                if (dayOfWeek !== 0 && dayOfWeek !== 6) { // skip weekends
                diffDays++;
                }
                currentDate = new Date(currentDate.getTime() + oneDay);
            }

            return diffDays;
        },
        async saveAbsence(){
            if(this.absenceDateFrom && this.absenceDateTill){
                const missingTime = this.newAbsenceMinutes ? this.newAbsenceMinutes : 0;
                const dateStart = new Date(this.absenceDateFrom);
                const dateEnd = new Date(this.absenceDateTill);
                const pupilId = this.absenceListPupil._id;
                const note = this.newAbsenceNote;
                const missingDays = this.newAbsenceMinutes ? 0 : this.calculateAbsentDays(dateStart,dateEnd)
                
                const fullPupil = this.pupilsById[pupilId]
                const fullAccount = this.accountsById[fullPupil.account];
                
                const groups = fullAccount.groups.map((group) => this.groupsById[group]);
                let startOfDay = new Date(dateStart.getFullYear(), dateStart.getMonth(), dateStart.getDate(), 0, 0, 0, 0);
                
                //just take first group of pupil, normally should only have one, otherwise it gets too complicated --> blame the teacher
                const appRes = await backend.getGroupsAppointmentsOnDay(groups[0]._id, startOfDay)
                const appointmentsOnDay = await appRes.json();
                const sortedAppointments = appointmentsOnDay.sort((a, b) => (new Date(a.timeslot.start).getHours() * 60 + new Date(a.timeslot.start).getMinutes())
                            - (new Date(b.timeslot.start).getHours() * 60 + new Date(b.timeslot.start).getMinutes()));

                //edit Absence

                if(this.editingAbsence){
                    const data = {
                        lateTimeId: this.editAbsenceItem._id,
                        dateStart: dateStart,
                        dateEnd: dateEnd,
                        pupil: pupilId,
                        group: this.group._id,
                        note: note,
                        excused: this.newAbsenceExcused,
                        isFullday: this.newAbsenceIsFullday,
                        missingDays: missingDays,
                        missingTime: missingTime,
                        appointment: this.editAbsenceItem.appointment,
                    }
                    if(note === this.editAbsenceItem.note && this.newAbsenceMinutes === (new Date(this.editAbsenceItem.timeEnd).getTime() -
                        new Date(this.editAbsenceItem.timeStart).getTime()) / (1000 * 60) && this.newAbsenceExcused === this.editAbsenceItem.excused){

                            this.resetNewAbsence();
                        return
                    }
                    else{
                        if(this.editAbsenceItem?.sicknote && this.editAbsenceItem?.sicknote !== undefined) {
                            const updatedSickNote = await this.toggleSickNote(this.editAbsenceItem.sicknote);
                            this.personalAbsenceEdited = true;
                        }
                        const res = await this.updateLateTime(data);

                            if(res){
                                this.showSnackbar({ message: "Fehlzeit erfolgreich bearbeitet", color: "success" });
                                this.personalAbsenceEdited = true;
                                 this.showAbsenceLoading = true;
                                this.absenceListDialog = true;
                                const list = await this.getPupilLateTimes(this.absenceListPupil._id);
                                const updatedList = this.transformLateTimeObjects(list);
                                const temp = [...updatedList];
                                this.personalAbsenceData = temp.sort((a, b) => b.dateStart.localeCompare(a.dateStart))
                                this.showAbsenceLoading = false;
                                //push new created late Time into list so it is displayed instantly
                                // if(this.personalAbsenceData === undefined){
                                //     this.personalAbsenceData = [];
                                // }
                                // const index = this.personalAbsenceData.findIndex((item) => item._id === this.editAbsenceItem._id);
                                // this.personalAbsenceData.splice(index, 1 ,{...res, missingTime: this.newAbsenceMinutes });


                            }
                            this.currentEditIndex = null;
                    }
                }
                //createNewAbsence
                else{
                    let data = {
                    dateStart: dateStart,
                    dateEnd: dateEnd,
                    pupil: pupilId,
                    group: this.group._id,
                    note: note,
                    excused: this.newAbsenceExcused,
                    isFullday: this.newAbsenceIsFullday,
                    missingDays: missingDays,
                    missingTime: missingTime,
                }
                 if(missingDays > 0 && missingTime === 0 && sortedAppointments){
                    data.appointment = sortedAppointments[0]._id;
                    data.isFullday = true;
                 }
                    const res = await this.postLateTime(data);
                    if(res){
                        this.showSnackbar({ message: "Fehlzeit erfolgreich eingetragen", color: "success" });
                        //push new created late Time into list so it is displayed instantly
                        // if(this.personalAbsenceData === undefined){
                        //     this.personalAbsenceData = [];
                        // }
                        // this.personalAbsenceData.push({...res, missingTime: this.newAbsenceMinutes, });
                        //reload Absences

                    }
                }
                this.personalAbsenceEdited = true;
                this.resetNewAbsence();
                this.forceRerender(1);//forces dialog to rerender to show changes
            }
            else if (!this.absenceDateFrom) {
                this.showSnackbar({ message: "Feld 'Datum von' darf nicht leer sein!", color: "error" });
            } else if (!this.absenceDateTill) {
                this.showSnackbar({ message: "Feld 'Datum bis' darf nicht leer sein!", color: "error" });
            } else if (!this.newAbsenceMinutes) {
                this.showSnackbar({ message: "Feld 'Fehlzeit' darf nicht leer sein!", color: "error" });
            }


        },
        onRowClick(absenceData) {
            if(!this.editingAbsence){
                const index = this.selectedAbsenceListItems.findIndex(
                    (item) => item.index === absenceData.index
                );
                if (index === -1) {
                    this.selectedAbsenceListItems.push(absenceData);
                } else {
                    this.selectedAbsenceListItems.splice(index, 1);
                }
            }
        },

        isSelected(absenceData) {
            return this.selectedAbsenceListItems.some((item) => item.index === absenceData.index);
        },
        closePersonalAbsence(){
            if(this.createNewAbsence || this.editingAbsence){
                this.saveAbsence()
            }
            this.absenceListDialog = false;
            this.personalAbsenceData = [];
            this.createNewAbsence = false;
            this.selectedAbsenceListItems = [];
            this.editAbsenceItem = null;
            this.edititngAbsence = false;
            this.selectToDelete = false;
            this.resetNewAbsence();
            this.currentEditIndex = null;
            this.mainDialogKey +=1;
        },
        clickDeleteButton(){
            this.selectToDelete = true;
        },
        deleteSelected(){
            this.deleteDialog = true;
        },
        async deleteSelectedAbsences(){
            const absencesToDelete = this.selectedAbsenceListItems.map(row => {
                return row.item._id;
            })
            const deleteResponses = await Promise.all(absencesToDelete.map(async (id) =>{
                const res = await this.deleteLateTime(id);
                    if(res){
                        return res;
                    }
                }
            ));
            if(deleteResponses.length !== absencesToDelete.length){
                this.showSnackbar({ message: "Beim Löschen ist ein fehler aufgetreten. Bitte laden Sie die Seite neu und versuchen Sie es erneut.",
                                        color: "error" });
            }else{
                this.showSnackbar({ message: "Fehlzeiten erfolgreich gelöscht",
                                        color: "success" });
            }
            // this.$store.dispatch("lateTimes/getLateTimes");
            this.$store.dispatch("lateTimes/getLateTimesForGroupOnDay",{groupId: this.group._id, day: this.date})
            this.selectedAbsenceListItems = [];
            this.deleteDialog = false;
            this.selectToDelete = false;
        },
        async editClickedAbsence(absenceData){
            //if createMode is active reset this first, since creating and editing at the same time isnt possible
            if(this.createNewAbsence){
                this.resetNewAbsence();
            }
            //if new row is clicked, save the previously edited Row first
            if (this.editAbsenceItem && this.currentEditIndex !== absenceData.index) {
                await this.saveAbsence();
            }
            this.currentEditIndex = absenceData.index;

            //initialize the item that will be editetd, by reusing the fields from creating a new Absence
            this.editingAbsence = true;
            this.editAbsenceItem = JSON.parse(JSON.stringify(absenceData.item));
            this.absenceDateFrom = this.editAbsenceItem.dateStart;
            this.absenceDateTill = this.editAbsenceItem.dateEnd;
            if(absenceData.item.isFullday){
                this.newAbsenceMinutes = this.editAbsenceItem.children.reduce((sum, child) => sum + (child.missingTime || 0), 0);
            }else{
                this.newAbsenceMinutes = this.editAbsenceItem.missingTime;
            }
            this.newAbsenceExcused = this.editAbsenceItem.excused;
            this.newAbsenceIsFullday = this.editAbsenceItem.isFullday;
            this.newAbsenceUnexcused = !this.editAbsenceItem.excused;
            this.newAbsenceNote = this.editAbsenceItem.note;

        },
        resetNewAbsence(){
            this.absenceDateFrom = null;
            this.absenceDateTill = null;
            this.newAbsenceMinutes = null;
            this.newAbsenceNote = '';
            this.formattedAbsenceDateFrom = null;
            this.formattedAbsenceDateTill = null;
            this.createNewAbsence = false;
            this.newAbsenceExcused = false;
            this.newAbsenceUnexcused = true;
            this.editAbsenceItem = null;
            this.editingAbsence = false;
            this.selectedAbsenceListItems = [];
        },

        getTimeslotTimes(timeslot) {
            if (timeslot) {
                return (
                    ("0" + new Date(timeslot.start).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.start).getMinutes()).slice(-2)
                    + ' - ' +
                    ("0" + new Date(timeslot.end).getHours()).slice(-2) + ":" + ("0" + new Date(timeslot.end).getMinutes()).slice(-2));
            }
        },

        getRoomNames(roomIds) {
            if (roomIds && roomIds.length > 0) {
                const rooms = this.rooms.filter((el) => roomIds.includes(el._id));
                const roomNames = rooms.map((room) => {return room.roomName});
                return roomNames.join(', ');
            }
            return '';
        },

        getSubstitutionTeacher(appointment) {
            if (appointment.substitutions) {
                const substitutions = this.substitutions?.filter((substitution) => appointment?.substitutions?.includes(substitution._id));
                if (substitutions && substitutions.length) {
                    if (substitutions[0].sickTeacher) {
                        if (substitutions && substitutions.some((el) => el.sickTeacher && el.substitutionTeacher)) {
                            return substitutions.find((el) => el.sickTeacher && el.substitutionTeacher).substitutionTeacher;
                        }
                    }
                }
            }
            return null;
        },

        hasSickTeacherButNoSubstitution(appointment) {
            const substitutions = this.substitutions?.filter((substitution) => appointment?.substitutions?.includes(substitution._id));
            if(substitutions && substitutions.length) {
                return substitutions[0].sickTeacher && !substitutions[0].substitutionTeacher && !substitutions[0].seenByMaintainer;
            }
            return false;
        },

        getTeacherName(teacherObj){
            let teacher = teacherObj;
                if(typeof teacherObj === 'string'){
                    teacher = this.teachersById[teacherObj];
                }
            return teacher.name[0] + '.' + teacher.lastName;
        },

        getSickTeacher(appointment) {
            if (appointment.substitutions) {
                const substitutions = this.substitutions?.filter((substitution) => appointment?.substitutions?.includes(substitution._id));
                if (substitutions.length) {
                    if (substitutions[0].sickTeacher) {
                        return substitutions[0].sickTeacher;
                    }
                }
            }
            return null;
        },

        forceRerender(child) {
            if (child === 1) {
                this.tableKey += 1;
            } else if( child === 2) {
                this.pupilRefreshKey += 1;
            }

        }

    }
}
</script>

<style lang="scss">
.excusedLateTimeBadgeDiary .v-badge__badge {
  height: 17px !important;
  min-width: 17px !important;
}


@media only screen and (orientation: landscape) {
    .diaryClassVAutocomplete .mdi-menu-down::before {
        font-size: 4vh !important;
    }
}

@media only screen and (orientation: portrait) {
    .diaryClassVAutocomplete .mdi-menu-down::before {
        font-size: 4vw !important;
    }
}

</style>

<style lang="scss" scoped>
.buttonDisabled {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
    opacity:0.4;
}
#headerBar {
    color: white;
    background-color: rgb(var(--v-theme-dunkelgruen));
}
.v-text-field ::placeholder {
  font-weight: normal;
}
//#region style v-data-table
.v-data-table:deep(th) {
    font-size: 16px !important;
}

// add border between columns
.personalAbsenceTable .v-data-table:deep(th:nth-child(n+3)),
.personalAbsenceTable .v-data-table:deep(td:nth-child(n+3)),
.absenceListTable .v-data-table:deep(th:nth-child(n+3)),
.absenceListTable .v-data-table:deep(td:nth-child(n+3)) {
  border-left: 1px solid #dddddd;
}
.diaryNoteTable .v-data-table:deep(th:not(:first-child)),
.diaryNoteTable .v-data-table:deep(td:not(:first-child)),
.diaryTable .v-data-table:deep(th:not(:first-child)),
.diaryTable .v-data-table:deep(td:not(:first-child)) {
  border-left: 1px solid #dddddd;
}
.diaryTable .v-data-table::v-deep th{
vertical-align: bottom !important;
padding-bottom: 1px;
padding-top: 5px;
font-size: 16px !important;
}
// remove border between rows
.v-data-table td {
    border-bottom: none !important;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    padding: 0 8px !important;
}
.personalAbsenceTable .v-data-table::v-deep th:nth-child(7),
.absenceListTable .v-data-table::v-deep th:nth-child(5),
.diaryNoteTable .v-data-table::v-deep th:nth-child(4) {
    color: #f44336 !important;
}

.personalAbsenceTable .v-data-table::v-deep th:nth-child(6),
.absenceListTable .v-data-table::v-deep th:nth-child(4),
.diaryNoteTable .v-data-table::v-deep th:nth-child(3) {
    color: #3caa69 !important;
}
.absenceListTable .v-data-table:deep(th) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
.personalAbsenceTable .v-data-table:deep(th) {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
}
//#endregion

//dialog border radius
.v-dialog > .v-card {
border-radius: 8px !important;
}
.v-dialog{
   border-radius: 8px !important;
}
.sixteenPx {
    font-size: 16px;
}

.iconSelect {
    padding: 2px;
    border-radius: 5px;
}

.pointer {
    cursor: pointer;
}

.icon {
    max-height: 20px;
    height: 20px;
}

//filter generated with https://codepen.io/sosuke/pen/Pjoqqp
.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.redText {
    color: rgb(var(--v-theme-rot));
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tableHeaders {
    font-weight: bold;
}

.tableHeaderCol {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonFocusHover:hover .imgFocusHover {
    filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg) brightness(98%) contrast(91%) !important;
}

.fileWidgetButton {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    background-color: transparent;
    right: 5%;
    pointer-events: all;
}

.dialogChild {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: 0.5rem !important;
    padding-right: 0.5rem !important;
    padding-bottom: 0!important;
    overflow-y: auto;
    max-height: 75vh; /* set the maximum height for the dialog body */
}
@media only screen and (max-width: 600px) {
    .absenceListTitle{
        max-width: 45vw !important;
    }
}
.absenceListTitle{
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 70vw;
    }
.dialogHeader{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    padding: 0.25rem !important;
    height: 50px;
}

.tableContainer {
    max-width: 100%;
    overflow-x: auto;
}
.optionsIcon {
    height: 40px;
    margin: 5px;
    cursor: pointer;
}

@media only screen and (max-width: 1050px) {
    .responsiveDatepicker {
        font-size: 14px;
    }
}
.table {
    border-collapse: collapse;
}

.table th, .table td {
    border: 1px solid #ddd;
    padding: .25rem;
}

.table th {
    padding-top: .25rem;
    padding-bottom: .25rem;
    text-align: left;
    background-color: #f2f2f2;
}
.teacherProfilePicture {
    overflow: hidden;
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    margin: 5%;
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

.appointmentItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    color: rgb(var(--v-theme-dunkelgrau));
    border-radius: 12px;
    box-shadow: 1px 2px 3px silver;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    background-color: #FFF;
    width: 100%;
    height: 70%;
    max-height: 55px;
}

.fachView{
    animation: 20s autoplay2 infinite ease-in-out;
}

#carouselItem1 {   animation-delay: 0s }
#carouselItem2 {  animation-delay: 10s }

.carouselItem {
    width: 100%;
    overflow: hidden;
    position: absolute;
    top: 100%;
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 0.7);
    box-shadow: 1px 2px 3px silver;
    margin: 5%;
}

.numberStyle {
    color: white;
    display: flex;
    width: 100%;
    justify-content: space-around;
    align-content: center;
    align-self: center;
}

@keyframes autoplay {
  0% {top: 100%}
  5% {top: 0%}
  30% {top: 0%}
  45% {top: -100%}
  100% {top: -100%}
}
@keyframes autoplay2 {
  0% {top: 100%}
  5% {top: 0%}
  45% {top: 0%}
  55% {top: -100%}
  100% {top: -100%}
}

.fachTextContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-left: 8px;
    padding: 6px 0;
}

.iconToSick {
    filter: brightness(0) saturate(100%) invert(32%) sepia(94%) saturate(6875%) hue-rotate(352deg) brightness(110%) contrast(87%);
}

.iconToSuccessGreen {
    filter: brightness(0) saturate(100%) invert(51%) sepia(64%) saturate(530%) hue-rotate(68deg) brightness(93%) contrast(89%);
}

.roomEllipsis {
    text-overflow: ellipsis;
    width: 70px;
    /* Required for text-overflow to do anything */
    white-space: nowrap;
    overflow: hidden;
}

@media only screen and (orientation: landscape) {
    .diarySize {
        height: 85vh;
        width: 60vh;
    }
    .fontSize {
        font-size: 6vh;
    }
    .fontSizeTeacher {
        font-size: 4.5vh;
    }
    .closeBtnSize {
        height: 5vh;
    }
    .faenchenSize {
        height: 12vh;
    }
    .openBookSize {
        height: 50vh;
        width: 5vh;
    }
    .openBookBtnSize {
        height: 5vh;
    }
    .classDateSize {
        height: 18vh;
        width: 35vh;
    }
    .classSize {
        width: 28vh;
        max-height: 8vh;
    }
    .smallerFontSize {
        font-size: 2.5vh;
    }
    .dateArrowSize {
        height: 2.5vh;
    }
    .lineSize {
        width: 28vh;
    }
    .teacherNameSize {
        font-size: 3.5vh;
    }
    .responsiveMargin {
        margin-left: 2vh;
    }
    @media only screen and (max-height: 955px) {
        .openBookBtnSize {
            margin-left: -1px !important;
        }
    }
}

@media only screen and (orientation: portrait) {
    .diarySize {
        height: 85vw;
        width: 60vw;
    }
    .fontSize {
        font-size: 6vw;
    }
    .fontSizeTeacher {
        font-size: 4.5vw;
    }
    .closeBtnSize {
        height: 5vw;
    }
    .faenchenSize {
        height: 12vw;
    }
    .openBookSize {
        height: 50vw;
        width: 5vw;
    }
    .openBookBtnSize {
        height: 5vw;
    }
    .classDateSize {
        height: 18vw;
        width: 35vw;
    }
    .classSize {
        width: 20vw;
        max-height: 8vw;
    }
    .smallerFontSize {
        font-size: 2.5vw;
    }
    .dateArrowSize {
        height: 2.5vw;
    }
    .lineSize {
        width: 28vw;
    }
    .teacherNameSize {
        font-size: 3.5vw;
    }
    .responsiveMargin {
        margin-left: 2vw;
    }
    .deletedTableRow {
        background-color: red !important;

    }
    @media only screen and (max-width: 810px) {
        .openBookBtnSize {
            margin-left: -1px !important;
        }
    }
    @media only screen and (max-width: 695px) {
        .faenchenSize {
            height: 10vw;
        }
    }
    @media only screen and (max-width: 1085px) {
        .diarySize {
            height: 105vw;
            width: 75vw;
        }
        .fontSize {
            font-size: 8vw;
        }
        .fontSizeTeacher {
            font-size: 6.5vw;
        }
        .closeBtnSize {
            height: 7vw;
        }
        .faenchenSize {
            height: 14vw;
        }
        .openBookSize {
            height: 52vw;
            width: 7vw;
        }
        .openBookBtnSize {
            height: 7vw;
        }
        .classDateSize {
            height: 20vw;
            width: 37vw;
        }
        .classSize {
            width: 22vw;
            max-height: 10vw;
        }
        .smallerFontSize {
            font-size: 3.2vw;
        }
        .dateArrowSize {
            height: 3.5vw;
        }
        .lineSize {
            width: 30vw;
        }
        .teacherNameSize {
            font-size: 5.5vw;
        }
        .responsiveMargin {
            margin-left: 4vw;
        }
    }
}
.datePickerFont{
    font-size: calc(1vw + 7px);
    align-content: center;
}
@media only screen and (max-width: 370px) {
    .datePickerFont{
    font-size: 9px;
    align-content: center;
}
}
.noAnimationItem{
    width: 100%;
    overflow: hidden;
    display: inline-flex;
    width: 48px;
    height: 48px;
    border-radius: 12px;
    background-color: rgba(234, 234, 234, 0.7);
    box-shadow: 1px 2px 3px silver;
    margin: 5%;
}
.text-right .v-text-field__input {
  text-align: right;
}
.scroll-area-lateDialog{
    margin: auto;
    height: auto;
    max-height: 75vh;
    min-width: 300px;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden
}
</style>
