<template>
    <div
        v-if="loaded"
        style="position: relative"
        class="backgroundImg"
    >
        <Nutzungsbedingungen
            :usage-agreement-not-signed="usageAgreementNotSigned"
            :current-account="crtAccount"
            :revoke="revokeAgreement"
            @close-dialog="()=>{usageAgreementNotSigned = false}"
        />
        <!--#region simplified view -->
        <!-- overflow hidden is needed for carousel -->
        <div
            v-if="babyView"
            style="overflow: hidden !important;"
        >
            <!-- Navbar tag for Accessibility Reasons (not Navbar Component) -->
            <nav role="navigation">
                <!--bottom navigation bar (simplified view version), used only for mobile devices-->
                <div
                    v-if="!faq && babyView && !hideNavbar"
                    class="btmNav"
                >
                    <div
                        class="d-flex flex-row justify-space-around align-center"
                        style="height: 100%; width: 100%"
                    >
                        <v-tooltip
                            v-for="item in btmNavItemsSimple"
                            :key="item.name"
                            :disabled="!showTooltips"
                            location="top"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    elevation="0"
                                    size="small"
                                    class="btmNavBtn pa-0"
                                    :style="currSlide === item.goToIndex ? 'background-color:' + item.bgColor + '!important;' : ''"
                                    v-bind="props"
                                    @click="switchSlide(item.goToIndex);"
                                >
                                    <v-badge
                                        :content="getBadge(item.goToIndex)"
                                        :model-value="getBadge(item.goToIndex)"
                                        color="#ff0000"
                                        offset-x="27"
                                        style="z-index: 1;"
                                    >
                                        <img
                                            v-if="item.icon"
                                            :src="item.icon"
                                            class="btmNavIcon"
                                            :style="currSlide === item.goToIndex ? 'filter: brightness(1000%);' : item.color"
                                            :alt="item.alt"
                                        >

                                        <v-icon
                                            v-if="item.mdi"
                                            size="xx-large"
                                            :style="currSlide === item.goToIndex ? 'color: white' : `color:${item.bgColor}`"
                                        >
                                            {{ item.mdi }}
                                        </v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>
                            <span>{{ item.alt }}</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <!-- <v-tooltip
                            v-if="textSelected"
                            :disabled="!showTooltips"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    elevation="0"
                                    small
                                    class="btmNavBtn pa-0"
                                    style="z-index: 201"
                                    v-on="on"
                                    @click.self="clickTTS"
                                >
                                    <TTSSmall
                                        ref="tts"
                                        :icon-size="'35px'"
                                        alt="Vorlesen"
                                    />
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip> -->

                        <div @click="sidebarMobile = true; sidebar = true;">
                            <Avatar
                                :profile="accountId"
                                :show-burger="true"
                                :alt="'Profil öffnen'"
                            />
                        </div>
                    </div>
                </div>
            </nav>
            <v-overlay
                :model-value="showOverlay"
                z-index="11"
                scrim="rgba(0,0,0,0.9)"
            />
            <header
                role="banner"
                style="z-index: 12 !important;"
            >
                <!--top menu bar-->
                <v-row
                    style="background-color: rgb(var(--v-theme-headerblue));"
                    class="displayHide pt-2"
                >
                    <v-col
                        class="d-flex align-center"
                        style="margin-left: 2.5%"
                        cols="4"
                    >
                        <!-- Klassenraum Button -->
                        <join-klassenraum-button />
                    </v-col>

                    <!-- name and options -->
                    <v-col
                        class="d-flex justify-end align-center"
                        style="margin-right: 2.5%"
                    >
                        <div class="d-flex justify-center align-center">
                            <p style="color: white">
                                {{ this.person ? `${this.person.name} ${this.person.lastName.slice(0,1)}.` : '' }}
                            </p>
                        </div>

                        <v-badge
                            :model-value="showCalendarBadge"
                            location="top left"
                            color="#ff0000"
                            class="ml-2"
                            :content="eventsNotSeen"
                        >
                            <v-btn
                                v-if="!calendarDeactivated"
                                size="x-small"
                                style="width: 50px; height: 50px; border-radius: 8px; background-color: #FFFFFF"
                                elevation="0"
                                @click="openCalendar"
                            >
                                <img
                                    :src="kalenderIcon"
                                    alt="Kalender"
                                    class="iconToHeaderblue icon30"
                                >
                            </v-btn>
                        </v-badge>

                        <v-tooltip
                            v-if="magnifier"
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4"
                                    v-bind="props"
                                    @click="toggleMagnifier();"
                                >
                                    <img
                                        :src="lupenIcon"
                                        class="icon25"
                                        alt="Lupe einschalten/ausschalten (Alt + L)"
                                    >
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)</span>
                                <img
                                    v-if="hoeren"
                                    :src="lupeMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt="Lupe"
                                >
                            </div>
                        </v-tooltip>

                        <v-tooltip
                            v-if="keyboard"
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4"
                                    style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                    v-bind="props"
                                    @click="toggleKeyboard()"
                                >
                                    <img
                                        :src="keyboardIcon"
                                        alt="Tastatursteuerung einschalten/ausschalten (Alt + T)"
                                        class="icon25"
                                    >
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)</span>
                                <img
                                    v-if="hoeren"
                                    :src="tastaturTippenMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt="Tastatursteuerung"
                                >
                            </div>
                        </v-tooltip>

                        <!--                        <v-tooltip v-if="speechControl" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    v-on="on"
                                    x-small elevation="0"
                                    class="ml-4"
                                    style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                    @click="toggleControl()"
                                >
                                    <img :src="mikrofonIcon" style="max-width: 25px; max-height: 25px;" alt="Sprachsteuerung aktiv"/>
                                </v-btn>
                            </template>
                            <span>Sprachsteuerung aktiv</span>
                        </v-tooltip>-->
                        <keep-alive>
                            <BabyChatDropDown
                                v-if="windowWidth >= 900 && !chatDeactivated"
                                :popup-chat="popupChat"
                                @open-popup="openPopup('popupChat')"
                            />
                        </keep-alive>
                        <keep-alive>
                            <BabyBlackboardDropDown
                                v-if="!brettDeactivated"
                                :popup-brett="popupBrett"
                                @open-popup="openPopup('popupBrett')"
                            />
                        </keep-alive>

                        <keep-alive>
                            <BabyAppDropDown
                                v-if="!appsDeactivated"
                                :popup-apps="popupApps"
                                @open-popup="openPopup('popupApps')"
                            />
                        </keep-alive>

                        <v-tooltip
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4"
                                    v-bind="props"
                                    @click="reloadPage()"
                                    @focusin="popupChat = false;"
                                >
                                    <img
                                        :src="aktualisierenIcon"
                                        class="iconToHeaderblue icon25"
                                        alt="Aktualisieren"
                                    >
                                </v-btn>
                            </template>
                            <span>Aktualisieren</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <!-- <v-tooltip
                            v-if="textSelected"
                            :disabled="!showTooltips"
                            bottom
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    elevation="0"
                                    small
                                    class="btmNavBtn pa-0 ml-4"
                                    style="background-color: #6995CD !important; z-index: 201"
                                    v-on="on"
                                    @click.self="clickTTS"
                                >
                                    <TTSSmall
                                        ref="tts"
                                        :icon-color="'white'"
                                        alt="Vorlesen"
                                    />
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip> -->

                        <div
                            class="d-flex align-center ml-4 pointer"
                            @click="showSidebar"
                        >
                            <Avatar
                                :profile="accountId"
                                :show-burger="true"
                                :alt="'Profil öffnen'"
                            />
                        </div>
                        <!-- sidebars should follow their activators in the DOM -->
                        <Sidebar
                            v-if="sidebar && !sidebarMobile"
                            v-model="sidebar"
                            :sidebar-open="sidebar"
                            @close-sidebar="()=>{sidebar = false; sidebarMobile = false;}"
                            @open-usage-agreement="revokeUsageAgreement"
                        />
                    </v-col>
                </v-row>
            </header>
            <main v-if="windowWidth > 900 && !faq && !profileManagement">
                <BadgeExplanations
                    v-if="!weeklyView"
                    :small="false"
                />
                <div class="big mt-4">
                    <div style="display: flex; justify-content: space-around; width: 100%">
                        <keep-alive>
                            <PlanWidget
                                class="pa-3 pb-1"
                                :style="weeklyView ? 'min-height: auto; height: 90vh; width: 96vw;' : ''"
                                @start-bbb-tut="startBBBTut"
                                @plan-badge="isFirstPageUpdated.push('plan')"
                                @clear-all-badge="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                                @audio-overlay="handleAudioUrl"
                                @plan-mounted="planMounted = true"
                            />
                        </keep-alive>
                        <keep-alive>
                            <FileWidgetv2
                                v-if="!weeklyView "
                                class="pa-3 pb-1"
                                style="width: 48%"
                                :baby-view="babyView"
                                :use-width="true"
                                :widget-mode-homework="true"
                                @filesystem-mounted="filesystemMounted = true"
                            />
                        </keep-alive>
                    </div>
                </div>
                <SupportChat
                    v-if="supportPageOpen"
                />
            </main>

            <main v-else-if="windowWidth > 900 && faq">
                <Faq
                    style="height: 100%; min-height: 90vh"
                />
            </main>

            <main
                v-else
            >
                <swiper
                    v-if="!(faq || profileManagement)"
                    class="mySwiper "
                    :style="weeklyView ? 'width: 90vw; margin-left: auto; margin-right: auto;' : ''"
                    slides-per-view="auto"
                    :touch-start-prevent-default="false"
                    :allow-slide-next="!weeklyView"
                    :allow-slide-previous="!weeklyView"
                    @swiper="onSwiper"
                    @slide-change="swiperOnChange"
                >
                    <swiper-slide>
                        <PlanWidget
                            v-if="!planDeactivated"
                            :small="true"
                            :reset="reloadPlan"
                            class="pb-1"
                            style="height: 100vh"
                            @plan-remounted="reloadPlan = false"
                            @start-bbb-tut="startBBBTut"
                            @audio-overlay="handleAudioUrl"
                            @plan-mounted="planMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <BrettWidget
                            v-if="!brettDeactivated"
                            class="pb-1"
                            :class="weeklyView ? 'ml-14' : ''"
                            :reset="reloadBrett"
                            style="height: 100vh"
                            @brett-remounted="reloadBrett = false"
                            @brett-badge="isFirstPageUpdated.push('brett')"
                            @clear-brett-badge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                            @clear-all-badges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                            @brett-mounted="brettMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <ChatWidgetV2
                            v-if="!chatDeactivated"    
                            :reset="reloadChat"
                            @chat-remounted="reloadChat = false"     
                            @chat-badge="isFirstPageUpdated.push('chat')"
                            @clear-all-badge="isFirstPageUpdated = isFirstPageUpdated.filter((entry) => entry !== 'chat')"
                            @chat-mounted="chatMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <FileWidgetv2
                            v-if="!filesDeactivated"    
                            style="height: 100vh"
                            :use-width="true"
                            :widget-mode-homework="true"
                            :reset="reloadFilesystem"
                            @filesystem-remounted="reloadFilesystem = false"
                            @filesystem-mounted="filesystemMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <AppWidget
                            v-if="!appsDeactivated"    
                            :pinboards="allPinboards"
                            :reset="reloadAppwidget"
                            @app-widget-remounted="reloadAppwidget = false"
                            @appwidget-mounted="appwidgetMounted = true"
                        />
                    </swiper-slide>
                </swiper>
                <Faq
                    v-else-if="faq"
                    style="height: 100%; min-height: 100vh"
                />
                <ProfileManagement
                    v-else
                    style="height: 100%; min-height: 100vh"
                /> 
                <SupportChat
                    v-if="supportPageOpen"
                />
            </main>
        </div>
        <!--#endregion -->

        <!--#region regular view -->
        <div
            v-else
            style="overflow: hidden !important;"
        >
            <nav role="navigation">
                <!--bottom navigation bar, used only for mobile devices-->
                <div
                    v-if="!faq && !hideNavbar"
                    class="btmNav"
                >
                    <div
                        class="d-flex flex-row justify-space-around align-center"
                        style="height: 100%; width: 100%"
                    >
                        <v-tooltip
                            v-for="item in btmNavItems"
                            :key="item.name"
                            :disabled="!showTooltips"
                            location="top"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    elevation="0"
                                    size="small"
                                    class="btmNavBtn pa-0"
                                    :style="currSlide === item.goToIndex ? 'background-color:' + item.bgColor + '!important;' : ''"
                                    v-bind="props"
                                    @click="switchSlide(item.goToIndex);"
                                >
                                    <v-badge
                                        :content="getBadge(item.goToIndex)"
                                        :model-value="getBadge(item.goToIndex)"
                                        color="#ff0000"
                                        offset-x="27"
                                        style="z-index: 1;"
                                    >
                                        <img
                                            v-if="item.icon"
                                            :src="item.icon"
                                            class="btmNavIcon"
                                            :alt="item.alt"
                                            :style="currSlide === item.goToIndex ? 'filter: brightness(1000%);' : item.color"
                                        >
                                        <v-icon
                                            v-if="item.mdi"
                                            size="xx-large"
                                            :style="currSlide === item.goToIndex ? 'color: white' : `color:${item.bgColor}`"
                                        >
                                            {{ item.mdi }}
                                        </v-icon>
                                    </v-badge>
                                </v-btn>
                            </template>
                            <span> {{ item.alt }}</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                        <!-- <v-tooltip
                            v-if="textSelected"
                            :disabled="!showTooltips"
                            top
                        >
                            <template v-slot:activator="{ on }">
                                <v-btn
                                    elevation="0"
                                    small
                                    class="btmNavBtn pa-0"
                                    style="z-index: 201"
                                    v-on="on"
                                    @click.self="clickTTS"
                                >
                                    <TTSSmall
                                        ref="tts"
                                        :icon-size="'35px'"
                                        alt="Vorlesen"
                                    />
                                </v-btn>
                            </template>
                            <span>Vorlesen</span>
                        </v-tooltip> -->

                        <div @click="sidebarMobile = true; sidebar = true;">
                            <Avatar
                                :profile="accountId"
                                :show-burger="true"
                                :alt="'Profil öffnen'"
                            />
                        </div>
                    </div>
                </div>
            </nav>

            <v-overlay
                :model-value="showOverlay"
                z-index="10"
                scrim="rgba(0,0,0,0.9)"
            />

            <!--top menu bar-->
            <header role="banner">
                <v-row
                    style="background-color: rgb(var(--v-theme-headerblue));"
                    class="displayHide pt-2"
                >
                    <v-col
                        class="d-flex align-center"
                        style="margin-left: 2.5%"
                        cols="4"
                    >
                        <!-- Klassenraum Button -->
                        <join-klassenraum-button />
                    </v-col>

                    <!-- Right buttons -->
                    <v-col
                        class="d-flex justify-end align-center"
                        style="margin-right: 2.5%"
                    >
                        <div class="d-flex justify-center align-center">
                            <p style="color: white">
                                {{ `${this.person ? this.person.name : ''} ${this.person ? this.person.lastName.slice(0,1) + '.' : ''}` }}
                            </p>
                        </div>

                        <v-badge
                            :model-value="showCalendarBadge"
                            location="top left"
                            color="#ff0000"
                            class="ml-4"
                            :content="eventsNotSeen"
                        >
                            <v-btn
                                v-if="!calendarDeactivated"
                                size="x-small"
                                style="width: 50px; height: 50px; border-radius: 8px; background-color: #FFFFFF"
                                elevation="0"
                                @click="openCalendar"
                            >
                                <img
                                    :src="kalenderIcon"
                                    alt="Kalender"
                                    class="iconToHeaderblue icon25"
                                >
                            </v-btn>
                        </v-badge>

                        <v-tooltip
                            v-if="magnifier"
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4"
                                    v-bind="props"
                                    @click="turnOffMagnifier();"
                                >
                                    <img
                                        :src="lupenIcon"
                                        class="icon25"
                                        alt="Lupe einschalten/ausschalten (Alt + L)"
                                    >
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">L</span>upe einschalten/ausschalten (Alt + L)</span>
                                <img
                                    v-if="hoeren"
                                    :src="lupeMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt="Lupe"
                                >
                            </div>
                        </v-tooltip>

                        <v-tooltip
                            v-if="keyboard"
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4"
                                    style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                    v-bind="props"
                                    @click="toggleKeyboard()"
                                >
                                    <img
                                        :src="keyboardIcon"
                                        alt="Tastatursteuerung einschalten/ausschalten (Alt + T)"
                                        class="icon25"
                                    >
                                </v-btn>
                            </template>
                            <div style="display: flex; flex-direction: column; text-align: center">
                                <span><span style="text-decoration: underline white">T</span>astatursteuerung einschalten/ausschalten (Alt + T)</span>
                                <img
                                    v-if="hoeren"
                                    :src="tastaturTippenMetacom"
                                    width="100"
                                    style="margin: auto"
                                    alt="Tastatursteuerung"
                                >
                            </div>
                        </v-tooltip>

                        <!--                        <v-tooltip v-if="speechControl" :disabled="!showTooltips" bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn
                                        v-on="on"
                                        x-small elevation="0"
                                        class="ml-4"
                                        style="background-color: rgb(var(--v-theme-chatColor)) !important; width: 50px; height: 50px; border-radius: 8px;"
                                        @click="toggleControl()"
                                >
                                    <img :src="mikrofonIcon" style="max-width: 25px; max-height: 25px;" alt="Sprachsteuerung aktiv"/>
                                </v-btn>
                            </template>
                            <span>Sprachsteuerung aktiv</span>
                        </v-tooltip>-->

                        <!-- Reload -->
                        <v-tooltip
                            :disabled="!showTooltips"
                            location="bottom"
                        >
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    style="background-color: #FFFFFF; width: 50px; height: 50px; border-radius: 8px;"
                                    size="x-small"
                                    elevation="0"
                                    class="ml-4 animateIcon"
                                    v-bind="props"
                                    @click="reloadPage()"
                                >
                                    <img
                                        :src="aktualisierenIcon"
                                        alt="Aktualisieren"
                                        class="iconToHeaderblue icon25"
                                    >
                                </v-btn>
                            </template>
                            <span>Aktualisieren</span>
                        </v-tooltip>

                        <!--textToSpeech when text is selected-->
                      

                        <div
                            class="d-flex align-center ml-4 pointer"
                            @click="showSidebar"
                        >
                            <Avatar
                                :profile="accountId"
                                :show-burger="true"
                                :alt="'Profil öffnen'"
                            />
                        </div>
                        <!-- sidebars should follow their activators in the DOM -->
                        <Sidebar
                            v-if="sidebar && !sidebarMobile"
                            v-model="sidebar"
                            :sidebar-open="sidebar"
                            @close-sidebar="()=>{sidebar = false; sidebarMobile = false;}"
                            @open-usage-agreement="revokeUsageAgreement"
                        />
                    </v-col>
                </v-row>
            </header>

            <div
                v-if="windowWidth > 900 && !faq && !profileManagement"
                class="big mt-4"
                style="justify-content: space-around; align-items: center"
            >
                <v-tooltip
                    v-if="!weeklyView && showNextSlide"
                    :disabled="!showTooltips"
                    location="right"
                >
                    <template v-slot:activator="{ props }">
                        <v-card
                            style="height: 50vh; display: flex"
                            class="buttonFocusHover"
                            v-bind="props"
                            @click="changePage(-1)"
                            @focusin="buttonFocusLeft = true"
                            @focusout="buttonFocusLeft = false"
                        >
                            <v-fab
                                size="small"
                                color="#eaeaea"
                                style="width: 2vw"
                                class="align-self-center mx-1 mb-8"
                                icon="$vuetify"
                                variant="plain"
                            >
                                <img
                                    :alt="page === 0 ? 'Vorherige Seite mit Dateien' : page === 1 ? 'Vorherige Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Vorherige Seite'"
                                    style="position: relative; margin:auto; width: 30px"
                                    :src="left"
                                    :class="buttonFocusLeft ? 'imgFocus' : ''"
                                    class="imgFocusHover"
                                >
                            </v-fab>
                        </v-card>
                    </template>
                    <span>Vorherige Seite</span>
                </v-tooltip>
                <div>
                    <BadgeExplanations
                        v-if="!weeklyView"
                        :small="false"
                    />
                    <keep-alive>
                        <PlanWidget
                            v-if="page === 0 && !planDeactivated"
                            class="pb-1"
                            :style="weeklyView ? 'width: 94vw;' : '' "
                            @start-bbb-tut="startBBBTut"
                            @plan-badge="isFirstPageUpdated.push('plan')"
                            @clear-all="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                            @audio-overlay="handleAudioUrl"
                            @plan-mounted="planMounted = true"
                        />
                    </keep-alive>

                    <div v-if="!weeklyView">
                        <keep-alive>
                            <FileWidgetv2
                                v-if="page === 1 && !filesDeactivated"
                                class="pb-1"
                                @filesystem-mounted="filesystemMounted = true"
                            />
                        </keep-alive>
                    </div>
                </div>
                <div v-if="!weeklyView">
                    <keep-alive>
                        <BrettWidget
                            v-if="page === 0 && !brettDeactivated"
                            class="pb-1"
                            @brett-badge="isFirstPageUpdated.push('brett')"
                            @clear-brett-badge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                            @clear-all-badges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                            @brett-mounted="brettMounted = true"
                        />

                        <Verlag v-else-if="page === 1 && bonusFunctions.etherpad" />
                    </keep-alive>

                    <!-- Placeholder -->
                    <keep-alive>
                        <AppWidget
                            v-if="page === 1 && !appsDeactivated"
                            class="pb-1"
                            :pinboards="allPinboards"
                            @appwidget-mounted="appwidgetMounted = true"
                        />
                    </keep-alive>
                </div>
                <div v-if="!weeklyView">
                    <keep-alive>
                        <ChatWidgetV2
                            v-if="page === 0 && !chatDeactivated"
                            class="pb-1"
                            @chat-badge="isFirstPageUpdated.push('chat')"
                            @clear-all-badge="isFirstPageUpdated = isFirstPageUpdated.filter((entry) => entry !== 'chat')"
                            @chat-mounted="chatMounted = true"
                        />
                    </keep-alive>

                    <!-- Placeholder -->
                    <div
                        v-if="page === 1"
                        style="box-shadow: none"
                        class="widgetContainer"
                    />
                </div>
                <v-tooltip
                    v-if="!weeklyView && showNextSlide"
                    :disabled="!showTooltips"
                    location="left"
                >
                    <template v-slot:activator="{ props }">
                        <v-badge
                            :model-value="isUpdatesOnOtherPage"
                            location="left"
                            offset-x="10"
                            offset-y="15"
                            class="badgeClass"
                            color="#ff0000"
                        >
                            <v-card
                                v-if="!weeklyView"
                                style="height: 50vh; display: flex"
                                class="buttonFocusHover"
                                v-bind="props"
                                @click="changePage(1)"
                                @focusin="buttonFocusRight = true"
                                @focusout="buttonFocusRight = false"
                            >
                                <v-fab
                                    size="small"
                                    style="width: 2vw;"
                                    class="align-self-center mx-1 mb-8"
                                    icon="$vuetify"
                                    variant="plain"
                                >
                                    <img
                                        style="position: relative; margin:auto; width: 30px"
                                        :alt="page === 0 ? 'Nächste Seite mit Dateien' : page === 1 ? 'Nächste Seite mit Stundenplan, Schwarzem Brett und Chat' : 'Nächste Seite'"
                                        :src="right"
                                        :class="buttonFocusRight ? 'imgFocus' : ''"
                                        class="imgFocusHover"
                                    >
                                </v-fab>
                            </v-card>
                        </v-badge>
                    </template>
                    <span>Nächste Seite</span>
                </v-tooltip>
                <SupportChat
                    v-if="supportPageOpen"
                />
            </div>
            <main v-else-if="profileManagement">
                <ProfileManagement style="height: 100%; min-height: 90vh" />
            </main>
            <main v-else-if="windowWidth > 900">
                <Faq
                    style="height: 100%; min-height: 90vh"
                />
            </main>

            <main
                v-else
            >
                <swiper
                    v-if="!faq"
                    class="mySwiper "
                    :style="weeklyView ? 'width: 90vw; margin-left: auto; margin-right: auto;' : ''"
                    slides-per-view="auto"
                    :touch-start-prevent-default="false"
                    :allow-slide-next="!weeklyView"
                    :allow-slide-previous="!weeklyView"
                    @swiper="onSwiper"
                    @slide-change="swiperOnChange"
                >
                    <swiper-slide>
                        <PlanWidget
                            v-if="!planDeactivated"
                            :small="true"
                            style="height: 100vh"
                            class="pb-1"
                            :reset="reloadPlan"
                            @plan-remounted="reloadPlan = false"
                            @start-bbb-tut="startBBBTut"
                            @plan-badge="isFirstPageUpdated.push('plan')"
                            @clear-all="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'plan')"
                            @audio-overlay="handleAudioUrl"
                            @plan-mounted="planMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <BrettWidget
                            v-if="!brettDeactivated"
                            class="pb-1"
                            style="height: 100vh"
                            :class="weeklyView ? 'ml-14' : ''"
                            :reset="reloadBrett"
                            @brett-remounted="reloadBrett = false"
                            @brett-badge="isFirstPageUpdated.push('brett')"
                            @clear-brett-badge="isFirstPageUpdated.splice(isFirstPageUpdated.indexOf('brett'),1)"
                            @clear-all-badges="isFirstPageUpdated = isFirstPageUpdated.filter((el) => el !== 'brett')"
                            @brett-mounted="brettMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <ChatWidgetV2
                            v-if="!weeklyView && !chatDeactivated"      
                            :reset="reloadChat"
                            @chat-remounted="reloadChat = false"
                            @chat-badge="isFirstPageUpdated.push('chat')"
                            @clear-all-badge="isFirstPageUpdated = isFirstPageUpdated.filter((entry) => entry !== 'chat')"
                            @chat-mounted="chatMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <FileWidgetv2
                            v-if="!weeklyView && !filesDeactivated"
                            class="pb-1"
                            style="height: 100vh"
                            :reset="reloadFilesystem"
                            @filesystem-remounted="reloadFilesystem = false"
                            @filesystem-mounted="filesystemMounted = true"
                        />
                    </swiper-slide>
                    <swiper-slide>
                        <AppWidget
                            v-if="!weeklyView && !appsDeactivated"
                            :pinboards="allPinboards"
                            :reset="reloadAppwidget"
                            @app-widget-remounted="reloadAppwidget = false"
                            @appwidget-mounted="appwidgetMounted = true"
                        />
                    </swiper-slide>
                </swiper>
                <Faq
                    v-else-if="faq"
                    style="height: 100%; min-height: 100vh"
                />
                <ProfileManagement
                    v-else
                    style="height: 100%; min-height: 100vh"
                /> 
                <SupportChat
                    v-if="supportPageOpen"
                />
            </main>
        </div>
        <!--#endregion -->

        <v-dialog v-model="openProfileManagementDialog">
            <ProfileManagement
                @close-dialog="openProfileManagementDialog = false"
            />
        </v-dialog>

        <!-- SlideTutorial for baby view -->
        <SlideTutorial
            v-if="(!introTutorial || showBBBTutorial) && babyView"
            :content="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.img) : tutorialCollection.babyView.map(el => el.img)"
            :subtitle-headers-german="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.header) : tutorialCollection.babyView.map(el => el.header)"
            :subtitles-german="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.text) : tutorialCollection.babyView.map(el => el.text)"
            :force-tutorial="false"
            @close="showBBBTutorial ? closeBBBTutorial() : closeIntroTutorial()"
        />
        <!-- SlideTutorial for normal view -->
        <SlideTutorial
            v-if="!introTutorial || showBBBTutorial"
            :content="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.img) : tutorialCollection.normalPupil.map(el => el.img)"
            :subtitle-headers-german="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.header) : tutorialCollection.normalPupil.map(el => el.header)"
            :subtitles-german="showBBBTutorial ? tutorialCollection.bbbPupil.map(el => el.text) : tutorialCollection.normalPupil.map(el => el.text)"
            :force-tutorial="false"
            @close="showBBBTutorial ? closeBBBTutorial() : closeIntroTutorial()"
        />

        <SlideTutorial
            v-if="getPrivacyPolicy"
            :content="privacyPolicySlidesContent"
            :subtitles-german="privacyPolicySlidesSubtitle"
            :force-tutorial="getForcePrivacyPolicy"
            @close="closePrivacyPolicyTutorial()"
        />

        <Sidebar
            v-if="sidebar && sidebarMobile"
            v-model="sidebar"
            :sidebar-open="sidebar"
            @close-sidebar="sidebar = false; sidebarMobile = false"
            @open-usage-agreement="revokeUsageAgreement"
        />

        <!-- Dialog Voice Control aktivieren BabyView -->
        <v-dialog
            v-if="voiceControlDialog && babyView"
            :model-value="voiceControlDialog"
            max-width="600"
            scrim="rgba(0,0,0,0.8)"
            @click:outside="voiceControlDialog = false"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                    <img
                        :src="mikrofonIcon"
                        style="height: 30px"
                        class="iconToWhite"
                    >
                    <p
                        tabindex="0"
                        class="ml-2 mb-0 heading"
                        style="font-size: x-large"
                    >
                        Befehle
                    </p>
                    <v-spacer />
                    <v-icon
                        role="button"
                        aria-label="Schließen"
                        size="large"
                        theme="dark"
                        class="mr-1"
                        @click="voiceControlDialog = false"
                    >
                        mdi-close
                    </v-icon>
                </v-toolbar>
                <v-card-text>
                    <div class="pt-9 px-3">
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="font-size: x-large; width: fit-content"
                        >
                            Die Sprachsteuerung ist jetzt aktiviert.
                        </p>
                        <p
                            tabindex="0"
                            style="font-size: large"
                        >
                            Diese Befehle kannst du verwenden um eKlara mit deiner Stimme zu steuern:
                            <br>
                            <br>
                            Sage <span style="font-weight: bold">"Chat öffnen"</span> um den Chat zu öffnen
                            <br>
                            Sage <span style="font-weight: bold">"Chat schließen"</span> um den Chat zu schließen
                            <br>
                            Sage <span style="font-weight: bold">"Unterricht"</span> um dir die aktuelle Stunde vorlesen zu lassen
                            <br>
                            Sage <span style="font-weight: bold">"Starte Unterricht"</span> um der aktuellen Stunde beizutreten
                            <br>
                            Sage <span style="font-weight: bold">"Hausaufgaben"</span> um deine aktuellen Hausaufgaben vorlesen zu lassen
                            <br>
                            Sage <span style="font-weight: bold">"Abmelden"</span> um dich abzumelden
                            <br>
                            <br>
                            <br>
                            Versuch es doch mal!
                            <br>
                            Sage
                            <span
                                style="font-weight: bold"
                                class="mt-7"
                            >"Sprachsteuerung"</span> um dieses Fenster zu schließen.
                        </p>
                        <div
                            v-if="voiceControlSuccessful"
                            style="display: flex; justify-content: center"
                        >
                            <p
                                tabindex="0"
                                class="mb-0 pt-3 pr-4"
                                style="font-size: x-large;"
                            >
                                Super!
                            </p>
                            <img
                                :src="daumenHochIcon"
                                style="height: 50px; display:inline-block"
                                alt="0"
                            >
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>

        <!-- Dialog Voice Control aktivieren Regular View -->
        <v-dialog
            v-if="voiceControlDialog && !babyView"
            :model-value="voiceControlDialog"
            max-width="600"
            scrim="rgba(0,0,0,0.8)"
            @click:outside="voiceControlDialog = false"
        >
            <v-card>
                <v-toolbar
                    color="primary"
                    dark
                >
                    <img
                        :src="mikrofonIcon"
                        style="height: 30px"
                        class="iconToWhite"
                    >
                    <p
                        tabindex="0"
                        class="ml-2 mb-0 heading"
                        style="font-size: x-large"
                    >
                        Befehle
                    </p>
                    <v-spacer />
                    <v-icon
                        role="button"
                        aria-label="Schließen"
                        size="large"
                        theme="dark"
                        class="mr-1"
                        @click="voiceControlDialog = false"
                    >
                        mdi-close
                    </v-icon>
                </v-toolbar>
                <v-card-text>
                    <div class="pt-9 px-3">
                        <p
                            tabindex="0"
                            class="mb-0"
                            style="font-size: x-large; width: fit-content"
                        >
                            Die Sprachsteuerung ist jetzt aktiviert.
                        </p>
                        <p
                            tabindex="0"
                            style="font-size: large"
                        >
                            Diese Befehle kannst du verwenden um eKlara mit deiner Stimme zu steuern:
                            <br>
                            <br>
                            Sage <span style="font-weight: bold">"Starte Unterricht"</span> um der aktuellen Stunde beizutreten
                            <br>
                            Sage <span style="font-weight: bold">"Abmelden"</span> um dich abzumelden
                            <br>
                            <br>
                            <br>
                            Versuch es doch mal!
                            <br>
                            Sage
                            <span
                                style="font-weight: bold"
                                class="mt-7"
                            >"Sprachsteuerung"</span> um dieses Fenster zu schließen.
                        </p>
                        <div
                            v-if="voiceControlSuccessful"
                            style="display: flex; justify-content: center"
                        >
                            <p
                                tabindex="0"
                                class="mb-0 pt-3 pr-4"
                                style="font-size: x-large;"
                            >
                                Super!
                            </p>
                            <img
                                :src="daumenHochIcon"
                                style="height: 50px; display:inline-block"
                                alt="0"
                            >
                        </div>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
        <ForceLogoutDialog
            v-model:show-force-logout-dialog="showForceLogoutDialog"
            :current-account="crtAccount?._id"
            :other-connected-devices="otherConnectedDevices"
            @close-force-logout="(showSnackbar)=>{if(showSnackbar){ confirmForceLogout();} showForceLogoutDialog = false}"
            @confirm-force-logout="confirmForceLogout"
        />
        <VideoTutorial
            v-if="videoTutorial && !videoTutorialClosed && !usageAgreementNotSigned"
            :video-tutorial="videoTutorial"
            @close="videoTutorialClosed = true"
        />
        <calendar-app
            v-if="showCalendarWidget"
            @close="() => {showCalendarWidget = false; hasUserNewEvents();}"
        />
        <v-overlay
            v-if="!hidePreloader 
                || (windowWidth < 901 && !allWidgetsMounted)
                || (windowWidth > 900 && page === 0 && !page0WidgetsMounted) 
                || (windowWidth > 900 && page === 1 && !page1WidgetsMounted)
                || (windowWidth > 900 && babyView && !babyWidgetsMounted)"
            z-index="50"
            :opacity="1.0"
            scrim="white"
        >
            <Preloader />
        </v-overlay>
        <v-overlay
            v-if="audioOverlay"
            opacity="0.8"
            z-index="95"
        >
            <v-icon
                size="large"
                style="position: fixed; top: 50px; right: 50px; z-index: 99"
                theme="dark"
                @click="audioOverlay = false"
            >
                mdi-close
            </v-icon>
            <audio
                id="audioPlayer"
                :src="audioUrl"
                controls
                :width="windowWidth > 900 ? '80%' : '40%'"
                :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }"
            >
                <source
                    :src="audioUrl"
                    type="audio/mpeg"
                >
                Your browser does not support the video tag.
            </audio>
        </v-overlay>
        <v-tooltip
            v-if="textSelected"
            :disabled="!showTooltips"
            location="bottom"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    elevation="0"
                    style="z-index: 201; padding: 0px !important;"
                    :style="buttonStyle"
                    max-width="30"
                    width="30"
                    height="30"
                    icon="$vuetify"
                    variant="plain"
                    max-height="30"
                    color="rgb(var(--v-theme-headerblue))"
                    v-bind="props"
                    @click.self="clickTTS"
                >
                    <TTSSmall
                        ref="tts"
                        :icon-color="'white'"
                        alt="Vorlesen"
                    />
                </v-btn>
            </template>
            <span>Vorlesen</span>
        </v-tooltip>
    </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

import TTSSmall from "../TextToSpeechSmall";
import PlanWidget from "@/components/SharedViews/Stundenplan/Schueler_und_Lehrer/PlanWidget";
import AppWidget from "@/components/Apps/AppWidget";
import HausiWidgetBaby from "@/components/Schueler/HausiWidget/HausiWidgetBaby";
import BrettWidget from "@/components/BrettWidget";
// import BrettWidget from "@/components/BrettWidget";
import FileWidgetv2 from "@/components/FileArchive/FileWidgetv2";
import BabyChatDropDown from "@/components/Schueler/BabyChatDropDown";
import BabyAppDropDown from "@/components/Schueler/BabyAppDropDown";
import ChatWidgetV2 from "../ChatV2/ChatWidgetV2.vue";
import * as backend from "../../api/backend";
import JoinKlassenraumButton from "@/components/JoinKlassenraumButton";
import Verlag from "@/components/Verlag";
// optional style for arrows & dots
import setGlobalButtonContrast from "../../util/accessibilityButtonContrast";
import VideoTutorial from "@/components/Tutorial/VideoTutorial.vue";
import deFlagIcon from '@/assets/Icons/deutsch-59.svg';

import frFlagIcon from '@/assets/Icons/französisch-60.svg';
import enFlagIcon from '@/assets/Icons/englisch-61.svg';
import trFlagIcon from '@/assets/Icons/türkisch-97.svg';
import arFlagIcon from '@/assets/Icons/syrisch-98.svg';
import ruFlagIcon from '@/assets/Icons/russisch.svg';
import itFlagIcon from '@/assets/Icons/flag-italy.svg';
import roFlagIcon from '@/assets/Icons/flag-romania.svg';
import kuFlagIcon from '@/assets/Icons/flag-kurdistan.svg';
import ukFlagIcon from '@/assets/Icons/flag-ukraine.svg';
import left from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import right from '@/assets/Icons/FaceliftIcons/vor_pfeil.svg';
import logoutIcon from '../../assets/Icons/redesignV3/exit-red.svg';
import stundenplanIcon from '../../assets/Icons/timetable.svg';
import brettIcon from '../../assets/Icons/brett.svg';
import chatIcon from '../../assets/Icons/chat-alternative-filled-102.svg';
import dateienIcon from '../../assets/Icons/file.svg';
import menuIcon from '../../assets/Icons/FaceliftIcons/menu_lang_weiss.svg';
import tutorialIcon from '../../assets/Icons/redesignV3/whiteboard.svg';
import fragezeichenIcon from '../../assets/Icons/redesignV3/questionmark.svg';
import abbrechenIcon from '../../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import lupenIcon from '../../assets/Icons/redesignV3/magnifier.svg';
import aktualisierenIcon from '../../assets/Icons/FaceliftIcons/aktualisieren.svg';
import kalenderIcon from "../../assets/Icons/FaceliftIcons/kalender_datum_zeit.svg";
import logo from '../../assets/logo_klassenraum.png';
import mikrofonIcon from '../../assets/Icons/FaceliftIcons/mikrofon_an.svg';
import daumenHochIcon from '../../assets/Icons/daumen-hoch.svg';
import keyboardIcon from '../../assets/Icons/keyboard-24px.svg';
import menuMetacom from '../../assets/METACOM/Header/menueSW.png';
import lupeMetacom from '../../assets/METACOM/Allgemein/suchenlupe.png';
import tastaturTippenMetacom from '../../assets/METACOM/Allgemein/tastaturtippen.png';
import tutorialMetacom from '../../assets/METACOM/Header/erklaeren.png';
import faqMetacom from '../../assets/METACOM/Header/weiterfragen2.png';

import privacyPolicySlide1 from '../../assets/Tutorial/Tutorials_eklara_start_Serverstandort.svg'
import privacyPolicySlide2 from '../../assets/Tutorial/Tutorials_eklara_start_SSL_verschluesselt.svg'
import privacyPolicySlide3 from '../../assets/Tutorial/Tutorials_eklara_translate_off.svg'
import privacyPolicySlide4 from '../../assets/Tutorial/Tutorials_eklara_translate_masking.svg'
import privacyPolicySlide5 from '../../assets/Tutorial/Tutorials_eklara_translate_zensur.svg'

import Faq from "../Faq";
import ProfileManagement from "@/components/ProfileManagement";
import BabyBlackboardDropDown from "@/components/Schueler/BabyBlackboardDropDown";
import Sidebar from "@/components/Sidebar.vue";
import SlideTutorial from "@/components/Tutorial/SlideTutorial";
import tutorialCollection from "@/constants/tutorialCollection";
import Avatar from "@/components/SharedViews/Profile/Avatar";
import CalendarApp from "../Calendar/CalendarApp.vue";
import EventBus from "../../util/eventBus";
import SupportChat from "../Support/SupportChat";
import Preloader from "@/components/Utils/Preloader.vue";
import Nutzungsbedingungen from '../Policys/Nutzungsbedingungen.vue';
import BadgeExplanations from '../Utils/BadgeExplanations.vue';
import ForceLogoutDialog from '../Utils/ForceLogoutDialog.vue';
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
export default {
    name: "PlanSchueler",
    components: {
        AppWidget,
        Avatar,
        SlideTutorial,
        ProfileManagement,
        BabyBlackboardDropDown,
        Faq,
        ChatWidgetV2,
        BrettWidget,
        TTSSmall,
        PlanWidget,
        FileWidgetv2,
        // VueSlickCarousel,
        // HausiWidgetBaby,
        BabyAppDropDown,
        BabyChatDropDown,
        JoinKlassenraumButton,
        Verlag,
        VideoTutorial,
        Sidebar,
        CalendarApp,
        SupportChat,
        Preloader,
        Nutzungsbedingungen,
        BadgeExplanations,
        ForceLogoutDialog,

        Swiper,
        SwiperSlide,
    },
    props: {
        isRunning: {type: Boolean, required: false, default: false},
    },
    data: () => ({
        swiperInstance: null, // Holds the Swiper instance

        otherConnectedDevices: null,
        showForceLogoutDialog: false,
        hidePreloader: false,
        usageAgreementNotSigned: false,
        revokeAgreement: false,
        sidebar: false,
        sidebarMobile: false,

        isSecondPageUpdated: [],
        isFirstPageUpdated: [],

        crtAccount: null,


        allPinboards: [],
        date: new Date(),
        appointments: [],
        person: null,
        currentTTSLangImg: deFlagIcon,
        page: 0,
        openProfileManagementDialog: false,
        initialSlide: 0,

        babyView: null,             // For displaying simplified view
        sehen: null,
        hoeren: null,
        motorik: null,

        introTutorial: null,
        bbbTutorialCookie: null,
        showBBBTutorial: false,

        showCalendarWidget: false,
        showCalendarBadge: false,
        eventsNotSeen: '',

        privacyPolicySlidesContent: [
            privacyPolicySlide1,
            privacyPolicySlide2,
            privacyPolicySlide3,
            privacyPolicySlide4,
            privacyPolicySlide5,
        ],
        privacyPolicySlidesSubtitle: [
            "Jeder Schule bei EKLARA wird ein sicherer Schulserver von Hetzner mit Serverstandort in Deutschland bereitgestellt. ",
            "EKLARA nutzt die sichere online HTTPS + SSL-Verschlüsselung, um dich und deine Daten während der Nutzung besser zu schützen.",
            "Texte und Inhalte, die du übersetzen lassen möchtest werden mit Google geteilt.",
            "Achtung, personenbezogene Daten wie Namen, Mailadressen, Telefonnummern oder Adressen dürfen ohne Genehmigung nicht mit Google geteilt werden!",
            "Diese Funktion kann individuell ein- und ausgeschaltet werden.",
            "Direkte Anfragen zur Übersetzung von Texten an Google hinterlassen auch Informationen zu deinem Standort.",
            "Um dich und deinen Standort online besser zu schützen, übernimmt stattdessen EKLARA für dich über einen anonymisierten und dezentralen Dienst die Anfragen zur Übersetzung an Google.",
            "EKLARA anonymisiert hier nur deinen Standort, nicht deine zu übersetzenden Texte und Inhalte.",
            "Es wird daher empfohlen keine personenbezogenen Daten mit Google zu teilen.",
            "Solltest Du ausversehen personenbezogene Daten mit Google zur Übersetzung teilen wollen, warnt und zensiert EKLARA vorher zur Sicherheit erkannte Namen, Mailadressen, Telefonnummern oder Adressen.",
        ],

        tutorialCollection,

        callbackMethod: null,       //joinBBB method passed up from Fach-componen

        currSlide: 0,               //to color icons of mobile bottom navBar
        slideChangeAllowed: true,   //to prevent spamming of btmNavBar, which would bring highlighting of btmNavIcons and currently displayed widget out of sync

        textSelected: false,
        selectedText: '',

        popupFileWidget: false,
        popupChat: false,
        popupBrett: false,
        popupApps: false,
        buttonFocusLeft: false,
        buttonFocusRight: false,

        voiceControlDialog: false,
        voiceControlSuccessful: false,

        videoTutorial: false,
        videoTutorialClosed: false,

        setGlobalButtonContrast,

        loaded: false,
        deFlagIcon,
        frFlagIcon,
        trFlagIcon,
        arFlagIcon,
        enFlagIcon,
        ruFlagIcon,
        itFlagIcon,
        roFlagIcon,
        kuFlagIcon,
        ukFlagIcon,
        left,
        right,
        logoutIcon,
        menuIcon,
        tutorialIcon,
        fragezeichenIcon,
        lupenIcon,
        abbrechenIcon,
        aktualisierenIcon,
        kalenderIcon,
        logo,
        mikrofonIcon,
        daumenHochIcon,
        keyboardIcon,
        menuMetacom,
        lupeMetacom,
        tastaturTippenMetacom,
        tutorialMetacom,
        faqMetacom,


        audioOverlay: false,
        audioUrl: null,

        appwidgetMounted: false,
        brettMounted: false,
        chatMounted: false,
        planMounted: false,
        filesystemMounted: false,
        reloadChat: false,
        reloadBrett: false,
        reloadPlan: false,
        reloadFilesystem: false,
        reloadAppwidget: false,
        buttonStyle: {
        position: 'absolute',
        top: '0px',
        left: '0px',
        display: 'none'
      }
    }),
    computed: {
        ...mapState('weeklyView', ["weeklyView"]),
        ...mapGetters('auth',['accountRole', 'accountId']),
        ...mapGetters('videoTutorial', [ 'videoTutorialsByFilename', 'getPrivacyPolicy', 'getForcePrivacyPolicy', 'getPrivacyPolicyTutorialInfo' ]),
        ...mapGetters('badges', ['babyFilesToDoBadge', 'babyFilesCorrectedBadge']),
        ...mapGetters('chat',["chatBadgeEventList"]),
        ...mapGetters('blackboard',['blackboardBadgeEventList']),
       ...mapState('faq', ['faq']),
        ...mapState('auth', ['stayLoggedIn', 'preventLogout']),
        ...mapState('profileManagement', ['profileManagement']),
        ...mapState('speechControl', ['speechControl']),
        ...mapState('speechControl', ['word']),
        ...mapState('magnifier', ['magnifier']),
        ...mapState('util', ['keyboard', 'bonusFunctions', 'browserSupported', 'windowWidth']),
        ...mapState('utilUnpersistent', ['blockMobileWidgetChangeByDrag','hideNavbar']),
        ...mapState('tooltips', ['shortcutTooltips', 'showTooltips', 'tabPressedOnce']),
        ...mapState('translation', [ 'targetLang' ]),
        ...mapState('blackboard', ['blackboard']),
        ...mapState('badges', ['blackboardBadge', 'timetableBadge', 'chatBadge', 'fileWidgetBadge', 'appBadge']),
        ...mapGetters('support',['supportPageOpen']),
        ...mapGetters('deactivatedFeatures',['deactivatedFeatures', 'appsDeactivated','chatDeactivated','brettDeactivated','planDeactivated',
                                                'messagesDeactivated','filesDeactivated','calendarDeactivated','diaryDeactivated','pinboardDeactivated',]),

        allWidgetsMounted(){
            return this.chatMounted && this.brettMounted && this.planMounted && this.appwidgetMounted && this.filesystemMounted; 
        },
        page0WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 0 && !this.babyView){
                return   this.chatMounted && this.brettMounted && this.planMounted;
            }
            return true;
        },
        page1WidgetsMounted(){
            if(this.windowWidth > 900 && this.page === 1 && !this.babyView){
                return this.filesystemMounted && this.appwidgetMounted;
            }
            return true;
        },
        babyWidgetsMounted(){
            if(this.windowWidth > 900 && this.babyView){
               return this.planMounted && this.filesystemMounted;
            }
            return true;
        },
        isUpdatesOnOtherPage() {
            return (this.isSecondPageUpdated.length && this.page === 0) || (this.isFirstPageUpdated.length && this.page === 1);
        },
        showMenuTooltips() {
            return this.shortcutTooltips;
        },
        showNextSlide(){
            return !(this.messagesDeactivated && this.filesDeactivated && this.appsDeactivated)
        },
        btmNavItems(){
            const items = [];
        
            if(!this.planDeactivated){
                items.push(
                { name: 'stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: items.length, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                )
           }
            if(!this.brettDeactivated){
                items.push(
                { name: 'brett', alt: 'Schwarzes Brett', icon: brettIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-dunkelgrau))', color: 'filter: brightness(0) saturate(100%) invert(40%) sepia(22%) saturate(0%) hue-rotate(171deg) brightness(102%) contrast(80%);' },

                )
            }
            if(!this.chatDeactivated){
                items.push(
                    { name: 'chat', alt: 'Chat', icon: chatIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-chatColor))', color: 'filter: brightness(0) saturate(100%) invert(55%) sepia(8%) saturate(4130%) hue-rotate(70deg) brightness(97%) contrast(81%);' },

                )
            }
            if(!this.filesDeactivated){
                items.push(
                    { name: 'dateien', alt: 'Dateien', icon: dateienIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-fileArchiveOrange))', color: 'filter: brightness(0) saturate(100%) invert(54%) sepia(78%) saturate(2780%) hue-rotate(337deg) brightness(99%) contrast(105%);' },
                )
            }

            if(!this.appsDeactivated){
                items.push(
                { name: 'apps', alt: 'Apps', mdi: 'mdi-apps', goToIndex: items.length, bgColor: 'rgb(var(--v-theme-headerblue))',}

                )
            }
            return items;
        },
        chatWidgetSlide(){
            if(this.babyView){
                return this.btmNavItemsSimple.findIndex((item)=> item.name === 'chat');
            }else{
                return this.btmNavItems.findIndex((item)=> item.name === 'chat');
            }
        },
        btmNavItemsSimple(){

            const items = [];
        
            if(!this.planDeactivated){
                items.push(
                { name: 'stundenplan', alt: 'Stundenplan', icon: stundenplanIcon, goToIndex: items.length, bgColor: '#6995CD', color: 'filter: brightness(0) saturate(100%) invert(56%) sepia(46%) saturate(448%) hue-rotate(174deg) brightness(93%) contrast(86%);' },
                )
           }
            if(!this.brettDeactivated){
                items.push(
                { name: 'brett', alt: 'Schwarzes Brett', icon: brettIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-dunkelgrau))', color: 'filter: brightness(0) saturate(100%) invert(40%) sepia(22%) saturate(0%) hue-rotate(171deg) brightness(102%) contrast(80%);' },

                )
            }
            if(!this.chatDeactivated){
                items.push(
                    { name: 'chat', alt: 'Chat', icon: chatIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-chatColor))', color: 'filter: brightness(0) saturate(100%) invert(55%) sepia(8%) saturate(4130%) hue-rotate(70deg) brightness(97%) contrast(81%);' },

                )
            }
            if(!this.filesDeactivated){
                items.push(
                    { name: 'hausaufgaben', alt: 'Dateien', icon: dateienIcon, goToIndex: items.length, bgColor: 'rgb(var(--v-theme-fileArchiveOrange))', color: 'filter: brightness(0) saturate(100%) invert(54%) sepia(78%) saturate(2780%) hue-rotate(337deg) brightness(99%) contrast(105%);' },
                )
            }
            if(!this.appsDeactivated){
                items.push(
                { name: 'apps', alt: 'Apps', mdi: 'mdi-apps', goToIndex: items.length, bgColor: 'rgb(var(--v-theme-headerblue))',}

                )
            }
            return items;
        },
         showOverlay(){
           return this.sidebar || this.supportPageOpen
        }

    },
    watch: {
        word(newVal) {
            if(newVal === 'sprachsteuerung') {
                this.voiceControlSuccessful = true;
                setTimeout(() => {
                    this.voiceControlDialog = false;
                    this.voiceControlSuccessful = false;
                }, 2000);
            }
            if(newVal === 'abmelden') {
                this.logoutUser();
                this.$router.push({ name: 'login'});
            }
        },
        windowWidth(val) {
            if(val < 900) {
                this.turnOffMagnifier()
            }
        },
        // selectedText(newVal) {
        //     if (newVal.length) {
        //         if (!this.textSelected) {
        //             this.textSelected = true;
        //         }
        //     } else {
        //         if (this.textSelected) {
        //             this.textSelected = false;
        //         }
        //     }
        // },
        allWidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page0WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        page1WidgetsMounted(newVal, oldVal){
            if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
            }
        },
        babyWidgetsMounted(newVal, oldVal){
             if (oldVal === false && newVal === true) {
                this.hidePreloader = true;
             }
        }
    },
    beforeUnmount() {
        window.removeEventListener('keydown', this.handleKeyUp);
        window.removeEventListener('resize', this.onResize);
        window.removeEventListener('orientationchange', this.onOrientationChange);
        document.removeEventListener('selectionchange', this.handleSelectionChange);
    },
    async created() {
        this.checkQueryForPage();
        this.checkTutorialState();
        await this.requestBabyViewAndAccessibilityMode();
        // Check query param for page
        const page = Number.parseInt(this.$route.query.page);
        if (page && Number.isSafeInteger(page)) {
            if (this.windowWidth > 900) {
                this.page = page;
                if (page > 1) {
                    this.page = Math.floor(page / 3);
                    await this.$router
                        .replace({ query: { ...this.$route.query, page: this.page }});
                }
            } else {
                this.currSlide = page;
            }
        }
    },
    async mounted() {
        setTimeout(() => {
            this.hidePreloader = true;
        }, 5000);
        this.crtAccount = await this.getCurrentAccount(true);

        this.usageAgreementNotSigned = this.crtAccount.signedUsageAgreement.isSigned ? false : true;
        this.checkForCalendarUpdates();
        // await this.checkForPinboardUpdates();
        this.requestBonusFunctions();
        window.addEventListener("keydown", this.handleKeyUp);
        window.addEventListener('resize', this.onResize);
        window.addEventListener('orientationchange', this.onOrientationChange);
        // setInterval(() => {
        //     let selection = { ...this.selectedText };
        //     if (window.getSelection) {
        //         selection = window.getSelection();
        //         console.log('sel',selection);
        //     }
        //     // document.getSelection
        //     else if (document.getSelection) {
        //         selection = document.getSelection();
        //         console.log('sel',selection);

        //     }
        //     // document.selection
        //     else if (document.selection) {
        //         selection =
        //             document.selection.createRange().text;
        //         console.log('sel',selection);

        //     }
        //     if(selection.toString() !== this.selectedText) {
        //         this.update = !this.update;
        //     }
        //     this.selectedText = selection.toString();

        // }, 100);
        document.addEventListener('selectionchange', this.handleSelectionChange);

        if (this.targetLang !== 'de') {
            this.currentTTSLangImg = this[`${this.targetLang}FlagIcon`];
        }

        this.date = new Date(await this.loadServerTime());
        this.requestPerson();
        this.videoTutorial = this.babyView
            ? this.videoTutorialsByFilename['Tutorial_Schueler_einfach.mp4']
            : (Number.parseInt(this.$route.query.page) === 1) ? this.videoTutorialsByFilename['Tutorial_Schueler2.mp4']
            : this.videoTutorialsByFilename['Tutorial_Schueler.mp4'];

        EventBus.on('blackboard-badge', () => {
            this.isFirstPageUpdated.push('brett');
        });
        EventBus.off('account-forcelogout');
        EventBus.on('account-forcelogout', () => {
            if (!this.preventLogout) {
                this.logoutUser();
                this.$router.push({ name: 'login'});
            } else {
                this.setPreventLogout(false);
            }
        });
           //this is needed because if feature is disabled it wont mount so we would get stuck in infinite loading screen
        if(this.deactivatedFeatures.apps){
            this.appwidgetMounted = true;
        }
        if(this.deactivatedFeatures.plan){
            this.planMounted = true;
        }
        if(this.deactivatedFeatures.chat){
            this.chatMounted = true;
        }
        if(this.deactivatedFeatures.files){
            this.filesystemMounted = true;
        }if(this.deactivatedFeatures.blackboard){
            this.brettMounted = true;
        }

        const connectionsRes =  await backend.checkWebsocketConnections(this.crtAccount._id);
        const clientsObject = await connectionsRes.json();
        this.otherConnectedDevices = clientsObject.connected - 1;
        if(this.otherConnectedDevices > 0){
            this.showForceLogoutDialog = true;
        }
    },
    methods: {
        ...mapActions("appointments", ["getAppointments"]),
        ...mapActions("pupils", ["getMePupil"]),
        ...mapActions("translation", ["setTargetLang"]),
        ...mapActions("auth", ["logoutUser" , "getCurrentAccount"]),
        ...mapActions("util", ["loadServerTime", "toggleKeyboard", "requestBonusFunctions"]),
        ...mapActions("magnifier", ["toggleMagnifier", "turnOffMagnifier"]),
        ...mapActions("faq", ["toggleFaq", "closeFaq"]),
        ...mapActions("profileManagement", ["toggleProfileManagement", "closeProfileManagement"]),
        ...mapActions("speechControl", ["toggleSpeechControl", "setSpeechControlWord"]),
        ...mapActions("tooltips", ["triggerShortcutTooltips", "toggleTooltips", "triggerTabPressedOnce"]),
        ...mapActions("videoTutorial", [ "getTutorialProperties", "updateTutorialProperties", "setPrivacyPolicyState" ]),
        ...mapMutations("snackbar", ["showSnackbar"]),
        ...mapActions("calendar", ['getUnseenEventsForUser']),
        ...mapActions('weeklyView', ['toggleWeeklyView', 'closeWeeklyView']),
        ...mapActions('pinboard', ['getPinboards', 'hasUserSeenPinboard', 'toggleOpenPinboardLibrary']),
        ...mapMutations('auth', ['setPreventLogout']),
        
        swiperOnChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.swiperInstance.activeIndex;
        },
        onSwiper(swiper) {

            this.swiperInstance = swiper;
        },
        handleSelectionChange() {
                let selection = '';
                let rect = null;

                if (window.getSelection) {
                    const sel = window.getSelection();
                    selection = sel.toString();
                    if (sel.rangeCount > 0) {
                    rect = sel.getRangeAt(0).getBoundingClientRect();
                    }
                } else if (document.getSelection) {
                    const sel = document.getSelection();
                    selection = sel.toString();
                    if (sel.rangeCount > 0) {
                    rect = sel.getRangeAt(0).getBoundingClientRect();
                    }
                } else if (document.selection) {
                    const sel = document.selection.createRange();
                    selection = sel.text;
                    rect = sel.getBoundingClientRect();
                }
                if (selection !== this.selectedText && selection !== '') {
                    this.selectedText = selection;
                    this.updateButtonPosition(rect);
                    this.textSelected = true;
                } else {
                    this.textSelected = false; // Hide button if no selection
                }
            },
        updateButtonPosition(rect) {
            if (rect) {
                this.buttonStyle = {
                position: 'absolute',
                top: `${rect.bottom + window.scrollY}px`, // Adjust the button below the selection
                left: `${rect.right + window.scrollX}px`, // Align the button to the left of the selection
                display: 'block',
                };
            }
        },
        confirmForceLogout(){
            this.showForceLogoutDialog = false;
            this.showSnackbar({message:`Andere Geräte erfolgreich ausgeloggt.`, color:'success'})
        },
        isMounted(item){
            switch(item.name){
                case 'stundenplan':
                    return this.planMounted;
                case 'brett':
                    return this.brettMounted;
                case 'chat':
                    return this.chatMounted;
                case 'apps':
                    return this.appwidgetMounted;
                case 'hausaufgaben':
                    return this.filesystemMounted;
                case 'dateien':
                    return this.filesystemMounted;
                default: 
                return true;
            }
        },
        
        revokeUsageAgreement(){
            this.usageAgreementNotSigned = true;
            this.revokeAgreement = true;
        },

        async checkForCalendarUpdates() {
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            this.showCalendarBadge = this.eventsNotSeen > 0;
            if (this.eventsNotSeen) {
                this.isSecondPageUpdated.push('calendar');
            }
        },

        async checkForPinboardUpdates() {
            const pinboardsJson = await this.getPinboards();
            this.allPinboards = pinboardsJson;
            for(var i = 0; i < pinboardsJson.length; i++) {
                const crtPinboard = pinboardsJson[i];
                const resJson = await this.hasUserSeenPinboard( { pinboardId: crtPinboard._id, accountId: this.crtAccount._id } );
                if(!resJson.seen) {
                    this.isSecondPageUpdated.push('pinboard');
                    return;
                }
            }
        },

        async changePage(direction) {
            this.page = Math.abs((this.page + direction) %2)
            if (this.page === 0) {
                this.isFirstPageUpdated = [];
            }
            // await this.setFocusOnNextPage()

            // update query params
            await this.$router.replace({ query: { ...this.$route.query, page: this.page } });
        },

        showSidebar() {
            this.sidebar = true;
        },
        closeIntroTutorial() {
            this.introTutorial = true;
            localStorage.setItem('tutorial', 'true');
        },

        closeBBBTutorial() {
            this.showBBBTutorial = false;
            localStorage.setItem('bbbTut', 'true');
            this.openBBB();
        },

        openPopup(popup) {
            switch (popup) {
                case 'popupFileWidget':
                    this.popupFileWidget = true;
                    this.popupBrett = false;
                    this.popupChat = false;
                    this.popupApps = false;
                    break;
                case 'popupChat':
                    this.popupFileWidget = false;
                    this.popupBrett = false;
                    this.popupChat = true;
                    this.popupApps = false;
                    break;
                case 'popupBrett':
                    this.popupFileWidget = false;
                    this.popupBrett = true;
                    this.popupChat = false;
                    this.popupApps = false;
                    break;
                case 'popupApps':
                    this.popupFileWidget = false;
                    this.popupBrett = false;
                    this.popupChat = false;
                    this.popupApps = true;
                    break;
            }
        },
        vueSlickBabyViewCarouselAfterChange() {
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carouselBabyView.$refs.innerSlider.currentSlide;
        },
        vueSlickCarouselAfterChange() {
            console.count('vueSlickCarouselAfterChange');
            this.slideChangeAllowed = true;
            this.currSlide = this.$refs.carousel.$refs.innerSlider.currentSlide;
        },
        handleKeyUp(event) {
            if (event.altKey && event.key === 'a') {
                this.clickLogoutUser()
                this.triggerShortcutTooltips();
            }
            if(event.key === 'Tab' && !this.tabPressedOnce && this.babyView) {
                this.triggerTabPressedOnce();
            }
            if (event.altKey && event.key === 't') {
                this.toggleKeyboard()
                this.triggerShortcutTooltips();
            }
            if (event.altKey && event.key === 'l') {
                this.toggleMagnifier();
                this.triggerShortcutTooltips();
            }
        },
        async setFocusOnNextPage() {
            await this.$nextTick();

            if(this.page === 1) {
                document.getElementById("dateienHeading").focus();
            } else if(this.page === 0) {
                document.getElementById("stundenplanHeading").focus();
            }

        },

        async subscribe() {
            navigator.serviceWorker.getRegistrations().then(async (registrations) => {
                // Register Push
                const subscription = await registrations[0].pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: this.urlBase64ToUint8Array('BKFBvlmQYh9nNP9ECrMgMj-3v__wDW8Q3M0NPcHpH3LXSq7YsYvSR_dj757TxzwL2hKr--gN6SuIyuy3H0OUgeo')
                });
                await backend.subscribe({ subscription });
            });
            this.showSnackbar({
                message: 'Benachrichtigungen aktiv für das Schwarze Brett',
                color: 'info'
            });
        },

        urlBase64ToUint8Array(base64String) {
            const padding = "=".repeat((4 - base64String.length % 4) % 4);
            const base64 = (base64String + padding)
            // eslint-disable-next-line no-useless-escape
                .replace(/\-/g, "+")
                .replace(/_/g, "/");

            const rawData = window.atob(base64);
            const outputArray = new Uint8Array(rawData.length);

            for (let i = 0; i < rawData.length; ++i) {
                outputArray[i] = rawData.charCodeAt(i);
            }
            return outputArray;
        },

        onEnd({ lastSentence, transcription }) {
            this.setSpeechControlWord(lastSentence.toLowerCase())
        },

        startBBBTut(callback) {
            this.callbackMethod = callback;
            if(this.bbbTutorialCookie) {
                this.callbackMethod();
            } else {
                this.showBBBTutorial = true;
            }
        },
        toggleControl() {
            if(!!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)) {
                if(!this.speechControl) {
                    this.toggleSpeechControl(true);
                    this.voiceControlDialog = true;
                } else {
                    this.showSnackbar({ message: 'Die Sprachsteuerung ist bereits aktiv. Lade die Seite neu um sie zu deaktivieren.', color: 'error'});
                }
            } else {
                this.showSnackbar({ message: 'Dieser Browser wird nicht unterstützt. Bitte benutzen Sie einen anderen Browser.', color: 'error'});
            }
        },
        openBBB() {
            this.callbackMethod();
        },

        resetTutorial() {
            this.introTutorial = false;
            localStorage.setItem('tutorial', 'false');
        },

        checkTutorialState() {
            this.introTutorial = JSON.parse(localStorage.getItem('tutorial'));
            this.bbbTutorialCookie = JSON.parse(localStorage.getItem('bbbTut'));
        },

        async requestAppointments(update = false) {
            this.appointments = await this.getAppointments(update);
        },

        async requestBabyViewAndAccessibilityMode() {
            let temp = await this.getMePupil();
            this.babyView = temp.babyView;
            if(temp.accessibility) {
                this.sehen = temp.accessibility.sehen;
                this.hoeren = temp.accessibility.hoeren;
                this.motorik = temp.accessibility.motorik;
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
            }
            this.loaded = true; //to check if they are babys
        },

        async requestPerson() {
            this.person = await this.getMePupil();

            //set babyView and accessibility initale
            this.babyView = this.person.babyView;
            if (this.person.accessibility) {
                this.sehen = this.person.accessibility.sehen;
                this.hoeren = this.person.accessibility.hoeren;
                this.motorik = this.person.accessibility.motorik;

                if (this.person.accessibility.sehen) {
                    this.setGlobalButtonContrast(
                        'invert(75%) saturate(200%) brightness(150%)',
                        '5px solid orange'
                    );
                }
            } else {
                this.sehen = false;
                this.hoeren = false;
                this.motorik = false;
                this.setGlobalButtonContrast('none', 'none');
            }
            return this.person;
        },

        async requestSlots() {
            this.slots = await this.getSlots();
        },

        clickLogoutUser() {
            if (this.profileManagement) {
                this.closeProfileManagement();
            }
            if (this.faq) {
                this.toggleFaq();
            }
            this.logoutUser();
            this.$router.push({ name: 'login'});
        },

        ISO8601_week_no(dt) {
            let tdt = new Date(dt.valueOf());
            let dayn = (dt.getDay() + 6) % 7;
            tdt.setDate(tdt.getDate() - dayn + 3);
            let firstThursday = tdt.valueOf();
            tdt.setMonth(0, 1);
            if (tdt.getDay() !== 4)
            {
                tdt.setMonth(0, 1 + ((4 - tdt.getDay()) + 7) % 7);
            }
            return 1 + Math.ceil((firstThursday - tdt) / 604800000);
        },

        addWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()+7);
        },
        subWeek() {
            this.date = new Date(this.date .getFullYear(), this.date .getMonth(), this.date .getDate()-7);
        },
        getMonday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6:1); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getTuesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -5:2); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getWednesday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -4:3); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getThursday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -3:4); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getFriday(d) {
            d = new Date(d);
            let day = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -2:5); // adjust when day is sunday
            return new Date(d.setDate(diff));
        },
        getAppointment(slot, day) {
            switch (day) {
                case 'mon':
                    day = this.getMonday(this.date);
                    break;
                case 'die':
                    day = this.getTuesday(this.date);
                    break;
                case 'mit':
                    day = this.getWednesday(this.date);
                    break;
                case 'don':
                    day = this.getThursday(this.date);
                    break;
                case 'fre':
                    day = this.getFriday(this.date);
                    break;
            }
            return this.appointments.find((el) => {
                return (el.timeslot === slot && this.sameDay(new Date(el.day), day))
            })
        },

        sameDay(first, second) {
            return   first.getFullYear() === second.getFullYear() &&
                first.getMonth() === second.getMonth() &&
                first.getDate() === second.getDate();
        },

        async changeLang(newLang, newLangImg) {
            const langChanged = await this.setTargetLang(newLang);
            this.currentTTSLangImg = langChanged ? newLangImg : this.currentTTSLangImg;
        },

        reloadPage() {
            this.$router.go();
        },

        /*btmNav methods*/
        async switchSlide(index) {
            this.closeFaq();
            if(this.slideChangeAllowed && index !== this.currSlide) {
                if (this.babyView) {
                    if(!this.swiperInstance) {
                        await new Promise(resolve => setTimeout(resolve, 2000));
                    }
                    this.swiperInstance.slideTo(index);
                } else {
                    if(!this.swiperInstance) {
                        await new Promise(resolve => setTimeout(resolve, 2000));
                    }
                    this.swiperInstance.slideTo(index);
                }
                this.currSlide = index;
                //this.slideChangeAllowed = false;
                // set weeklyView to closed on change
                this.sidebar = false;
                 this.sidebarMobile = false;
                this.closeWeeklyView();

                // add page to query
                this.$router.replace({ query: {page: this.currSlide } });
           }else if(index === this.currSlide){
                this.reloadWidget(index)
            }
        },
        reloadWidget(index){
            switch(index){
                  case 0:
                    this.reloadPlan =true;
                    break;
                case 1:
                     this.reloadBrett = true;
                     break;
                case 2:
                    this.reloadChat = true;
                    break;
                case 3:
                   this.reloadFilesystem = true;
                    break;
                case 4:
                    this.reloadAppwidget = true;
                    break;
            }
        },
        onOrientationChange() {
            let index = 0;
            if (this.$router.currentRoute.query.page >= 1) {
                index = 3;
            }
            this.switchSlide(index);
            this.slideChangeAllowed = true;
        },
        clickTTS() {
            this.$refs.tts.$el.click();
        },
        clickTTSFloat() {
            this.$refs.ttsFloat.$el.click();
        },

        /**
         * Returns the value/text to display in the badge for given widget index
         * 0 = timetable, 1 = blackboard, 2 = chat, 3 = file widget, 4 = app
         * @param index of the widget you want the badge for
         */
        getBadge(index) {
            switch (index) {
                case 0:
                    return this.timetableBadge;
                case 1:
                    return this.blackboardBadgeEventList.length;
                case 2:
                    return this.chatBadgeEventList.length;
                case 3:
                    return this.fileWidgetBadge;
                case 4:
                    return this.appBadge;
                default:
                    return false;
            }
        },

        async requestPrivacyPolicyTutorialInfo() {
            const today = new Date();
            this.privacyPolicyTutorialInfo = await this.getTutorialProperties({
                videoTutorialFilename: 'privacyPolicyTutorial',
                privacyPolicy: true,
                nextShowDate: new Date(today.getFullYear(), today.getMonth() + 6, today.getDate(), today.getHours(), today.getMinutes(), today.getSeconds(), today.getMilliseconds()),
            })
            if(this.privacyPolicyTutorialInfo.timesShownRemaining < 3) {
                this.forcePrivacyPolicyTutorial = false;
            }
            return (this.privacyPolicyTutorialInfo.timesShownRemaining > 0 && this.privacyPolicyTutorialInfo.seeAgain)
                || Date.parse(this.privacyPolicyTutorialInfo.nextShowDate) < Date.now();
        },

        async closePrivacyPolicyTutorial() {
            let data;
            let tutInfo = this.getPrivacyPolicyTutorialInfo;
            if(Date.parse(tutInfo.nextShowDate) < Date.now()) {
                const today = new Date();
                data = {
                    videoTutorialFilename: 'privacyPolicyTutorial',
                    timesShownRemaining: 3,
                    nextShowDate: new Date(today.getFullYear(), today.getMonth() + 6, today.getDate(), today.getHours(), today.getMinutes()),
                }
            } else {
                data = {
                    videoTutorialFilename: 'privacyPolicyTutorial',
                    timesShownRemaining: tutInfo.timesShownRemaining - 1,
                }
            }
            await this.updateTutorialProperties(data);
            this.setPrivacyPolicyState(false);
        },

        openCalendar() {
            this.showCalendarWidget = true;
        },

        async hasUserNewEvents() {
            this.showCalendarBadge = false;
            const eventsNotSeen = await this.getUnseenEventsForUser();
            this.eventsNotSeen = eventsNotSeen.length;

            if (this.eventsNotSeen > 0) {
                this.showCalendarBadge = true;
            }
        },

        handleAudioUrl(audioUrl) {
            this.audioOverlay = true;
            this.audioUrl = audioUrl;
            setTimeout(() => {
                const audioPlayer = document.getElementById('audioPlayer');
                audioPlayer.play();
            }, 500);
        },

        checkQueryForPage() {
            if (this.$route.query.page) {
                this.initialSlide = parseInt(this.$route.query.page);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
    .backgroundImg {
        background-image: url(../../assets/Bilder/scribble.svg);
        background-repeat: repeat;
        height: 100%;
    }

    table, th, td {
        border: 1px solid black;
        border-collapse: collapse;
    }

    tr {
        height: 40px;
    }

    table tr th:first-child,
    table tr td:first-child {
        width: 10px !important;
    }

    table tr th,
    table tr td {
        width: 9em;
        border: none;
    }
    table, tr, td {
        border: none;
    }

    .fach {
        cursor: pointer;
    }

    .small {
        display: none;
    }

    .big {
        display: flex;
    }

    .slick-slider {
        user-select: auto!important;
    }

    .ellipsis {
        text-overflow: ellipsis;

        /* Required for text-overflow to do anything */
        white-space: nowrap;
        overflow: hidden;
    }

    .greyText {
        color: rgb(var(--v-theme-dunkelgrau));
    }

    .roundProfilePic {
        border-radius: 8px;
        height: 50px;
        width: 50px;
    }

    .roundProfilePicBtmNav {
        border-radius: 50%;
        height: 25px;
        width: 25px;
    }

    .btmNav {
        display: none;
        z-index: 11;
        position: fixed;
        bottom: 0;
        width: 100% !important;
        height: 70px !important;
        background-color: rgb(var(--v-theme-headerblue));
    }

    .btmNavBtn {
        height: 50px !important;
        width: 50px !important;
        background-color: white !important;
        border-radius: 8px !important;
    }

    /*removes darker background after btn is clicked
    also works without the specific class in front*/
    .btmNavBtn.v-btn:before {
        opacity: 0 !important;
    }
    .arrowBtn:before {
      opacity: 0 !important;
    }

    // .arrowBtn:focus {
    //     background-color: rgb(var(--v-theme-primary)) !important;
    // }

    .imgFocus {
      filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%) !important;
    }

/*    .buttonFocusHover:hover {
      background-color: #6994cd !important;
    }*/

    .buttonFocusHover:hover .imgFocusHover{
      filter: invert(51%) sepia(66%) saturate(310%) hue-rotate(175deg) brightness(98%) contrast(91%) !important;
    }

    .btmNavIcon {
        height: 35px;
    }

    .hideMe {
        display: none !important;
    }

    .pointer {
        cursor: pointer;
    }

    .hiddenBtmNavBtn {
        height: 50px !important;
        width: 50px !important;
        background-color: transparent !important;
        border-radius: 8px !important;
    }

    @media only screen and (max-width: 900px) {
        .small {
            display: block !important;
        }

        .big {
            display: none !important;
        }

        .topPadding {
            padding-top: 12px;
        }

        .displayHide {
            display: none;
        }

        .btmNav {
            display: block;
        }

        .reload {
            display: block;
            position: fixed;
            bottom: 0;
            width: 100% !important;
            margin-bottom: 78px !important;
        }
    }

    @media only screen and (max-width: 500px), (max-width: 900px) and (orientation: portrait) {
        .displayHide {
            display: none;
        }
    }

    .animateIcon {
        overflow: hidden;
    }

    .animateIcon:after {
        animation: shine 60s ease-in-out infinite;
        animation-fill-mode: forwards;
        content: "";
        position: absolute;
        top: -110%;
        left: -210%;
        width: 200%;
        height: 200%;
        opacity: 0;
        transform: rotate(30deg);

        background: rgba(255, 255, 255, 0.0);
        background: linear-gradient(
                to right,
                rgba(112, 112, 112, 0.2) 0%,
                rgba(112, 112, 112, 0.2) 77%,
                rgba(112, 112, 112, 0.5) 92%,
                rgba(112, 112, 112, 0.0) 100%
        );
    }

    /* Hover state - trigger effect */

    /* Active state */
    .animateIcon:active:after {
        opacity: 0;
    }

    @keyframes shine{
        1% {
            opacity: 1;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
            transition-duration: 0.7s, 0.7s, 0.15s;
            transition-timing-function: ease;
        }
        5% {
            opacity: 0;
            top: -30%;
            left: -30%;
            transition-property: left, top, opacity;
        }
    }

    .v-snack.v-snack--top {
        bottom: initial; /* or auto */
    }

    .v-snack.v-snack--bottom {
        top: initial; /* or auto */
    }

    .iconToWhite {
        filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
    }

    .iconToHeaderblue {
        filter: brightness(0) saturate(100%) invert(21%) sepia(43%) saturate(391%) hue-rotate(183deg) brightness(94%) contrast(92%);
    }
</style>
