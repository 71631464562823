<template>
    <div
        class="d-flex flex-row align-center"
        :class=" !small ? 'actionbar hidden-print' : 'ml-8'"
    >
        <div class="d-flex align-center">
            <div>
                <v-badge
                    color="green"
                    location="top left"
                    class="greenBadge"
                    offset-x="-12"
                >
                    <p style="font-size:10px;">
                        Schritt für Schritt Anleitung
                    </p>
                </v-badge>
            </div>
            <div class="ml-8">
                <v-badge
                    color="red"
                    location="top left"
                    offset-x="-12"
                    class="redBadge"
                >
                    <p style="font-size: 10px;">
                        Meldung
                    </p>
                </v-badge>
            </div>
        </div>
    </div>    	
</template>

<script>
import { mapState, mapMutations, mapActions } from "vuex";
import { AuthorizationError, ConnectionRefusedError } from '../../util/error';

// v-if="process.env.NODE_ENV === 'development'"
export default {
    name: "BadgeExplanantions",
    props: { small:{default: false,}},
    data: () => ({
        
    }),

};
</script>
<style scoped>
.actionbar {
    position: fixed;
    bottom: 8px;
    left: 40px;
    z-index: 1;
}
.v-badge__badge{
    font-size: 8px;
}

@media only screen and (max-width: 500px),
    (max-width: 900px) and (orientation: portrait) {
    .actionbar {
        bottom: 8px;
    }
}

@media only screen and (max-width: 900px) {
    .actionbar {
        bottom: 75px;
    }
}

@media print {
    .hidden-print {
        display: none !important;
    }
}

</style>
<style>
.redBadge .v-badge__badge{
    padding: 0px;
}
.greenBadge .v-badge__badge {
  border-radius: 3px;
  height: 18px;
  padding: 2px;
  cursor: pointer;
  top:-10px !important;
}
</style>