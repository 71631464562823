<template>
    <v-dialog
        :model-value="showDialog"
        width="400"
        style="80vh;"
        @update:model-value="closeDialog"
    >
        <v-card
            class="cardStyle"
            style="height: 80vh; overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="d-flex align-center justify-space-between"
                style="height: 56px; background-color: rgb(var(--v-theme-chatColor))"
            >
                <div>
                    <p
                        v-if="windowWidth > 324"
                        style="color: white"
                    >
                        Chat erstellen
                    </p>
                    <p
                        v-else
                        style="color: white"
                    >
                        Chat erst.
                    </p>
                </div>
                <div>
                    <v-tooltip
                        v-if="!isGroupChatCreatable"
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                v-if="!isGroupChatCreatable"
                                size="x-small"
                                variant="text"
                                min-width="20"
                                style="margin-left: auto"
                                color="white"
                                v-bind="props"
                                @click="createGroupChat"
                            >
                                <img
                                    :src="saveIcon"
                                    class="icon20 iconToWhite"
                                    alt="speichern"
                                >
                            </v-btn>
                        </template>
                        <span>Erstellen</span>
                    </v-tooltip>
                    <v-tooltip
                        location="bottom"
                    >
                        <template v-slot:activator="{ props }">
                            <v-btn
                                id="closeButton"
                                size="x-small"
                                variant="text"
                                elevation="0"
                                class="pa-0 pl-1"
                                style="padding: 5px"
                                v-bind="props"
                                @click="closeDialog"
                            >
                                <img
                                    :src="cancelIcon"
                                    class="icon20 iconToWhite"
                                    alt="schließen"
                                >
                            </v-btn>
                        </template>
                        <span>Schließen</span>
                    </v-tooltip>
                </div>
            </v-card-title>
            <v-card-text
                style="height: calc(85dvh - 120px); padding: 5px 10px;"
            >
                <div
                    class="d-flex flex-row align-center"
                >
                    <icon-picker
                        :current-color="selectedColor"
                        :callback="(color) => { this.selectedColor = color; }"
                        :current-icon="this.selectedIcon"
                        :callback-icon="(icon) => { this.selectedIcon = icon; }"
                        :group-icons="icons"
                        color-picker-usage="createGroupChat"
                        @change-icon="(icon) => { this.selectedIcon = icon; }"
                    />
                    <div
                        class="d-flex flex-column pl-6"
                        style="width: 307px"
                    >
                        <p>
                            Gruppenname<span class="text-red bold">*</span>
                        </p>
                        <v-text-field
                            v-model="newGroupChatName"
                            variant="underlined"
                            density="compact"
                            hide-details
                            placeholder="Gruppenname eingeben"
                            style="max-width: 500px"
                        />
                    </div>
                </div>
                <h1 class="header">
                    Teilnehmer hinzufügen (mind. 2)<span class="text-red bold">*</span>
                </h1>
                <div class="d-flex align-center">
                    <v-text-field
                        v-model="searchTreeView"
                        variant="underlined"
                        density="compact"
                        hide-details
                        clearable
                        placeholder="Name suchen..."
                        style="max-width: 500px"
                    >
                        <template v-slot:prepend-inner>
                            <img
                                :src="searchIcon"
                                alt="Suche"
                                class="icon mr-2"
                            >
                        </template>
                    </v-text-field>
                </div>
                <simple-bar
                    :auto-hide="false"
                    class="scrollContainer"
                >
                    <v-treeview
                        v-model:selected="selectedAccountSetIds"
                        :items="selectableAccountSets"
                        item-title="displayName"
                        select-strategy="classic"
                        item-key="_id"
                        item-value="_id"
                        :search="searchTreeView"
                        selected-color="rgb(var(--v-theme-chatColor))"
                        selectable
                        dense
                        class="mt-2"
                        style="font-size: 16px; color: black;"
                    />
                </simple-bar>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import IconPicker from "@/components/Utils/IconPicker";

import searchIcon from "@/assets/Icons/lupe-18.svg";
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import saveIcon from '@/assets/Icons/FaceliftIcons/speichern.svg';
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";
import groupIcon from "@/assets/Icons/group.svg";

export default {
    name: "ChatRoomCreateGroupChatDialog",
    components: {
        IconPicker,
    },
    data: () => ({
        showDialog: true,
        newGroupChatName: "",
        searchTreeView: "",
        searchIcon,
        cancelIcon,
        saveIcon,
        selectedAccountSetIds: [],
        selectedColor: 'rgb(var(--v-theme-chatColor))',
        selectedIcon: groupIcon,
        selectableAccountSets: [],

        icons: [
            {icon: groupIcon, text: "Gruppen-Icon"},
            {icon: avatar1, text: "avatar1"},
            {icon: avatar2, text: "avatar2"},
            {icon: avatar3, text: "avatar3"},
            {icon: avatar4, text: "avatar4"},
            {icon: avatar5, text: "avatar5"},
            {icon: avatar6, text: "avatar6"},
            {icon: avatar7, text: "avatar7"},
            {icon: avatar8, text: "avatar8"},
            {icon: avatar9, text: "avatar9"},
            {icon: avatar10, text: "avatar10"},
            {icon: avatar11, text: "avatar11"},
            {icon: avatar12, text: "avatar12"},
            {icon: avatar13, text: "avatar13"},
            {icon: avatar14, text: "avatar14"},
        ],
    }),
    computed: {
        ...mapGetters("accountSets", ["accountSets"]),
        ...mapGetters("accounts", ["accountsById"]),
        ...mapGetters("groups", ["groupsById"]),
        ...mapState("auth", ["account"]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters("groups", ["groups","groupLeadersByGroupId"]),
        ...mapGetters("teachers", ["teachersById","allTeacherGroups","meTeacher"]),
        ...mapGetters("pupils", ["pupilsById"]),
        ...mapGetters("parents", ["parentsById"]),
        ...mapGetters("auth", ["accountRole"]),

        isGroupChatCreatable() {
            return (
                this.newGroupChatName.trim() === "" ||
                this.selectedAccountSetIds.length < 1
            );
        },
        myAccountSet() {
            return this.accountSets.find(
                (accountSet) =>
                    accountSet.type === "account" &&
                    accountSet.account === this.account._id
            );
        },
    },
    async mounted(){
        await this.getSelectableAccountSets();
    },
    methods: {
        ...mapActions("matrix", ["createCustomRoom"]),
        ...mapActions("chat", ["postChatRoom"]),

        async createGroupChat() {
            const memberships = this.selectedAccountSetIds.map((accountSet) => ({
                accountSet,
                role: "member",
            }));
            const myMembership = {
                accountSet: this.myAccountSet._id,
                role: "moderator",
            };

            await this.postChatRoom({
                memberships: [...memberships, myMembership],
                name: this.newGroupChatName,
                description: "",
                type: "groupChat",
                isOpen: true,
                icon: this.selectedIcon,
                color: this.selectedColor,
            });

            //   await this.createCustomRoom({
            //     groupName: this.newGroupChatName,
            //     members: this.newGroupChatMembers,
            //   });
            this.closeDialog();
        },

        closeDialog() {
            this.$emit("closeDialog");
        },
        async getSelectableAccountSets(){
            const getAccountSetDisplayName = (accountSet) => {
                if (accountSet.type === "account") {
                    const account = this.accountsById[accountSet.account];
                    return account ? account.displayName : account._id;
                } else if (accountSet.type === "groupLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Leiter`;
                } else if (accountSet.type === "groupAppointmentLeaders") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Fachlehrer`;
                } else if (accountSet.type === "groupParticipants") {
                    const group = this.groupsById[accountSet.group];
                    return `Gruppe "${group?.name}" Mitglieder`;
                } else if (accountSet.type === "allTeachers") {
                    return "Alle Lehrer";
                } else if (accountSet.type === "allMaintainers") {
                    return "Verwaltung";
                } else if (accountSet.type === "allPupils") {
                    return "Alle Schüler";
                } else if (accountSet.type === "allParents") {
                    return "Alle Eltern";
                }
            };

            this.selectableAccountSets =  this.accountSets
                .filter((accountSet) => accountSet._id !== this.myAccountSet?._id)
                .map((accountSet) => ({
                    ...accountSet,
                    displayName: getAccountSetDisplayName(accountSet),
                }))
                .filter((accountSet) => accountSet.displayName && !accountSet.displayName.includes("PersonAlle"))
                .filter((accountSet) => accountSet.type !== 'allMaintainers');
            const alleSet = this.selectableAccountSets.filter(set => set.type === 'groupParticipants' && set.displayName === 'Gruppe "Alle" Mitglieder')
            const groupAlleId = alleSet[0].group;
            this.selectableAccountSets = this.selectableAccountSets.filter(set => {
                if(set.group){
                    return set.group !== groupAlleId;
                }else{
                    return true;
                }
            })
            if(this.accountRole === 'teacher' && !this.meTeacher.fullChatRights){
                        // Using a Set directly to avoid duplicates early on
                const uniqueAccountsSet = new Set();

                // Process teacher group participants and leaders in one loop to avoid multiple iterations
                this.allTeacherGroups.forEach(group => {
                    // Process participants
                    group.participants.forEach(participantId => {
                        const participant = this.pupilsById[participantId];
                        if (participant) {
                            uniqueAccountsSet.add(participant.account); // Add participant's account

                            // Check and add parent account if it exists
                            if (participant.parent) {
                                const parent = this.parentsById[participant.parent];
                                if (parent) uniqueAccountsSet.add(parent.account); // Add parent's account
                            }
                        }
                    });

                    // Process leaders
                    group.leaders.forEach(leaderId => {
                        const leader = this.teachersById[leaderId];
                        if (leader) uniqueAccountsSet.add(leader.account); // Add leader's account
                    });
                });

                // Convert Set to Array to ensure uniqueness
                const uniqueAccounts = Array.from(uniqueAccountsSet);

                // Filter `selectableAccountSets` based on unique accounts and role criteria
                this.selectableAccountSets = this.selectableAccountSets.filter(set => {
                    const accountObj = this.accountsById[set.account];
                    const accountRole = accountObj?.role;
                    
                    if (accountRole === 'pupil' || accountRole === 'parent') {
                        // Return true only if the account is in the uniqueAccountsSet
                        return uniqueAccountsSet.has(set.account.toString());
                    } 
                    // Maintainers and teachers are automatically included
                    return true;
                });
                const removeTypes = ['allPupils','allTeachers','allParents'];
                const allTeacherGroupIds = this.allTeacherGroups.map(group =>group._id);
                this.selectableAccountSets = this.selectableAccountSets.filter(set=>{
                    if(set.group){
                        return allTeacherGroupIds.includes(set.group.toString())
                    }else{
                        return true
                    }
                }).filter(set=> !removeTypes.includes(set.type));
            }
        }
    },
};
</script>

<style lang="scss">
.cardStyle {
    .v-card__text {
        // unify border-radius
        .v-btn {
            border-radius: 8px;
        }
        .v-input__control > .v-input__slot {
            border-radius: 8px;
        }

        // remove border bottom line/underline from all the inputs in v-cards with this class
        .v-input__control > .v-input__slot:before,
        .v-input__control > .v-input__slot:after {
            border-style: none !important;
        }
    }
}
</style>
<style scoped lang="scss">
.chat-header {
    background-color: rgb(var(--v-theme-chatColor));
    border-radius: 15px 15px 0 0;
    font-size: larger;
    padding-top: 20px;
    padding-bottom: 20px;
    text-align: center;
    color: white;
}



.scroll-area {
    flex: 1;
}

.header {
    font-size: 16px;
    margin-top: 15px !important;
    margin-bottom: 15px !important;
}

.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%)
    hue-rotate(36deg) brightness(104%) contrast(105%);
}

.scrollContainer {
    //necessary to set a maximum height to enable scrolling (can also just be height)
    max-height: calc(55vh - 60px);
    //necessary if we want to disable horizontal scroll
    overflow-x: hidden;
}
</style>
