const defaultState = {
    // Boolean to stop the touch-slide function of the Vue-Slick-Carousel on mobile
    blockMobileWidgetChangeByDrag: false,
    //Boolean to hide navbar on chat focus
    hideNavbar: false,
};

const mutations = {
    setBlockMobileWidgetChangeByDrag(state, value) {
        state.blockMobileWidgetChangeByDrag = value;
    },
    setHideNavbar(state, value) {
        state.hideNavbar = value;
    },
};
const getters = {
    getHideNavbar:(state) => state.hideNavbar,
};
export default {
    namespaced: true,
    state: defaultState,
    mutations,
    getters,
};
