<template>
    <div style="justify-content: flex-end; align-items: flex-end; position: absolute; pointer-events: none;">
        <v-tooltip location="top">
            <template v-slot:activator="{ props }">
                <v-btn
                    ref="pinboardElMenuButton"
                    size="x-small"
                    icon="$vuetify"
                    variant="plain"
                    elevation="0"
                    class="ml-3 pa-0 pinboardButton pinboardButtonPosition"
                    v-bind="props"
                    @click="toggleMenu"
                >
                    <img
                        :src="optionsIcon"
                        style="height: 25px; filter: brightness(1000%); opacity: 1;"
                        :alt="openMenu ? 'Menü schließen' : 'Menü öffnen'"
                    >
                </v-btn>
            </template>
            <span>Weitere Aktionen</span>
        </v-tooltip>
        <div
            v-if="openMenu"
            v-click-outside="clickOutsideMenu"
            class="pinboardTileMenuContainer"
        >
            <simplebar
                class="scroll-area"
                data-simplebar="init"
            >
                <div
                    class="d-flex justify-center align-center mt-1"
                    style="display: flex; flex-direction: column; overflow-x: hidden; overflow-y: auto"
                >
                    <v-btn
                        elevation="0"
                        color="white"
                        class="pa-0 text-none pinboardTileMenuElement"
                        @click="openEditDialog"
                    >
                        <img
                            :src="stiftIcon"
                            class="iconToDunkelgrau"
                            style="height: 20px; margin-left: 13px"
                        >
                        <p
                            class="pl-2"
                        >
                            Bearbeiten
                        </p>
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="error"
                        class="pa-0 text-capitalize pinboardTileMenuElement"
                        @click="emitButtonPress('deleteItem')"
                    >
                        <img
                            :src="papierkorbIcon"
                            alt=""
                            class="pinboardTileMenuElementIcon"
                            style="filter: brightness(1000%); width: 6%; height: 6%; margin-left: 13px"
                        >
                        <p
                            class="pl-2"
                            style="color: white"
                        >
                            Löschen
                        </p>
                    </v-btn>
                    <v-btn
                        elevation="0"
                        color="white"
                        class="pa-0 text-capitalize pinboardTileMenuElement"
                        @click="toggleMenu()"
                    >
                        <v-icon
                            class="pinboardTileMenuElementIcon"
                            alt=""
                        >
                            mdi-close
                        </v-icon>
                        <p
                            class="pl-2"
                        >
                            Abbrechen
                        </p>
                    </v-btn>
                </div>
            </simplebar>
        </div>
        <PinboardCreateDialog
            v-if="showPinboardCreateDialog"
            :item="item"
            :edit-mode="true"
            :update-pinboard="updatePinboard"
            @close="showPinboardCreateDialog = false; closeCreateDialog()"
            @close-button-clicked="showPinboardCreateDialog = false;"
        />
    </div>
</template>

<script>
import stiftIcon from "@/assets/Icons/bearbeiten-komplimentär-weiß-88.svg";
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import optionsIcon from "@/assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import PinboardCreateDialog from "./PinboardCreateDialog";
import simplebar from 'simplebar-vue';
import 'simplebar-vue/dist/simplebar.min.css';

export default {
    name: "PinboardTileMenu",
    components: {
        PinboardCreateDialog,
        simplebar,
    },
    props: {
        item: {required: true, type: Object},
        updatePinboard: {required: true, type: Function},
        closeCreateDialog: {required: true, type: Function}
    },
    data: () => ({
        openMenu: false,
        stiftIcon,
        papierkorbIcon,
        optionsIcon,
        showPinboardCreateDialog: false,
    }),
    methods: {
        toggleMenu() {
            this.openMenu = !this.openMenu;
            this.$emit('openMenu', this.openMenu);
        },
        openEditDialog() {
            this.showPinboardCreateDialog = true;
        },
        emitButtonPress(identifier, payload = null) {
            this.$emit(identifier, payload);
        },
        clickOutsideMenu(event) {
            // Check if event.path contains pinboardElMenuButton
            if (event.path && !event.path.includes(this.$refs.pinboardElMenuButton.$el)) {
                this.openMenu = false;
                this.$emit('openMenu', false);
            }

            if (!event.path) {
                const path = [];
                let currentElem = this.target;
                while (currentElem) {
                    path.push(currentElem);
                    currentElem = currentElem.parentElement;
                }
                if (!path.includes(this.$refs.pinboardElMenuButton.$el)) {
                    this.openMenu = false;
                    this.$emit('openMenu', false);
                }
            }
        }
    }
}
</script>

<style scoped>
.scroll-area {
    position: relative;
    min-height: 125px;
    max-height: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch !important;
    align-content: flex-start;
    pointer-events: all;
    background-color: rgb(var(--v-theme-dunkelgrau));
    border-radius: 6px;
}

.pinboardButtonPosition {
    top: 3px;
    right: 13px;
}

.pinboardButton {
    height: 35px !important;
    width: 35px !important;
    min-width: 35px !important;
    background-color: transparent;
    position: absolute;
    pointer-events: all;
    box-shadow: 0 1px 1px rgba(0,0,0,0.11),
    0 2px 2px rgba(0,0,0,0.11),
    0 4px 4px rgba(0,0,0,0.11),
    0 8px 8px rgba(0,0,0,0.11),
    0 16px 16px rgba(0,0,0,0.11),
    0 32px 32px rgba(0,0,0,0.11);
    border-radius: 6px;
}

.pinboardTileMenuContainer {
    width: 90%;
    height: 100%;
    margin: auto;
    pointer-events: all;
    padding-left: 5px;
}

.pinboardTileMenuElement {
    width: 95%;
    padding: 5px;
    display: inline-flex;
    justify-content: flex-start;
    border-radius: 5px;
    margin: 1px;
    color: black;
    font-size: x-small;
}

.pinboardTileMenuElementIcon {
    width: 20px;
    color: rgb(var(--v-theme-dunkelgrau)) !important;
    margin-left: 15px;
}
</style>
