<template>
    <v-dialog
        :model-value="showDialog"
        max-width="337"
        content-class="rounded-4"
        :scrim="opacity ? `rgba(0,0,0,${opacity})`: 'rgba(0,0,0,0.5)'"
        @click:outside="close()"
        @update:model-value="updateDialog"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 16px"
        >
            <v-card-title
                class="text-h5 mb-1 d-flex align-center justify-space-between"
                :style="headerColor ? {'background-color': headerColor} :''"
            >
                <p
                    :style="headerColor ? 'color: white' :''"
                >
                    {{ usage === 'warning' ? 'Achtung!' : (accountRole === 'pupil' ? 'Bist du' : 'Sind Sie') + ' sicher?' }}
                </p>
                <v-btn
                    icon="$vuetify"
                    variant="plain"
                    color="transparent"
                    elevation="0"
                    style="margin-right: -8px"
                    @click="close(true)"
                >
                    <img
                        :src="cancelButtonIcon ? cancelButtonIcon : cancelIcon"
                        class="icon30"
                        :class="headerColor ? 'iconToWhite' : ''"
                        alt="Abbrechen"
                    >
                </v-btn>
            </v-card-title>

            <v-card-text class="mt-2">
                {{ contentText }}
            </v-card-text>

            <div
                v-if="enableCheckbox"
                class="pl-5 pr-5 pb-2 d-flex"
                style="align-items: center"
            >
                <v-checkbox
                    v-model="checkboxStatus"
                    hide-details
                    style="padding: 0; margin: 0;"
                />
                <span style="margin-left: 8px">
                    {{ checkboxText }}
                </span>
                <v-tooltip
                    v-if="checkboxInfoText !== ''"
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <img
                            :src="infoIcon"
                            class="icon20 "
                            alt="Info"
                            v-bind="props"
                        >
                    </template>
                    <span style="max-width:300px">{{ checkboxInfoText }}</span>
                </v-tooltip>
            </div>
            <div
                v-if="secondCheckbox"
                class="pl-5 pr-5 pb-2 d-flex"
                style="align-items: center"
            >
                <v-checkbox
                    v-model="secondCheckboxStatus"
                    hide-details
                    style="padding: 0; margin: 0;"
                />
                <span style="margin-left: 8px">
                    {{ secondCheckboxText }}
                </span>
                <v-tooltip
                    v-if="secondCheckboxInfoText !== ''"
                    location="top"
                    :offset="[0, 40]"
                >
                    <template v-slot:activator="{ props }">
                        <img
                            :src="infoIcon"
                            class="icon20 "
                            alt="Info"
                            v-bind="props"
                        >
                    </template>
                    <span style="max-width:300px">{{ secondCheckboxInfoText }}</span>
                </v-tooltip>
            </div>
            <div
                v-if="usage==='exchange'"
                class="d-flex flex-row justify-space-between px-4"
            >
                <v-text-field
                    v-model="password"
                    :type="showPassword ? 'text' : 'password'"
                    variant="outlined"
                    density="compact"
                    hide-details
                    placeholder="eklara Passwort eingeben"
                    class="inputField mb-2"
                    style="max-width: 80%"
                    @blur="clickVerifyPassword"
                >
                    <template v-slot:append>
                        <img
                            tabindex="0"
                            role="button"
                            :alt="showPassword ? 'Passwort ausblenden' : 'Passwort anzeigen'"
                            :src="showPassword ? eyeIcon : eyeOffIcon"
                            style="display: block; height: 25px; margin-top: -5px; color: #747474; margin-right: 5px"
                            @click="showPassword = !showPassword"
                        >
                    </template>
                </v-text-field>
                <v-btn
                    background-color="green"
                    class="mb-2"
                    style="background-color: rgb(var(--v-theme-chatColor)); color: white;height: 30px; max-width:28px; min-width: 28px;"
                >
                    <img
                        class="iconToWhite icon20"
                        alt="bestätigen"
                        :src="checkIcon"
                    >
                </v-btn>
            </div>

            <v-card-actions class="d-flex justify-center mb-2">
                <v-btn
                    class="optionBtn text-none ml-2"
                    style="color:white"
                    :style="cancelBtnColor ? `background-color:  ${cancelBtnColor}` : 'background-color: #F5F5F5'"
                    @click="close(true)"
                >
                    <img
                        v-if="cancelButtonIcon"
                        :src="cancelButtonIcon"
                        class="icon mr-2 iconToWhite"
                        alt="abbrechen"
                    >
                    {{ windowWidth >= 350 ? cancelButtonText : !cancelButtonIcon ? cancelButtonText : '' }}
                </v-btn>
                <v-spacer />
                <v-btn
                    class="optionBtn text-none ml-2"
                    style="color:white;"
                    :style="{'background-color': okBtnColor}"
                    :disabled="disableButton"
                    @click="okClicked"
                >
                    <img
                        v-if="okButtonIcon"
                        :src="okButtonIcon"
                        class="icon iconToWhite mr-2"
                        alt="bestätigen"
                    >
                    {{ okButtonText }}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import cancelIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import papierkorbIcon from '../../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import eyeIcon from "@/assets/Icons/eye.svg";
import eyeOffIcon from "@/assets/Icons/eye-off.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import infoIcon from '@/assets/Icons/FaceliftIcons/info_hinweis.svg';
import * as backend from '@/api/backend';
import {mapGetters, mapState, mapMutations} from "vuex";

export default {
    name: 'AreYouSurePopup',
    props: {
        showDialog: { required: true, type: Boolean },
        contentTextProp: { required: false, type: String, default: '' },
        cancelButtonText: {required: false, type: String, default: 'Abbrechen' },
        okButtonText: { required: false, type: String, default: 'Löschen' },
        enableCheckbox: { required: false, type: Boolean, default: false },
        secondCheckbox: { required: false, type: Boolean, default: false },
        secondCheckboxText: {required: false, type: String },
        secondCheckboxInfoText: {required: false, type: String, default: '' },
        checkboxText: { required: false, type: String },
        checkboxInfoText: { required: false, type: String, default: '' },
        opacity: {required: false, type: String },
        usage: { required: false, type: String },
        headerColor: { required: false, type: String, default: '' },
        okButtonIcon: { required: false, default: papierkorbIcon },
        cancelButtonIcon: { required: false, default: cancelIcon },
        okBtnColor: { required: false, type: String, default: 'rgb(var(--v-gruen))'},
        cancelBtnColor: { required: false, type: String, default: 'grey'},
    },
    emits: ['update:showDialog', 'close', 'okCheckboxTrue', 'okCheckboxFalse', 'ok', 'okTwoCheckboxes'],
    data() {
        return {
            eyeIcon,
            eyeOffIcon,
            cancelIcon,
            checkIcon,
            infoIcon,
            // eslint-disable-next-line
            papierkorbIcon,
            checkboxStatus: false,
            password: '',
            showPassword: false,
            verifyPassword: false,
            secondCheckboxStatus: true,
        }
    },
    computed: {
        ...mapGetters("auth", ["accountRole", "accountId"]),
        ...mapState("util", ["windowWidth"]),

        contentText() {
            return this.contentTextProp || 'Wollen Sie diese Aktion wirklich ausführen?';
        },
        disableButton(){
            return this.usage === 'exchange' && !this.verifyPassword;
        }
    },
    methods: {
        ...mapMutations('snackbar', ['showSnackbar']),

        updateDialog(value){
            this.$emit('update:showDialog', value);
        },
        close(cancel) {
            this.$emit('close', cancel);
        },
        okClicked() {
            if(this.enableCheckbox && this.secondCheckbox){
                this.$emit('okTwoCheckboxes', this.checkboxStatus, this.secondCheckboxStatus)
            }
            else if (this.enableCheckbox && !this.secondCheckbox) {
                if (this.checkboxStatus) {
                    this.checkboxStatus = false;
                    this.$emit('okCheckboxTrue');
                } else {
                    this.$emit('okCheckboxFalse');
                }
            } else {
                this.$emit('ok');
            }
        },
        async clickVerifyPassword(){
            const res = await backend.isValidPassword(this.accountId, {
                    accountPassword: this.password,
                });
                if(res.status === 200){
                    this.verifyPassword = true;
                }else{
                    this.verifyPassword = true;
                    this.showSnackbar({message: 'Falsches Passwort eingegeben!', color: 'error'});
                }
        }
    }
}
</script>

<style scoped>
.icon {
    height: 20px;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}
</style>
