// src/plugins/vuetify.js
import { createVuetify } from 'vuetify';
import * as vuetifycomponents from 'vuetify/components';
import * as directives from 'vuetify/directives';
import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import 'typeface-roboto/index.css';
import 'vuetify/styles';
import { VTimePicker } from 'vuetify/labs/components';
import { VTreeview } from 'vuetify/labs/VTreeview';
import { de } from 'vuetify/locale'
const prefersDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches;

export default createVuetify({
    components:{
        ...vuetifycomponents,
        VTreeview,
        VTimePicker,
    },
    directives,
    locale: {
        locale: 'de', // Default locale
        fallback: 'en', // Fallback locale
        messages: {
            de,
        },
    },
    theme: {
        defaultTheme: 'light', 
        themes: {
            light: {
                colors: {
                    // Vuetify default light theme colors
                    background: '#FFFFFF',
                    surface: '#FFFFFF',
                    primary: '#6996cd',
                    secondary: '#3ba934',
                    error: '#B00020',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00',
                    
                    // Custom colors
                    headerblue: '#343e55',
                    widgetBg: '#ffffff',
                    weiss: '#f8f8f8',
                    hellgrau: '#eaeaea',
                    grau: '#dbdbdb',
                    dunkelgrau: '#707070',
                    editorprimary: '#3ba934',
                    editorWrong: '#E62D2D',
                    editorNeutral: '#538ECB',
                    editorGrey: '#ededed',
                    fileArchiveOrange: '#FF6633',
                    dunkelgruen: '#3caa69',
                    chatColor: '#3ba934',
                    exchangeprimary: '#3caa69',
                    userColor: '#E6272F',
                    timetableColor: '#6995CD',
                    sickColor: '#ef2b2a',
                    blackboardGrey: '#3c3d3b',
                    parentLetterPurple: '#95509c',
                    gelb: '#fac837',
                    hellorange: '#f5a032',
                    orange: '#f07d32',
                    pink: '#e6415a',
                    rot: '#e6231e',
                    hellblau: '#6996cd',
                    blau: '#505faa',
                    gruen: '#3ba934',
                    fgelb: '#f5be2d',
                    forange: '#f0a03c',
                    fpink: '#e67dff',
                    frot: '#d50000',
                    fhellblau: '#009ae4',
                    fblau: '#3f4fb3',
                    fhellgruen: '#2fb277',
                    fgruen: '#0b8348',
                    fmittelblau: '#7587c7',
                    flila: '#94519B',
                    fgrau: '#bdbdbd',
                    fdunkelgrau: '#636363',
                },
            },
            dark: {
                colors: {
                    // Vuetify default dark theme colors
                    background: '#121212',
                    surface: '#121212',
                    primary: '#BB86FC',
                    secondary: '#03DAC6',
                    error: '#CF6679',
                    info: '#2196F3',
                    success: '#4CAF50',
                    warning: '#FB8C00',

                    // Custom colors
                    headerblue: '#343e55',
                    widgetBg: '#ffffff',
                    weiss: '#f8f8f8',
                    hellgrau: '#eaeaea',
                    grau: '#dbdbdb',
                    dunkelgrau: '#707070',
                    editorprimary: '#3ba934',
                    editorWrong: '#E62D2D',
                    editorNeutral: '#538ECB',
                    editorGrey: '#ededed',
                    fileArchiveOrange: '#FF6633',
                    dunkelgruen: '#3caa69',
                    chatColor: '#3ba934',
                    exchangeprimary: '#3caa69',
                    userColor: '#E6272F',
                    timetableColor: '#6995CD',
                    sickColor: '#ef2b2a',
                    blackboardGrey: '#3c3d3b',
                    parentLetterPurple: '#95509c',
                    gelb: '#fac837',
                    hellorange: '#f5a032',
                    orange: '#f07d32',
                    pink: '#e6415a',
                    rot: '#e6231e',
                    hellblau: '#6996cd',
                    blau: '#505faa',
                    gruen: '#3ba934',
                    fgelb: '#f5be2d',
                    forange: '#f0a03c',
                    fpink: '#e67dff',
                    frot: '#d50000',
                    fhellblau: '#009ae4',
                    fblau: '#3f4fb3',
                    fhellgruen: '#2fb277',
                    fgruen: '#0b8348',
                    fmittelblau: '#7587c7',
                    flila: '#94519B',
                    fgrau: '#bdbdbd',
                    fdunkelgrau: '#636363',
                },
            },
        },
    },
    icons: {
        defaultSet: 'mdi',
    },
});
