<template>
    <div
        ref="editorAudioMessageContainer"
        class="audioMessageOuter"
    >
        <!-- record audio message -->
        <v-tooltip
            :disabled="!noPermission"
            location="top"
        >
            <template v-slot:activator="{ props:tooltip1 }">
                <v-tooltip location="top">
                    <template v-slot:activator="{ props: tooltip2 }">
                        <v-fab
                            
                            elevation="2"
                            class="bigButton"
                            :order="mainButtonStatus"
                            :show-blue-border="accountRole === 'pupil' && !playingToggles.start"
                            v-bind="$mergeProps(tooltip1,tooltip2)"
                            @click="mainButtonClick"
                        >
                            <img
                                :src="mainButtonIcon"
                                class="bigButtonIcon"
                                :order="mainButtonStatus"
                                alt="Sprachnotiz des Lehrers"
                            >
                        </v-fab>
                    </template>
                    <span>{{ recordingToggle ? 'Aufnahme stoppen' : playingToggles.start ? 'Wiedergabe stoppen' : pulledStartMessage ? 'Wiedergabe starten' : 'Aufnahme starten' }}</span>
                </v-tooltip>

                <div
                    ref="additionalInfoContainer"
                    class="additionalInfoContainer"
                    :order="mainButtonStatus"
                >
                    <p
                        v-show="mainButtonStatus !== 'idle'"
                    >
                        {{ recordingTime || playTime || countdown || errorOnRecording }}
                    </p>
                    <v-icon
                        v-if="recordingTime && recordingToggle"
                        style="font-size: 10px; color: red"
                    >
                        mdi-circle
                    </v-icon>
                </div>
            </template>
            <span>Aufnahmeerlaubnis fehlt!</span>
        </v-tooltip>

        <!-- record answer audio message -->
        <div
            v-if="answerActivated || pulledAnswerMessage"
            id="pupilButtonContainer"
        >
            <v-tooltip location="top">
                <template v-slot:activator="{ props }">
                    <v-fab
                        elevation="2"
                        class="bigButton"
                        :order="pupilButtonStatus"
                        :show-blue-border="mode === 'teacher' && !playingToggles.answer"
                       
                        v-bind="props"
                        @click="pupilButtonClick"
                    >
                        <img
                            :src="pupilButtonIcon"
                            class="bigButtonIcon"
                            :order="pupilButtonStatus"
                            alt="Sprachnotiz des Schülers"
                        >
                    </v-fab>
                </template>
                <span>{{ recordingTogglePupil ? 'Aufnahme stoppen' : playingToggles.answer ? 'Wiedergabe stoppen' : pulledAnswerMessage ? 'Wiedergabe starten' : 'Aufnahme starten' }}</span>
            </v-tooltip>

            <div
                ref="additionalInfoContainer"
                class="additionalInfoContainer"
                :order="pupilButtonStatus"
            >
                <p v-show="pupilButtonStatus !== 'idle'">
                    {{ recordingTime || playTimeAnswer || countdown }}
                </p>
                <v-icon
                    v-if="recordingTime && recordingTogglePupil"
                    class="ml-2"
                    style="font-size: 12px; color: red"
                >
                    mdi-circle
                </v-icon>
            </div>
        </div>

        <!-- Absolute Buttons to hang out by the side -->
        <!-- re-record recording (author) -->
        <v-tooltip
            v-if="pulledStartMessage && (accountRole === 'teacher' || isMine) && mode === 'creator' && !previewPupilUI"
            location="right"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-if="pulledStartMessage && (accountRole === 'teacher' || isMine)"
                    class="sideButton editorOptionsFade"
                    size="x-small"
                    style="top: 55px;"
                    v-bind="props"
                    @click="resetTeacherRecording"
                >
                    <img
                        :src="aktualisierenIcon"
                        alt="sprachnotiz erneut aufnehmen"
                    >
                </v-btn>
            </template>
            <span>Aufnahme wiederholen</span>
        </v-tooltip>

        <!-- toggle answer ui -->
        <v-tooltip
            v-if="pulledStartMessage && !isMine && !pulledAnswerMessage"
            location="right"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-if="pulledStartMessage && !isMine"
                    class="sideButton editorOptionsFade"
                    size="x-small"
                    :style="`top: ${accountRole === 'teacher' ? 55 : 0}px;`"
                    v-bind="props"
                    @click="answerActivated = true"
                >
                    <img
                        :src="mikrofonIcon"
                        alt="Antwort aufnehmen"
                    >
                </v-btn>
            </template>
            <span>Antwort aufnehmen</span>
        </v-tooltip>

        <!-- re-record recording answer -->
        <v-tooltip
            v-if="pulledAnswerMessage && !isMine"
            location="right"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    v-if="pulledAnswerMessage && !isMine"
                    class="sideButton editorOptionsFade"
                    :style="`top: ${accountRole === 'teacher' ? 55 : 0}px;`"
                    v-bind="props"
                    @click="resetAnswerRecording"
                >
                    <img
                        :src="aktualisierenIcon"
                        alt="sprachnotiz erneut aufnehmen"
                    >
                </v-btn>
            </template>
            <span>Notiz wiederholen</span>
        </v-tooltip>

        <audio
            ref="startMessageAudio"
            :src="pulledStartMessage"
            controls
            hidden
        >
            Your browser does not support the audio tag.
        </audio>
        <audio
            ref="answerMessageAudio"
            :src="pulledAnswerMessage"
            controls
            hidden
        >
            Your browser does not support the audio tag.
        </audio>
    </div>
</template>

<script>
import schliesenIcon from '@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg';
import mikrofonIcon from '@/assets/Icons/FaceliftIcons/mikrofon_an.svg'
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'
import lautsprecherIcon from '@/assets/Icons/lautsprecher-weiss-13.svg'
import playIcon from '@/assets/Icons/Play-114.svg'
import aufnahmeKreisIcon from '@/assets/Icons/recording-dot.svg'
import aufnahmeStopIcon from '@/assets/Icons/Stop_recording-151.svg';
import aktualisierenIcon from '@/assets/Icons/FaceliftIcons/aktualisieren.svg';
import muteIcon from '@/assets/Icons/mute.svg';
import * as backend from "@/api/backend";
import Recorder from "js-audio-recorder";
import {mapState, mapActions, mapGetters, mapMutations} from 'vuex';

    export default {
    name: 'EditorAudioMessage',
    props: {
        uploadMessage: { type: Object, required: true },
        index: { type: Number, required: true },
        mode: { type: String, required: true },
        tabIndex: { type: Number, required: false, default: 0 },
        previewPupilUI: { type: Boolean, required: false, default: false },
    },
    data: () => ({
        // Icons
        schliesenIcon,
        mikrofonIcon,
        playIcon,
        papierkorbIcon,
        lautsprecherIcon,
        aufnahmeKreisIcon,
        aufnahmeStopIcon,
        aktualisierenIcon,
        muteIcon,

        // Switchable UI Properties
        mainButtonColor: '#707070',
        mainButtonSpeakerState: null,

        isPlaying: false,
        isPlayingPupil: false,
        answerActivated: false,

        // Everything else
        isOpen: false,
        permissionState: 'unknown',
        recordedMessage: null,
        recordingToggle: false,
        recordingTogglePupil: false,
        playingToggles: {
            start: false,
            answer: false,
        },
        recorder: new Recorder(),
        pulledStartMessage: null,
        pulledAnswerMessage: null,
        recordingTooltip: false,

        // Timer
        recordingTime: '',
        playTime: '',
        playTimeAnswer: '',
        countdown: '',
        elapsedTimeIntervalRef: '',
        errorOnRecording: '',
    }),
    computed: {
        ...mapState("util", ["keyboard"]),
        ...mapState("auth", ["token"]),
        ...mapGetters("auth", ["accountRole", 'accountId']),
        mainButtonIcon() {
            return this.noPermission ? this.muteIcon :
                this.recordingToggle || this.playingToggles.start ? this.aufnahmeStopIcon :
                (this.pulledStartMessage ? this.lautsprecherIcon : this.aufnahmeKreisIcon);
        },
        mainButtonStatus() {
            if (this.recordingToggle) {
                return 'record';
            } else if (this.playingToggles.start) {
                return 'play';
            } else if (this.pulledStartMessage) {
                return 'idle';
            } else if (this.noPermission) {
                return 'denied';
            } else {
                return 'preidle';
            }
        },
        pupilButtonIcon() {
            return this.recordingTogglePupil || this.playingToggles.answer ?
                this.aufnahmeStopIcon :
                (this.pulledAnswerMessage ? this.lautsprecherIcon : this.aufnahmeKreisIcon);
        },
        pupilButtonStatus() {
            return this.recordingTogglePupil ? 'record' : (this.playingToggles.answer ? 'play' : (this.pulledAnswerMessage ? 'idle' : 'preidle'));
        },
        noPermission() {
            return this.permissionState === 'none';
        },
        isMine() {
            return this.uploadMessage.author === this.accountId;
        },
    },
    watch: {
        isOpen() {
            this.playingToggles.start = false;
            this.$refs.startMessageAudio.pause();
            this.playingToggles.answer = false;
            this.$refs.answerMessageAudio.pause();
        },
        recordingToggle(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.mainButtonColor = '#e6231e';
            }
            if (!newVal && oldVal) {
                this.mainButtonColor = '#707070';
            }
        }
    },
    unmounted() {
        if (this.pulledStartMessage) {
            window.URL.revokeObjectURL(this.pulledStartMessage);
        }

        if (this.pulledAnswerMessage) {
            window.URL.revokeObjectURL(this.pulledAnswerMessage);
        }
    },
    mounted() {
        // request files for playback
        if (this.uploadMessage.start) {
            this.pullAudioFile(this.uploadMessage.start, 'pulledStartMessage');
        } else if (this.uploadMessage.startFile) {
            this.pulledStartMessage = window.URL.createObjectURL(this.uploadMessage.startFile);
        }

        if (this.uploadMessage.answers && this.uploadMessage.answers[0]) {
            this.pullAudioFile(this.uploadMessage.answers[0].element, 'pulledAnswerMessage')
        } else if (this.uploadMessage.answerFile) {
            this.pulledAnswerMessage = window.URL.createObjectURL(this.uploadMessage.answerFile);
        }
    },
    methods: {
        ...mapMutations('snackbar', ["showSnackbar"]),
        async mainButtonClick(event) {
            if (this.permissionState === 'unknown' && !this.pulledStartMessage) {
                await this.checkPermission();
            }

            // Check if there is a current teacher recording
            if (!this.noPermission && this.pulledStartMessage) {
                this.playRecordedMessage('startMessageAudio', 'start')
            }

            // Check for mode creator
            if (!this.noPermission && (this.mode === 'creator' || this.isMine) && !this.pulledStartMessage) {
                // Record or stop recording
                if (!this.recordingToggle && !this.pulledStartMessage) {
                    this.beginRecording();
                } else if (this.recordingToggle) {
                    this.stopRecording('start');
                }
            }

            // Show snackbar if permission is not given
            if (this.noPermission) {
                this.errorOnRecording = '--:--'
                await this.showSnackbar({
                    message: 'Erlaubnis zum Aufnehmen von diesem Gerät fehlt!',
                    color: 'info'
                })
            }
        },

        async pupilButtonClick() {
            if (this.permissionState === 'unknown' && !this.pulledAnswerMessage) {
                await this.checkPermission();
            }

            if (this.pulledAnswerMessage) {
                this.playRecordedMessage('answerMessageAudio', 'answer')
            }

            // Check for mode creator
            if (!this.isMine) {
                // Record or stop recording
                if (!this.recordingTogglePupil && !this.pulledAnswerMessage) {
                    this.beginRecording('pupil');
                } else if (this.recordingTogglePupil) {
                    this.stopRecording('answer');
                }
            }
        },

        resetTeacherRecording() {
            this.playTime = '';
            this.recordingTime = '';
            if (this.recordingToggle) {
                this.stopRecording('start');
            }

            if (this.$refs.startMessageAudio.duration > 0 && !this.$refs.startMessageAudio.paused) {
                this.playingToggles.start = false;
                this.$refs.startMessageAudio.pause();
            }

            this.pulledStartMessage = null;
        },

        resetAnswerRecording() {
            this.playTimeAnswer = '';
            this.recordingTime = '';
            if (this.recordingTogglePupil) {
                this.stopRecording('answer');
            }

            if (this.$refs.answerMessageAudio.duration > 0 && !this.$refs.answerMessageAudio.paused) {
                this.playingToggles.answer = false;
                this.$refs.answerMessageAudio.pause();
            }

            this.pulledAnswerMessage = null;
        },

        async checkPermission() {
            try {
                const permission = await Recorder.getPermission();
                if (permission) {
                    this.permissionState = 'given';
                }
            } catch (error) {
                this.permissionState = 'none';
                console.error(`${error.name} : ${error.message}`);
            }
        },
        beginRecording(role = 'teacher') {
            if ((role === 'teacher' && !this.recordingToggle)
                || (role === 'pupil' && !this.recordingTogglePupil)) {
                if (role === 'teacher') {
                    this.recordingToggle = true;
                } else {
                    this.recordingTogglePupil = true;
                }

                let intervalCount = 0;
                this.countdown = 3 - intervalCount;
                const countdownInterval = setInterval(() => {
                    intervalCount++;
                    this.countdown = 3 - intervalCount;

                    if (this.countdown === 0) {
                        this.startTimer();
                        this.recordingTooltip = true;
                        this.recorder.startRecord();
                        this.countdown = '';
                        clearInterval(countdownInterval);
                    }
                }, 1000);
            }
        },
        stopRecording(msgType) {
            this.recordingTooltip = false;
            this.recordingToggle = false;
            this.recordingTogglePupil = false;
            this.recorder.stopRecord();
            const file = this.recorder.getWAVBlob();

            if (msgType === 'start') {
                this.pulledStartMessage = window.URL.createObjectURL(file);
                this.$emit('history', { startFile: null });
            } else if (msgType === 'answer') {
                this.pulledAnswerMessage = window.URL.createObjectURL(file);
            }
            this.$emit('recordingMade', msgType, file);
            setTimeout(() => {
              this.stopTimer();
            }, 500);
        },
        async pullAudioFile(messageId, resultContainer) {
            const res = await backend.getUploadMessage(messageId);
            fetch(res.url, {
                method: 'GET',
                headers: new Headers({
                    "Authorization": "Bearer " + this.token
                    })
                })
                .then(response => response.blob())
                .then(blob => {
                    this[resultContainer] = window.URL.createObjectURL(blob);
                });
        },
        playRecordedMessage(audioRef, stylingToggle) {
            try {
                if (this.$refs[audioRef].duration > 0 && !this.$refs[audioRef].paused) {
                    this.playingToggles[stylingToggle] = false;
                    this.$refs[audioRef].pause();
                } else {
                    this.playingToggles[stylingToggle] = true;
                    this.$refs[audioRef].play();
                    this.$refs[audioRef].ontimeupdate = () => {
                        if (stylingToggle === 'start') {
                            this.playTime
                                = new Date(
                                    this.$refs[audioRef].currentTime * 1000)
                                .toISOString().substr(14, 5);
                        } else {
                            this.playTimeAnswer
                                = new Date(
                                    this.$refs[audioRef].currentTime * 1000)
                                .toISOString().substr(14, 5);
                        }
                    }
                }

                this.$refs[audioRef].onended = () => {
                    this.playingToggles[stylingToggle] = false;
                    if (stylingToggle === 'start') {
                        this.playTime = '';
                    } else {
                        this.playTimeAnswer = '';
                    }
                }
            } catch (e) {
                // console.log(e);
            }
        },

        startTimer() {
            var startTime = new Date();
            this.recordingTime = this.getElapsedTime(startTime);
            this.elapsedTimeIntervalRef = setInterval(() => {
                this.recordingTime = this.getElapsedTime(startTime);
            }, 1000);
        },
        stopTimer() {
            if (typeof this.elapsedTimeIntervalRef !== "undefined") {
                clearInterval(this.elapsedTimeIntervalRef);
                this.elapsedTimeIntervalRef = undefined;
            }
            this.recordingTime = '';
        },
        getElapsedTime(startTime) {
            // Record end time
            let endTime = new Date();
            // Compute time difference in milliseconds
            let timeDiff = endTime.getTime() - startTime.getTime();
            // Convert time difference from milliseconds to seconds
            timeDiff = timeDiff / 1000;
            // Extract integer seconds that dont form a minute using %
            let seconds = Math.floor(timeDiff % 60); //ignoring uncomplete seconds (floor)
            // Pad seconds with a zero if neccessary
            let secondsAsString = seconds < 10 ? "0" + seconds : seconds + "";
            // Convert time difference from seconds to minutes using %
            timeDiff = Math.floor(timeDiff / 60);
            // Extract integer minutes that don't form an hour using %
            let minutes = timeDiff % 60; //no need to floor possible incomplete minutes, becase they've been handled as seconds
            // Pad minutes with a zero if neccessary
            let minutesAsString = minutes < 10 ? "0" + minutes : minutes + "";
            // Convert time difference from minutes to hours
            timeDiff = Math.floor(timeDiff / 60);
            // Extract integer hours that don't form a day using %
            let hours = timeDiff % 24; //no need to floor possible incomplete hours, becase they've been handled as seconds
            // Convert time difference from hours to days
            timeDiff = Math.floor(timeDiff / 24);
            // The rest of timeDiff is number of days
            let days = timeDiff;
            let totalHours = hours + (days * 24); // add days to hours
            let totalHoursAsString = totalHours < 10 ? "0" + totalHours : totalHours + "";
            if (totalHoursAsString === "00") {
                return minutesAsString + ":" + secondsAsString;
            } else {
                return totalHoursAsString + ":" + minutesAsString + ":" + secondsAsString;
            }
        },
    }
}
</script>

<style lang="scss" scoped>
#card {
    background-color: white;
    width: 220px;
    max-width: 300px;
}

.audioMessageOuter {
    //z-index: 7 !important;
    max-width: 300px;
    height: 50px;
}

.bigButtonImage {
    height: 40px !important;
    position: absolute;
    left: 5px;
    top: 5px;
    filter: brightness(1000%)
}

/* ----------------------------------------------
* Generated by Animista on 2021-1-19 12:22:3
* Licensed under FreeBSD License.
* See http://animista.net/license for more info.
* w: http://animista.net, t: @cssanimista
* ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.heartbeat {
    -webkit-animation: heartbeat 2s ease-in-out 2s infinite both;
    animation: heartbeat 2s ease-in-out 2s infinite both;
}

@keyframes heartbeat {
    from {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-transform-origin: center center;
        transform-origin: center center;
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    10% {
        -webkit-transform: scale(0.91);
        transform: scale(0.91);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    17% {
        -webkit-transform: scale(0.98);
        transform: scale(0.98);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
    33% {
        -webkit-transform: scale(0.87);
        transform: scale(0.87);
        -webkit-animation-timing-function: ease-in;
        animation-timing-function: ease-in;
    }
    45% {
        -webkit-transform: scale(1);
        transform: scale(1);
        -webkit-animation-timing-function: ease-out;
        animation-timing-function: ease-out;
    }
}

.iconToWhite {
    filter: brightness(1000%);
}

.playButton {
    text-transform: inherit;
    color: white;
}

#micIcon {
    background-image: url('../../assets/Icons/FaceliftIcons/mikrofon_an.svg');
    height: 20px;
    width: 20px;
    filter: brightness(1000%);
}

.iconToRecording {
    /*filter: brightness(1);*/
    /*background-image: url('../assets/Icons/recording-dot.svg') !important;*/
}
#hintBox {
    display: inline-flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    max-width: 300px;
    color: grey;
}

.additionalInfoContainer {
    display: flex;
    margin-top: 2px;
    height: 2em;
    padding: 5px;
    border-radius: 2px;
    border: 1px;

    p {
        margin: auto;
        text-align: center;
        color: #3c3c3b;
    }
}

.additionalInfoContainer[order='play'] {
    background-color: rgb(var(--v-theme-hellgrau));
}

.additionalInfoContainer[order='record'] {
    background-color: rgb(var(--v-theme-hellgrau));
}

.additionalInfoContainer[order='denied'] {
    background-color: rgb(var(--v-theme-hellgrau));
}

.bigButton[order='play'] {
    background-color: rgb(var(--v-theme-hellgrau)) !important;
}

.bigButton[order='record'] {
    background-color: rgb(var(--v-theme-rot)) !important;
}

.bigButton[order='denied'] {
    background-color: rgb(var(--v-theme-rot)) !important;
}

//.bigButton[showBlueBorder=true] {
//    border: 2px solid rgb(var(--v-theme-fmittelblau));
//
//    img {
//        filter: invert(76%) sepia(38%) saturate(5103%) hue-rotate(196deg) brightness(95%) contrast(100%)
//    }
//}

.bigButtonIcon {
    height: 25px;
    position: absolute;
}

.bigButtonIcon[order='play'] {
    height: 20px;
    filter: saturate(0) brightness(20%);
}

.bigButtonIcon[order='record'] {
    height: 20px;
    filter: saturate(0) brightness(1000%);
}

.bigButtonIcon[order='denied'] {
    height: 35px;
    filter: invert(0) brightness(100%);
}

.bigButtonIcon[order='idle'] {
    filter: brightness(20%);
}

.bigButtonIcon[order='preidle'] {
    filter: none;
}

.sideButton {
    position: absolute;
    right: -55px;
    width: 45px !important;
    min-width: 45px !important;
    height: 45px !important;
    border-radius: 2px;

    img {
        width: 25px;
        height: 25px;
        filter: brightness(150%);
    }
}
</style>
