<template>
    <div>
        <v-menu
            :close-on-content-click="false"
            location="bottom"
            :offset="[0, 3]"
            :min-width="200"
            :max-width="200"
        >
            <template v-slot:activator="{ props }">
                <v-btn
                    :key="currentColor"
                    variant="outlined"
                    size="x-small"
                    min-height="20px"
                    max-height="20px"
                    min-width="20px"
                    max-width="20px"
                    elevation="0"
                    :style="`background-color: ${currentColor}`"
                    style="border: thin solid #BDBDBD"
                    v-bind="props"
                >
                    <img
                        class="iconToDarkBlue"
                        :src="bearbeitenIcon"
                        :style="'margin-right: -12px; margin-bottom: -12px; width: 20px;'"
                    >
                </v-btn>
            </template>
            <v-card>
                <div
                    style="display: flex; justify-content: flex-start; background-color: #e5e5e5; flex-wrap: wrap"
                    class=""
                >
                    <v-btn
                        v-for="(color, index) in availableColors"
                        :key="index"
                        size="x-small"
                        min-height="20px"
                        max-height="20px"
                        min-width="20px"
                        max-width="20px"
                        elevation="0"
                        style="margin: 10px"
                        :style="`background-color: ${color}`"
                        @click="currentColor = color; close()"
                    />
                </div>
            </v-card>
        </v-menu>
    </div>
</template>

<script>

import bearbeitenIcon from "@/assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
export default {
    name: 'ColorPicker',
    props: {
        colors: { required: false, type: Array },
        previousColor: { required: false, type: String },
    },
    data: () => ({
        bearbeitenIcon,
        currentColor: '#dd0718',
        availableColors: ['#dd0718', '#3ba934', '#fd4e27', '#803b8a', '#5782c2', '#5d5d5d', '#facc0f', '#e91e63', '#1b5e20', '#000000', '#FFFFFF'],
    }),
    watch: {
        colors: {
            immediate: true,
            handler() {
                this.availableColors = this.colors;
            }
        },
        previousColor: {
            immediate: true,
            handler() {
                if(this.previousColor){
                    this.currentColor = this.previousColor;
                }
            }
        }
    },
    methods: {
        close() {
            this.$emit('selected', this.currentColor);
        },
    }
}
</script>

<style lang="scss" scoped>
    .iconToDarkBlue {
    filter:
        brightness(0)
        saturate(100%)
        invert(15%)
        sepia(34%)
        saturate(669%)
        hue-rotate(187deg)
        brightness(91%)
        contrast(89%)
    ;
}
</style>
