<template>
    <div>
        <div class="widgetAlign">
            <v-card
                id="brettWidgetContainer"
                class="whiteBg widgetContainer"
                style="overflow: unset !important;"
                @dragenter="onDragEnter"
                @dragover="onDragOver"
                @dragleave="onDragLeave"
                @drop="dropFile"
                @dragover.prevent
                @drop.prevent
            >
                <!-- Full-size drop target -->
                <div
                    v-if="dropUiActive"
                    class="dropUiContainer"
                >
                    <div class="dropUi">
                        <p style="color: rgb(var(--v-theme-blackboardGrey)); font-size: 25px">
                            Dateien hier loslassen!
                        </p>
                    </div>
                </div>

                <!-- Clickable badge -->
                <div style="z-index: 3;">
                    <ClickableBadge
                        :event-list="blackboardBadgeEventList"
                        :delete-button="true"
                        usage="blackboard"
                        @badge-clicked="blackboardBadgeClicked"
                        @clear-badges="clearBlackboardBadges"
                    />
                </div>

                <!-- Header Create/Edit entry -->
                <div
                    v-if="eintragPopup"
                    class="brettWidgetHeader d-flex justify-space-between align-center"
                >
                    <div class="d-flex justify-space-between align-center">
                        <v-btn
                            size="small"
                            icon="$vuetify"
                            variant="plain"
                            theme="dark"
                            class="mr-2"
                            @click="() =>{ eintragPopup = false; bearbeiten = false; resetPopup() }"
                        >
                            <img
                                :src="backIcon"
                                class="icon20 iconToWhite"
                                alt="zurück"
                            >
                        </v-btn>
                        <p style="font-size: larger">
                            {{ bearbeiten ? 'Eintrag bearbeiten' : 'Neuer Eintrag' }}
                        </p>
                    </div>

                    <!-- resend message -->
                    <v-btn
                        v-if="bearbeiten"
                        class="mr-2"
                        theme="dark"
                        location="right"
                        elevation="0"
                        style="background-color:rgb(var(--v-theme-gruen));color:white;"
                        v-bind="attrs"
                        v-on="on"
                        @click="editEntry"
                    >
                        <img
                            :src="sendenIcon"
                            class="icon20 iconToWhite"
                            style="height: 25px"
                            alt="senden"
                        >
                        <p
                            v-if="windowWidth > 1300 || windowWidth < 900"
                            class="text-capitalize"
                        >
                            Speichern
                        </p>
                    </v-btn>

                    <!-- send message -->
                    <v-btn
                        v-if="!bearbeiten"
                        theme="dark"
                        elevation="0"
                        style="background-color:rgb(var(--v-theme-gruen));color:white;"
                        v-bind="attrs"
                        v-on="on"
                        @click="createEntry"
                    >
                        <img
                            :src="sendenIcon"
                            class="icon20 iconToWhite"
                            style="height: 25px"
                            alt=""
                        >
                    </v-btn>
                </div>

                <!-- Regular header -->
                <v-tooltip
                    v-else
                    location="top"
                    style="z-index:99;"
                >
                    <template v-slot:activator="{ props }">
                        <div class="brettWidgetHeader d-flex justify-space-between">
                            <div
                                style="white-space: nowrap"
                                :style="accountRole === 'teacher' ? 'cursor: pointer' : ''"
                                class="d-flex align-center noselect py-0 my-0"
                               
                                v-bind="props"
                            >
                                <img
                                    :src="containerTitel === 'Schwarzes Brett' ? brettIcon : containerTitel === 'Eingang' ? eingangIcon: ausgangIcon"
                                    aria-hidden="true"
                                    alt="Schwarzes Brett"
                                    class="icon20 iconToWhite mr-2"
                                    @click="() =>{ if (accountRole === 'teacher') { shiftFilterList(); } }"
                                >

                                <!-- <h1 style="font-size: larger">
                                    {{ containerTitel }}
                                </h1> -->
                                <v-text-field
                                    v-model="searchBlackboardMessages"
                                    clearable
                                    clear-icon="mdi-close"
                                    hide-details
                                    theme="dark"
                                    density="compact"
                                    tabindex="0" 
                                    variant="underlined"
                                    class="mb-1"
                                    :style="windowWidth > 900 && windowWidth < 1100 ? 'width: 168px;' : 'width:230px' "
                                    :placeholder="containerTitel + ' suchen...'"
                                    @click:clear="() => { searchBlackboardMessages = ''}"
                                />
                            </div>
                          
                               
                            <div
                                v-if="!multiselect"
                                class="d-flex align-center justify-end"
                            >
                                <v-tooltip
                                    location="bottom"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            icon="$vuetify"
                                            variant="plain"
                                            height="20"
                                            color="white"
                                            class="mr-1"
                                            v-bind="props"
                                            @click="triggerRemount"
                                        >
                                            <v-icon>
                                                mdi-reload
                                            </v-icon>
                                        </v-btn>
                                    </template>
                                    <span>Schwarzes Brett neu laden</span>
                                </v-tooltip>
                                <v-menu
                                    v-if="(accountRole === 'teacher' || accountRole === 'maintainer') && myGroups[0]"
                                    :offset="[0,10]"
                                    location="left"
                                >
                                    <template v-slot:activator="{ props }">
                                        <v-btn
                                            variant="text"
                                            min-width="20"
                                            class="pa-0"
                                            style="height: 20px; width: 20px"
                                           
                                            v-bind="props"
                                        >
                                            <img
                                                :src="plusIcon"
                                                class="icon20 iconToWhite"
                                                alt="Aktionen"
                                            >
                                        </v-btn>
                                    </template>

                                    <v-list>
                                        <v-list-item
                                            @click="createBlackboardDialog()"
                                        >
                                            <template #prepend>
                                                <img
                                                    :src="plusIcon"
                                                    class="icon20 iconToDunkelgrau"
                                                    alt="Erstellen"
                                                >
                                            </template>
                                            <v-list-item-title>Erstellen</v-list-item-title>
                                        </v-list-item>
                                        <v-divider />
                                        <v-list-item
                                            @click="multiselect =true"
                                        >
                                            <template #prepend>
                                                <img
                                                    :src="deleteIcon"
                                                    class="icon20 iconToRed"
                                                    alt="Löschen"
                                                >
                                            </template>
                                            <v-list-item-title style="color: red">
                                                Löschen
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                            <div
                                v-else
                                class="pr-2 py-0 d-flex align-center justify-end "
                            >
                                <v-btn
                                    v-if="(accountRole === 'teacher' || accountRole === 'maintainer') && myGroups[0]&& multiselect"
                                    icon="$vuetify"
                                    variant="plain"
                                    size="large"
                                    class="py-0"
                                    @click="deleteDialog=true"
                                >
                                    <img
                                        class="iconToWhite"
                                        :src="deleteIcon"
                                        style="height: 20px;"
                                        alt="loeschen"
                                    >
                                </v-btn>

                                <div v-if="multiselect">
                                    <v-btn
                                        icon="$vuetify"
                                        variant="plain"
                                        theme="dark"
                                        @click="()=>{ multiselect=false; }"
                                    >
                                        <img
                                            class="iconToWhite icon20"
                                            :src="schliessenIcon"
                                            alt="schließen"
                                        >
                                    </v-btn>
                                </div>
                            </div>
                        </div>
                    </template>
                    <p style="max-width: 28vw;">
                        {{
                            containerTitel === 'Schwarzes Brett' ? brettTooltip : containerTitel === 'Eingang' ? eingangTooltip : ausgangTooltip
                        }}
                    </p>
                </v-tooltip>

                <div
                    v-if="!eintragPopup && pinnedAll.length > 0 "
                    class="d-flex justify-space-between pl-2 py-2"
                    style="background-color: white; box-shadow: 0px 4px 6px -3px #888888; align-items: center; margin-bottom: 8px"
                >
                    <!-- Row next pinned message -->
                    <v-tooltip location="top">
                        <template v-slot:activator="{ props }">
                            <div
                                class="d-flex justify-flex-start mr-2"
                                style="align-items: center; cursor:pointer; width: 100%; overflow:hidden;text-overflow:ellipsis;"
                               
                                v-bind="props"
                            >
                                <img
                                    class="icon20 mr-1"
                                    :src="pinIcon"
                                    alt="gepinnt"
                                >
                                <p
                                    class="mr-1 noselect"
                                    style="font-size:small; color:black; font-weight:bold;"
                                    @click="jumpToNextPinned"
                                >
                                    Gepinnt:
                                </p>
                                <p
                                    class="text-truncate noselect"
                                    style="font-size:small;"
                                    @click="jumpToNextPinned"
                                    v-html="sanitizeHtml(urlify(nextPinned))"
                                />
                            </div>
                        </template>
                        <span>Klicken um durch die Liste der gepinnten Nachrichten zu springen</span>
                    </v-tooltip>
                    <div
                        class="d-flex mr-2"
                        style="cursor:pointer; align-items:center;"
                    >
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    icon="$vuetify"
                                    variant="plain"
                                    size="small"
                                    v-bind="props"
                                    @click="jumpToPreviousPinned"
                                >
                                    <img
                                        class="iconToDunkelgrau icon10"
                                        :src="downThinIcon"
                                        alt="vorherige Nachricht"
                                        style="transform: rotate(180deg);"
                                    >
                                </v-btn>
                            </template>
                            <span>zur vorherigen Nachricht</span>
                        </v-tooltip>
                        <v-tooltip location="top">
                            <template v-slot:activator="{ props }">
                                <v-btn
                                    icon="$vuetify"
                                    variant="plain"
                                    size="small"
                                    v-bind="props"
                                    @click="jumpToNextPinned"
                                >
                                    <img
                                        class="iconToDunkelgrau icon10"
                                        :src="downThinIcon"
                                        alt="nächste Nachricht"
                                    >
                                </v-btn>
                            </template>
                            <span>zur nächsten Nachricht</span>
                        </v-tooltip>
                    </div>
                </div>

                <!-- Body -->
                <simple-bar
                    class="scroll-area-messages"
                    :style="pinnedAll.length && !eintragPopup ? 'max-height: calc(90dvh - 142px)' : 'max-height: calc(90dvh - 80px)'"
                    :auto-hide="false"
                    @scroll="scrolled"
                >
                    <!-- Create new message -->
                    <div v-if="eintragPopup">
                        <v-card
                            class="mx-3 my-3"
                            style="border-radius: 16px"
                        >
                            <!-- Blackboard message -->
                            <div>
                                <!-- Message header -->
                                <div
                                    class="blackboardCardHeader px-2"
                                    style="display: flex; flex-flow: row nowrap;"
                                >
                                    <div class="d-flex align-center pb-0">
                                        <v-fab
                                            v-if="!profileIcon"
                                            style="position: absolute; top: 12px; left: 4px; z-index: 30; background-color: red; width: 16px !important; height: 16px !important;"
                                            class="pa-0"
                                            icon="$vuetify"
                                            variant="plain"
                                            @click="() => { brettEintrag.icon = fullMaintainer ? fullMaintainer._id : fullTeacher._id; profileIcon = true; }"
                                        >
                                            <v-icon
                                                style="color: white"
                                                size="small"
                                            >
                                                mdi-close
                                            </v-icon>
                                        </v-fab>
                                        <icon-picker
                                            :callback="(result) => { brettEintrag.color = result; profileIcon = false; }"
                                            :current-color="profileIcon ? profileColor : brettEintrag.color"
                                            :current-icon="profileIcon ? profilePic : brettEintrag.icon"
                                            :callback-icon="(result) => {brettEintrag.icon = result; profileIcon = false; }"
                                            :group-icons="icons"
                                            :filter-icon-color-to-grey="true"
                                            :color-picker-usage="'brett'"
                                        />
                                    </div>
                                    <div
                                        class="align-center mb-0 pb-0"
                                        style="flex-flow: column; width: 100%; margin-left: 15px;"
                                    >
                                        <div style="display: flex; flex-flow: row nowrap; margin-bottom: -10px; margin-top: -5px">
                                            <p
                                                style="margin-top: 17px; font-weight: bold"
                                                :style="brettEintrag.color==='#fcd20a' ? '' : `color:${brettEintrag.color}`"
                                            >
                                                An:
                                            </p>
                                            <v-container
                                                fluid
                                                style="min-width: 100%"
                                            >
                                                <v-select
                                                    v-model="groupsBlackboard"
                                                    hide-details
                                                    density="compact"
                                                    item-title="name"
                                                    variant="underlined"
                                                    flat
                                                    :menu-props="{offsetY: true,}"
                                                    :items="myGroupsWithoutAlle"
                                                    :custom-filter="customFilter"
                                                    no-data-text="Keine passende Gruppe"
                                                    class="ma-0 pa-0 m-2"
                                                    :color="brettEintrag.color==='#fcd20a' ? '' : `${brettEintrag.color}`"
                                                    style="max-width: 87%"
                                                    :multiple="!bearbeiten"
                                                    return-object
                                                    @update:model-value="displayInfoSnackbar"
                                                >
                                                    <template v-slot:prepend-item>
                                                        <v-list-item
                                                            v-if="!bearbeiten"
                                                            ripple
                                                            @mousedown.prevent
                                                            @click="selectAll"
                                                        >
                                                            <v-list-item-action class="d-flex flex-row">
                                                                <v-icon class="mr-2">
                                                                    {{ selectIcon }}
                                                                </v-icon>
                                                                <v-list-item-title>
                                                                    Alle
                                                                </v-list-item-title>
                                                            </v-list-item-action>
                                                            <v-divider class="mt-2" />
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="accountRole !== 'maintainer' && !bearbeiten"
                                                            :disabled="groupsBlackboard.length < 1"
                                                            ripple
                                                            class="d-flex flex-row justify-flex-start"
                                                            :class="maintainerSelected ? ' primary--text' : ''"
                                                            @mousedown.prevent
                                                            @click="selectMaintainer"
                                                        >
                                                            <v-list-item-action>
                                                                <v-checkbox-btn
                                                                    color="primary"
                                                                    :disabled="groupsBlackboard.length < 1"
                                                                    :value="maintainerSelected"
                                                                    @click="selectMaintainer"
                                                                />
                                                                <v-list-item-title>
                                                                    Verwaltung
                                                                </v-list-item-title>
                                                            </v-list-item-action>
                                                        </v-list-item>
                                                    </template>
                                                </v-select>
                                            </v-container>
                                        </div>

                                        <v-text-field
                                            v-model="brettEintrag.title"
                                            style="width: 90%; margin-bottom: -15px"
                                            label="Betreff eintragen"
                                            density="compact"
                                            variant="underlined"
                                            flat
                                            prepend-inner-icon="mdi-pound"
                                            single-line
                                        />
                                    </div>
                                </div>

                                <!-- Preview, content, attachment(s) -->
                                <div class="pa-2">
                                    <advanced-quill-editor
                                        v-model="brettEintrag.message"
                                        style="width: 100%; min-height: 187px;"
                                        placeholder="Text eingeben"
                                        :hide-image-btn="true"
                                        :text-down="true"
                                        @link-clicked="showLinkExplanation"
                                    />

                                    <hr
                                        class="my-2"
                                        style="color: grey"
                                    >
                                    <div
                                        v-for="(item, index) in brettEintrag.uploads"
                                        :key="item._id + index"
                                        class="d-flex justify-flex-start my-1"
                                    >
                                        <div class="d-flex justify-space-around mr-2 text-truncate">
                                            {{ item.title }}
                                        </div>
                                        <div class="d-flex justify-space-around">
                                            <v-btn
                                                theme="dark"
                                                class="ml-auto mr-0"
                                                size="small"
                                                style="background-color:rgb(var(--v-theme-rot));color:white;"
                                                @click="removeUpload(item)"
                                            >
                                                <img
                                                    class="iconToWhite"
                                                    :src="deleteIcon"
                                                    style="height: 20px;"
                                                    alt="loeschen"
                                                >
                                            </v-btn>
                                        </div>
                                    </div>
                                    <!-- <div
                                        v-if="!bearbeiten || !editToDoList"
                                        class="d-flex justify-flex-start align-center"
                                    >
                                        <v-checkbox
                                            v-model="createToDoList"
                                        />
                                        <h1> Kontrollliste hinzufügen</h1>
                                    </div> -->
                                    <div
                                        v-if="editToDoList"
                                        class="d-flex justify-flex-start align-center"
                                    >
                                        <v-checkbox
                                            v-model="listToBeDeleted"
                                        />
                                        <h1> Kontrollliste löschen</h1>
                                    </div>
                                    <ToDoList
                                        v-if="createToDoList"
                                        class="mb-2"
                                        :mode="'create'"
                                        @update-inputs="setNewInputs"
                                        @update-title="setToDoTitle"
                                        @cancel-to-do="createToDoList = false"
                                    />
                                    <ToDoList
                                        v-else-if="editToDoList"
                                        class="mb-2"
                                        :mode="'edit'"
                                        :edit-object="editToDoList"
                                        @notify-parent-delete-tasks="setTasksToBeDeleted"
                                        @title-edited="setEditedTitle"
                                        @input-edited="setEditedInput"
                                    />
                                    <div
                                        class="ml-1"
                                        style="display:flex; justify-content: flex-start"
                                    >
                                        <img
                                            :src="paperclipIcon"
                                            class="icon20"
                                            alt=""
                                        >
                                        <h1 style="margin-top: 4px">
                                            Anhang
                                        </h1>
                                    </div>
                                    <!-- Input -->
                                    <v-row class="d-flex justify-space-between mx-4 mt-5">
                                        <!-- Previews -->
                                        <div
                                            v-for="(item, index) in filesToUpload"
                                            :key="index"
                                            class="uploadPreview"
                                            style="min-height: 180px; min-width: 180px;"
                                        >
                                            <div class="uploadPreviewHeader text-truncate">
                                                <p
                                                    class="text-truncate"
                                                    style="color: white"
                                                >
                                                    {{ item.name }}
                                                </p>

                                                <v-btn
                                                    height="25"
                                                    width="25"
                                                    icon="$vuetify"
                                                    variant="plain"
                                                    theme="dark"
                                                    @click="removeFileToUpload(item.lastModified)"
                                                >
                                                    <img
                                                        class="iconToWhite icon20"
                                                        :src="schliessenIcon"
                                                        alt="schließen"
                                                    >
                                                </v-btn>
                                            </div>
                                            <!-- <object
                                                v-if="item.thumbnail"
                                                :data="getThumb(item.thumbnail) + '#toolbar=0&navpanes=0&scrollbar=0&view=fitH'"
                                                align="center"
                                                type="image/png"
                                                border="0"
                                                loading="lazy"
                                                class="iOS_ResponsiveHack"
                                                style="cursor: pointer"
                                            />      -->
                                            <v-img
                                                v-if="item.thumbnail"
                                                :src="getThumb(item.thumbnail)"
                                                align="center"
                                                class="pa-1 thumbnailDisplayNormal mx-auto"
                                            >
                                                <div class="fill-height bottom-gradient" />
                                                <template v-slot:placeholder>
                                                    <v-row
                                                        class="fill-height fill-width ma-0"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <!-- <v-progress-circular
                                                            indeterminate
                                                            color="grey"
                                                        /> -->
                                                    </v-row>
                                                </template>
                                            </v-img>
                                            <div
                                                v-else-if="(item['type'].split('/')[0] === 'image')"
                                                class="displayWholeThumbnail"
                                                style="overflow:hidden;"
                                            >
                                                <v-img
                                                    :src="loadPicture(item)"
                                                    align="center"
                                                >
                                                    <div class="fill-height bottom-gradient" />
                                                    <template v-slot:placeholder>
                                                        <v-row
                                                            class="fill-height fill-width ma-0"
                                                            align="center"
                                                            justify="center"
                                                        >
                                                            <!-- <v-progress-circular
                                                                indeterminate
                                                                color="grey"
                                                            /> -->
                                                        </v-row>
                                                    </template>
                                                </v-img>
                                            </div>
                                            <div
                                                v-else-if="(item['type'].split('/')[0] === 'video')"
                                                class="pa-1 thumbnailDisplayNormal fill-height bottom-gradient mx-auto"
                                                style="margin-bottom: 5px; overflow:hidden"
                                            >
                                                <v-img
                                                    :src="videoIcon"
                                                    class="mb-2 iconToDunkelgrau "
                                                    align="center"
                                                    alt="Video"
                                                    cover
                                                />
                                            </div>
                                        </div>

                                        <div
                                            class="uploadPreview d-flex flex-column align-center justify-center"
                                            style="min-height: 180px; min-width: 180px; justify-content: center; cursor: pointer; background-color: rgba(80, 80, 80, 0.2);"
                                            :style="dropzone"
                                            @click="clickUpload"
                                        >
                                            <div
                                                class="mx-auto d-flex flex-column align-center justify-center"
                                                style="margin-bottom: 5px; overflow: hidden;"
                                            >
                                                <v-tooltip location="top">
                                                    <template v-slot:activator="{ props }">
                                                        <img
                                                            :src="plusIcon"
                                                            class="mb-2 iconToDunkelgrau"
                                                            style="height: 30px"
                                                            alt="Plus"
                                                           
                                                            v-bind="props"
                                                        >
                                                    </template>
                                                    <span>Klicken um Datei auszuwählen oder direkt hereinziehen</span>
                                                </v-tooltip>
                                                <p style="white-space: normal; max-width: 75%; text-align: center; color: rgb(var(--v-theme-blackboardGrey))">
                                                    Dateien hier ablegen
                                                </p>
                                                <input
                                                    id="fileUpload"
                                                    ref="fileUpload"
                                                    type="file"
                                                    hidden
                                                    @change="uploadInputChange"
                                                >
                                            </div>
                                        </div>
                                        <div
                                            v-if="!createToDoList && !editToDoList"
                                            class="uploadPreview d-flex flex-column align-center justify-center"
                                            style="min-height: 180px; min-width: 180px; justify-content: center; cursor: pointer; background-color: rgba(80, 80, 80, 0.2);"
                                            @click="createToDoList = true"
                                        >
                                            <div
                                                class="mx-auto d-flex flex-column align-center justify-center"
                                                style="margin-bottom: 5px; overflow: hidden;"
                                            >
                                                <v-tooltip location="top">
                                                    <template v-slot:activator="{ props }">
                                                        <img
                                                            :src="plusIcon"
                                                            class="mb-2 iconToDunkelgrau"
                                                            style="height: 30px"
                                                            alt="Plus"
                                                           
                                                            v-bind="props"
                                                        >
                                                    </template>
                                                    <span>Klicken Kontrollliste zu erstellen</span>
                                                </v-tooltip>
                                                <p style="white-space: normal; max-width: 75%; text-align: center; color: rgb(var(--v-theme-blackboardGrey))">
                                                    Kontrollliste erstellen
                                                </p>
                                            </div>
                                        </div>
                                    </v-row>
                                </div>
                            </div>
                        </v-card>
                    </div>

                    <!-- List of blackboard messages -->
                    <div
                        v-else
                        :key="updateBlackboard"
                        :style="blackboard.length === 0 && (accountRole==='teacher' || accountRole==='maintainer') ? 'cursor: pointer' : ''"
                        @click="blackboard.length === 0 && (accountRole==='teacher' || accountRole==='maintainer') && myGroups[0] ? createBlackboardDialog() : ''"
                    >
                        <div
                            v-if="dataLoading"
                            class="d-flex flex-column justify-space-around align-center"
                            style="padding-top: 30vh"
                        >
                            <v-progress-circular
                                indeterminate
                                color="rgb(var(--v-theme-blackboardGrey))"
                            />
                            <p class="text-center">
                                Wird geladen
                            </p>
                        </div>
                        <!-- Placeholder if there are no entries -->
                        <div
                            v-else-if="blackboard.length === 0"
                            class="ma-4 text-center"
                            style="z-index: 0; padding-top: 11vh"
                        >
                            <img
                                :src="keineNachrichtenIcon"
                                alt="Keine Nachrichten im schwarzen Brett"
                                class="mt-2"
                                style="height: 100px"
                            >
                            <p
                                class="mt-2 text-center"
                                style="font-size: 20px"
                            >
                                Aktuell keine Einträge vorhanden
                            </p>
                            <p
                                v-if="(accountRole==='teacher' || accountRole==='maintainer') && myGroups[0] "
                                class="mt-2 text-center"
                                style="font-size: 20px"
                            >
                                Auf "+ Plus" oder hier klicken um neuen Eintrag zu erstellen
                            </p>
                            <p
                                v-else-if="accountRole==='teacher' || accountRole==='maintainer'"
                                class="mt-2 text-center"
                                style="font-size: 20px"
                            >
                                Um Nachrichten versenden zu können, bitten Sie die Verwaltung Sie einer Gruppe als Klassenlehrer zuzuweisen.
                            </p>
                        </div>
                        <!-- Blackboard entry -->
                        <div ref="startcontainer" />
                        <v-lazy
                            v-for="(item, index) in searchBlackboard"
                            :key="item._id + index + urls + reloadKey"
                            :ref="`container${item._id}`"
                            :options="{
                                threshold: 0.25
                            }"
                            transition=""
                            class="mt-2 pt-0 pb-1 px-2"
                        >
                            <v-sheet
                                :min-height="item.uploads.length ? 160 : 100"
                                color="transparent"
                            >
                                <!--                                    v-model="item.isActive"-->
                               
                                <v-card
                                    :ref="`blackboardCard${item._id}`"
                                    class="blackboardCard"
                                >
                                    <!-- <v-badge
                                        color="#ff0000"
                                        content="1"
                                        :model-value="!seenByMe(item)"
                                        location="left"
                                        style="z-index: 9; width:100%"
                                    > -->
                                    <div
                                        :key="forceRefreshComputed"
                                        class="boardMessageHeader d-flex justify-space-between align-center"
                                        :style="item.showExpandButton ? 'cursor: pointer' : ''"
                                        @click="handleHeaderClick(item)"
                                    >
                                        <!--#region Icon and sender/receiver + subject -->
                                        <div
                                            class="d-flex flex-row align-center"
                                            style="min-width: 80%; min-height:48px;"
                                        >
                                            <!-- Icon or checkbox -->
                                            <div class="align-center">
                                                <div v-if="!multiselect">
                                                    <img
                                                        v-if="item.icon && !(item.uploadedIcon || item.profileIcon)"
                                                        :src="chooseIcon(item.icon)"
                                                        class="pa-1 ma-1 boardIcon"
                                                        :style="`background-color: ${item.color}`"
                                                        alt=""
                                                    >
                                                    <Avatar
                                                        v-else-if="item.uploadedIcon || item.profileIcon"
                                                        class="pa-0 ma-1"
                                                        :size="35"
                                                        :profile="item.createdBy"
                                                        :show-burger="false"
                                                    />
                                                </div>

                                                <v-checkbox
                                                    v-else
                                                    v-model="item[item._id]"
                                                    :disabled="item.createdBy !== me.account"
                                                    :indeterminate="item.createdBy !== me.account"
                                                    value="item._id"
                                                    density="compact"
                                                    class="pl-1 boardIcon"
                                                    multiple
                                                    @click="selectCards(item)"
                                                />
                                            </div>

                                            <div
                                                :class="clicked.active && (clicked.id === item._id) ? '' : 'ellipsis'"
                                                class="d-flex flex-column justify-space-around"
                                                style="overflow: hidden; width: 75%; max-width: calc(100% - 44px)"
                                            >
                                                <p
                                                    aria-hidden="true"
                                                    :style="item.uploadedIcon || item.color==='#fcd20a' ? '' : `color:${item.color}`"
                                                    style="font-weight: bold"
                                                >
                                                    {{ getTitle(item.createdBy, item.group) }}
                                                </p>

                                                <!-- Accessibility -->
                                                <p
                                                    class="visually-hidden"
                                                    :style="item.uploadedIcon || item.color==='#fcd20a' ? '' : `color:${item.color}`"
                                                    style="font-weight: bold"
                                                >
                                                    {{ getTitle(item.createdBy, item.group, true) }}
                                                </p>

                                                <p>
                                                    {{ item.title ? '' + item.title : '' }}
                                                </p>
                                            </div>
                                        </div>
                                        <v-tooltip
                                            :disabled="!showTooltips"
                                            location="top"
                                        >
                                            <template v-slot:activator="{ props }">
                                                <div
                                                    :ref="`speaker${item._id}`"
                                                    :style="showSpeaker === item._id ? '' : 'display: none !important'"
                                                >
                                                    <v-fab
                                                        v-if="accountRole === 'pupil' || accountRole === 'parent'"
                                                        :aria-hidden="me && me.accessibility && me.accessibility.screenreader"
                                                            
                                                        absolute
                                                        location="top right"
                                                        class="pa-0 mr-4 preventColorInvert"
                                                        elevation="6"
                                                        style="height: 30px; width:30px; background-color:white; border-radius: 4px !important; top:-5px; right: 10%;"
                                                        v-bind="props"
                                                        @click="readText(item, $event)"
                                                    >
                                                        <img
                                                            :src="lautsprecherIcon"
                                                            class="icon20"
                                                            alt="Vorlesen"
                                                        >
                                                    </v-fab>
                                                </div>
                                            </template>
                                            <span>{{ speechActive ? 'Stop' : 'Vorlesen' }}</span>
                                        </v-tooltip>
                                        <!--#endregion -->
                                        <!--#region Actions in header -->
                                        <div
                                            class="d-flex justify-end align-center ml-2 mr-1"
                                            style="max-width: 20%"
                                        >
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <v-icon
                                                        v-if="item.toDoList"
                                                        class="icon20 mr-2"
                                                        v-bind="props"
                                                    >
                                                        mdi-list-status
                                                    </v-icon>
                                                </template>
                                                <span>Kontrollliste</span>
                                            </v-tooltip>
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <img
                                                        v-if="item.uploads.length > 0"
                                                        :src="clipIcon"
                                                        class="icon20 mr-2"
                                                        style="margin-left: -10px;"
                                                        alt="Anhang"
                                                           
                                                        v-bind="props"
                                                        @click="paperclipDownload(item.uploads, item._id)"
                                                    >
                                                </template>
                                                <span>Anhang enthalten</span>
                                            </v-tooltip>
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <img
                                                        v-if="item.isFixed"
                                                        class="icon20 iconToGrey mr-2"
                                                        style="margin-left: -10px;"
                                                        :src="religionIconNormal"
                                                        alt="fixiert"
                                                           
                                                        v-bind="props"
                                                    >
                                                </template>
                                                <span>Fixiert</span>
                                            </v-tooltip>
                                            <v-tooltip location="bottom">
                                                <template v-slot:activator="{ props }">
                                                    <img
                                                        v-if="item.pin"
                                                        class="icon20 iconToGrey mr-1"
                                                        :style="!item.showExpandButton ? 'margin-right: -3px !important' : ''"
                                                        :src="pinIcon"
                                                        alt="gepinnt"
                                                           
                                                        v-bind="props"
                                                    >
                                                </template>
                                                <span>Angepinnt</span>
                                            </v-tooltip>

                                            <v-btn
                                                v-if="item.showExpandButton"
                                                icon="$vuetify" 
                                                variant="plain"
                                                class="pa-0 pl-1"
                                                :class="item.pin ? 'mx-2' : 'mr-1'"
                                                style="width: 15px"
                                            >
                                                <img
                                                    :src="downIcon"
                                                    class="icon20"
                                                    :style="clicked.active && (clicked.id === item._id) ? 'transform: rotate(180deg)' : ''"
                                                    :alt="clicked.active && (clicked.id === item._id) ? 'Nachricht einklappen' : 'Nachricht ausklappen'"
                                                >
                                            </v-btn>
                                            <div
                                                class="ml-0"
                                                @click:outside="()=>{ optionsOpen = false;}"
                                            >
                                                <v-menu
                                                    :offset="[0,10]"
                                                    location="left"
                                                    class="ml-0"
                                                    :close-on-content-click="item.isFixed"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <v-btn
                                                            icon="$vuetify"
                                                            variant="plain"
                                                            :style="item.pin && item.showExpandButton ? 'margin-right: 12px' : ''"
                                                            v-bind="props"
                                                            @click="optionsOpen = !optionsOpen"
                                                        >
                                                            <img
                                                                class="icon20"
                                                                style="color: black !important;"
                                                                :src="optionsIcon"
                                                                alt="Einstellungen"
                                                            >
                                                        </v-btn>
                                                    </template>
                                                    <v-list
                                                        density="compact"
                                                        class="menu-list"
                                                    >
                                                        <v-list-item
                                                            @click="pinEntry(item._id)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="item.pin ? pinIconOutlined : pinIcon"
                                                                    class="icon20"
                                                                    alt="Anpinnen"
                                                                >
                                                            </template>
                                                            <v-list-item-title>
                                                                {{ item.pin ? 'Lösen' : 'Anpinnen' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider v-if="accountRole !== 'pupil'" />
                                                        <v-list-item
                                                            v-if="accountRole === ('teacher' || 'maintainer')"
                                                            @click="item.isFixed ? toggleFix(item) : expandFixOptions()"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="religionIconNormal"
                                                                    class="icon20"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title>
                                                                {{ item.isFixed ? 'Fixierung lösen' : fixClicked ? 'Fixieren für' : 'Fixieren' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="fixClicked"
                                                            class="pl-8"
                                                            @click="fixForReceivers(item, 'Alle')"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="groupIcon"
                                                                    class="icon20 iconToGrey"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title
                                                                style="opacity: 0.8"
                                                            >
                                                                {{ 'Alle' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="fixClicked"
                                                            @click="toggleFix(item)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="groupIcon"
                                                                    class="icon20 iconToGrey"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title style="opacity: 0.8">
                                                                {{ 'Mich' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="fixClicked"
                                                            @click="fixForReceivers(item, 'Schueler')"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="pupilIcon"
                                                                    class="icon20 iconToGrey"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title style="opacity: 0.8">
                                                                {{ 'Schüler' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="fixClicked"
                                                            @click="fixForReceivers(item, 'Lehrer')"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="teacherIcon"
                                                                    class="icon20 iconToGrey"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title style="opacity: 0.8">
                                                                {{ 'Lehrer' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item
                                                            v-if="fixClicked"
                                                            @click="fixForReceivers(item, 'Eltern')"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="groupIcon"
                                                                    class="icon20 iconToGrey"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title style="opacity: 0.8">
                                                                {{ 'Eltern' }}
                                                            </v-list-item-title>
                                                        </v-list-item>

                                                        <v-list-item
                                                            v-if="accountRole !== ('teacher' || 'maintainer')"
                                                            @click="toggleFix(item)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="religionIconNormal"
                                                                    class="icon20"
                                                                    alt="Fixieren"
                                                                >
                                                            </template>
                                                            <v-list-item-title>
                                                                {{ item.isFixed ? 'Fixierung lösen' : 'Fixieren' }}
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider v-if="accountRole !== 'pupil'" />
                                                        <v-list-item
                                                            @click="clickEdit(item)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="bearbeitenIcon"
                                                                    class="icon20"
                                                                    alt="Bearbeiten"
                                                                    :class="!myEntry(item.createdBy) ? 'iconToGrey' : ''"
                                                                >
                                                            </template>
                                                            <v-list-item-title>Bearbeiten</v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider />
                                                        <v-list-item
                                                            @click="clickHideItem(item._id)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="deleteIcon"
                                                                    class="icon20 iconToOrange"
                                                                    alt="Löschen"
                                                                >
                                                            </template>
                                                            <v-list-item-title>Verbergen</v-list-item-title>
                                                        </v-list-item>
                                                        <v-divider />
                                                        <v-list-item
                                                            @click="clickDelete(item)"
                                                        >
                                                            <template #prepend>
                                                                <img
                                                                    :src="deleteIcon"
                                                                    class="icon20 iconToRed"
                                                                    :class="!myEntry(item.createdBy) ? 'iconToGrey' : ''"
                                                                    alt="Löschen"
                                                                >
                                                            </template>
                                                            <v-list-item-title :style="!myEntry(item.createdBy) ? '' : 'color: red'">
                                                                Löschen
                                                            </v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </div>
                                        <!--#endregion -->
                                    </div>
                                    <!-- Card body -->
                                    <div
                                        class="pa-2"
                                    >
                                        <v-overlay
                                            v-if="!seenByMe(item)"
                                            :scrim="highlightedOverlayId === item._id ? overlayColor : 'white'"
                                            opacity="0.9"
                                            style="border-radius: 8px;position: absolute; top: 48px;cursor: pointer;"
                                            z-index="5"
                                            @click="setBlackboardEntryAsSeen(item._id);"
                                        >
                                            <div
                                                class="overlayFontSize"
                                            >
                                                *** Klicke hier um zu lesen ***
                                            </div>
                                        </v-overlay>
                                        <!-- Expanded blackboard entry -->
                                        <div
                                            v-if="clicked.id === item._id"
                                            :ref="`paragraph${item._id}`"
                                            class="ql-editor"
                                        >
                                            <!-- disable no-html rule because blackboard message is sanitized on backend create/patch -->
                                            <!-- eslint-disable vue/no-v-html -->
                                            <p
                                                :id="'entryMessage-' + item._id"
                                                style="font-size: medium; color: #444444"
                                                :style="item.showExpandButton ? 'cursor: pointer' : ''"
                                                @click="item.showExpandButton ? expandCard(item._id) : setBlackboardEntryAsSeen(item._id)"
                                                v-html="sanitizeHtml(urlify(item.message))"
                                            />
                                            <div v-if="item.toDoList">
                                                <v-divider />
                                                <ToDoList
                                                    class="mb-2"
                                                    :mode="'view'"
                                                    :to-do-list-object="item.toDoList"
                                                    :logged-in="me.account"
                                                    :blackboard-id="item._id"
                                                />
                                            </div>
                                            <!-- eslint-enable vue/no-v-html -->
                                            <div
                                                style="display:flex; flex-direction: row; flex-wrap: wrap; width:100%; height:100%;"
                                            >
                                                <div
                                                    v-for="(el, index2) in item.uploads"
                                                    :key="el._id + index2"
                                                    :class="el.file.isDeleted ? 'oudated-image' : ''"
                                                    style="height: 30%; width: 30%; min-width: 30%; min-height: 30%;"
                                                >
                                                    <div
                                                        v-if="isFileThumbnailCompatibleExtension(el.title.split('.').pop().toLowerCase()) && el.hasThumbnail"
                                                        :key="forceRefreshComputed"
                                                        class="my-2 d-flex align-center justify-center"
                                                        style="background-color: #e6e6e6; border-radius: 8px; height: 100%; width: 100%;"
                                                    >
                                                        <img
                                                            :src="el.thumbnail"
                                                            class="iOS_ResponsiveHack"
                                                            style="object-fit: scale-down !important; cursor: pointer;"
                                                            width="100px"
                                                            height="100px"
                                                            :alt="el.title"
                                                            @click="() => handleThumbnailClicked(el, item)"
                                                        >
                                                    </div>
                                                    <div
                                                        v-else-if="isFileThumbnailCompatibleExtension(el.title.split('.').pop().toLowerCase())"
                                                        :key="forceRefreshComputed "
                                                        class="my-2 d-flex align-center justify-center"
                                                        style="background-color: #e6e6e6; border-radius: 8px"
                                                    >
                                                        <img
                                                            :src="img_eklaraLogo"
                                                            class="iOS_ResponsiveHack"
                                                            style="cursor: pointer; max-width: 100%;"
                                                            width="auto"
                                                            height="auto"
                                                            :alt="el.title"
                                                            @click="() => handleThumbnailClicked(el, item)"
                                                        >
                                                    </div>
                                                    <div
                                                        v-else-if="urls[el._id]"
                                                        class="my-2 d-flex align-center justify-center"
                                                    >
                                                        <!-- Display image preview -->
                                                        <img
                                                            v-if="types[el._id].includes('image') || ['png', 'jpg', 'jpeg', 'bmp'].includes(el.title.split('.').pop().toLowerCase())"
                                                            :src="urls[el._id]"
                                                            style="cursor: pointer; max-width: 100%;"
                                                            width="auto"
                                                            height="auto"
                                                            alt=""
                                                            @click="() => showFullImage(item._id, el)"
                                                        >
                                                        <div
                                                            v-else-if="el && el.title && ['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                            style="position: relative"
                                                        >
                                                            <v-icon
                                                                color="grey-darken-3"
                                                                size="100px"
                                                                style="top: 0; bottom: 0; margin-left: auto; margin-right: auto; left: 0; right: 0; position: absolute;"
                                                                @click="() => playVideo(item._id, el)"
                                                            >
                                                                mdi-play-circle-outline
                                                            </v-icon>
                                                            <img
                                                                :src="urls[el._id]"
                                                                style="cursor: pointer; max-width: 100%;"
                                                                width="auto"
                                                                height="auto"
                                                                alt=""
                                                                @click="() => playVideo(item._id, el)"
                                                            >
                                                        </div>
                                                        <!-- Display pdf preview -->
                                                        <object
                                                            v-else-if="el && el.title && ['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                            type="application/pdf"
                                                            :data="urls[el._id] + '#toolbar=0&navpanes=0&scrollbar=0&view=Fit'"
                                                            style="cursor: pointer; min-height: 300px; max-height: 400px; max-width: 100%"
                                                            width="auto"
                                                            height="auto"
                                                        />
                                                        <!-- Display video preview -->
                                                        <!--                                            <video-->
                                                        <!--                                                v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                                        <!--                                                :poster="videoPoster"-->
                                                        <!--                                                controls-->
                                                        <!--                                                style="cursor: pointer;" width="100%" height="auto" >-->
                                                        <!--                                                <source :src="urls[el._id]" />-->
                                                        <!--                                            </video>-->
                                                        <v-container
                                                            v-else-if="el && el.title && ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'].includes(el.title.split('.').pop().toLowerCase())"
                                                            class="px-2 py-0 justify-center"
                                                            style="flex-wrap: wrap"
                                                        >
                                                            <v-flex
                                                                align-self-start
                                                                class="d-flex flex-row pa-2"
                                                                shrink="true"
                                                                style="align-items:center; background-color:rgb(var(--v-theme-hellgrau)); border-radius: 6px 6px 6px 6px;"
                                                            >
                                                                {{ el.title }}

                                                                <v-tooltip
                                                                    location="top"
                                                                >
                                                                    <template v-slot:activator="{ props }">
                                                                        <v-icon
                                                                            v-bind="props"
                                                                        >
                                                                            mdi-information-outline
                                                                        </v-icon>
                                                                    </template>
                                                                    <p>
                                                                        Dieses Dateiformat kann in Eklara nicht
                                                                        angesehen und bearbeitet werden. Bitte
                                                                        laden
                                                                        Sie diese dazu herunter
                                                                    </p>
                                                                </v-tooltip>

                                                                <v-btn
                                                                    class="btnStyle"
                                                                    size="x-small"
                                                                    elevation="0"
                                                                    @click="() => clickDownload(el,item._id)"
                                                                >
                                                                    <img
                                                                        :src="runterladenIcon"
                                                                        alt="Herunterladen"
                                                                        style="height: 20px;"
                                                                    >
                                                                </v-btn>
                                                            </v-flex>
                                                        </v-container>
                                                    </div>
                                                </div>
                                            </div>
                                            <v-menu
                                                location="top left"
                                                style="z-index: 99"
                                                :disabled="(!(accountRole==='teacher' || 'maintainer'))||item.seenByTarget.length<1"
                                            >
                                                <template v-slot:activator="{ props }">
                                                    <div
                                                        v-if="item.createdOn"
                                                        class="blackboardItemFooter justify-space-between pb-0"
                                                           
                                                        v-bind="props"
                                                    >
                                                        <!-- Accessibility -->
                                                        <p
                                                            class="visually-hidden"
                                                        >
                                                            {{
                                                                dayname[new Date(item.createdOn).getDay()] + ', ' +
                                                                    new Date(item.createdOn).toLocaleDateString('de-DE', {
                                                                        day: '2-digit',
                                                                        month: '2-digit'
                                                                    }) + ' ' +
                                                                    new Date(item.createdOn).toLocaleTimeString('de-DE', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    }) + ', Gelesen ' +
                                                                    item.seenByCount + ' von ' +
                                                                    item.seenByTarget.length + ' Personen' +
                                                                    getAllSeenByCountNames(item)
                                                            }}
                                                        </p>
                                                        <div
                                                            class="d-flex justify-flex-end pb-0"
                                                            aria-hidden="true"
                                                        >
                                                            <span class=" mr-1">
                                                                {{ dayname[new Date(item.createdOn).getDay()].slice(0, 2) }}.
                                                                {{
                                                                    new Date(item.createdOn).toLocaleDateString('de-DE', {
                                                                        day: '2-digit',
                                                                        month: '2-digit'
                                                                    })
                                                                }}
                                                                <span class="hideText"> {{
                                                                    new Date(item.createdOn).toLocaleTimeString('de-DE', {
                                                                        hour: '2-digit',
                                                                        minute: '2-digit'
                                                                    })
                                                                }}</span>
                                                            </span>

                                                            <span
                                                                v-if="accountRole === 'teacher' && item.checkMarkStyle !== 'hide'"
                                                                :style="item.checkMarkStyle==='all' ? 'rgb(var(--v-theme-chatColor));' : 'color: rgb(var(--v-theme-timetableColor));'"
                                                            >
                                                                {{ item.seenByCount ? item.seenByCount : 0 }}/{{ item.seenByTarget.length }}
                                                            </span>
                                                            <span
                                                                v-if="accountRole === 'teacher' && item.checkMarkStyle === 'none'"
                                                            >
                                                                <img
                                                                    :src="checkIcon"
                                                                    class="iconToBlue"
                                                                    style="width: 16px; height: 16px; margin-top: -3px"
                                                                >
                                                            </span>
                                                            <span
                                                                v-if="accountRole === 'teacher' && item.checkMarkStyle === 'some'"
                                                            >
                                                                <img
                                                                    :src="checkAllIcon"
                                                                    class="iconToBlue"
                                                                    style="width: 16px; height: 16px; margin-top: -3px"
                                                                >
                                                            </span>
                                                            <span
                                                                v-if="accountRole === 'teacher' && item.checkMarkStyle==='all'"
                                                            >
                                                                >
                                                                <img
                                                                    :src="checkAllIcon"
                                                                    class="iconToGreen"
                                                                    style="width: 16px; height: 16px; margin-top: -3px"
                                                                >
                                                            </span>
                                                        </div>
                                                    </div>
                                                </template>
                                                <simple-bar
                                                    class="scroll-area"
                                                >
                                                    <v-list
                                                        density="compact"
                                                        class="darkMenuList"
                                                    >
                                                        <v-list-item
                                                            class="d-flex flex-row justify-space-between"
                                                        >
                                                            <div style="color: white">
                                                                Gelesen von:
                                                            </div>
                                                            <v-spacer />
                                                            <div
                                                                style="font-size: 12px; align-items: flex-end; color: white"
                                                            >
                                                                {{ "('" }}
                                                                <img
                                                                    :src="checkIcon"
                                                                    class="iconToWhite"
                                                                    style="width: 14px; height: 14px; margin-left: -5px; margin-right: -4px;"
                                                                >
                                                                {{ "' = erhalten, '" }}
                                                                <img
                                                                    :src="checkAllIcon"
                                                                    class="iconToWhite"
                                                                    style="width: 14px; height: 14px; margin-left: -3px; margin-right: -2px;"
                                                                >
                                                                {{ "' = gesehen)" }}
                                                            </div>
                                                        </v-list-item>
                                                        <template
                                                            v-for="element in item.seenByTarget"
                                                            :key="element._id"
                                                        >
                                                            <div
                                                                style="background-color: transparent"
                                                            >
                                                                <v-divider />
                                                                <div
                                                                    v-if="element.role === 'parent' && item.seenByNames"
                                                                    class="d-flex flex-row justify-space-between px-2"
                                                                    style="max-width: 28vw;"
                                                                >
                                                                    <div
                                                                        class="justify-flex-start"
                                                                        style="font-size: 14px; color: white"
                                                                    >
                                                                        {{ 'Eltern von ' + element.pupil.name + ' ' + element.pupil.lastName }}
                                                                    </div>
                                                                    <img
                                                                        v-if="item.seenByNames.includes('Eltern von ' + element.pupil.name + ' ' + element.pupil.lastName)"
                                                                        :src="checkAllIcon"
                                                                        class="iconToWhite justify-flex-end"
                                                                        style="width: 14px; height: 14px;"
                                                                    >
                                                                    <img
                                                                        v-else
                                                                        :src="checkIcon"
                                                                        class="iconToWhite justify-flex-end"
                                                                        style="width: 14px; height: 14px;"
                                                                    >
                                                                </div>
                                                                <div
                                                                    v-else
                                                                    class="d-flex flex-row justify-space-between px-2"
                                                                    style="max-width: 28vw;"
                                                                >
                                                                    <div
                                                                        class="justify-flex-start"
                                                                        style="font-size: 14px; color: white"
                                                                    >
                                                                        {{ element.name + ' ' + element.lastName }}
                                                                    </div>
                                                                    <img
                                                                        v-if="item.seenByNames && item.seenByNames.includes(element.name + ' ' + element.lastName)"
                                                                        :src="checkAllIcon"
                                                                        class="iconToWhite justify-flex-end"
                                                                        style="width: 14px; height: 14px;"
                                                                    >
                                                                    <img
                                                                        v-else
                                                                        :src="checkIcon"
                                                                        class="iconToWhite justify-flex-end"
                                                                        style="width: 14px; height: 14px;"
                                                                    >
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </v-list>
                                                </simple-bar>
                                            </v-menu>
                                        </div>

                                        <!-- Collapsed blackboard entry -->
                                        <div
                                            v-else
                                            :ref="`paragraph${item._id}`"
                                            style="width: 100%"
                                        >
                                            <div>
                                                <div class="collapsed ql-editor">
                                                    <!-- disable no-html rule because blackboard message is sanitized on backend create/patch -->
                                                    <!-- eslint-disable vue/no-v-html -->
                                                    <p
                                                        :id="'entryMessage-' + item._id"
                                                        style="font-size: medium; color: #444444; width: fit-content; max-width: 100%"
                                                        :style="item.showExpandButton ? 'cursor: pointer' : ''"
                                                        @click="item.showExpandButton ? expandCard(item._id) : setBlackboardEntryAsSeen(item._id)"
                                                        v-html="sanitizeHtml(urlify(item.message))"
                                                    />
                                                </div>
                                                <div v-if="item.toDoList">
                                                    <v-divider />
                                                    <ToDoList
                                                        class="mb-2"
                                                        :mode="'view'"
                                                        :to-do-list-object="item.toDoList"
                                                        :logged-in="me.account"
                                                        :blackboard-id="item._id"
                                                    />
                                                </div>
                                                <!-- eslint-enable vue/no-v-html -->
                                                <div
                                                    style="display:flex; flex-direction: row; flex-wrap: wrap; width:100%; height:100%;"
                                                >
                                                    <div
                                                        v-for="(el, index2) in item.uploads"
                                                        :key="forceRefreshComputed + index2"
                                                        :class="`mt-2 mr-1 ml-1 align-center justify-center ${el.file && el.file.isDeleted ? 'outdated-image' : ''}`"
                                                        :style="'height: ' + getPreviewSize(item.uploads) + '%; width: ' + getPreviewSize(item.uploads) + '%; min-width: 30%; min-height: 30%'"
                                                    >
                                                        <p
                                                            v-if="el.file && el.file.isDeleted"
                                                            style="font-size: 14px; color: white margin-right: 0.5 rem;"
                                                        >
                                                            Gelöscht:
                                                        </p>
                                                        <div
                                                            v-if="el && el.title && isFileThumbnailCompatibleExtension(el.title.split('.').pop().toLowerCase()) && el.hasThumbnail"
                                                            class="my-2"
                                                            style="background-color: #e6e6e6; border-radius: 8px; height: 100%; width: 100%;"
                                                        >
                                                            <img
                                                                :src="el.thumbnail"
                                                                class="iOS_ResponsiveHack"
                                                                style="object-fit: scale-down !important; cursor: pointer;"
                                                                :width="item.uploads.length === 1 ? '' : '100px'"
                                                                :height="item.uploads.length === 1 ? '' : '100px'"
                                                                :alt="el.title"
                                                                @click="() => handleThumbnailClicked(el, item)"
                                                            >
                                                        </div>
                                                        <div
                                                            v-else-if="el && el.title && isFileThumbnailCompatibleExtension(el.title.split('.').pop().toLowerCase())"
                                                            class="my-2"
                                                            style="background-color: #e6e6e6; border-radius: 8px"
                                                        >
                                                            <img
                                                                :src="img_eklaraLogo"
                                                                class="iOS_ResponsiveHack"
                                                                style="cursor: pointer; max-width: 100%;"
                                                                width="auto"
                                                                height="auto"
                                                                :alt="el.title"
                                                                @click="() => handleThumbnailClicked(el, item)"
                                                            >
                                                        </div>
                                                        <div
                                                            v-else-if="urls[el._id]"
                                                            class="my-2 d-flex align-center justify-center"
                                                            style="background-color: #e6e6e6; border-radius: 8px"
                                                        >
                                                            <!-- Display image preview -->
                                                            <img
                                                                v-if="el && el.title && types[el._id].includes('image') || ['png', 'jpg', 'jpeg', 'bmp', 'gif'].includes(el.title.split('.').pop().toLowerCase())"
                                                                :src="urls[el._id]"
                                                                style="cursor: pointer; max-width: 100%;"
                                                                width="auto"
                                                                height="auto"
                                                                alt=""
                                                                @click="() => showFullImage(item._id, el)"
                                                            >
                                                            <div
                                                                v-else-if="el && el.title && ['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"
                                                                style="position: relative"
                                                            >
                                                                <v-icon
                                                                    color="grey-darken-3"
                                                                    size="100px"
                                                                    style="top: 0; bottom: 0; margin-left: auto; margin-right: auto; left: 0; right: 0; position: absolute;"
                                                                    @click="() => playVideo(item._id, el)"
                                                                >
                                                                    mdi-play-circle-outline
                                                                </v-icon>
                                                                <img
                                                                    :src="urls[el._id]"
                                                                    style="cursor: pointer; max-width: 100%;"
                                                                    width="auto"
                                                                    height="auto"
                                                                    alt=""
                                                                    @click="() => playVideo(item._id, el)"
                                                                >
                                                            </div>
                                                            <!-- Display pdf preview -->
                                                            <object
                                                                v-else-if="el && el.title && ['pdf'].includes(el.title.split('.').pop().toLowerCase())"
                                                                type="application/pdf"
                                                                :data="urls[el._id] + '#toolbar=0&navpanes=0&scrollbar=0&view=Fit'"
                                                                style="cursor: pointer; min-height: 300px; max-height: 400px; max-width: 100%"
                                                                width="auto"
                                                                height="auto"
                                                            />
                                                            <!-- Display video preview -->
                                                            <!--                                            <video-->
                                                            <!--                                                v-else-if="['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(el.title.split('.').pop().toLowerCase())"-->
                                                            <!--                                                :poster="videoPoster"-->
                                                            <!--                                                controls-->
                                                            <!--                                                style="cursor: pointer;" width="100%" height="auto" >-->
                                                            <!--                                                <source :src="urls[el._id]" />-->
                                                            <!--                                            </video>-->
                                                            <v-container
                                                                v-else-if="el && el.title && ['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt'].includes(el.title.split('.').pop().toLowerCase())"
                                                                class="px-2 py-0 justify-center"
                                                                style="flex-wrap: wrap"
                                                            >
                                                                <v-flex
                                                                    align-self-start
                                                                    class="d-flex flex-row pa-2"
                                                                    shrink="true"
                                                                    style="align-items:center; background-color:rgb(var(--v-theme-hellgrau)); border-radius: 6px 6px 6px 6px; justify-content: space-between"
                                                                >
                                                                    {{ el.title }}
                                                                    <div>
                                                                        <v-tooltip
                                                                            location="top"
                                                                        >
                                                                            <template v-slot:activator="{ props }">
                                                                                <v-icon
                                                                                    v-bind="props"
                                                                                >
                                                                                    mdi-information-outline
                                                                                </v-icon>
                                                                            </template>
                                                                            <p>
                                                                                Dieses Dateiformat kann in
                                                                                Eklara nicht
                                                                                angesehen und bearbeitet werden.
                                                                                Bitte laden
                                                                                Sie diese dazu herunter
                                                                            </p>
                                                                        </v-tooltip>

                                                                        <v-btn
                                                                            class="btnStyle"
                                                                            size="x-small"
                                                                            elevation="0"
                                                                            @click="() => clickDownload(el,item._id)"
                                                                        >
                                                                            <img
                                                                                :src="runterladenIcon"
                                                                                alt="Herunterladen"
                                                                                style="height: 20px;"
                                                                            >
                                                                        </v-btn>
                                                                    </div>
                                                                </v-flex>
                                                            </v-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <v-menu
                                                    location="top left"
                                                    style="z-index: 99"
                                                    :disabled="(!(accountRole==='teacher' || 'maintainer'))||item.seenByTarget?.length<1"
                                                    :role="(!(accountRole==='teacher' || 'maintainer'))||item.seenByTarget?.length<1 ? '' : 'button'"
                                                >
                                                    <template v-slot:activator="{ props }">
                                                        <div
                                                            :style="(!(accountRole==='teacher' || 'maintainer'))||item.seenByTarget?.length<1 ? 'cursor: unset' : 'cursor: pointer'"
                                                            class="blackboardItemFooter justify-space-between pb-0"
                                                               
                                                            v-bind="props"
                                                        >
                                                            <!-- Accessibility -->
                                                            <p
                                                                v-if="item.createdOn"
                                                                class="visually-hidden"
                                                            >
                                                                {{
                                                                    dayname[new Date(item.createdOn).getDay()] + ', ' +
                                                                        new Date(item.createdOn).toLocaleDateString('de-DE', {
                                                                            day: '2-digit',
                                                                            month: '2-digit'
                                                                        }) + ' ' +
                                                                        new Date(item.createdOn).toLocaleTimeString('de-DE', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        }).toString() + ', Gelesen ' +
                                                                        item.seenByCount + ' von ' +
                                                                        item.seenByTarget?.length + ' Personen' +
                                                                        getAllSeenByCountNames(item)
                                                                }}
                                                            </p>
                                                            <div
                                                                v-if="item.createdOn"
                                                                class="d-flex justify-flex-end pb-0"
                                                                aria-hidden="true"
                                                            >
                                                                <span class="mr-1">
                                                                    {{ dayname[new Date(item.createdOn).getDay()].slice(0, 2) }}.
                                                                    {{
                                                                        new Date(item.createdOn).toLocaleDateString('de-DE', {
                                                                            day: '2-digit',
                                                                            month: '2-digit'
                                                                        })
                                                                    }}
                                                                    <span class="hideText"> {{
                                                                        new Date(item.createdOn).toLocaleTimeString('de-DE', {
                                                                            hour: '2-digit',
                                                                            minute: '2-digit'
                                                                        })
                                                                    }}</span>
                                                                </span>

                                                                <span
                                                                    v-if="accountRole === 'teacher' && item.checkMarkStyle !== 'hide'"
                                                                    :style="item.checkMarkStyle==='all' ? 'color: rgb(var(--v-theme-chatColor));' : 'color: rgb(var(--v-theme-timetableColor));'"
                                                                >
                                                                    {{ item.seenByCount ? item.seenByCount : 0 }}/{{ item.seenByTarget.length }}
                                                                </span>
                                                                <span
                                                                    v-if="accountRole === 'teacher' && item.checkMarkStyle === 'none'"
                                                                >
                                                                    <img
                                                                        :src="checkIcon"
                                                                        class="iconToBlue"
                                                                        style="width: 16px; height: 16px; margin-top: -3px"
                                                                    >
                                                                </span>
                                                                <span
                                                                    v-if="accountRole === 'teacher' && item.checkMarkStyle === 'some'"
                                                                >
                                                                    <img
                                                                        :src="checkAllIcon"
                                                                        class="iconToBlue"
                                                                        style="width: 16px; height: 16px; margin-top: -3px"
                                                                    >
                                                                </span>
                                                                <span
                                                                    v-if="accountRole === 'teacher' && item.checkMarkStyle==='all'"
                                                                >
                                                                    <img
                                                                        :src="checkAllIcon"
                                                                        class="iconToGreen"
                                                                        style="width: 16px; height: 16px; margin-top: -3px"
                                                                    >
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </template>
                                                    <simple-bar
                                                        class="scroll-area"
                                                    >
                                                        <v-list
                                                            density="compact"
                                                            class="darkMenuList"
                                                        >
                                                            <v-list-item class="d-flex flex-row justify-space-between">
                                                                <div style="color: white">
                                                                    Gelesen von:
                                                                </div>
                                                                <v-spacer />
                                                                <div
                                                                    style="font-size: 12px; align-items: flex-end; color: white"
                                                                >
                                                                    {{ "('" }}
                                                                    <img
                                                                        :src="checkIcon"
                                                                        class="iconToWhite"
                                                                        style="width: 14px; height: 14px; margin-left: -5px; margin-right: -4px;"
                                                                    >
                                                                    {{ "' = erhalten, '" }}
                                                                    <img
                                                                        :src="checkAllIcon"
                                                                        class="iconToWhite"
                                                                        style="width: 14px; height: 14px; margin-left: -3px; margin-right: -2px;"
                                                                    >
                                                                    {{ "' = gesehen)" }}
                                                                </div>
                                                            </v-list-item>
                                                            <template
                                                                v-for="element in item.seenByTarget"
                                                                :key="element._id"
                                                            >
                                                                <div
                                                                    style="background-color: transparent"
                                                                >
                                                                    <v-divider />
                                                                    <div
                                                                        v-if="element.role === 'parent'"
                                                                        class="d-flex flex-row justify-space-between px-2"
                                                                        style="max-width: 28vw;"
                                                                    >
                                                                        <div
                                                                            class="justify-flex-start"
                                                                            style="font-size: 14px; color: white"
                                                                        >
                                                                            {{ 'Eltern von ' + element.pupil.name + ' ' + element.pupil.lastName }}
                                                                        </div>
                                                                        <img
                                                                            v-if="item.seenByNames && item.seenByNames.includes('Eltern von ' + element.pupil.name + ' ' + element.pupil.lastName)"
                                                                            :src="checkAllIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px;"
                                                                        >
                                                                        <img
                                                                            v-else
                                                                            :src="checkIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px;"
                                                                        >
                                                                    </div>
                                                                    <div
                                                                        v-else
                                                                        class="d-flex flex-row justify-space-between px-2"
                                                                        style="max-width: 28vw;"
                                                                    >
                                                                        <div
                                                                            class="justify-flex-start"
                                                                            style="font-size: 14px; color: white"
                                                                        >
                                                                            {{ element.name + ' ' + element.lastName }}
                                                                        </div>
                                                                        <img
                                                                            v-if="item.seenByNames && item.seenByNames.includes(element.name + ' ' + element.lastName)"
                                                                            :src="checkAllIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px;"
                                                                        >
                                                                        <img
                                                                            v-else
                                                                            :src="checkIcon"
                                                                            class="iconToWhite justify-flex-end"
                                                                            style="width: 14px; height: 14px;"
                                                                        >
                                                                    </div>
                                                                </div>
                                                            </template>
                                                        </v-list>
                                                    </simple-bar>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </div>
                                    <!-- </v-badge> -->
                                </v-card>
                            </v-sheet>
                        </v-lazy>
                        <div
                            id="hiddenContainer"
                            class="hiddenContainerClass"
                            style="opacity: 0; margin: 0 20px"
                        />
                    </div>
                </simple-bar>
                <!-- Jump to start floating button -->
                <v-tooltip
                    v-if="showJumpToStart && !eintragPopup "
                    location="top"
                >
                    <template v-slot:activator="{ props }">
                        <v-fab
                            class="mb-10"
                            size="small"
                            location="bottom right"
                            
                            absolute
                            style="background-color: rgb(var(--v-theme-blackboardGrey))"
                           
                            v-bind="props"
                            @click="jumpToStart"
                        >
                            <img
                                :src="backIcon"
                                class="icon20 iconToWhite"
                                style="transform: rotate(90deg)"
                                alt="Pfeil nach oben"
                            >
                        </v-fab>
                    </template>
                    <span>Zum Anfang springen</span>
                </v-tooltip>
            </v-card>
        </div>
        <!-- <BadgeExplanations
            v-if="windowWidth < 900"
            :small="true"
        /> -->
        <!--#region dialogs -->
        <v-snackbar
            v-model="imageRightsWarning"
            color="warning"
            timeout="5000"
        >
            <template v-slot:actions="{ attrs }">
                <v-btn
                    variant="text"
                    v-bind="attrs"
                    size="small"
                    @click="imageRightsWarning = false"
                >
                    <img
                        style="max-width: 20px"
                        class="iconToWhite"
                        alt="Schließen"
                        :src="schliessenIcon"
                    >
                </v-btn>
            </template>
            Eltern folgender Kinder haben Bildrechte eingeschränkt: <strong
                v-for="(name, index) in warningNames"
                :key="index"
            > {{ name }}</strong>. 
            Bitte gehen Sie sicher das keines dieser Kinder in Ihrem Inhalt vorkommen.
        </v-snackbar>
        <!-- Fullscreen video dialog -->
        <v-dialog
            v-if="videoUrl"
            :model-value="videoUrl"
            scrim="rgba(0,0,0,0.9)"
            max-width="90%"
            width="unset"
            @click:outside="videoUrl = null; $refs.normalVideo.stop();"
        >
            <v-row
                justify="end"
                align="end"
            >
                <v-col
                    cols="1"
                    align-self="end"
                >
                    <v-icon
                        size="large"
                        theme="dark"
                        @click="videoUrl = null; primaryVideoUrl = null; secondaryVideoUrl = null; $refs.normalVideo.stop();"
                    >
                        mdi-close
                    </v-icon>
                </v-col>
            </v-row>
            <v-row justify="center">
                <v-col cols="auto">
                    <!--                    <video ref="normalVideo" :src="videoUrl" controls :width="windowWidth > 900 ? '80%' : '40%%'"-->
                    <!--                           :style="{ marginLeft: windowWidth > 900 ? '10%' : '10px' }">-->
                    <!--                        Your browser does not support the video tag.-->
                    <!--                    </video>-->
                    <video-in-video
                        ref="normalVideo"
                        :primary-video-src="videoUrl"
                        :subtitle-src="subtitleURL"
                        :fullscreen-mode="true"
                    />
                </v-col>
            </v-row>
        </v-dialog>

        <FullscreenImageDialog
            :image="imgFullscreen"
            :file-name="'eKlara_schwarzes_brett_bild'"
            :image-alt-text="'Bild im Schwarzen Brett'"
            @close="imgFullscreen = null"
        />
        <AreYouSurePopup
            v-if="showAreYouSure"
            v-model:show-dialog="showAreYouSure"
            :content-text-prop="areYouSureText"
            :usage="'brett'"
            ok-btn-color="rgb(var(--v-theme-frot))"
            :ok-button-text="hideDialog ? 'Verbergen': 'Löschen'"
            :header-color="'#3c3d3b'"
            @close="closeAreYouSure"
            @ok="acceptAreYouSure"
        />
        <FilePreviewDialog
            v-if="enableFilePreview"
            ref="previewDialog"
            :enable-fullscreen-preview="enableFilePreview"
            :enable-editing="true"
            :files="tempFiles"
            :is-user-author="true"
            :preview-selection="previewSelection"
            :preview-information="previewSelection"
            :preview-selection-index="0"
            :chat-mode="true"
            :thumbnail-url="urls[previewSelection._id]"
            :file-name="previewSelection.title"
            :disable-editor="true"
            :usage="'brett'"
            @close="resetFilePreview"
            @open-editor="openFileInEditor"
            @open-libre="openFileInLibre"
            @cycle-next="cycleNext"
            @cycle-prev="cyclePrevious"
            @download="clickDownload(previewSelection)"
        />
        <!--#endregion -->
    </div>
</template>

<script>
import {mapState, mapActions, mapGetters, mapMutations} from "vuex";
import sanitizeHtml from '../util/sanitizeHtml';
import urlify from '../util/urlify';
import { isFileThumbnailCompatibleExtension } from "../util/MimetypeHelper.js"

import IconPicker from "@/components/Utils/IconPicker";
import ToDoList from '@/components/ToDoList.vue';

import brettIcon from '../assets/Icons/redesignV3/blackboard.svg';
import backIcon from '@/assets/Icons/FaceliftIcons/zurueck_pfeil.svg';
import eingangIcon from '../assets/Icons/redesignV3/enter.svg';
import ausgangIcon from '../assets/Icons/redesignV3/exit.svg';
import pinIcon from "../assets/Icons/FaceliftIcons/pin.svg";
import pinIconOutlined from "../assets/Icons/FaceliftIcons/pin_kontur.svg";
import lautsprecherIcon from "../assets/Icons/lautsprecher-13.svg";
import lautsprecherAusIcon from "../assets/Icons/lautsprecher-aus-89.svg";
import infoIcon from "../assets/Icons/info-2-weiß-89.svg";
import downIcon from "../assets/Icons/FaceliftIcons/dropdown_mehr_unten_pfeil.svg";
import paperclipIcon from "@/assets/Icons/FaceliftIcons/anhang_bueroklammer.svg";
import clipIcon from "@/assets/Icons/FaceliftIcons/anhang_senkrecht.svg";
import optionsIcon from "../assets/Icons/FaceliftIcons/menu_mehr_weiteres_setup.svg";
import downThinIcon from "../assets/Icons/Icon_Pfeil_unten.svg";
import checkIcon from "@/assets/Icons/FaceliftIcons/haeckehn_versendet.svg";
import checkAllIcon from "@/assets/Icons/FaceliftIcons/haeckehn_gesehen.svg";
import keineNachrichtenIcon from "@/assets/Icons/FaceliftIcons/keine_nachrichten_schwarzes_brett.svg";
import groupIcon from '@/assets/Icons/group.svg';
import pupilIcon from '@/assets/Icons/pupil.svg';
import teacherIcon from '@/assets/Icons/teacher-white.svg';

import sonneIcon from "../assets/Icons/FaceliftIcons/sonne_weiss.svg";
import erdkundeIcon from '../assets/Icons/FaceliftIcons/f-erdkunde-weiss.svg';
import religionIcon from '../assets/Icons/FaceliftIcons/f-religion-weiss.svg';
import politikIcon from '../assets/Icons/FaceliftIcons/f-politik-weiss.svg';
import bioIcon from '../assets/Icons/FaceliftIcons/f-biologie-weiss.svg';
import englischIcon from "../assets/Icons/FaceliftIcons/f-englisch.svg";
import englischIconWeiss from "../assets/Icons/FaceliftIcons/f-englisch-weiss.svg";
import plusIcon from "../assets/Icons/plus.svg";
import schliessenIcon from "../assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import sendenIcon from "../assets/Icons/FaceliftIcons/absenden_versenden_flugzeug.svg";
import bearbeitenIcon from "../assets/Icons/FaceliftIcons/stift_bearbeiten_.svg";
import runterladenIcon from '../assets/Icons/FaceliftIcons/herunterladen.svg';
import deleteIcon from '../assets/Icons/FaceliftIcons/loeschen_muelleimer.svg';
import videoIcon from '@/assets/Icons/video.svg';
import cameraIcon from "@/assets/Icons/FaceliftIcons/kamera.svg";
import img_eklaraLogo from '@/assets/Icons/FaceliftIcons/datei_blatt.svg';

import videoPoster from "@/assets/Bilder/videoposter.png";
import * as backend from "../api/backend";
import sonneIconNormal from "@/assets/Icons/FaceliftIcons/sonne.svg";
import bioIconNormal from "@/assets/Icons/FaceliftIcons/f-biologie.svg";
import englischIconNormal from "@/assets/Icons/FaceliftIcons/f-englisch.svg";
import erdkundeIconNormal from "@/assets/Icons/FaceliftIcons/f-erdkunde.svg";
import politikIconNormal from "@/assets/Icons/FaceliftIcons/f-politik.svg";
import religionIconNormal from "@/assets/Icons/FaceliftIcons/f-religion.svg";
import AdvancedQuillEditor from "@/components/Utils/AdvancedQuillEditor";
import VideoInVideo from "@/components/Utils/VideoInVideo";
import {textToSpeech} from "@/util/textToSpeech";
import FullscreenImageDialog from "@/components/Utils/FullscreenImageDialog";
import ClickableBadge from "@/components/Utils/ClickableBadge";
import FilePreviewDialog from "@/components/Utils/FilePreviewDialog";
import AreYouSurePopup from "@/components/Utils/AreYouSurePopup"
import pdfjs from "@/util/pdfjs";
import { generateThumbnailData } from "@/util/tempThumbnailGen";
import EventBus from '../util/eventBus.js'
import { getCurrentInstance } from 'vue';
import Avatar from "@/components/SharedViews/Profile/Avatar";
import avatar1 from "@/assets/Icons/Avatar1.svg";
import avatar2 from "@/assets/Icons/Avatar2.svg";
import avatar3 from "@/assets/Icons/Avatar3.svg";
import avatar4 from "@/assets/Icons/Avatar4.svg";
import avatar5 from "@/assets/Icons/Avatar5.svg";
import avatar6 from "@/assets/Icons/Avatar6.svg";
import avatar7 from "@/assets/Icons/Avatar7.svg";
import avatar8 from "@/assets/Icons/Avatar8.svg";
import avatar9 from "@/assets/Icons/Avatar9.svg";
import avatar10 from "@/assets/Icons/Avatar10.svg";
import avatar11 from "@/assets/Icons/Avatar11.svg";
import avatar12 from "@/assets/Icons/Avatar12.svg";
import avatar13 from "@/assets/Icons/Avatar13.svg";
import avatar14 from "@/assets/Icons/Avatar14.svg";
import BadgeExplanations from './Utils/BadgeExplanations.vue';

export default {
    name: "BrettWidget",
    components: {
        Avatar,
        ClickableBadge,
        FullscreenImageDialog,
        VideoInVideo,
        AdvancedQuillEditor,
        IconPicker,
        AreYouSurePopup,
        ToDoList,
        FilePreviewDialog,
        //BadgeExplanations,
    },
    props: {
        showTooltips: {required: false, default: true},
        currentChild: {required: false, type: Object},
        reset: { required: false, type: Boolean},
    },
    data: () => ({
        isFileThumbnailCompatibleExtension,

        debounceTimeout: null,

        urlify,
        //#region Icons
        pinIcon,
        brettIcon,
        backIcon,
        eingangIcon,
        ausgangIcon,
        lautsprecherIcon,
        lautsprecherAusIcon,
        infoIcon,
        plusIcon,
        schliessenIcon,
        sendenIcon,
        bearbeitenIcon,
        videoPoster,
        runterladenIcon,
        deleteIcon,
        downIcon,
        paperclipIcon,
        clipIcon,
        optionsIcon,
        downThinIcon,
        pinIconOutlined,
        videoIcon,
        cameraIcon,
        checkIcon,
        checkAllIcon,
        keineNachrichtenIcon,
        img_eklaraLogo,
        groupIcon,
        pupilIcon,
        teacherIcon,

        defaultPictures: {
            Avatar1: avatar1,
            Avatar2: avatar2,
            Avatar3: avatar3,
            Avatar4: avatar4,
            Avatar5: avatar5,
            Avatar6: avatar6,
            Avatar7: avatar7,
            Avatar8: avatar8,
            Avatar9: avatar9,
            Avatar10: avatar10,
            Avatar11: avatar11,
            Avatar12: avatar12,
            Avatar13: avatar13,
            Avatar14: avatar14,
        },

        sonneIconNormal,
        bioIconNormal,
        englischIconNormal,
        erdkundeIconNormal,
        politikIconNormal,
        religionIconNormal,
        //#endregion

        // Filepreviewdialog
        previewSelection: null,
        enableFilePreview: false,
        thumbnailUrl: '',
        tempFiles: [],
        currentBlackboardId: null,

        // pupils: [],
        imgFullscreen: null,
        urls: [],
        types: [],
        group: '',
        icon: '',
        color: '',
        message: '',
        timestamp: '',
        reloadKey: false,

        forceRefreshComputed: 0,

        // blackboard: [],
        countingLength: 10,
        remainingBlackboardItems: '',
        // hiddenBlackboard: [],
        updateBlackboard: true,
        eintragPopup: false,
        brettEintrag: {title: '', message: '', icon: 'sonneIcon', color: '#fcd20a', groupId: '', blackboardId: '', uploads: [], uploadedIcon: null},
        // used to store currently selected groups
        groupsBlackboard: [],

        bearbeiten: false,
        showLoadingOverlay: false,
        // teachers: [],
        // allAccounts: [],
        newEntryTitle: '',
        videoUrl: null,
        subtitleURL: null,
        editEntryDetails: false,
        showTTSBtn: '',

        isAllRemovedFromGroups: false,

        editorOption: {
            placeholder: 'Text eingeben',
            modules: {
                toolbar: {
                    container: [
                        [{'size': ['large', false]}],
                        [{'color': []}],
                        ['bold', 'italic', 'underline'],
                        [{'list': 'ordered'}, {'list': 'bullet'}],
                        ['link', 'video'],
                    ],
                },
            }
        },
        settings: {
            suppressScrollY: false,
            suppressScrollX: true,
            wheelPropagation: false
        },

        eingangTooltip: 'Dies ist der Nachrichteneingang des Schwarzen Bretts. Titel anklicken, um ausschließlich gesendete Nachrichten anzuzeigen.',
        ausgangTooltip: 'Dies ist der Nachrichtenausgang des Schwarzen Bretts. Titel anklicken, um alle Nachrichten anzuzeigen.',

        // Icon array for dynamically picking the icon via string
        icons: [
            {icon: englischIcon, text: "englischIcon"},
            {icon: sonneIcon, text: "sonneIcon"},
            {icon: englischIconWeiss, text: "englischIconWeiss"},
            {icon: politikIcon, text: "politikIcon"},
            {icon: erdkundeIcon, text: "erdkundeIcon"},
            {icon: religionIcon, text: "religionIcon"},
            {icon: bioIcon, text: "bioIcon"},
        ],

        dayname: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
        showTextTimeout: null,
        sanitizeHtml,

        clicked: '',
        filterList: ['Alle', 'Eingang', 'Ausgang'],

        //blackboard: '',
        on: '',
        attrs: '',

        selectedCards: [],
        selectedItems: [],
        multiselect: false,

        deleteDialog: false,
        hideDialog: false,
        hideItemId: null,
        deleteItem: false,
        filesToUpload: [],
        pinIndexAll: '',
        nextPinned: '',
        speechActive: false,

        showJumpToStart: false,
        // Drag and drop functionality stuff
        dropUiActive: false,
        dropzone: false,

        showProfilePicUploadProgress: false,
        profilePicUploadProgress: 0.0,
        profilePictureBlob: null,
        uploadIcon: null,
        loadOnScrollEnabled: false,
        showTranslationBtn: false,

        //ToDoList stuff
        createToDoList: false,
        toDoInputs: null,
        toDoTitle: null,

        editToDoList: null,
        addedToDoInputs: null,
        editedToDoTitle: null,
        tasksToBeDeleted: [],
        listToBeDeleted: false,

        profileIcon: true,
        fullTeacher: null,
        fullMaintainer: null,
        profilePic: null,
        profileColor: '',

        maintainerSelected: false,
        fixClicked: false,
        optionsOpen: false,

        showSpeaker: '',
        timeoutId: '',

        dataLoading: true,
        imageRightsWarning: false,
        warningNames: '',
        overlayColor: '#f5a032',
        highlightedOverlayId: null,

        searchBlackboardMessages: '',
    }),
    computed: {
        ...mapState('translation', ['targetLang',]),
        ...mapState('util', ['windowWidth']),
        ...mapGetters("auth", ['accountRole', 'accountId']),
        // transition to store handling
        ...mapGetters("groups", {'vuexGroups': 'groups'}),
        ...mapGetters("groups", ['groupsById', 'groupsByLeader']),
        ...mapGetters("teachers", {'vuexTeachers': 'teachers'}),
        ...mapGetters("teachers", ['teachersByAccountId', 'teachersById', 'profilePicturesById']),
        ...mapGetters('parents', ['parentsById']),
        ...mapGetters("pupils", {'vuexPupils': 'pupils'}),
        ...mapGetters("accounts", {'vuexMe': 'me'}),
        ...mapGetters("accounts", ["accounts",'accountsById']),
        ...mapGetters("blackboard", {'hiddenBlackboard':'prettyBlackboardEntries'}),
        ...mapGetters('blackboard', ['getThumbnail','blackboardBadgeEventList']),
        ...mapGetters('imageRights', ['imageRightsByGroup']),

        showAreYouSure(){
            return this.deleteDialog || this.hideDialog;
        },
        areYouSureText(){
            return this.deleteDialog ? 'Sollen diese Nachrichten endgültig gelöscht werden?' : 'Soll diese Nachricht in deinem Schwazen Brett Verborgen werden? Dies hat keine Auswirkungen bei anderen Nutzern.'
        },
        // the main actor in this game
        blackboard() {
            let result = this.hiddenBlackboard;
            // filter for selected headers
            if (this.selectedFilter === 'Eingang') {
                result = result.filter((blackBoardItem) => {
                return !this.myEntry(blackBoardItem.createdBy)
                });

            } else if (this.selectedFilter === 'Ausgang') {
                result = result.filter((blackBoardItem) => {
                return this.myEntry(blackBoardItem.createdBy)
                });
            }

            result = result.reduce((previous, blackBoardItem) => {
                if (blackBoardItem) {
                const changedItem = {
                    ...blackBoardItem,
                    checkMarkStyle: this.calculateCheckmarkStyle(blackBoardItem),
                };
                previous.push(changedItem);
                }
                return previous;
            }, []);
            return result;
        },
        searchBlackboard(){
            if(this.searchBlackboardMessages !== ''){
                return this.blackboard.filter((entry) => (entry.title && entry.title.toLowerCase().includes(this.searchBlackboardMessages.toLowerCase())) ||
                 (entry.message.toLowerCase().includes(this.searchBlackboardMessages.toLowerCase())));
            }
            return this.blackboard;
        },
        selectedAllGroups() {
            return this.groupsBlackboard.length === this.myGroups.length;
        },
        selectedSomeGroups() {
            return this.groupsBlackboard.length > 0 && !this.selectedAllGroups;
        },
        selectIcon () {
            if (this.selectedAllGroups) return 'mdi-close-box';
            if (this.selectedSomeGroups) return 'mdi-minus-box';
            return 'mdi-checkbox-blank-outline';
        },
        selectMaintainerIcon(){
            if (this.maintainerSelected) return 'mdi-checkbox-outline';
            return 'mdi-checkbox-blank-outline';
        },
        myGroupsWithoutAlle() {
            const groups = this.accountRole === 'maintainer' ? this.vuexGroups : this.groupsByLeader(this.me._id);
            return groups.filter((group) => group.name !== 'Alle');
        },
        myGroups() {
            return this.accountRole === 'maintainer' ? this.vuexGroups : this.groupsByLeader(this.me._id);
        },
        teachers() {
            return this.vuexTeachers;
        },
        pupils() {
            return this.vuexPupils;
        },
        me() {
            const defaultMe = {
                accessibility: {
                    screenreader: false,
                }
            };
            return this.vuexMe || defaultMe;
        },
       
        selectedFilter() {
            return this.filterList[0];
        },
        containerTitel() {
            let titel = 'Schwarzes Brett';
            if (this.selectedFilter !== 'Alle') {
                titel = this.selectedFilter;
            }
            return titel;
        },
        pinnedAll() {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            let entryItems = [];
            const bbItems = this.blackboard;

            if (entries && entries.length > 0) {
                entries.forEach((entry) => {
                    bbItems.forEach((el) => {
                        if (el._id === entry) {
                            entryItems.push(el);
                        }
                    });
                });
            }
            entryItems = entryItems.sort((a, b) => {
                let keyA = new Date(a.createdOn);
                let keyB = new Date(b.createdOn);
                if (keyA > keyB) return 1;
                if (keyA < keyB) return -1;
                return 0;
            });
            return entryItems;
        },
        pinnedEingang() {
            return this.pinnedAll.filter((el) => {
                return !this.myEntry(el.createdBy)
            });
        },
        pinnedAusgang() {
            return this.pinnedAll.filter((el) => {
                return this.myEntry(el.createdBy)
            });

        },
        startIndex() {
            let index = this.pinnedAll.length - 1;
            if (index < 0) {
                index = 0;
            }
            return index;
        },
        dragDropUploadAllowed() {
            return (this.accountRole === 'teacher' || this.accountRole === 'maintainer') && this.eintragPopup;
        },
        isActive() {
            return this.blackboard.map((item) => item && item.isActive);
        },
        role() {
            return this.accountRole;
        },
        brettTooltip() {
            return 'Dies ist das Schwarze Brett mit allen Nachrichten.' + (this.accountRole === 'teacher' ? ' Titel anklicken, um ausschließlich eingehende Nachrichten anzuzeigen.' : '');
        },
        teacherOrMaintainerId() {
            if (this.fullTeacher) {
                return this.fullTeacher._id;
            } else if (this.fullMaintainer) {
                return this.fullMaintainer._id;
            } else {
                return null;
            }
        },
        usageAgreementsInSelectedGroups(){
            if(this.groupsBlackboard.length > 0){
                let parentAccounts = [];
                const allParticipants = [].concat(...this.groupsBlackboard.map(group => group.participants))

                const participantAccounts = allParticipants.map(participant => {
                    if(participant.parent){
                        const parent = this.parentsById[participant.parent]
                        if(parent){
                            const parentAccount = this.accountsById[parent.account];
                            if(!parentAccounts.includes(parentAccount)){
                                 parentAccounts.push(parentAccount);
                            }
                        }
                    }
                     return this.accountsById[participant.account]
                });
                const accountsToBeExported = [...participantAccounts, ...parentAccounts];
                return accountsToBeExported;
            }
            return [];
        }
    },
    watch: {
        isActive(newValue) {
            this.showOrHideExpandButtons();
        },
         currentChild: {
            async handler(newVal) {
                
                if(newVal && newVal._id){
                    //child changed, needs to remount
                    this.dataLoading = true
                    await this.getAllToDoListsWithTasks();
                    await this.getParentBlackboard(newVal._id);
                    this.brettEintrag.groupIds = [];
                    await this.requestBlackboard();
                    // Disabled image previews for now due to too much traffic
                    //await this.loadImagePreviews();
                    this.initializeIndex();
                    this.loadOnScrollEnabled = true;
                    // initialize event bus
                    this.initializeEventBus();
                    this.dataLoading = false;
                }
           

            // Disabled image previews for now due to too much traffic
            // await this.loadImagePreviews();
            },immediate: true,
        },
        // getGroupStatus(newVal, oldVal) {
        //     if (newVal && !oldVal) {
        //         this.requestGroups();
        //     }
        // },
        pinIndexAll(newVal, oldVal) {
            if (newVal) {
                if (newVal < 0) {
                    this.pinIndexAll = this.pinnedAll.length - 1;
                }
            }
        },
        startIndex(newVal, oldVal) {
            if (newVal && !oldVal) {
                this.initializeIndex();
            }
        },
        optionsOpen(newVal){
            if(!newVal){
                this.fixClicked = false;
            }
        },
         reset(newVal, oldVal){
            if(newVal && newVal === true ){
                this.triggerRemount()
            }
        }
    },
    beforeMount() {
        // call methods of store, if the data is not there it will send a request itself
        this.getTeachers();
        this.getPupils();
        this.getAccount(true);
    },
    async mounted() {
        const t0 = performance.now();

        await this.getAllToDoListsWithTasks();
        if (this.role === 'parent' ) {
            if(this.currentChild?._id){
             await this.getParentBlackboard(this.currentChild?._id);
            }
        } else if (this.role === 'teacher' || this.role === 'maintainer') {
            const currentAcc = await this.getCurrentAccount(true);
            await this.determineProfilePicture(currentAcc);
            const t5=performance.now();
            await this.getBlackboard();
            const t6=performance.now();
            console.debug(`this.getBlackboard Perfomance ${t6-t5} ms`);
        } else {
            await this.getBlackboard();
        }
        this.brettEintrag.groupIds = [];
        await this.requestBlackboard();
        // this.calculateBadge();
        // Disabled image previews for now due to too much traffic
        //await this.loadImagePreviews();
        this.initializeIndex();
        this.loadOnScrollEnabled = true;
        // initialize event bus
        this.initializeEventBus();
        this.dataLoading = false;
        const t1 = performance.now();
        console.debug(`BrettMounted in: ${t1 - t0} ms.`);
        this.$emit('brettMounted');
    },
    async unmounted() {
        this.urls.forEach(async (url) => {
            URL.revokeObjectURL(url);
        });
        URL.revokeObjectURL(this.videoUrl);
        URL.revokeObjectURL(this.subtitleURL);
    },
    methods: {
        ...mapState('auth', ['account', 'getAccount']),
        ...mapActions("blackboard", [
            "getBlackboard",
            "getParentBlackboard",
            "createBlackboardEntry",
            "updateBlackboardEntry",
            "deleteBlackboardEntry",
            "deleteBlackboardUpload",
            "getBlackboardUpload",
            'markBlackboardMessageSeen',
            'uploadBlackboardAttachment',
            'getBlackboardUploadAsUrl',
            'getBlackboardUploadThumbnail',
            'emptyBufferIntoState',
            'pushNewSingleEntry',
            'getBlackboardProfilePicture',
            'toggleFixEntry',
            'enrichAndUpdateSingleEntry',
            'hideBlackboardMessage'
        ]),
        ...mapActions('todolist',['getAllToDoListsWithTasks', 'postToDoList']),
        ...mapActions('translation', [ 'setTranslatedText', 'showTranslation', 'translateToTargetLang' ]),
        ...mapActions('parents', [ 'getMeParent', 'getParent']),
        ...mapActions('auth', ['getAccount', 'getCurrentAccount']),
        // ...mapActions('accounts', ['getAccounts']),
        ...mapActions("groups", ['getGroups']),
        ...mapActions("teachers", ['getTeachers', 'getMeTeacher', 'getProfilePicture', 'deleteProfilePicture']),
        ...mapActions("pupils", [ 'getMePupil', 'getPupils']),
        ...mapActions("maintainers", ['getMeMaintainer','getMaintainers', 'deleteMaintainerProfilePicture', 'getMaintainerProfilePicture']),
        ...mapActions('mimeTypes', [ 'validateMimeType','getFileExtension']),
        ...mapActions('uploadedIcons', ['getUploadedIcon']),
        ...mapMutations('snackbar', [ 'showSnackbar' ]),
        ...mapMutations('badges', [ 'setBlackboardBadge' ]),
        ...mapMutations('blackboard', ['setEntrySeen', 'updateSingleEntry', 'spliceSingleEntry', 'pushUploadIntoEntry', 'setAllBlackboardEntries', 'addThumbnail']),
        ...mapActions('imageRights',['getImageRightNamesForGroups']),
       triggerRemount(){
                this.countingLength = 10,
                this.remainingBlackboardItems = '',
                this.eintragPopup = false,
                this.brettEintrag= {title: '', message: '', icon: 'sonneIcon', color: '#fcd20a', groupId: '', blackboardId: '', uploads: [], uploadedIcon: null},
                this.groupsBlackboard= [],
                this.bearbeiten= false,
                this.newEntryTitle = false,
                this.editEntryDetails= false,
                this.clicked= '';
                this.filterList= ['Alle', 'Eingang', 'Ausgang'];
                this.selectedCards= [];
                this.selectedItems= [];
                this.deleteDialog= false;
                this.filesToUpload= false;
                this.pinIndexAll = '';
                this.nextPinned= '';
                this.showJumpToStart= false;
                this.createToDoList= false;
                this.toDoInputs = null;
                this.toDoTitle= null;
                this.editToDoList = null;
                this.addedToDoInputs = null;
                this.editedToDoTitle = null;
                this.tasksToBeDeleted = [];
                this.listToBeDeleted = false;
                this.maintainerSelected= false;
                this.fixClicked=false;
                this.optionsOpen= false;
                this.hideItemId = false;
                this.$emit('brettRemounted');
                this.jumpToStart();
        },
        clickEdit(item){
            if(!this.myEntry(item.createdBy)){
                    this.showHint();
            }else{
            this.bearbeiten = true; this.eintragPopup = true; this.loadOnScrollEnabled = false; this.setBrettPopup(item._id);
            }
        },
        clickDelete(item){
            if(!this.myEntry(item.createdBy)){
                    this.showHint();
            }else{
                this.deleteDialog=true; this.deleteItem=item;
            }
        },
        showHint() {
            this.showSnackbar({message:'Nur der Ersteller der Nachricht kann diese Berabeiten und Löschen.', color:'warning',})
        },
        filterUnseenBlackboardEntries() {
            return this.blackboard.filter(blackboardEntry => blackboardEntry && !this.seenByMe(blackboardEntry));
        },

  
        // updateEntryCount(entries) {
        //     this.$emit('updateBlackboardEntryCount', entries.length);
        // },
        checkForDeletion(file) {
            if (file && file.isDeleted) {
                this.showSnackbar({
                    message: "Aufgrund 30 Tagen Inaktivität ist die Datei nicht mehr verfügbar.",
                    color: "error"
                });
                return true;
            }
            return false;
        },
        async determineProfilePicture(currentAcc) {

            const setDefaultPicture = () => {
                this.brettEintrag.icon = currentAcc.profilePicture?.img;
                this.brettEintrag.color = currentAcc.profileColor;
                this.profileColor = currentAcc.profileColor;
                let avatarKey = 'Avatar3';
                if (this.account && this.account.profilePicture
                    && this.account.profilePicture.img
                    && this.account.profilePicture.img.includes('Avatar')) {
                    avatarKey = this.account.profilePicture.img.replace('/', '').replace('.svg', '');
                }
                this.profilePic = this.defaultPictures[`${avatarKey}`];
            };

            if (this.role === 'teacher') {
                this.fullTeacher = this.teachersByAccountId[currentAcc._id]
                if (this.fullTeacher.profilePicture) {
                    this.brettEintrag.icon = this.fullTeacher._id;
                    this.profilePic = this.profilePicturesById[this.fullTeacher._id]
                } else {
                    const t3=performance.now();
                    setDefaultPicture();
                    const t4=performance.now();
                    console.debug(`settingDeafultPicture Perfomance?? ${t4-t3} ms`);

                }
            } else {
                let maintainers = await this.getMaintainers();
                this.fullMaintainer = maintainers[0];
                if (this.fullMaintainer.profilePicture) {
                    this.brettEintrag.icon = this.fullMaintainer._id;
                    this.profilePic = await this.getMaintainerProfilePicture(this.fullMaintainer._id);
                } else {
                    setDefaultPicture();
                }
            }
        },

        cycleNext() {
            this.previewSelectionIndex++;
            if (this.tempFiles.length === this.previewSelectionIndex) {
                this.previewSelectionIndex = 0;
            }
            // this.tempFiles[this.previewSelectionIndex].thumbnail = this.urls[this.tempFiles[this.previewSelectionIndex]._id];
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },
        cyclePrevious() {
            this.previewSelectionIndex--;
            if (this.previewSelectionIndex < 0) {
                this.previewSelectionIndex = this.tempFiles.length - 1;
            }
            // this.tempFiles[this.previewSelectionIndex].thumbnail = this.urls[this.tempFiles[this.previewSelectionIndex]._id];
            this.previewSelection = this.tempFiles[this.previewSelectionIndex];
        },
        async openFileInEditor() {
        },
        async openFileInLibre() {
            let file;
            let res;
            if (this.previewSelection.uploader !== this.accountId) {
                res = await backend.getCopyOfFile(this.previewSelection.file, this.previewSelection.fileAccessToken);
                file  = await res.json();
            } else {
                file = this.previewSelection;
            }
            res = await backend.getLibreDocFromFile(file.file ? file.file : file._id);
            const doc = await res.json();

            const libreAccessToken = doc.accessToken;
            const libreDocId = doc.libreDoc._id;
            const docFile = doc.file;
            const fileType = await this.getFileExtension(docFile.mimetype);


            const query = {
                ... this.$route.query,

                fileId: docFile._id,
                libreDocId: libreDocId,
                accessToken: libreAccessToken,
                fileAccessToken: file.fileAccessToken ? file.fileAccessToken : file.accessToken,
                fileType: fileType,
                mode: 'default',
            };

            await this.$router.push({
                name: 'libreoffice',
                query,
            });
        },

        /**
         * Method needed for live update of blackboard via sockets
         */
        initializeEventBus() {
            EventBus.off('new-thumbnail-blackboard');
            EventBus.on('new-thumbnail-blackboard', async (fileId, fileAccessToken) => {
                let foundUpload;
                const foundBlackboardMessage = this.blackboard.find((message) => {
                    foundUpload = message.uploads.find((upload) => upload.file._id === fileId);
                    return foundUpload;
                });
                if (!foundBlackboardMessage) {
                    return;
                }
                await this.loadImagePreview(foundBlackboardMessage, foundUpload, fileAccessToken);
                this.forceRefreshComputed++;
            });
            EventBus.off('blackboard-new');
            EventBus.on('blackboard-new', async (body) => {
                if (body.sender === this.accountId) return;
                this.pushNewSingleEntry(body.createdMessage);
            });
            EventBus.off('blackboard-seen');
            EventBus.on('blackboard-seen', async (body) => {
                    console.log('blackboard-seen');
                await this.setEntrySeen(body);
            });
            EventBus.off('blackboard-delete');
            EventBus.on('blackboard-delete', (messageId) => {
                this.spliceSingleEntry(messageId);
            });
            EventBus.off('blackboard-patch');
            EventBus.on('blackboard-patch', async (updatedMessage) => {
                const returnUpdated = updatedMessage;
                if(updatedMessage && updatedMessage.toDoList){
                    returnUpdated.toDoList = await this.populateToDoList(updatedMessage.toDoList);
                }
                if (updatedMessage.uploads.length) {
                    await Promise.all(updatedMessage.uploads.map(async (currentUpload) => {
                        const foundThumbnail = await this.getThumbnail(currentUpload._id);
                        if (foundThumbnail) {
                            currentUpload.hasThumbnail = foundThumbnail.hasThumbnail;
                            currentUpload.thumbnail = foundThumbnail.thumbnail;
                        } else {
                            await this.loadImagePreview(updatedMessage, currentUpload, currentUpload.fileAccessToken);
                        }
                    }));
                }
                await this.updateSingleEntry(returnUpdated);
            });
            EventBus.off('blackboard-new-upload');
            EventBus.on('blackboard-new-upload', async(body) => {
                const foundMessage = this.blackboard.find((message) => message._id === body.messageId);
                if (!foundMessage || foundMessage.uploads.find((upload) => upload._id === body.upload._id)) {
                    return;
                }
                body.upload.fileAccessToken = body.upload.file.accessToken;
                const updatedMsg = {
                    ...foundMessage,
                    uploads: foundMessage.uploads.concat([ body.upload ]),
                };
                await this.enrichAndUpdateSingleEntry(updatedMsg);
            });
        },

        selectAll() {
            if (this.selectedAllGroups) {
                this.groupsBlackboard = [];
            } else {
                this.groupsBlackboard = this.myGroupsWithoutAlle.slice();
            }
            if(this.accountRole !== 'maintainer'){
                if(this.groupsBlackboard.length >0){
                    this.maintainerSelected = true;
                }else{
                    this.maintainerSelected = false;
                }
            }
            this.displayInfoSnackbar();
        },
        selectMaintainer(){
            this.maintainerSelected = !this.maintainerSelected;
            this.displayInfoSnackbar();
        },
        /**
         * This method waits until the necessary message element exist in the DOM and executes
         * this.showOrHideExpandButtons() afterwards.
         * @param idOfEditedElement if an entry is edited this is the id of the edited Element
         */
        checkShowOrHideExpandButtonsAfterLoad(idOfEditedElement) {
            let i = 0;
            const interval = setInterval(() => {
                if (this.blackboard.length === 0 || i > 10000) {
                    clearInterval(interval);
                    return;
                }
                const elementId = idOfEditedElement ? 'entryMessage-' + idOfEditedElement : 'entryMessage-' + this.blackboard[0]._id;
                if (document.getElementById(elementId) !== null) {
                    clearInterval(interval);
                    this.showOrHideExpandButtons();
                }
                i++;
            }, 50)
        },

        /**
         * This will decide if a blackboard entry needs a dropdown arrow to expand it's content or not.
         */
        showOrHideExpandButtons() {
            // console.log('showOrHideExpandButtons');
            this.blackboard.forEach((item) => {
                if (item && item.message !== "") {
                    const pContainer = document.getElementById('entryMessage-' + item._id);
                    if (pContainer && !item.showExpandButton) {
                        item.showExpandButton = pContainer.offsetWidth === pContainer.parentElement.offsetWidth
                            || pContainer.childElementCount > 1
                            || pContainer.getElementsByTagName('ol').length > 0
                            || pContainer.getElementsByTagName('ul').length > 0;
                    }
                }
            })
            this.forceRefreshComputed++;
        },

        scrolled(scrollAmount) {
            this.showJumpToStart = scrollAmount > 0;
            // Debounce this to fix expand buttons
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }

            this.debounceTimeout = setTimeout(() => {
                this.showOrHideExpandButtons();
            }, 200);
        },

        // async requestPupils() {
        //     this.pupils = await this.getPupils();
        // },

        // async requestAccounts() {
        //     this.allAccounts = await this.getAccounts();
        // },

        removeFileToUpload(fileId) {
            this.filesToUpload = this.filesToUpload.filter(obj => obj.lastModified !== fileId);
        },
        getThumb(blob) {
            return URL.createObjectURL(blob);
        },
        loadPicture(file) {
            const zwischenBlob = URL.createObjectURL(file);
            return zwischenBlob;

        },

        clickUpload() {
            document.getElementById("fileUpload").click();
        },

        displayTTSBtn(itemId) {
            let one = this.showTTSBtn === itemId;
            let two = this.accountRole === 'pupil' || this.accountRole === 'parent'
            return one && two;
        },


        async loadFullscreenPreview(upload, blackboardId) {
            const foundBlackboard = await this.blackboard.find((blackboard) => blackboard._id === blackboardId);
            if (!foundBlackboard) {
                return;
            }
            const foundUpload = foundBlackboard.uploads.find((currentUpload) => currentUpload._id === upload._id);
            if (!foundUpload) {
                return;
            }
            this.tempFiles = foundBlackboard.uploads;
            this.previewSelectionIndex = this.tempFiles.indexOf(upload);
            this.previewSelection = upload;
            this.currentBlackboardId = blackboardId;
            this.enableFilePreview = true;
            // setTimeout(() => {
                // go to html element of vue component to get correct focus
            //     this.$refs.previewDialog.$el.focus();
            // }, 150);
        },

        resetFilePreview() {
            this.tempFiles = [];
            this.previewSelectionIndex = 0;
            this.enableFilePreview = false;
        },
        async clickDownload(file, blackboard) {
            if (this.checkForDeletion(file.file)) return;
            const blackboardId = blackboard ? blackboard : this.currentBlackboardId;
            const fileId = file.file._id ? file.file._id : file.file;
            // call this to get the upload directly as an object url (or empty image as fallback)
            const res = await this.getBlackboardUploadAsUrl({ blackboardId, fileId });
            const url = res.link;
            const a = document.createElement("a");
            a.href = url;
            a.download = file.title;
            document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
            a.click();
            setTimeout(() => {
                a.remove();
                window.URL.revokeObjectURL(url);
            }, 1000);
        },
        async paperclipDownload(uploads, blackboardId) {
            await uploads.reduce(async (uploadsPromise, upload) => {
                const previousUploads = await uploadsPromise;
                await this.clickDownload(upload, blackboardId);
                return previousUploads;
            }, Promise.resolve([]));
        },
        // calculateBadge() {
        //     let count = 0;
        //     for (let i = 0; i < this.blackboard.length; i++) {
        //         if (!this.seenByMe(this.blackboard[i])) {
        //             count++;
        //         }
        //     }
        //     if (count <= 0) {
        //         this.setBlackboardBadge(false);
        //     } else {
        //         this.setBlackboardBadge(count);
        //         this.$emit('brettBadge');
        //     }
        // },
        initializeIndex() {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            let entryItems = [];
            const bbItems = this.blackboard;
            if (entries && entries.length > 0) {
                entries.forEach((entry) => {
                    bbItems.forEach((el) => {
                        if (el._id === entry) {
                            entryItems.push(el);
                        }
                    });
                });
            }
            this.pinIndexAll = entryItems.length - 1;


            this.initializeNextPinned();

        },
        initializeNextPinned() {
            if (this.pinnedAll.length > 0) {
                if (this.pinnedAll[this.startIndex].title === undefined)
                    this.nextPinned = this.pinnedAll[this.startIndex].message
                else if (this.pinnedAll[this.startIndex].title === '') {
                    this.nextPinned = this.pinnedAll[this.startIndex].message;
                } else {
                    this.nextPinned = this.pinnedAll[this.startIndex].title;
                }
            }

        },

        expandCard(id) {
            if (this.clicked.active) {
                if (!(this.clicked.id === id)) {
                    this.clicked = {id: '', active: false};
                    this.clicked = {id: id, active: true};
                } else {
                    this.clicked = {id: '', active: false};
                }

            } else {
                this.clicked = {id: id, active: true};

                // set seen if not already seen
                this.setBlackboardEntryAsSeen(id);
            }
        },
        seenByMe(item){
            if(item && item.seenBy.length){
                return item.seenBy.includes(this.me.account)
            }
            return false;
        },
        async setBlackboardEntryAsSeen(id) {
            const foundEntry = this.blackboard.find(entry => entry._id === id);
            if (!this.seenByMe(foundEntry)) {
                const updated = await this.markBlackboardMessageSeen(id);
              
                }
            
            // set as seen locally
            if (!this.blackboard.find(entry => this.seenByMe(entry) === false)) {
                this.$emit('clearBrettBadge');
            }
            this.forceRefreshComputed++;
            // this.calculateBadge();
        },

        createBlackboardDialog() {
            this.loadOnScrollEnabled = false;
            this.eintragPopup = true;
            this.brettEintrag.groupId = this.myGroups[0]._id;
            this.brettEintrag.uploadedIcon = false;
            //this.setTitle();
        },

        async shiftFilterList() {
            let localeFilterList = this.filterList;
            const filter = localeFilterList[0];
            localeFilterList = localeFilterList.slice(1);
            localeFilterList.push(filter);

            this.filterList = localeFilterList;
            await this.requestBlackboard();

        },

        calculateCheckmarkStyle(blackBoardItem) {
            // Don't show checkmark for when recipient is group alle
            if (!this.myGroups.filter(item => item._id === blackBoardItem.group).length) {
                return 'hide';
            }
            // Everybody has seen the blackboard message
            if (blackBoardItem.seenByTarget && blackBoardItem.seenByCount >= blackBoardItem.seenByTarget.length) {
                return 'all';
            }
            // Nobody has seen the blackboard message
            if (!blackBoardItem.seenByCount || blackBoardItem.seenByCount === 0) {
                return 'none';
            }
            return 'some';
        },
        /**
         * #Region drag and drop stuff
         */
        limitEvent(event) {
            event.stopPropagation();
            event.preventDefault();
        },
        onDragEnter(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                e.currentTarget.classList.add('dragFeedback');
                this.dropUiActive = true;
            }
        },
        onDragLeave(e) {
            this.limitEvent(e);
            e.currentTarget.classList.remove('dragFeedback');
            this.$nextTick(() => {
                this.dropUiActive = false;
            });
        },
        onDragOver(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                const path = e.path || (e.composedPath && e.composedPath());
                this.dropUiActive = !!path.find(el => el.id === 'brettWidgetContainer');
            }
        },
        async dropFile(e) {
            this.limitEvent(e);
            if (this.dragDropUploadAllowed) {
                const fileArray = Array.from(e.dataTransfer.files);
                if (await this.validateMimeType(fileArray)) {
                    if ((fileArray[fileArray.length - 1]['type'] === 'application/pdf')) {
                        await generateThumbnailData(fileArray[fileArray.length - 1])
                            .then(r => fileArray[fileArray.length - 1].thumbnail = r);
                    }
                    this.filesToUpload = this.filesToUpload.concat(fileArray);
                }
                this.dropUiActive = false;
            }
        },

        selectCards(item) {
            if (this.selectedCards.includes(item)) {
                this.selectedCards.splice(this.selectedCards.indexOf(item), 1)
            } else {
                this.selectedCards.push(item);
            }
        },
        async multiselectDelete() {
            let goodRes = 0;
            let cardsToDelete = this.selectedCards.length;
            await Promise.all(this.selectedCards.map(async (item) => {
                const id = item._id;
                let data = {
                    _id: id,
                    requestAccountId: this.me.account,
                };
                const res = await this.deleteBlackboardEntry(data);
                if (res === 204) {
                    this.spliceSingleEntry(id);
                    goodRes += 1;
                }
            }))
            this.multiselect = false;
            if (goodRes === cardsToDelete) {
                this.showSnackbar({
                    message: 'Einträge erfolgreich gelöscht'
                });
            } else {
                this.showSnackbar({message: 'Etwas ist schief gelaufen, bitte Seite neu Laden', color: 'error'});
            }
            this.deleteDialog = false;
        },
        async setBrettPopup(id) {
            const eintrag = this.blackboard.find(item => item._id === id);
            this.currentEntryId = id;
            this.brettEintrag.title = eintrag.title;
            this.brettEintrag.color = eintrag.color;
            this.brettEintrag.icon = eintrag.icon;
            this.brettEintrag.message = eintrag.message;
            // this.brettEintrag.groupIds = [eintrag.blackboardId];
            this.brettEintrag.uploads = eintrag.uploads;
            this.brettEintrag.uploadedIcon = eintrag.uploadedIcon ? eintrag.uploadedIcon : false;
            this.editToDoList = eintrag.toDoList ? eintrag.toDoList : null;
            this.maintainerSelected = eintrag.isMaintainerMessage ? true : false;
            this.brettEintrag.isMaintainerMessage = eintrag.isMaintainerMessage;
            this.brettEintrag.fixed = eintrag.fixed ? eintrag.fixed : [];
            // let group = '';
            // this.groups.forEach((el) => {
            //     if (el._id === eintrag.group) {
            //         group = el.name;
            //     }
            // });
            //this.brettEintrag.title = this.me.name.slice(0, 1) + '. ' + this.me.lastName;
            this.groupsBlackboard = this.groupsById[eintrag.group];
        },
        // async requestGroups() { //this is a hotfix wich works and i have no clue why so please dont break it
        //     this.showLoadingOverlay = true;
        //     this.groups = await this.getGroupsInfo();
        //     if (this.groups.length > -1) {
        //         this.groups.splice(0,1);
        //     }
        //     this.showLoadingOverlay = false;
        // },

        // async requestTeachers() {
        //     this.teachers = await this.getTeachers();
        // },

        customFilter(item, queryText, itemText) {
            const textOne = item.name.toLowerCase();
            const searchText = queryText.toLowerCase();

            return textOne.indexOf(searchText) > -1
        },

        iconToString(icon) {
            switch (icon) {
                case bioIconNormal:
                    return 'bioIcon';
                case englischIcon:
                    return 'englischIcon'
                case erdkundeIconNormal:
                    return 'erdkundeIcon'
                case politikIconNormal:
                    return 'politikIcon'
                case religionIconNormal:
                    return 'religionIcon'
                case sonneIconNormal:
                    return 'sonneIcon'
                case bioIcon:
                    return 'bioIcon'
                case erdkundeIcon:
                    return 'erdkundeIcon'
                case politikIcon:
                    return 'politikIcon'
                case religionIcon:
                    return 'religionIcon'
                case sonneIcon:
                    return 'sonneIcon'
            }
        },

        async editEntry() {
            try {
                this.showLoadingOverlay = true;
                const _id = this.currentEntryId;


                let newToDoList = null;
                let updatedList = null;


                if(this.toDoInputs){
                    newToDoList = await this.createNewToDoList(this.toDoInputs);
                }

                if(this.addedToDoInputs || this.listToBeDeleted || this.tasksToBeDeleted.length){
                    updatedList = await this.updateToDoListOnEntry()
                }
                let data = {
                        _id,
                        title: this.brettEintrag.title,
                        message: this.brettEintrag.message,
                        icon: this.brettEintrag.icon,
                        color: this.brettEintrag.color,
                        group: this.groupsBlackboard._id,
                        requestAccountId: this.me.account,
                        // if its an standardIcon icon has textString with 'Icon' in it, else
                        // icon contains iconId
                        uploadedIcon: !this.brettEintrag.icon?.includes('Icon') && !this.profileIcon,
                        profileIcon: this.profileIcon,
                        isMaintainerMessage: this.maintainerSelected ? this.brettEintrag.isMaintainerMessage : '',
                        fixed: this.brettEintrag.fixed,
                        isFixed: this.brettEintrag.fixed.includes(this.me.account)
                };
                if(updatedList){
                    data.toDoList = updatedList._id;
                }
                else if(newToDoList){
                    data.toDoList = newToDoList._id;
                }

                await this.updateBlackboardEntry(data);
                const input = this.filesToUpload;
                // reduce over all new files to post to edited blackboard entry
                await input.reduce(async (inputPromise, file) => {
                    const inputArray = await inputPromise;
                    const fileUploadRes = await this.uploadBlackboardAttachment({ blackboardId: _id, file });
                    inputArray.push(fileUploadRes);
                    return inputArray;
                }, Promise.resolve([]));

                await this.requestBlackboard();
                await this.resetPopup();
                // Disabled image previews for now due to too much traffic
                // await this.loadImagePreviews();
                this.checkShowOrHideExpandButtonsAfterLoad(_id);

                this.eintragPopup = false;
                this.showLoadingOverlay = false;
                this.loadOnScrollEnabled = true;
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async deleteEntry(item) {
            const _id = item._id;
            let data = {
                _id: _id,
                requestAccountId: this.me.account,
            };
            const res = await this.deleteBlackboardEntry(data);
            if (res === 204) {
                this.spliceSingleEntry(_id);
                this.showSnackbar({message: 'Eintrag erfolgreich gelöscht'});
                this.deleteDialog = false;
            } else {
                this.showSnackbar({message: 'Etwas ist schief gelaufen, bitte Seite neu Laden', color: 'error'});
            }
        },
        async showImageRightsWarning(){
           const names = await this.getImageRightNamesForGroups(this.myGroups)
           if(names.length){
            this.warningNames = names;
            this.imageRightsWarning = true;
           }
        },
        async uploadInputChange() {
            await this.showImageRightsWarning();
            const fileArray = Array.from(this.$refs.fileUpload.files);
            if (await this.validateMimeType(fileArray)) {
                if ((fileArray[0]['type'] === 'application/pdf')) {
                    await generateThumbnailData(fileArray[fileArray.length - 1])
                        .then(r => fileArray[fileArray.length - 1].thumbnail = r);
                }
                this.filesToUpload.push(fileArray[fileArray.length - 1]);
            }
        },

        async createEntry() {
            if (this.brettEintrag.title === '' || this.brettEintrag.title.trim() === '') {
                this.showSnackbar({
                    message: 'Der Titel des Posts darf nicht leer sein!',
                    color: 'error'
                });
                return;
            }

            if (this.groupsBlackboard.length === 0) {
                this.showSnackbar({
                    message: 'Es muss mindestens ein Empfänger ausgewählt werden!',
                    color: 'error'
                });
                return;
            }

            if (this.groupsBlackboard.length === this.myGroupsWithoutAlle.length) {
                const alleGroup = this.myGroups.find((group) => group.name === 'Alle');
                this.groupsBlackboard = alleGroup ? [alleGroup] : this.groupsBlackboard;
            }
            try {
                this.showLoadingOverlay = true;
                const input = this.filesToUpload;
                let toDoList = null;
                if(this.toDoInputs){
                    toDoList = await this.createNewToDoList(this.toDoInputs);
                }
                let maintainerString = ''
                if(this.maintainerSelected){
                        // creating a random unique string, so in backend the message can be filtered for maintainer
                        // to just see one message if it has multiple groups as receiver
                        maintainerString =  Math.random().toString(36).substring(2, 9) + Date.now();
                    }
                await this.groupsBlackboard.reduce(async (previousPromise, group) => {
                    const previous = await previousPromise;
                    let data = {};

                    if(toDoList){
                        data = {
                            title: this.brettEintrag.title,
                            message: this.brettEintrag.message,
                            icon: this.profileIcon ? this.teacherOrMaintainerId : this.brettEintrag.icon,
                            color: this.brettEintrag.color ? this.brettEintrag.color : '#FF6633' ,
                            group: group._id,
                            createdBy: this.me.account,
                            uploadedIcon: !this.brettEintrag.icon?.includes('Icon') && !this.profileIcon,
                            toDoList: toDoList._id,
                            profileIcon: this.profileIcon,
                            isMaintainerMessage: this.maintainerSelected ? maintainerString : '',
                        }
                    }else {
                        data = {
                            title: this.brettEintrag.title,
                            message: this.brettEintrag.message,
                            icon: this.profileIcon ? this.teacherOrMaintainerId : this.brettEintrag.icon,
                             color: this.brettEintrag.color ? this.brettEintrag.color : '#FF6633' ,
                            group: group._id,
                            createdBy: this.me.account,
                            uploadedIcon: !this.brettEintrag.icon?.includes('Icon') && !this.profileIcon,
                            profileIcon: this.profileIcon,
                            isMaintainerMessage: this.maintainerSelected ? maintainerString : '',
                        }
                    }
                    const res = await this.createBlackboardEntry(data);
                    // reduce over inputs to upload all files for the correct blackboards
                    await input.reduce(async (inputPromise, file) => {
                        const inputArray = await inputPromise;
                        const fileUploadRes = await this.uploadBlackboardAttachment({ blackboardId: res._id, file });
                        inputArray.push(fileUploadRes);
                        return inputArray;
                    }, Promise.resolve([]));
                    previous.push(res);
                    return previous;
                }, Promise.resolve([]));

                // push Buffer of new Blackboard Entries to state
                this.emptyBufferIntoState();

                // Even tho the upper code gets awaited completely the requestBlackboard below often returns entry's
                // that doesn't already have the uploads included, because of that we just wait for a few ms so
                // that the ImagePreviews will get loaded correctly
                // await this.requestBlackboard();
                this.checkShowOrHideExpandButtonsAfterLoad();
                await this.resetPopup();
                // await this.loadImagePreviews();
                // this.checkShowOrHideExpandButtonsAfterLoad();

                this.eintragPopup = false;
                this.showLoadingOverlay = false;
                this.loadOnScrollEnabled = true;
                this.showSnackbar({
                    message: 'Eintrag erfolgreich erstellt'
                });
            } catch (e) {
                console.error(e);
                this.showLoadingOverlay = false;
            }
        },
        async removeUpload(upload) {
            this.showLoadingOverlay = true;
            const params = {
                blackboardId: this.currentEntryId,
                fileId: upload._id,
            }
            await this.deleteBlackboardUpload(params);
            this.brettEintrag.uploads = this.brettEintrag.uploads.filter((el) => el !== upload);
            await this.requestBlackboard();
            this.showLoadingOverlay = false;

        },
        resetPopup() {
            this.currentEntryId = '';
            this.brettEintrag.title = '';
            this.brettEintrag.message = '';
            this.brettEintrag.icon = this.iconToString(sonneIcon);
            this.brettEintrag.color = '#fcd20a';
            this.brettEintrag.groupIds = [];
            this.brettEintrag.blackboardId = '';
            this.brettEintrag.uploads = [];
            this.brettEintrag.uploadedIcon = null;
            this.filesToUpload = [];
            this.allowEdit = false;
            this.bearbeiten = false;

            this.toDoInputs = null;
            this.createToDoList = false;
            this.toDoTitle = null;
            this.editToDoList = null;
            this.addedToDoInputs = null;
            this.editedToDoTitle = null;
            this.tasksToBeDeleted = [];
            this.listToBeDeleted = false;
            this.maintainerSelected = false;
            this.groupsBlackboard = [];
        },

        /**
         * Only use this method to update a single file preview,
         * to avoid to many redraws
         * @param blackboardEntry message the file belongs to
         * @param file current file of which we want the thumbnail
         * @returns {Promise<void>}
         */
        async loadImagePreview(blackboardEntry, upload, fileAccessToken) {
            if (upload.file) {
                let pupil = null;
                if (this.role === 'parent' && this.currentChild) {
                    pupil = this.currentChild._id;
                }
                const res = await this.getBlackboardUploadThumbnail({
                    blackboardId: blackboardEntry._id,
                    fileId: upload.file._id || upload.file,
                    fileAccessToken,
                });
                upload.hasThumbnail = res !== 'text/plain; charset=utf-8';
                upload.thumbnail = window.URL.createObjectURL(res);
                // this.addThumbnail(upload._id, { thumbnail: upload.thumbnail, hasThumbnail: upload.hasThumbnail });
            }
            // await this.updateSingleEntry(blackboardEntry);
            // this.reloadKey = !this.reloadKey;
        },

        async loadImagePreviews() {
            this.urls = [];
            this.types = [];

            const bbWithPreviews = await Promise.all(this.blackboard.map(async (blackboard) => {
               await Promise.all(blackboard.uploads.map(async (upload) => {
                   let url, hasThumbnail;
                   let res;
                   if (upload.file) {
                       let pupil = null;
                       if (this.role === 'parent' && this.currentChild) {
                           pupil = this.currentChild._id;
                       }
                       res = await this.getBlackboardUploadThumbnail({
                           fileId: upload.file._id || upload.file,
                           blackboardId: blackboard._id,
                           pupilId: pupil
                       });
                       url = window.URL.createObjectURL(res);
                       hasThumbnail = res.type !== 'text/plain; charset=utf-8';
                       upload.thumbnail = url;
                       upload.hasThumbnail = hasThumbnail;
                   }
                   return upload;
               }));
            //    this.updateSingleEntry(blackboard);
               return blackboard;
            }));
            this.setAllBlackboardEntries(bbWithPreviews)
            this.forceRefreshComputed ++;
        },

        getSrc() {
            return this.thisSubject ? this[this.thisSubject.icon] : (this.thisAppointment ? this[this.thisAppointment.schoolSubject.icon] : null);
        },

        getBackgroundColor() {
            let returnString = "background-color: ";
            return (returnString + this.thisAppointment.schoolSubject.color);
        },

        async requestBlackboard() {
            this.resetBlackboardDisplay();
            this.countingLength = this.hiddenBlackboard.length < 10 ? this.hiddenBlackboard.length : 10;

            this.checkShowOrHideExpandButtonsAfterLoad();
            this.loadOnScrollEnabled = true;
        },

        fillDisplayedMessages() {
            if(this.hiddenBlackboard.length < 10) {
                this.countingLength = this.hiddenBlackboard.length;
            }
            for(let i = 0; i < this.countingLength; i++) {
                this.blackboard.push(this.hiddenBlackboard[i]);
            }
        },

        resetBlackboardDisplay() {
            this.loadOnScrollEnabled = false;
            // this.blackboard = [];
            // this.hiddenBlackboard = [];
            this.countingLength = 10;
            this.remainingBlackboardItems = '';
        },

        pinEntry(entry) {
            //liste aller gepinnten
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if (!entries) {
                entries = [];
            }
            if (!entries.includes(entry)) {
                entries.push(entry);
                this.showSnackbar({
                    message: 'Eintrag wurde für alle Nutzer angepinnt', color: 'info'
                });
            } else {
                entries = entries.filter((el) => el !== entry);
                this.showSnackbar({
                    message: 'Eintrag wurde für alle Nutzer losgelöst', color: 'info'
                });
            }

            localStorage.setItem('blackboard', JSON.stringify(entries));
            //this commits the entry with the updated pin into the state so the hiddenblackboard
            //and therefore the computed Blackboard property is updated correctly
            const updated = this.blackboard.filter(item=> item._id === entry)[0];
            this.updateSingleEntry({...updated, pin: !updated.pin})

            this.initializeIndex();
            this.requestBlackboard();
        },
        expandFixOptions(){
            this.fixClicked = !this.fixClicked;
        },
        async fixForReceivers(entry,type){
            let memberIds = [];
            const isGroupAlle = this.groupsById[entry.group].name === 'Alle' && this.groupsById[entry.group].participants.length === 0 && this.groupsById[entry.group].leaders.length === 1;
            if(type === 'Alle'){
            //check if group is 'Alle' so we theen need to push every account into the list
                if(isGroupAlle){
                    memberIds = this.accounts.map(acc => acc._id);
                }
            //otherwise put every leader and participant into the list
            else {
                await Promise.all(this.groupsById[entry.group].leaders.map((leader)=> {
                        memberIds.push(this.teachersById[leader].account);
                    }));
                await Promise.all(this.groupsById[entry.group].participants.map((participant)=> {
                        memberIds.push(participant.account);
                        if(participant.parent){
                            memberIds.push(this.parentsById[participant.parent].account);
                        }
                    }));
            }
            }else if(type === 'Lehrer'){
                if(isGroupAlle){
                    const teacherAccounts = this.accounts.filter(acc => acc.role === "teacher")
                    memberIds = teacherAccounts.map(acc => acc._id);
                }else{
                      await Promise.all(this.groupsById[entry.group].leaders.map((leader)=> {
                        memberIds.push(this.teachersById[leader].account);
                    }));
                }
            }else if(type === 'Schueler'){
                 if(isGroupAlle){
                    const pupilAccounts = this.accounts.filter(acc => acc.role === "pupil")
                    memberIds = pupilAccounts.map(acc => acc._id);
                    }
                    else {
                            await Promise.all(this.groupsById[entry.group].participants.map((participant)=> {
                            memberIds.push(participant.account);
                        }));
                    }
            }else if(type === 'Eltern'){
                 if(isGroupAlle){
                    const parentAccounts = this.accounts.filter(acc => acc.role === "parent")
                    memberIds = parentAccounts.map(acc => acc._id);
                }else{
                    await Promise.all(this.groupsById[entry.group].participants.map((participant)=> {
                        if(participant.parent){
                            memberIds.push(this.parentsById[participant.parent].account);
                        }
                    }));
                }
            }
            // add these fixed to already existing and remove duplicates
            memberIds = entry.fixed?.length > 0 ? memberIds.concat(entry.fixed) : memberIds;
            const finalFixedIds = [...new Set(memberIds)]
            const fixForMe = finalFixedIds.includes(this.me.account)
            let todoId = entry.toDoList
            if(typeof entry.toDoList === "object"){
                todoId = entry.toDoList._id;
            }
            await this.updateBlackboardEntry({ ...entry, toDoList: todoId, fixed: finalFixedIds, isFixed: fixForMe, updateFixed: true, });

            this.optionsOpen = false;
            this.fixClicked=false;

            const snackbarMessage = type === 'Alle' ? 'Eintrag wurde für alle Nutzer fixiert.' : `Eintrag wurde für ${type} der Gruppe fixiert.`;
            this.showSnackbar({
                    message: snackbarMessage, color: 'info'
                });
        },
        async toggleFix(entry){
            const fixed = await this.toggleFixEntry({entryId: entry._id, requestId: this.me.account});
            this.showSnackbar({
                    message: entry.isFixed ? 'Eintrag wurde erfolgreich losgelöst.' : ' Eintrag wurde erfolgreich fixiert.', color: 'info'
                });

            this.optionsOpen = false;
            this.fixClicked=false;
            this.updateBlackboard = !this.updateBlackboard;

        },
        updatedPin() {
            let entries = JSON.parse(localStorage.getItem('blackboard'));
            if (!entries) {
                entries = [];
            }

            this.hiddenBlackboard.forEach((el) => {
                if (entries.includes(el._id)) {
                    el['pin'] = true;
                } else {
                    el['pin'] = false;
                }
            });

            this.updateBlackboard = !this.updateBlackboard;
            this.initializeNextPinned();
        },

        chooseIcon(iconName) {
            for (let i of this.icons) {
                if (i.text === iconName || i === iconName) {
                    return i.icon;
                }
            }
        },

        getTitle(creatorId, groupId, aria) {
            let creator = 'Verwaltung';
            if (this.teachersByAccountId[creatorId]) {
                creator = `${this.teachersByAccountId[creatorId].name.slice(0,1)}. `
                    + `${this.teachersByAccountId[creatorId].lastName}`;
            }

            let group = this.groupsById[groupId] ? this.groupsById[groupId].name : '';
            if(this.accountRole === 'maintainer' && creator !== 'Verwaltung'){
                group = 'Verwaltung'
            }
            if (!aria) {
                return `${creator}${this.role === 'teacher' || this.role === 'maintainer' ? ' → ' + group : ''}`;
            }
            return 'Nachricht von ' + creator + (group ? ' an ' + group : '');
        },

        toggleTTSBtn(itemId) {
            if (this.showTTSBtn === itemId) {
                this.showTTSBtn = '';
            } else {
                this.showTTSBtn = itemId;
            }
        },

        async readText(item, event) {
            // Put together text to be read out
            const author = sanitizeHtml(this.getTitle(item.createdBy, item.group, true)) + ',';
            const topic = item.title ? 'Betreff' + item.title + ',' : '';
            const content = this.urlify(item.message);
            const date = ', vom' + this.dayname[new Date(item.createdOn).getDay()] + ', ' +
                new Date(item.createdOn).toLocaleDateString('de-DE', {
                    day: '2-digit',
                    month: '2-digit'
                }) + ' ' +
                new Date(item.createdOn).toLocaleTimeString('de-DE', {
                    hour: '2-digit',
                    minute: '2-digit'
                });
            const text = author + topic + content + date;

            let txt;
            if (text) {
                let tmp = document.createElement("DIV");
                tmp.innerHTML = text;
                txt = tmp.textContent || tmp.innerText || "";
            } else {
                txt = 'Dieser Eintrag hat keinen Inhalt.';
            }

            if (this.targetLang !== 'de') {
                txt = await this.translateToTargetLang({
                    targetLang: this.targetLang,
                    textToTranslate: txt
                });

                this.setTranslatedText(txt);
                this.showTranslation(true);

                if (this.showTextTimeout) {
                    clearTimeout(this.showTextTimeout);
                    this.showTextTimeout = null;
                }

                this.showTextTimeout = setTimeout(() => {
                    this.showTranslation(false);
                    this.showTextTimeout = null;
                }, 15000)
            }

            await textToSpeech(txt, this.targetLang, () => {
                event.target.src = lautsprecherAusIcon;
                event.target.alt = 'Stop';
                this.speechActive = true;
            }, () => {
                event.target.src = lautsprecherIcon;
                event.target.alt = 'Vorlesen';
                this.speechActive = false;
            });
            this.expandCard(item._id);
        },

        async readWidgetInfoText() {
            if (!this.me.accessibility.screenreader) {
                if ('speechSynthesis' in window) {
                    // Speech Synthesis supported 🎉
                } else {
                    // Speech Synthesis Not Supported 😣
                    alert("Sorry, your browser doesn't support text to speech!");
                    return
                }

                if (!this.eintragPopup) {
                    let text;
                    if (this.accountRole === 'pupil') {
                        text = 'Das Schwarze Brett zeigt dir die aktuellen Schulnachrichten an';
                    } else { //parent and teacher
                        text = 'Das Schwarze Brett zeigt Ihnen die aktuellen Schulnachrichten an.';
                    }

                    if (this.targetLang !== 'de') {
                        text = await this.translateToTargetLang({
                            targetLang: this.targetLang, textToTranslate: text
                        });
                    }

                    if (window.speechSynthesis.speaking) {
                        window.speechSynthesis.cancel();
                        this.showTranslation(false);
                    } else {
                        this.setTranslatedText(text);
                        this.showTranslation(true);

                        if (this.showTextTimeout) {
                            clearTimeout(this.showTextTimeout);
                            this.showTextTimeout = null;
                        }

                        this.showTextTimeout = setTimeout(() => {
                            this.showTranslation(false);
                            this.showTextTimeout = null;
                        }, 15000)

                        let msg = new SpeechSynthesisUtterance();
                        msg.text = text;
                        if (this.isLangPackageAvailable()) {
                            msg.lang = this.targetLang;
                            window.speechSynthesis.speak(msg);
                        }
                    }
                }
            }
        },

        isLangPackageAvailable() {
            for (let i = 0; i < window.speechSynthesis.getVoices().length; i++) {
                if (window.speechSynthesis.getVoices()[i].lang.includes(this.targetLang)) {
                    return true;
                }
            }

            return false;
        },

        expandText(event) {
            //from https://stackoverflow.com/questions/54459816/vue-text-highlight-event
            const isTextHighlighting = window.getSelection().toString().trim() !== '';

            //only expand when no text is being selected
            if (!isTextHighlighting) {
                event.target.classList.toggle('fade');
                event.target.classList.toggle('initHeight');
            }
        },
        async showFullImage(blackboardId, file) {
            if (this.checkForDeletion(file.file)) return;
            const fileId = file.file._id ? file.file._id : file.file;
            const res = await this.getBlackboardUploadAsUrl({ blackboardId, fileId });
            this.imgFullscreen = res.link;
        },
        async playVideo(blackboardId, file) {
            if (this.checkForDeletion(file.file)) return;
            if (file.subtitle) {
                const subtitle = await backend.getSubtitle(file.subtitle);
                this.subtitleURL = window.URL.createObjectURL(await subtitle.blob());
            }
            const res = await this.getBlackboardUploadAsUrl({ blackboardId, fileId: file.file });
            this.videoUrl = res.link;
        },
        setTitle() {
            const me = this.me.name.slice(0, 1) + '. ' + this.me.lastName;
            if (!this.bearbeiten) {
                this.newEntryTitle = me;
            } else {
                this.brettEintrag.title = me;
            }
        },

        // async requestMe() {
        //     if (this.accountRole === 'teacher') {
        //         this.me = await this.getMeTeacher();
        //         this.me.accessibility = {
        //             screenreader: false,
        //         };
        //     } else if (this.accountRole === 'pupil') {
        //         this.me = await this.getMePupil();
        //     } else if (this.accountRole === 'maintainer') {
        //         const maintainers = await this.getMaintainers();
        //         this.me = maintainers[0];
        //     }
        // },
        async requestMaintainer() {
            const maintainers = await this.getMaintainers();
            let maintainerObj = maintainers[0];
            maintainerObj = {
                _id: maintainerObj._id,
                account: maintainerObj.account._id,
                email: maintainerObj.email,
            }
            return maintainerObj;
        },
        myEntry(creatorId) {
            return creatorId === this.me.account;
        },


        // scroll blackboard entry into view and set is as seen
        blackboardBadgeClicked(neededEvent) {
            // find referenced blackboard element
            const overlayId = neededEvent.message;
            const element = this.$refs[`blackboardCard${neededEvent.message}`][0];

            // scroll element into view
            element.$el.scrollIntoView({
                behaviour: 'smooth',
                block: 'start',
            });
            // $set blackboard entry as seen
            // this.$set(this.blackboard.find(entry => entry._id === neededEvent.message), 'seenByMe', true);
            
            this.$nextTick(() => {
                if (element) {
                    this.highlightedOverlayId = null;
                    element.$el.classList.remove('badgeEventVisualize');

                    element.$el.classList.add('badgeEventVisualize');
                    this.highlightedOverlayId = overlayId;
                    setTimeout(() => {
                        element.$el.classList.remove('badgeEventVisualize');
                            this.highlightedOverlayId = null;
                    }, 1050);
                }
            });
        },

        jumpToNextPinned() {
            const element = this.$refs[`container${this.pinnedAll[this.pinIndexAll]._id}`][0];
            const message = element.$el;

            message.scrollIntoView({
                behaviour: 'smooth',
                block: 'end',
            });


            this.$nextTick(() => {
                if (message) {
                    message.classList.remove('pinnedVisualize');
                    message.classList.add('pinnedVisualize');

                    setTimeout(() => {
                        message.classList.remove('pinnedVisualize');
                    }, 1050);
                }
            });

            //update PinIndex and nextPinned
            this.pinIndexAll = this.pinIndexAll > 0 ? this.pinIndexAll - 1 : this.pinnedAll.length - 1;
            if (this.pinnedAll[this.pinIndexAll].title === undefined)
                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
            else if (this.pinnedAll[this.pinIndexAll].title === '') {
                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
            } else {
                this.nextPinned = this.pinnedAll[this.pinIndexAll].title;
            }
        },
        jumpToPreviousPinned() {
            const prevIndex = this.pinIndexAll < this.pinnedAll.length - 1 ? this.pinIndexAll + 1 : 0;
            const element = this.$refs[`container${this.pinnedAll[prevIndex]._id}`][0];
            const message = element.$el;
            message.scrollIntoView();
            this.$nextTick(() => {
                if (message) {
                    message.classList.remove('pinnedVisualize');
                    message.classList.add('pinnedVisualize');

                    setTimeout(() => {
                        message.classList.remove('pinnedVisualize');
                    }, 1050);
                }
            });
            this.pinIndexAll = this.pinIndexAll < this.pinnedAll.length - 1 ? this.pinIndexAll + 1 : 0;
            if (this.pinnedAll[this.pinIndexAll].title === undefined)
                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
            else if (this.pinnedAll[this.pinIndexAll].title === '') {
                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
            } else {
                this.nextPinned = this.pinnedAll[this.pinIndexAll].title;
            }
        },

        jumpToStart() {
            const id = this.blackboard[0]._id;
            const element = this.$refs.startcontainer;
            if(element){
                element.scrollIntoView();
            }

            if(this.pinnedAll.length){
                this.pinIndexAll = this.pinnedAll.length - 1;
                            if (this.pinnedAll[this.pinIndexAll].title === undefined)
                                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
                            else if (this.pinnedAll[this.pinIndexAll].title === '') {
                                this.nextPinned = this.pinnedAll[this.pinIndexAll].message;
                            } else {
                                this.nextPinned = this.pinnedAll[this.pinIndexAll].title;
                            }
            }
        },

        async loadMore() {
            if(this.loadOnScrollEnabled/* && (this.remainingBlackboardItems >= 1 || this.remainingBlackboardItems === '')*/){
                // this.remainingBlackboardItems = this.hiddenBlackboard.length - this.countingLength;
                //
                // // handle thumbnail pull of the given uploads
                // const pullThumbnailsForUploads = async (blackboardEntry) => {
                //     if (blackboardEntry) {
                //         await blackboardEntry.uploads.reduce(async (previousPromise, file, uploadIndex) => {
                //             const previous = await previousPromise;
                //             let res;
                //             if (file.file) {
                //                 let pupil = null;
                //                 if (this.role === 'parent' && this.currentChild) {
                //                     pupil = this.currentChild._id;
                //                 }
                //                 res = await this.getBlackboardUploadThumbnail({
                //                     blackboardId: blackboardEntry._id,
                //                     fileId: file.file._id || file.file,
                //                     pupilId: pupil
                //                 });
                //                 this.urls[file._id] = res.link;
                //                 this.types[file._id] = res.type;
                //             }
                //             previous.push(res);
                //             return previous;
                //         }, Promise.resolve([]));
                //     }
                // };
                //
                // // find out how many files should be pulled
                // const upperBound = (this.remainingBlackboardItems >= this.countingLength
                //     && this.remainingBlackboardItems > 10) ? 10 : this.remainingBlackboardItems;
                // for(let i = 0; i < upperBound; i++) {
                //     const index = i + this.countingLength;
                //     await pullThumbnailsForUploads(this.hiddenBlackboard[index]);
                //     this.blackboard.push(this.hiddenBlackboard[index]);
                // }
                // this.remainingBlackboardItems = this.remainingBlackboardItems > 10
                //     ? this.remainingBlackboardItems - 10 : 0;

                // Only update this
                this.countingLength += 10;
            }
        },

        handleHeaderClick(item){
            if(item.showExpandButton) {
                this.expandCard(item._id);

            }else {
                this.setBlackboardEntryAsSeen(item._id);
            }
            this.activateTranslationBtn(item._id);
        },

        activateTranslationBtn(id){
            if (this.timeoutId) {
                clearTimeout(this.timeoutId);
                this.timeoutId = '';
                this.showSpeaker = '';
            }
            const element = this.$refs[`speaker${id}`][0];
            if (element) {
                this.showSpeaker = id;

                this.timeoutId = setTimeout(() => {
                    this.showSpeaker = '';
                }, 3000);
            }
        },

        getAllSeenByCountNames(item) {
            let names = '';

            if (item.seenByNames) {
                const seenByTarget = item.seenByTarget;
                if(seenByTarget) {
                    for(let i = 0; i < seenByTarget.length; i++) {
                        const name = `${seenByTarget[i].name} ${seenByTarget[i].lastName}`
                        const parentName = `Eltern von ${(seenByTarget[i].pupil || {}).name} ${(seenByTarget[i].pupil || {}).lastName}`
                        if (seenByTarget[i].role === 'parent' && item.seenByNames.includes(parentName)){
                            names = names + ' ' + parentName;
                        } else if (item.seenByNames.includes(name)){
                            names = names + ' ' + name;
                        }
                    }
                }

            }

            return names ? ':' + names : '';
        },

        handleThumbnailClicked(el, item) {
            if (this.checkForDeletion(el.file)) return;
            if(el && el.title){
            const fileExtension = el.title.split('.').pop().toLowerCase();
                if (['mp4', 'wmv', 'flv', 'webm', 'mpg', 'm4v', 'mov', 'mkv'].includes(fileExtension)) {
                                this.playVideo(item._id, el);
                            } else if (['doc', 'docx', 'ppt', 'pptx', 'xls', 'xlsx', 'txt', 'png', 'jpg', 'jpeg', 'pdf'].includes(fileExtension)) {
                                this.loadFullscreenPreview(el, item._id);
            }
            }
        },
        setNewInputs(inputs){
            this.toDoInputs = inputs;
        },
        setToDoTitle(title){
            this.toDoTitle = title;
        },
        setEditedTitle(title){
            this.editedToDoTitle = title;
        },
        setEditedInput(inputs){
            this.addedToDoInputs = inputs;
        },
        setTasksToBeDeleted(tasks){
            this.tasksToBeDeleted = this.tasksToBeDeleted.concat(tasks);
        },
        async populateToDoList(toDoListId){
             const response = await backend.getToDoList(toDoListId);
            let toDoList = await response.json();
            const toDoTasks = await Promise.all(toDoList.tasks.map(async(taskId)=>{
                let taskResponse = await backend.getToDoTask(taskId);
                return taskResponse.json();
            }));
            toDoList.tasks = toDoTasks;
            return toDoList;
        },
        async createNewToDoList(toDoInputs){
            const tasks = await Promise.all(toDoInputs.map(async (task)=>{
                const res =  await backend.postToDoTask({ text: task });
                const createdTask = await res.json();
                return createdTask._id
            }))
            const taskTitle = this.toDoTitle ? this.toDoTitle : 'Kontrollliste'
            const toDoData = {
                author: this.me.account,
                tasks: tasks,
                title: taskTitle,
            }
            const created = await this.postToDoList(toDoData);
            //const created = await createResponse.json();
               return created;
        },
        async updateToDoListOnEntry(){
            let updatedList = null;

            if(this.tasksToBeDeleted.length){
                    await Promise.all(this.tasksToBeDeleted.map(async(taskId) => {
                        const res = await backend.deleteToDoTask(taskId);
                    }
                    ));
            }
            if(this.addedToDoInputs){
                let newTasks = await Promise.all(this.addedToDoInputs.map(async (task) => {
                    if(!task._id){
                        const res =  await backend.postToDoTask({ text: task.text });
                        const createdTask = await res.json();
                        return createdTask._id
                    }
                }))
                // if element was added to list of inputs and other element was removed
                // there will be null/undefined values in list, that need to be filtered
                newTasks = newTasks.filter((task)=> task !== (null || undefined));
                const taskTitle = this.editedToDoTitle !== null ? this.editedToDoTitle : this.editToDoList.title;
                const res = await backend.getToDoList(this.editToDoList._id);
                const currentList = await res.json();
                const currentTasks = currentList.tasks;
                const tasksToBeUpdated  = [...new Set([...currentTasks,...newTasks])]
                const updatedListRes = await backend.updateToDoList(this.editToDoList._id, {
                            title: taskTitle,
                            tasks: tasksToBeUpdated,
                        });
                updatedList = await updatedListRes.json();
            }
            if(this.listToBeDeleted){
                const res = await backend.deleteToDoList(this.editToDoList._id);
            }
            return updatedList;
        },
        getPreviewSize(blackboardFiles) {
            const size = blackboardFiles.length;
            if (size === 1) {
                return 100;
            } else if (size === 2) {
                return 90 / 2;
            } else if (size >= 3) {
                return 90 / 3;
            }
        },
        displayInfoSnackbar(){
            let usernames = '';
            if(this.usageAgreementsInSelectedGroups.length > 0){
             const accountsWithoutSigned = this.usageAgreementsInSelectedGroups.filter((user) => user.signedUsageAgreement && !user.signedUsageAgreement.isSigned);
                usernames = accountsWithoutSigned.map(account =>{
                    return account.displayName;
                }).join(", ");
            }
                this.showSnackbar({message: `Die Mitteilung wird an Lehrer, Eltern und Schüler der Gruppe versendet. Nutzen sie alternativ den Chat oder Elternbriefe.
                ${usernames !== '' ? ` Folgende Nutzer in ausgewählten Gruppen haben die Nutzungserklärung noch nicht unterschrieben: ${usernames}`:''}`, color: 'warning'
                });
        },
        async clearBlackboardBadges() {
            try {
                this.blackboardBadgeEventList.map(async (event) => {
                    await this.setBlackboardEntryAsSeen(event.message);
                })
                this.showSnackbar({
                    message: 'Benachrichtigungen wurden erfolgreich als gesehen markiert!',
                    color: 'success'
                });
            } catch(error) {
                this.showSnackbar({
                    message: 'Ein unerwarteter Fehler ist aufgetreten.',
                    color: 'error'
                });
                console.error(error);
            }
        },
        showLinkExplanation(){
            this.showSnackbar({message: 'Markieren Sie einen Text, klicken Sie auf das Link-Symbol und fügen Sie Ihren Link hinzu.'} );
        },
        async clickHideItem(itemId){
            this.hideDialog = true;
            this.hideItemId = itemId;
        },
        async acceptHideItem(itemId){
           await this.hideBlackboardMessage(itemId);
        },
        closeAreYouSure(){
            this.deleteDialog = false; 
            this.deleteItem = null;
            this.hideItemId = false;
            this.hideDialog = false;
        },
        acceptAreYouSure(){
            if(this.deleteDialog){
                if(this.multiselect){
                    this.multiselectDelete(this.selectedCards);
                }else{
                    this.deleteEntry(this.deleteItem);
                }
            } else if(this.hideDialog){
                    this.acceptHideItem(this.hideItemId);
                    this.hideDialog = false;
            } 
        }
    }
}
</script>

<style>
.hiddenContainerClass * {
    overflow: visible;
    white-space: nowrap;
    font-size: medium
}

.ql-video {
    max-width: 100%;
}


.collapsed * {
    white-space: nowrap;
    overflow: hidden;
    line-height: 2.0rem;
    text-overflow: ellipsis;
    max-height: 42px;
}

.notCollapsed * {
    overflow: visible !important;
    background-color: teal !important;
}

.ellipsis * {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* width */
::-webkit-scrollbar {
    width: 11px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #eee;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #aaa;
    border-radius: 6px;
    width: 7px;
}

::-webkit-scrollbar-thumb:hover {
    background: #999;
    border-radius: 6px;
    width: 11px;
}
</style>

<style lang="scss" scoped>
.v-menu__content{
    border-radius: 8px;
}

.v-dialog {
    box-shadow: none;
    overflow-x: hidden;
}

.thumbnailDisplayNormal {
    width: 100%;
    min-width: 150px;
    max-width: 10vmax;
    height: 100%;
    min-height: 150px;
    max-height: 10vmax;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
}

.displayWholeThumbnail {
    width: -webkit-fill-available;
    height: -webkit-fill-available;
    aspect-ratio: 1/1;
    cursor: pointer;
    display: flex;
    justify-content: center;
    border-radius: 0px 0px 8px 8px;
}

.iOS_ResponsiveHack {
    width: 1px;
    min-width: 100%;
    *width: 100%;
    padding-left: 10%;
    padding-right: 10%;
}

.uploadPreview {
    width: 45%;
    border-radius: 8px;
    margin-left: 10px;
    margin-right: 10px;
    background-color: rgba(80, 80, 80, 0.2);
    margin-bottom: 3%;
}

.uploadPreviewHeader {
    display: flex;
    justify-content: space-between;
    padding: 3%;
    background-color: rgb(var(--v-theme-blackboardGrey));
    font-size: 14px;
    border-radius: 8px 8px 0 0;
    height: 40px;
}

.fileSystemTile {
    display: flex;
    border-radius: 16px;
    color: rgb(var(--v-theme-schwarz));
    white-space: nowrap;
}

.fileSystemTile[dropzone="true"] {
    filter: saturate(0);
}

.object {
    // I don't know how this works but ios devs recommend this to create responsive fit for embed pdfs
    width: 1px;
    min-width: 100%;
    *width: 100%;
}

.scroll-area {
    margin: auto;
    height: auto;
    max-height: calc(90dvh - 80px);
    min-width: 28vw;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden
}
.scroll-area-messages{
    height: auto;
    min-width: 28vw;
    border-radius: 8px 8px 8px 8px;
    overflow-x: hidden
}
.highlight {
    background-color: #6995CD;
}

.brettWidgetHeader {
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(var(--v-theme-blackboardGrey));
    border-radius: 16px 16px 0 0;
    padding: 0 16px;
    color: white;
    z-index: 2;
}

.brettWidgetBody {
    background-color: rgb(var(--v-theme-widgetBg));
    flex: 1;
    display: flex;
    flex-direction: column;
    z-index: 1;
}

.whiteBg {
    background-color: #ffffff;
}

.icon {
    width: 15px;
    height: 15px;
}

.dialog {
    z-index: 100;
    width: 30vw;
    overflow: hidden;
    left: 34.5%;
}

.fade {
    width: 100%;
    overflow: hidden;
    position: relative;
    max-height: 44px;
}

.fade:after {
    content: "";
    text-align: right;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 25%;
    height: 50%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1) 50%);
}

.initHeight {
    max-height: initial !important;
}

.blackboardCardHeader {
    color: rgb(0, 0, 0);
    background-color: #e6e6e6;
    max-width: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: auto;
    align-content: center;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 8px 8px 0 0;
}

.boardMessageHeader {
    background-color: #e6e6e6;
    color: black;
    border-radius: 8px 8px 0 0;
}

.blackboardCard {
    border-radius: 8px;
}

.headerBtn {
    height: 30px !important;
    width: 30px !important;
    margin-left: 1%;
    color: #F8F8F880 !important;
}

.boardIcon {
    height: 35px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.boardIconDialog {
    height: 25px;
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

h1 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

h2 {
    display: inherit;
    font-size: inherit;
    margin: inherit;
    font-weight: inherit;
}

.blackboardItemFooter {
    float: right;
    font-size: small;
    color: #9e9e9e;
    margin: 2px;
}

.deleteDialog {
    justify-content: center;
    max-width: 300px;
}

.checkMarks {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
}

//make text not selectable
.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

#blackboardCardContainer {
    margin: auto auto;
    border-radius: 15px;
    background: white;
    height: 100%;
}

.boardIconEntries {
    height: 30px;
    color: white !important;
    border-radius: 4px;
    padding: 5px;
}

.timeAndDate {
    white-space: nowrap;
    font-size: small;
    line-height: 1rem !important;
    text-align: right;
    opacity: 0.47;
}

.popUpRow {
    width: 95%;
    margin: auto;
}

.iconSelect {
    width: 42px;
    padding: 2px;
    border: solid 2px #EAEAEA;
    border-radius: 10px;
    margin-right: 2px;
}

.iconSelect[selected] {
    width: 42px;
    padding: 2px;
    border: solid 2px #737373;
    border-radius: 10px;
}

.dropzone {
    background-color: grey;
}

.dragFeedback {
    background-color: #b9b9b9;
    box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14),
    0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.dropUiContainer {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.dropUi {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.4);
    display: flex;
    align-content: center;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    p {
        width: 75%;
        text-wrap: normal;
        text-align: center;
    }
}

.pinnedVisualize {
    animation: pinnedRipple 1000ms ease-in-out;
}

@keyframes pinnedRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.408);
    }
    10% {
        background-color: rgba(105, 149, 205, 0.567);
    }
    75% {
        background-color: rgba(105, 149, 205, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

.dropIconNormal {
    width: 100%;
    max-width: 10vmax;
    height: 100%;
    max-height: 10vmax;
    cursor: pointer;
    display: flex;
    color: rgb(var(--v-theme-fileArchiveOrange));
}

.btnStyle {
    min-width: 36px;
    min-height: 36px;
    margin-left:10px;
}

.badgeEventVisualize {
    animation: badgeAppointmentRipple 1000ms ease-in-out;
}

@keyframes badgeAppointmentRipple {
    0% {
        background-color: rgba(255, 255, 255, 0.0);
    }
    10% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    75% {
        background-color: rgba(245, 160, 50, 0.6);
    }
    100% {
        background-color: rgba(255, 255, 255, 0.0);
    }
}

@media only screen and (max-width: 501px) {
    .uploadPreview {
        width: 100%;
    }
    .uploadPreviewHeader {
        width: 100%;
    }
}

@media only screen and (max-width: 900px) {
    .dialog {
        width: 90vw;
        left: 1%;
    }

    .thumbnailDisplayNormal {
        max-width: 20vmax;
        max-height: 20vmax;
    }

    .popUpRow {
        margin-left: 33%;
        margin-right: 66%;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1050px) {
    .infoIcon {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1110px) {
    .timeAndDate {
        display: none;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1200px) {
    .titleText {
        font-size: small;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1300px) {
    .timeAndDate {
        display: none;
    }

    .titleText {
        font-size: medium;
    }
}

@media only screen and (min-width: 901px) and (max-width: 1500px) {
    .titleText {
        font-size: large;
    }
}

.iconToBlue {
    filter: brightness(0) saturate(100%) invert(49%) sepia(95%) saturate(213%) hue-rotate(173deg) brightness(98%) contrast(95%);
}

.iconToGreen {
    filter: brightness(0) saturate(100%) invert(47%) sepia(84%) saturate(398%) hue-rotate(69deg) brightness(96%) contrast(96%);
}
.overlayFontSize{
    font-size: 16px;
    color: black;
}
.menu-list .v-list-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: .1rem, .5rem;
}

.custom-select .v-list-item.d-flex.flex-row {
  flex-direction: row !important;
  align-items: center !important;
  justify-content: space-between !important;
}
</style>
