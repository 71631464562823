<template>
    <v-dialog
        v-model="showDeleteDialog"
        scrim="rgba(0,0,0,0.8)"
        persistent
        max-width="400"
    >
        <v-card
            class="cardStyle"
            style="overflow: hidden; border-radius: 8px"
        >
            <v-card-title
                class="d-flex align-center justify-space-between py-2 px-4 mb-2"
                style="color:white; background-color: rgb(var(--v-theme-chatColor))"
            >
                <p>
                    Nachricht löschen?
                </p>
                <v-btn
                    size="x-small"
                    variant="text"
                    elevation="0"
                    class="pa-0"
                    @click="closeDeleteDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon20 iconToWhite"
                        alt="schließen"
                    >
                </v-btn>
            </v-card-title>
            <v-card-text>
                <p
                    tabindex="0"
                    class="mb-0"
                    style="width: fit-content"
                >
                    Wollen Sie diese Nachricht wirklich löschen?
                </p>
            </v-card-text>
            <v-card-actions class="d-flex justify-space-between">
                <v-btn
                    class="optionBtn text-none"
                    style="background-color:#bdbdbd;color: white"
                    @click="closeDeleteDialog"
                >
                    <img
                        :src="cancelIcon"
                        class="icon iconToWhite mr-2"
                        alt="abbrechen"
                    >
                    Abbrechen
                </v-btn>
                <v-btn
                    
                    class="optionBtn text-none"
                    style="background-color: rgb(var(--v-theme-frot)); color: white"
                    @click="clickDelete"
                >
                    <img
                        :src="papierkorbIcon"
                        class="icon iconToWhite mr-2"
                        alt="löschen"
                    >
                    Löschen
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as backend from "../../../../api/backend";

import { mapActions, mapMutations } from "vuex";

import cancelIcon from "@/assets/Icons/FaceliftIcons/schliessen_abbrechen_zurueck.svg";
import papierkorbIcon from '@/assets/Icons/FaceliftIcons/loeschen_muelleimer.svg'

export default {
    name: "ChatRoomEventListItemDeleteDialog",
    components: {},
    props: {
        chatRoomEvent: { required: true, type: Object },
    },
    data: () => ({
        cancelIcon,
        papierkorbIcon,

        showDeleteDialog: true,
    }),
    computed: {
        ...mapMutations("snackbar", ["showSnackbar"]),
    },
    async unmounted() {
        if (this.loadedAudio) URL.revokeObjectURL(this.loadedAudio);
        if (this.loadedTeacherPic) URL.revokeObjectURL(this.loadedTeacherPic);
        if (this.loadedImage) URL.revokeObjectURL(this.loadedImage);
    },
    methods: {
        ...mapActions("chat", ["redactChatRoomEvent"]),
        clickDelete() {
            this.deleteMessage();
            this.closeDeleteDialog();
        },
        async deleteMessage() {
            try {
                await this.redactChatRoomEvent(this.chatRoomEvent._id);
            } catch {
                this.showSnackbar({ message: "Löschen der Nachricht fehlgeschlagen", color: 'error'})
            }
        },
        closeDeleteDialog() {
            this.$emit("closeDialog");
        },
    },
};
</script>


<style lang="scss" scoped>
.optionBtn {
    border-radius: 8px;
    min-width: 35px !important;
    height: 35px !important;
}

.headlineText {
    color: rgb(var(--v-theme-dunkelgrau));
    margin-bottom: 0;
    font-weight: bold;
}

.iconToWhite {
    filter: brightness(0) saturate(100%) invert(97%) sepia(97%) saturate(0%) hue-rotate(36deg) brightness(104%) contrast(105%);
}

.icon {
    width: 15px;
    height: 15px;
}
</style>
